import {
	ManageAthleteDto,
	Gender,
	ManageUserDto,
	FilesClient,
	RoleType,
} from "../../Api";

import {
	InputField,
	DateField,
	SelectField,
	RadioField,
	PictureField,
	NumberField,
} from "../../components/InputFormikFiled";
import {
	BeltTrans,
	GenderTrans,
	RoleTypeTrans,
} from "../../utilities/Translations";
import { Form, Formik, FormikErrors } from "formik";
import { formatPhoneNumber, formatProperName } from "../../components/utils";
import { Icons } from "../../utilities/icons";
import { useState } from "react";
import { submitWithFile } from "../../components/Fields/PictureField";
import { useClient } from "../../components/UseClient";
import { FormProps } from "../../utilities/typeUtils";
import * as Yup from "yup";
import {
	CsActionStack,
	CsGrid,
	CsSubmitButton,
} from "../../components/CsElements";
import { athleteSchema } from "../../utilities/yupUtils";
import FormLayout, { FormSection } from "../../components/UI/FormLayout";
import Button from "../../components/UI/Button";
import { AddressFields } from "../../components/CsForm";

type UserAthleteFormProps = {
	values: ManageAthleteDto;
	// schema: any;
	errors: FormikErrors<ManageUserDto>;
	// touched: FormikTouched<ManageUserDto>;
	setPictureValue?: React.Dispatch<React.SetStateAction<File | undefined>>;
	next: () => void | Promise<any>;
	prev: () => void | Promise<any>;
};

type AthleteFieldsProps = {
	values: ManageAthleteDto;
	// schema: any;
	// errors: FormikErrors<ManageUserDto>;
	// touched: FormikTouched<ManageUserDto>;
	setPictureValue?: React.Dispatch<React.SetStateAction<File | undefined>>;

	profilePicture?: string;
};

export function AthleteForm({
	initialValues,
	isLoading,
	onSubmit,
	action,
}: FormProps<ManageAthleteDto>) {
	const [profilePicture, setProfilePicture] = useState<File>();
	const client = useClient(FilesClient);

	return (
		<>
			<Formik<ManageAthleteDto>
				validationSchema={athleteSchema}
				initialValues={{ ...initialValues }}
				onSubmit={async (values) => {
					await submitWithFile(
						client,
						values,
						profilePicture,
						"profilePictureId",
						onSubmit
					);
				}}
			>
				{({
					values,
					errors,
					touched,
					isSubmitting,
					setFieldValue,
					validateForm,
				}) => (
					<Form>
						<FormLayout
							actions={
								<>
									<Button submit isLoading={isLoading}>
										{action == "create"
											? "Crea"
											: "Modifica"}
									</Button>
								</>
							}
						>
							<>
								<FormSection title="Informazioni Generali">
									<div className="flex flex-col gap-4">
										<AthleteFields
											values={values}
											setPictureValue={setProfilePicture}
										/>
									</div>
								</FormSection>

								{values?.userRoleType === RoleType.Athlete ? (
									<FormSection title="Informazioni atleta">
										<div className="flex flex-col gap-4">
											<DateField
												id={
													"medicalCertificateDeadline"
												}
												name={
													"medicalCertificateDeadline"
												}
												label="Scadenza Certificato Medico"
												icon={Icons.Deadline}
												placeholder="gg/mm/aaaa	"
											/>

											<div className="flex gap-4 max-md:flex-col w-full flex-1 ">
												<span className="flex-1">
													<SelectField
														icon={Icons.Belt}
														id={"belt"}
														name={"belt"}
														label="Grado"
														required
														options={BeltTrans}
														isInt
													/>
												</span>
												<span className="flex-1">
													<NumberField
														icon={Icons.Weight}
														id={"weight"}
														name={"weight"}
														label="Peso"
														min={0}
														precision={2}
														required
														rightElement="Kg"
													/>
												</span>
												<span className="flex-2">
													<NumberField
														icon={Icons.Point}
														id={"userPoint"}
														name={"userPoint"}
														label="Punti Atleta"
														min={0}
														required
													/>
												</span>
											</div>
										</div>
									</FormSection>
								) : null}
								<FormSection title="Residenza">
									<div className="flex flex-col gap-4">
										<AddressFields
											property={"residenceAddress"}
										/>
									</div>
								</FormSection>
							</>
						</FormLayout>
					</Form>
				)}
			</Formik>
		</>
	);
}


export function AthleteFields({
	values,
	setPictureValue,
	filedsPrefix,
}: AthleteFieldsProps & {
	filedsPrefix?: string;
}) {
	const minH = "6.2rem";
	const numOfColumn = 12;

	function prefixProperties(property: string) {
		return filedsPrefix != undefined
			? filedsPrefix + "." + property
			: property;
	}

	return (
		<>
			<PictureField
				label=""
				className="w-24 h-24"
				// ={{
				// 	name: values?.surname + " " + values?.name,
				// }}avatarProps
				id={prefixProperties("profilePictureId")}
				name={prefixProperties("profilePictureId")}
				setFile={setPictureValue!}
			/>
			<InputField
				icon={Icons.Name}
				id={prefixProperties("name")}
				name={prefixProperties("name")}
				label="Nome Atleta"
				placeholder="Inserisci Nome"
				format={(v) => v.charAt(0).toUpperCase() + v.substring(1)}
				required
			/>
			<InputField
				icon={Icons.Surname}
				id={prefixProperties("surname")}
				name={prefixProperties("surname")}
				placeholder="Inserisci Cognome"
				label="Cognome Atleta"
				format={formatProperName}
				required
			/>

			<RadioField
				icon={Icons.Gender}
				id={prefixProperties("gender")}
				name={prefixProperties("gender")}
				label="Sesso"
				defaultValue={Gender.Male}
				required
				options={Object.values(Gender)}
				translations={GenderTrans}
			/>
			<DateField
				id={prefixProperties("birth")}
				name={prefixProperties("birth")}
				label="Data di nascita"
				icon={Icons.Birth}
				required
				placeholder="Inserisci Data"
			/>
			<InputField
				icon={Icons.Taxid}
				id={prefixProperties("taxId")}
				name={prefixProperties("taxId")}
				label="Codice Fiscale"
				placeholder="VVVNNN00S00L222C"
				required
				format={(c) => c.substring(0, 16).toUpperCase()}
			/>
			<InputField
				icon={Icons.PhoneNumber}
				id={prefixProperties("phoneNumber")}
				name={prefixProperties("phoneNumber")}
				label="Telefono"
				placeholder="+39 111 222 3333"
				format={formatPhoneNumber}
			/>
			<SelectField
				icon={Icons.AccountType}
				id={prefixProperties("userRoleType")}
				name={prefixProperties("userRoleType")}
				label="Tipo di Utente"
				required
				options={RoleTypeTrans}
				isInt
				placeholder="Seleziona tipologia"
			/>

			{/* {values?.userRoleType == 0 ? (
				<>
					<SelectField
						icon={Icons.Belt}
						id={prefixProperties("belt")}
						name={prefixProperties("belt")}
						label="Grado"
						isRequired
						options={BeltTrans}
						isInt
					/>

					<DateField
						id={prefixProperties("medicalCertificateDeadline")}
						name={prefixProperties("medicalCertificateDeadline")}
						label="Scadenza Certificato Medico"
						icon={Icons.Deadline}
						placeholder="gg/mm/aaaa	"
					/>
					<NumberField
						icon={Icons.Weight}
						id={prefixProperties("weight")}
						name={prefixProperties("weight")}
						label="Peso"
						min={0}
						precision={2}
						isRequired
						rightElement="Kg"
					/>
					<NumberField
						icon={Icons.Point}
						id={prefixProperties("userPoint")}
						name={prefixProperties("userPoint")}
						label="Punti Atleta"
						min={0}
						isRequired
					/>
				</>
			) : null} */}

			{/* <CsGrid templateColumns={`repeat(${numOfColumn}, 1fr)`}>
				<RowTitleForm
					title="Inormazioni Generali"
					column={numOfColumn}
				/>

				<GridItem colSpan={numOfColumn} minH={minH}>
					<HStack>
						<Box flex="2">
							<PictureField
								label=""
								avatarProps={{
									name: values?.surname + " " + values?.name,
								}}
								id={prefixProperties("profilePictureId")}
								name={prefixProperties("profilePictureId")}
								setFile={setPictureValue!}
							/>
						</Box>
						<Stack flex="2">
							<InputField
								icon={Icons.Name}
								id={prefixProperties("name")}
								name={prefixProperties("name")}
								label="Nome Atleta"
								placeholder="Inserisci Nome"
								format={(v) =>
									v.charAt(0).toUpperCase() + v.substring(1)
								}
								isRequired
							/>
							<InputField
								icon={Icons.Surname}
								id={prefixProperties("surname")}
								name={prefixProperties("surname")}
								placeholder="Inserisci Cognome"
								label="Cognome Atleta"
								format={formatProperName}
								isRequired
							/>
						</Stack>
					</HStack>
				</GridItem>

				<GridItem colSpan={numOfColumn / 3} minH={minH}>
					<InputField
						icon={Icons.Taxid}
						id={prefixProperties("taxId")}
						name={prefixProperties("taxId")}
						label="Codice Fiscale"
						placeholder="VVVNNN00S00L222C"
						isRequired
						format={(c) => c.substring(0, 16).toUpperCase()}
					/>
				</GridItem>

				<GridItem colSpan={numOfColumn / 3} minH={minH}>
					<InputField
						icon={Icons.PhoneNumber}
						id={prefixProperties("phoneNumber")}
						name={prefixProperties("phoneNumber")}
						label="Telefono"
						placeholder="+39 111 222 3333"
						format={formatPhoneNumber}
					/>
				</GridItem>

				<GridItem colSpan={numOfColumn / 3} minH={minH}>
					<SelectField
						icon={Icons.AccountType}
						id={prefixProperties("userRoleType")}
						name={prefixProperties("userRoleType")}
						label="Tipo di Utente"
						isRequired
						options={RoleTypeTrans}
						isInt
						placeholder="Seleziona tipologia"
					/>
				</GridItem>
				<GridItem colSpan={numOfColumn / 3} minH={minH}>
					<DateField
						id={prefixProperties("birth")}
						name={prefixProperties("birth")}
						label="Data di nascita"
						icon={Icons.Birth}
						isRequired
						placeholder="Inserisci Data"
					/>
				</GridItem>

				<GridItem colSpan={numOfColumn / 3} minH={minH}>
					<RadioField
						icon={Icons.Gender}
						id={prefixProperties("gender")}
						name={prefixProperties("gender")}
						label="Sesso"
						defaultValue={Gender.Male}
						isRequired
						options={Object.values(Gender)}
						translations={GenderTrans}
					/>
				</GridItem>

				{values?.userRoleType == 0 ? (
					<>
						<RowTitleForm title="Atleta" column={numOfColumn} />

						<GridItem colSpan={6} minH={minH}>
							<SelectField
								icon={Icons.Belt}
								id={prefixProperties("belt")}
								name={prefixProperties("belt")}
								label="Grado"
								isRequired
								options={BeltTrans}
								isInt
							/>
						</GridItem>

						<GridItem colSpan={6} minH={minH}>
							<DateField
								id={prefixProperties(
									"medicalCertificateDeadline"
								)}
								name={prefixProperties(
									"medicalCertificateDeadline"
								)}
								label="Scadenza Certificato Medico"
								icon={Icons.Deadline}
								placeholder="gg/mm/aaaa	"
							/>
						</GridItem>
						<GridItem colSpan={3} minH={minH}>
							<NumberField
								icon={Icons.Weight}
								id={prefixProperties("weight")}
								name={prefixProperties("weight")}
								label="Peso"
								min={0}
								precision={2}
								isRequired
								rightElement="Kg"
							/>
						</GridItem>
						<GridItem colSpan={3} minH={minH}>
							<NumberField
								icon={Icons.Point}
								id={prefixProperties("userPoint")}
								name={prefixProperties("userPoint")}
								label="Punti Atleta"
								min={0}
								isRequired
							/>
						</GridItem>
					</>
				) : null}

				<AddressFields
					property={prefixProperties("residenceAddress")}
					title="Residenza"
				/>
			</CsGrid> */}
		</>
	);
}
