import { useMemo } from "react";
import { CSClientOptions } from "../Api";

export function useClient<T>(
  constructor: new (options: CSClientOptions) => T
) {
  const accessToken = localStorage.getItem("UserJWT") ?? "";
  return useMemo(
    () =>
      new constructor({
        accessToken: accessToken ?? undefined,
      }),
    [accessToken, constructor]
  );
}
