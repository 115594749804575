import { ManageQuoteDto } from "../../../Api";
import { Form, Formik } from "formik";
import { DateField, NumberField } from "../../../components/InputFormikFiled";
import * as Yup from "yup";
import { FormProps } from "../../../utilities/typeUtils";
import FormLayout, { FormSection } from "../../../components/UI/FormLayout";
import Button from "../../../components/UI/Button";

export default function QuoteForm({
	initialValues,
	action = "create",
	isLoading,
	onSubmit,
}: FormProps<ManageQuoteDto>) {
	const schema = Yup.object().shape({
		import: Yup.number().required("Questo campo è obbligatorio"),
		importDate: Yup.date().required("Questo campo è obbligatorio"),
	});

	return (
		<Formik<ManageQuoteDto>
			validationSchema={schema}
			initialValues={{ ...initialValues }}
			onSubmit={onSubmit}
		>
			{({
				values,
				errors,
				touched,
				isSubmitting,
				handleBlur,
				setFieldValue,
			}) => (
				<Form>
					<FormLayout
						actions={
							<>
								<Button submit isLoading={isLoading}>
									{action == "create" ? "Crea" : "Modifica"}
								</Button>
							</>
						}
					>
						<FormSection title="Data e importo">
							<div className="flex flex-col gap-4">
								<DateField
									id={"importDate"}
									name={"importDate"}
									label="Data di importo"
									icon={<></>}
									required
									placeholder="Inserisci Data"
								/>
								<DateField
									id={"deadline"}
									name={"deadline"}
									label="Scadenza"
									icon={<></>}
									required
									placeholder="Inserisci scadenza"
								/>
								<NumberField
									id="import"
									name="import"
									label="Importo"
									required
									min={0}
									precision={2}
									rightElement={"€"}
								/>
							</div>
						</FormSection>
					</FormLayout>
			
				</Form>
			)}
		</Formik>
	);
}
