
import { ContestsClient, ContestDto } from "../../Api";
import { useClient } from "../../components/UseClient";
import { useNavigate } from "react-router-dom";
import { DeleteModalProps } from "../../utilities/typeUtils";
import useToastMutation from "../../components/Hooks/useToastMutation";
import { Modal } from "../../components/UI/Modal";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Button from "../../components/UI/Button";

export function DeleteContestModal({
	isOpen,
	entity,
	onClose,
}: DeleteModalProps<ContestDto>) {
	const navigate = useNavigate();
	const client = useClient(ContestsClient);

	const { mutate: deleteMutation, isLoading } = useToastMutation(
		() => client.deleteContest(entity.id),
		() => {
			onClose();
			navigate("/contests");
		},
		["contests"],
		"Evento Rimosso"
	);

	return (
		<>
			<Modal
				title="Elimina Evento"
				// message={
				// 	<Highlight
				// 		query={[`${entity.name}`]}
				// 		styles={{
				// 			color: `red`,
				// 			fontWeight: "bold",
				// 		}}
				// 	>
				// 		{`Sei sicuro di voler eliminare l'evento ${entity.name}?`}
				// 	</Highlight>
				// }
				icon={
					<div className="rounded-full bg-red-100 p-2">
						<ExclamationTriangleIcon
							className="h-6 w-6 text-red-600"
							aria-hidden="true"
						/>
					</div>
				}
				isOpen={isOpen}
				onClose={onClose}
			>
				<div className="flex flex-col gap-3">
					<p>
						Sei sicuro di voler eliminare l'evento{" "}
						<span className="font-bold">{entity.name}</span>?
					</p>
					<div className="flex w-full justify-end gap-2 items-center">
						<Button onClick={onClose} type="secondary">
							Annulla
						</Button>
						<Button
							isLoading={isLoading}
							onClick={() => deleteMutation()}
							className="bg-red-500 hover:bg-red-400"
						>
							Elimina
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
}
