import {
	ManageNotificationDto,
	NotificationDto,
	NotificationsClient,
} from "../../../Api";
import { useClient } from "../../../components/UseClient";
import CsModal from "../../../components/CsModal";
import NotificationForm from "./NotificationForm";
import useToastMutation from "../../../components/Hooks/useToastMutation";
import { ModalProps } from "../../../utilities/typeUtils";
import { Modal } from "../../../components/UI/Modal";
import { CheckIcon } from "@heroicons/react/24/outline";
import { Icons } from "../../../utilities/icons";

export default function NotificationModal({
	isOpen,
	onClose,
	notification,
	show,
}: ModalProps & { notification?: NotificationDto; show?: boolean }) {
	const client = useClient(NotificationsClient);

	const { mutate: createMark, isLoading: isMarking } = useToastMutation(
		(values: ManageNotificationDto) => client.createNotification(values),
		onClose,
		["notifications"],
		"Notifica Aggiunta"
	);
	const { mutate: updateMark, isLoading: isUpdating } = useToastMutation(
		(values: ManageNotificationDto) =>
			client.updateNotification(notification?.id!, values),
		onClose,
		["notifications"],
		"Notifica Modificata"
	);
	const { mutate: deleteMark, isLoading: isDeleting } = useToastMutation(
		() => client.deleteNotification(notification?.id!),
		onClose,
		["notifications"],
		"Notifica Eliminata"
	);

	return (
		<Modal
			title={
				show
					? notification?.title ?? "Messaggio"
					: !!notification
					? "Modifica Notifica"
					: "Crea Notifica"
			}
			icon={
				show ? (
					<div className="rounded-full bg-green-100 p-2">
						<CheckIcon
							className="h-6 w-6 text-green-600 "
							aria-hidden="true"
						/>
					</div>
				) : (
					<div className="rounded-full bg-indigo-400 p-2 text-white">
						{!!notification ? Icons.Edit : Icons.Add}
					</div>
				)
			}
			isOpen={isOpen}
			onClose={onClose}
		>
			{show ? (
				notification?.message
			) : (
				<NotificationForm
					action={notification ? "update" : "create"}
					deleteMutation={deleteMark}
					isDeleting={isDeleting}
					isLoading={isMarking || isUpdating}
					initialValues={{
						title: notification ? notification.title : "",
						message: notification ? notification.message : "",
						fromUserId: "",
					}}
					onSubmit={!!notification ? updateMark : createMark}
				/>
			)}
		</Modal>
	);
}
