import  { useState } from "react";
import { Column } from "../CsTable";

export default function useVisibility<T>(
	persistanceKey: string | undefined,
	columns: Column<T>[]
) {
	const visibilityKey = persistanceKey + "_visibility";
	const localVisibility = localStorage.getItem(visibilityKey);
	const [visibility, _setVisibility] = useState(
		!!localVisibility
			? Array.from(localVisibility).map((x) => (x == "1" ? true : false))
			: columns.map((x) =>
					x.isVisible == undefined ? true : x.isVisible
			  )
	);

	const setVisibility = (value: boolean[]) => {
		value.toString();
		localStorage.setItem(
			visibilityKey,
			value.reduce(
				(accumulator: String, currentValue: boolean) =>
					accumulator + (currentValue ? "1" : "0"),
				""
			)
		);
		_setVisibility(value);
	};

	return { visibility: visibility, setVisibility: setVisibility };
}
