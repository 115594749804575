import { Select, SelectProps } from "@chakra-ui/react";
import { SubsidiaryDto, SubsidiariesClient } from "../../Api";
import { useQuery } from "@tanstack/react-query";
import { useClient } from "../UseClient";
import { FormControl, FormControlProps } from "../FormControl2";

type Props = FormControlProps & SelectProps;
export function SubsidiaryField({
	id,
	name,
	label,
	hint,
	icon,
	onChange,
	...rest
}: Props) {
	return (
		<>
			<FormControl
				id={id}
				name={name}
				label={label}
				hint={hint}
				icon={icon}
				required={rest.required}
				disabled={rest.disabled}
			>
				{({ field, helper }) => (
					<SubsidiarySelect
						id={id}
						border="2px solid gray"
						borderColor="gray.300"
						{...rest}
						{...field}
						subsidiary={field.value}
						setSubsidiary={(i) => {
							helper.setValue(i?.id);
						}}
					/>
				)}
			</FormControl>
		</>
	);
}
export function SubsidiarySelect({
	subsidiary,
	setSubsidiary,
	dontInclude,
	placeholderText,
	...rest
}: {
	subsidiary: SubsidiaryDto | string | undefined;
	setSubsidiary: (subsidiary: SubsidiaryDto | undefined) => void;
	dontInclude?: string[];
	placeholderText?: string;
} & SelectProps) {
	const subsidiaryClient = useClient(SubsidiariesClient);

	const { data: subsidiarys, isLoading } = useQuery({
		queryKey: ["subsidiaries"],
		queryFn: () =>
			subsidiaryClient
				.getAllSubsidiaries(undefined, undefined, undefined, undefined)
				.then((r) => r.result),
	});
	return (
		<Select
			minW="fit-content"
			value={
				subsidiary
					? typeof subsidiary == "string"
						? subsidiary
						: subsidiary?.id
					: "placeholder"
			}
			onChange={(x) =>
				setSubsidiary(
					subsidiarys?.items.find(
						(y) => y.id == x.currentTarget.value
					)
				)
			}
			{...rest}
		>
			<option value="placeholder">
				{placeholderText ?? "Seleziona sede"}
			</option>
			{subsidiarys?.items
				.filter((x) => !dontInclude?.includes(x.id))
				.map((x) => (
					<option value={x.id} key={"select" + x.id}>
						{x.name}
					</option>
				))}
		</Select>
	);
}
