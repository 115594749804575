import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export default function Spinner() {
	return (
		<div className="grid place-items-center w-full h-[80vh]">
			<AiOutlineLoading3Quarters className="animate-spin" />
		</div>
	);
}
