import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ContestsClient, ContestDto } from "../../Api";

import { ContestModal } from "./ContestModal";
import { DeleteContestModal } from "./DeleteContestModal";
import ContestData from "./ContestData";
import { useClient } from "../../components/UseClient";
import GetUser, { AccessLevelType } from "../../components/GetUser";
import { useUI } from "../../UiContext";
import { useNavigate } from "react-router-dom";
import { CsItemButton } from "../../components/CsElements";
import { isAfter } from "date-fns";
import useDisclosure from "../../components/Hooks/useDisclosure";
import useFilter from "../../components/Hooks/useFilter";
import { useDebounce } from "../../components/Hooks/useDebounce";
import { SearchBar } from "../../components/UI/Table";
import { Icons } from "../../utilities/icons";
import Button from "../../components/UI/Button";
import { motion } from "framer-motion";

export type ContestAction =
	| "update"
	| "delete"
	| "entrant"
	| "signup"
	| "unsignup";

export default function ContestPage() {
	const [changeContest, setChangeContest] = useState<ContestDto | undefined>(
		undefined
	);
	const navigate = useNavigate();
	const { filterBy, setFilterBy } = useFilter(
		"contest",
		localStorage.getItem("contest") ?? ""
	);
	const client = useClient(ContestsClient);

	const { data, isLoading } = useQuery(
		["contests", filterBy],
		async () =>
			await client
				.getContests(filterBy, "+date", undefined, undefined)
				.then((r) => r.result)
	);
	const { AccessLevel, currentUser } = GetUser();

	const {
		isOpen: isCreateContestOpen,
		onOpen: onCreateContestOpen,
		onClose: onCreateContestClose,
	} = useDisclosure();

	const {
		isOpen: isDeleteContestOpen,
		onOpen: onDeleteContestOpen,
		onClose: onDeleteContestClose,
	} = useDisclosure();

	const {
		isOpen: isUpdateContestOpen,
		onOpen: onUpdateContestOpen,
		onClose: onUpdateContestClose,
	} = useDisclosure();

	const {
		isOpen: isSignContestOpen,
		onOpen: onSignContestOpen,
		onClose: onSignContestClose,
	} = useDisclosure();

	const {
		isOpen: isUnSignContestOpen,
		onOpen: onUnSignContestOpen,
		onClose: onUnSignContestClose,
	} = useDisclosure();

	if (localStorage.getItem("ContestId") != undefined)
		navigate(`/contests/${localStorage.getItem("ContestId")}`);

	const handleSearch = useDebounce(
		(v: React.ChangeEvent<HTMLInputElement>) => {
			// Perform search operation with the debounced term
			setFilterBy(
				v.target.value ? v.target.value.replaceAll(" ", "") : undefined
			);
		},
		300
	);

	return (
		<>
			<div className="flex gap-4 pb-8 max-md:flex-col ">
				{AccessLevel >= AccessLevelType.Admin ? (
					<Button onClick={() => navigate("create")}>
						{Icons.Add}Crea
					</Button>
				) : null}

				<SearchBar
					defaultValue={filterBy ?? ""}
					onChange={handleSearch}
					containerClass="max-w-[50%] min-h-[30px]"
					// className="w-full"
				/>
			</div>
			<ul
				role="list"
				className="grid grid-cols-1 gap-6 max-md:gap-y-24 sm:grid-cols-2 lg:grid-cols-3"
			>
				{!data || data.numOfItems <= 0 ? (
					<div className="text-xl">Non Sono Presenti Eventi</div>
				) : (
					data?.items.map((contest: ContestDto, i) => (
						<li
							key={"showData-" + contest.name}
							className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow relative"
						>
							<motion.div
								initial={{ y: 10, opacity: 0 }}
								animate={{ y: 0, opacity: 1 }}
								transition={{
									duration: 0.3,
									delay: 0.1 * i,
								}}
							>
								<ContestData
									contest={contest}
									column={2}
									// handleChange={handleChange}
									// style={{ aspectRatio: "1/1" }}
								/>
								{/* <div className="flex w-full items-center justify-between space-x-6 p-6"> */}
								<>
									<div className="flex justify-between px-2 align-items-center bg-transparent absolute w-full pt-2">
										<div className="opacity-60">
											Da{" "}
											{contest.createdByAssociation.name}
										</div>

										{contest.createdByAssociation
											.associationId ==
											currentUser?.associationId ||
										AccessLevel ==
											AccessLevelType.SuperAdmin ? (
											<CsItemButton
												entity={contest}
												editTooltip="Evento concluso"
												editDisable={
													!isAfter(
														new Date(contest?.date),
														new Date()
													)
												}
												editFunction={() => {
													navigate(
														`${contest.id}/edit`
													);
													// setChangeContest(contest);
													// onUpdateContestOpen();
												}}
												deleteFunction={() => {
													setChangeContest(contest);
													onDeleteContestOpen();
												}}
											/>
										) : null}
									</div>
								</>
							</motion.div>
						</li>
					))
				)}
			</ul>

			{changeContest ? (
				<DeleteContestModal
					isOpen={isDeleteContestOpen}
					entity={changeContest!}
					onClose={onDeleteContestClose}
				/>
			) : null}
		</>
	);
}
