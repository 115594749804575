import { ManageNotificationDto } from "../../../Api";
import { GridItem } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import {
	InputField,
	TextareaField,
} from "../../../components/InputFormikFiled";
import * as Yup from "yup";
import { formatProperName } from "../../../components/utils";
import { Icons } from "../../../utilities/icons";
import {
	CsActionStack,
	CsDeleteButton,
	CsGrid,
	CsSubmitButton,
} from "../../../components/CsElements";
import GetUser from "../../../components/GetUser";
import { DeletingButtonProps, FormProps } from "../../../utilities/typeUtils";

export default function NotificationForm({
	initialValues,
	action = "create",
	isLoading,
	onSubmit,
	isDeleting,
	deleteMutation,
}: FormProps<ManageNotificationDto> & DeletingButtonProps) {
	const { currentUser } = GetUser();
	const minH = "6.2rem";
	const numOfColumn = 1;

	const schema = Yup.object().shape({
		title: Yup.string()
			.min(3, "Deve essere lungo almeno 3 caratteri")
			.required("Questo campo è obbligatorio"),
		message: Yup.string().required("Questo campo è obbligatorio"),
	});

	return (
		<Formik<ManageNotificationDto>
			validationSchema={schema}
			initialValues={{ ...initialValues }}
			onSubmit={(values) =>
				onSubmit({ ...values, fromUserId: currentUser?.id! })
			}
		>
			{({
				values,
				errors,
				touched,
				isSubmitting,
				handleBlur,
				setFieldValue,
			}) => (
				<Form>
					<CsGrid templateColumns={`repeat(${numOfColumn}, 1fr)`}>
						{/* nome---------------------------------------- */}
						<GridItem colSpan={4} minH={minH}>
							<InputField
								icon={Icons.Title}
								id="title"
								name="title"
								label="Titolo"
								placeholder="Inserisci titolo"
								required
								format={formatProperName}
							/>
						</GridItem>
						<GridItem colSpan={4} minH={minH}>
							<TextareaField
								icon={Icons.Message}
								id="message"
								name="message"
								label="Messaggio"
								placeholder="Inserisci messaggio"
								required
							/>
						</GridItem>
					</CsGrid>

					{/* Button---------------------------------------- */}
					<CsActionStack>
						<CsSubmitButton isLoading={isLoading || isSubmitting}>
							{action == "update" ? "Modifica" : "Crea"}
						</CsSubmitButton>
						{action == "update" ? (
							<CsDeleteButton
								onClick={deleteMutation}
								isLoading={isDeleting}
							/>
						) : null}
					</CsActionStack>
				</Form>
			)}
		</Formik>
	);
}
