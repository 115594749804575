import { Fragment, useState, PropsWithChildren } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
	Bars3Icon,
	BellIcon,
	CalendarIcon,
	ChartPieIcon,
	Cog6ToothIcon,
	DocumentDuplicateIcon,
	FolderIcon,
	HomeIcon,
	UsersIcon,
	XMarkIcon,
} from "@heroicons/react/24/outline";

import {
	ChevronDownIcon,
	MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import GetUser, { AccessLevelType } from "./components/GetUser";
import { Icons } from "./utilities/icons";
import { useNavigate, useLocation } from "react-router-dom";
import {
	GetAvatarInitial,
	getFileUrl,
	getIdentifier,
} from "./components/utils";
import { useQuery } from "@tanstack/react-query";
import { useClient } from "./components/UseClient";
import {
	FilesClient,
	AccountType,
	Feature,
	AssociationDto,
	FileRecordDto,
} from "./Api";
import { Link } from "react-router-dom";
import { CSBreadcrumbs } from "./components/Breadcrumbs";
import Separator from "./components/UI/Separator";
import { BiLogOutCircle } from "react-icons/bi";
import { FaHome, FaUserCircle } from "react-icons/fa";
import {
	FaBuilding,
	FaCartShopping,
	FaDumbbell,
	FaGear,
} from "react-icons/fa6";
import {
	MdEmojiEvents,
	MdFamilyRestroom,
	MdOutlineSchedule,
} from "react-icons/md";
import { IoPeople } from "react-icons/io5";
import { BsViewList } from "react-icons/bs";
import DarkLightButton, { darkClasses } from "./components/DarkLightButton";

export type RouteType = {
	name: string;
	href: string;
	icon: JSX.Element;
	// page?: JSX.Element;
	accessLevel?: AccessLevelType;
	// children?: {
	// 	page: JSX.Element;
	// };
};
type RouteFn = (association?: AssociationDto) => RouteType[];
const navigation: RouteFn = (association?: AssociationDto) => [
	{
		name: "Dashboard",
		href: "/",
		icon: <FaHome className="w-6 h-6" />,
		current: true,
	},
	{
		name: "Palestre",
		href: "/associations",
		icon: <FaDumbbell className="w-6 h-6" />,
		accessLevel: AccessLevelType.SuperAdmin,
	},
	...(!!association &&
	association.features.includes(Feature.AccountsController)
		? [
				{
					name: "Accounts",
					href: "/accounts",
					icon: <FaGear className="w-6 h-6" />,
					accessLevel: AccessLevelType.Admin,
				},
		  ]
		: []),
	...(!!association && association.features.includes(Feature.Events)
		? [
				{
					name: "Eventi",
					href: "/contests",
					icon: <MdEmojiEvents className="w-6 h-6" />,
				},
		  ]
		: []),
	{
		name: "Atleti",
		href: "/athletes",
		icon: <IoPeople className="w-6 h-6" />,
	},
	...(!!association && association.features.includes(Feature.FamilyAccount)
		? [
				{
					name: "Famiglie",
					href: "/families",
					icon: <MdFamilyRestroom className="w-6 h-6" />,
				},
		  ]
		: []),
	...(!!association && association.features.includes(Feature.Calendar)
		? [
				{
					name: "Orario",
					href: "/schedule",
					icon: <MdOutlineSchedule className="w-6 h-6" />,
				},
		  ]
		: []),
	...(!!association && association.features.includes(Feature.Subsidiary)
		? [
				{
					name: "Sedi",
					href: "/subsidiaries",
					icon: <FaBuilding className="w-6 h-6" />,
				},
		  ]
		: []),
	...(!!association && association.features.includes(Feature.Items)
		? [
				{
					name: "Articoli",
					href: "/items",
					icon: <FaCartShopping className="w-6 h-6" />,
				},
		  ]
		: []),
	...(!!association && association.features.includes(Feature.Courses)
		? [
				{
					name: "Corsi",
					href: "/courses",
					icon: <BsViewList className="w-6 h-6" />,
				},
		  ]
		: []),
	// {
	// 	name: "Documents",
	// 	href: "#",
	// 	icon: DocumentDuplicateIcon,
	//
	// },
	// { name: "Reports", href: "#", icon: ChartPieIcon, current: false },
];
const teams = [
	{ id: 1, name: "Heroicons", href: "#", initial: "H", current: false },
	{ id: 2, name: "Tailwind Labs", href: "#", initial: "T", current: false },
	{ id: 3, name: "Workcation", href: "#", initial: "W", current: false },
];

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(" ");
}

export function Logo({ className }: { className?: string }) {
	const navigate = useNavigate();
	return (
		<img
			className={classNames(
				"h-11 w-auto rounded-full cursor-pointer border-2  border-black bg-indigo-400 hover:-rotate-180 transition-all",
				className ?? ""
			)}
			src="/logo.png"
			alt="Your Company"
			onClick={() => navigate("/")}
		/>
	);
	// <Box
	// 	w="60px"
	// 	mx="auto"
	// 	style={{ aspectRatio: "1/1" }}
	// 	borderRadius="0"
	// 	p="1rem"
	// 	mb="1rem"
	// 	bgImage="/logo.png"
	// 	backgroundPosition="center"
	// 	backgroundRepeat="no-repeat"
	// 	backgroundSize="cover"
	// 	cursor="pointer"
	// 	onClick={() => navigate("/")}
	// />;
}

type Props = {
	logOff: () => void;
} & PropsWithChildren;

export default function SideMenuLayout({ logOff, children }: Props) {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const { user, AccessLevel, currentUser, isLoading, isAthlete, Role } =
		GetUser();

	const filesClient = useClient(FilesClient);
	const { data, isLoading: isSeraching } = useQuery({
		queryKey: ["files", currentUser],
		queryFn: () =>
			filesClient
				.getFile(currentUser!.athlete!.profilePictureId!)
				.then((r) => r.result),
		enabled:
			!!currentUser &&
			!!currentUser?.athlete &&
			!!currentUser?.athlete?.profilePictureId,
	});
	const location = useLocation();

	const sideSize = "72"; //52, 56,60,72

	return (
		<>
			{/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
			<div className={classNames(darkClasses.bg, darkClasses.text)}>
				<Transition.Root show={sidebarOpen} as={Fragment}>
					<Dialog
						as="div"
						className="relative z-50 lg:hidden"
						onClose={setSidebarOpen}
					>
						<Transition.Child
							as={Fragment}
							enter="transition-opacity ease-linear duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity ease-linear duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-gray-900/80" />
						</Transition.Child>

						<div className="fixed inset-0 flex">
							<Transition.Child
								as={Fragment}
								enter="transition ease-in-out duration-300 transform"
								enterFrom="-translate-x-full"
								enterTo="translate-x-0"
								leave="transition ease-in-out duration-300 transform"
								leaveFrom="translate-x-0"
								leaveTo="-translate-x-full"
							>
								<Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-300"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute left-full top-0 flex w-16 justify-center pt-5">
											<button
												type="button"
												className="-m-2.5 p-2.5"
												onClick={() =>
													setSidebarOpen(false)
												}
											>
												<span className="sr-only">
													Close sidebar
												</span>
												<XMarkIcon
													className="h-6 w-6 text-white"
													aria-hidden="true"
												/>
											</button>
										</div>
									</Transition.Child>
									{/* Sidebar component, swap this element with another sidebar if you like */}
									<div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
										<div className="flex h-16 shrink-0 items-center">
											<Link
												to="/"
												className="flex gap-2 h-16 shrink-0 items-center cursor-pointer"
												onClick={() =>
													setSidebarOpen(false)
												}
											>
												<Logo />
												<div className="flex flex-col">
													<div className="flex  font-bold italic">
														<span>Bamboo</span>{" "}
														<span className="pl-2">
															Manager
														</span>
													</div>
													<span className="text-gray-400 font-bold">
														{
															currentUser
																?.association
																?.name
														}
													</span>
												</div>
											</Link>
										</div>
										<nav className="flex flex-1 flex-col">
											<ul
												role="list"
												className="flex flex-1 flex-col gap-y-7"
											>
												<li>
													<ul
														role="list"
														className="-mx-2 space-y-1"
													>
														{navigation(
															currentUser?.association
														)
															.filter(
																(x) =>
																	AccessLevel >=
																	(x.accessLevel ??
																		AccessLevelType.User)
															)
															.map((item) => {
																const selected =
																	location.pathname ==
																		item.href ||
																	location.pathname.split(
																		"/"
																	)[1] ==
																		(
																			item.href as string
																		).split(
																			"/"
																		)[1];
																// matchRoutes?.map(
																// 	(x) =>
																// 		location.pathname ==
																// 		x
																// );
																return (
																	<li
																		key={
																			item.name
																		}
																	>
																		<Link
																			to={
																				item.href
																			}
																			className={classNames(
																				selected
																					? "bg-gray-50 text-indigo-600"
																					: "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
																				"group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
																			)}
																			onClick={() =>
																				setSidebarOpen(
																					false
																				)
																			}
																		>
																			<div className="flex gap-2">
																				<div
																					className={classNames(
																						selected
																							? "text-indigo-600"
																							: "text-gray-400 group-hover:text-indigo-600",
																						"h-6 w-6 shrink-0 grid place-content-center"
																					)}
																					aria-hidden="true"
																				>
																					{
																						item.icon
																					}
																				</div>
																				<div>
																					{
																						item.name
																					}
																				</div>
																			</div>
																		</Link>
																	</li>
																);
															})}
													</ul>
												</li>

												<li className="mt-auto">
													<Link
														to="/"
														className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
													>
														<Cog6ToothIcon
															className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
															aria-hidden="true"
														/>
														Settings
													</Link>
													<Link
														to="/myprofile"
														onClick={() =>
															setSidebarOpen(
																false
															)
														}
														className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
													>
														<FaUserCircle
															className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
															aria-hidden="true"
														/>
														Il mio Profilo
													</Link>
													<Link
														to="/"
														onClick={logOff}
														className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
													>
														<BiLogOutCircle
															className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
															aria-hidden="true"
														/>
														Disconnetti
													</Link>
												</li>
											</ul>
										</nav>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>

				{/* Static sidebar for desktop */}
				<div
					className={`hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex  lg:w-60 lg:flex-col`}
				>
					{/* Sidebar component, swap this element with another sidebar if you like */}
					<div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200  px-6 pb-4">
						<Link
							to="/"
							className="flex gap-2 h-16 shrink-0 items-center cursor-pointer"
							// onClick={() => navigate("/")}
						>
							<Logo />
							<div className="flex flex-col font-bold italic">
								<span>Bamboo</span>{" "}
								<span className="pl-2">Manager</span>
							</div>
						</Link>
						<nav className="flex flex-1 flex-col">
							<ul
								role="list"
								className="flex flex-1 flex-col gap-y-7"
							>
								<li>
									<ul role="list" className="-mx-2 space-y-1">
										{navigation(currentUser?.association)
											.filter(
												(x) =>
													AccessLevel >=
													(x.accessLevel ??
														AccessLevelType.User)
											)
											.map((item) => {
												const selected =
													location.pathname ==
														item.href ||
													location.pathname.split(
														"/"
													)[1] ==
														(
															item.href as string
														).split("/")[1];
												return (
													<li key={item.name}>
														<Link
															to={item.href}
															className={classNames(
																selected
																	? "bg-gray-50 text-indigo-600 dark:text-indigo-300 dark:bg-gray-600"
																	: "text-gray-700 dark:text-gray-400 dark:hover:text-indigo-200 hover:text-indigo-600 hover:bg-gray-50 dark:hover:bg-gray-600",
																"group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
															)}
														>
															<div className="flex gap-1">
																<div
																	className={classNames(
																		selected
																			? "text-indigo-600 dark:text-indigo-400"
																			: "text-gray-400 group-hover:text-indigo-600 dark:group-hover:text-indigo-400",
																		"h-6 w-6 shrink-0 grid place-content-center"
																	)}
																	aria-hidden="true"
																>
																	{item.icon}
																</div>
																<div>
																	{item.name}
																</div>
															</div>
														</Link>
													</li>
												);
											})}
									</ul>
								</li>
								{/* <li>
									<div className="text-xs font-semibold leading-6 text-gray-400">
										Your teams
									</div>
									<ul
										role="list"
										className="-mx-2 mt-2 space-y-1"
									>
										{teams.map((team) => (
											<li key={team.name}>
												<Link
													href={team.href}
													className={classNames(
														team.current
															? "bg-gray-50 text-indigo-600"
															: "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
														"group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
													)}
												>
													<span
														className={classNames(
															team.current
																? "text-indigo-600 border-indigo-600"
																: "text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600",
															"flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white"
														)}
													>
														{team.initial}
													</span>
													<span className="truncate">
														{team.name}
													</span>
												</Link>
											</li>
										))}
									</ul>
								</li> */}
								{/* <li className="mt-auto">
									<Link
										to="#"
										className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
									>
										<Cog6ToothIcon
											className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
											aria-hidden="true"
										/>
										Settings
									</Link>
								</li> */}
							</ul>
						</nav>
					</div>
				</div>

				<div className={"lg:pl-60"}>
					<div className="sticky top-0 z-40 lg:mx-auto lg:max-w-7xl lg:px-8  bg-gray backdrop-blur-lg">
						<div className="flex  h-16 items-center gap-x-4 border-b border-gray-200  px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-0 lg:shadow-none ">
							<button
								type="button"
								className="-m-2.5 p-2.5 text-gray-700 dark:text-gray-500 lg:hidden max-lg:flex max-lg:items-center max-lg:justify-between  "
								onClick={() => setSidebarOpen(true)}
							>
								<span className="sr-only">Open sidebar</span>
								<Bars3Icon
									className="h-6 w-6"
									aria-hidden="true"
								/>
							</button>

							<div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 ">
								<div className="flex flex-1 items-center gap-x-4 lg:gap-x-6 justify-end">
									<div className="flex flex-1 h-full">
										<CSBreadcrumbs />
									</div>
									{/* <Separator /> */}
									{/* <form
										className="relative flex flex-1"
										action="#"
										method="GET"
									>
										<label
											htmlFor="search-field"
											className="sr-only"
										>
											Cerca nella pagina
										</label>
										<MagnifyingGlassIcon
											className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
											aria-hidden="true"
										/>
										<input
											id="search-field"
											className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
											placeholder="Cerca nella pagina"
											type="search"
											name="search"
										/>
									</form> */}
									{/* <button
										type="button"
										className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500  max-md:hidden"
									>
										<span className="sr-only">
											View notifications
										</span>
										<BellIcon
											className="h-6 w-6"
											aria-hidden="true"
										/>
									</button> */}
									{/* <DarkLightButton /> */}
									{/* Separator */}
									<Separator />
									<div className="text-sm font-bold max-md:hidden">
										<div>
											{currentUser?.association?.name}
										</div>
									</div>
									<AvatarMenu
										data={data}
										logOff={logOff}
										classNames="max-lg:hidden"
									/>
								</div>
							</div>
						</div>
					</div>

					<main className="py-10">
						<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
							{children}
						</div>
					</main>
				</div>
			</div>
		</>
	);
}

function AvatarMenu({
	data,
	logOff,
	classNames: classes,
}: {
	data?: FileRecordDto;
	logOff: () => void;
	classNames?: string;
}) {
	const { user, AccessLevel, currentUser, isLoading, isAthlete, Role } =
		GetUser();
	const userNavigation = [
		{ name: "Il tuo profilo", href: "/myprofile" },
		{ name: "Disconnetti", href: undefined, onClick: logOff },
	];
	return (
		<>
			<Menu as="div" className={classNames("relative", classes ?? "")}>
				<Menu.Button className="-m-1.5 flex items-center p-1.5">
					<span className="sr-only">Open user menu</span>
					{currentUser?.athlete?.profilePictureId ? (
						<img
							className="h-8 w-8 rounded-full bg-gray-50"
							src={getFileUrl(data)}
							alt=""
						/>
					) : (
						<GetAvatarInitial user={currentUser} />
					)}
					<span className="hidden lg:flex lg:items-center">
						<div className="flex align-items-center flex-col justify-start">
							<span
								className="ml-4 text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200"
								aria-hidden="true"
							>
								{getIdentifier(currentUser)}
							</span>
							<div className="justify-content-start text-xs text-gray-400 font-bold">
								{Role}
							</div>
						</div>
						<ChevronDownIcon
							className="ml-2 h-5 w-5 text-gray-400 "
							aria-hidden="true"
						/>
					</span>
				</Menu.Button>
				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
						{userNavigation.map((item) => (
							<Menu.Item key={item.name}>
								{({ active }: any) => (
									<Link
										to={item.href ?? "/"}
										onClick={item.onClick}
										className={classNames(
											active ? "bg-gray-50" : "",
											"block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer"
										)}
									>
										{item.name}
									</Link>
								)}
							</Menu.Item>
						))}
					</Menu.Items>
				</Transition>
			</Menu>
		</>
	);
}
