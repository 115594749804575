import { ManageCourseDto } from "../../../Api";
import { GridItem } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import {
	InputField as InputFormikField,
	TextareaField,
} from "../../../components/InputFormikFiled";
import * as Yup from "yup";
import { formatProperName } from "../../../components/utils";
import { Icons } from "../../../utilities/icons";
import {
	CsActionStack,
	CsGrid,
	CsSubmitButton,
} from "../../../components/CsElements";
import { FormProps } from "../../../utilities/typeUtils";

export default function CourseForm({
	initialValues,
	action = "create",
	isLoading,
	onSubmit,
}: FormProps<ManageCourseDto>) {
	const minH = "6.2rem";
	const numOfColumn = 12;

	const schema = Yup.object().shape({
		name: Yup.string()
			.min(3, "Deve essere lungo almeno 3 caratteri")
			.required("Questo campo è obbligatorio"),
		description: Yup.string().nullable(),
	});
	return (
		<Formik<ManageCourseDto>
			validationSchema={schema}
			initialValues={{ ...initialValues }}
			onSubmit={(values) => onSubmit(values)}
		>
			{({
				values,
				errors,
				touched,
				isSubmitting,
				handleBlur,
				setFieldValue,
			}) => (
				<Form>
					<CsGrid templateColumns={`repeat(${numOfColumn}, 1fr)`}>
						{/* nome---------------------------------------- */}
						<GridItem colSpan={8} minH={minH}>
							<InputFormikField
								icon={Icons.Title}
								id="name"
								name="name"
								label="Nome"
								placeholder="Inserisci nome"
								required
								format={formatProperName}
							/>
						</GridItem>

						<GridItem colSpan={12} minH={minH}>
							<TextareaField
								icon={Icons.Description}
								id="description"
								name="description"
								label="Descrizione"
								placeholder="Inserisci descrizione"
							/>
						</GridItem>

						{/* Button---------------------------------------- */}
					</CsGrid>
					<CsActionStack>
						<CsSubmitButton isLoading={isLoading || isSubmitting}>
							{action == "update" ? "Modifica" : "Crea"}
						</CsSubmitButton>
					</CsActionStack>
				</Form>
			)}
		</Formik>
	);
}
