import { GridItem, GridItemProps } from "@chakra-ui/react";
import InfoButton, {
	InfoButtonProps,
} from "../pages/contests/components/InfoButton";
import { motion } from "framer-motion";

export type AnimatedProps = InfoButtonProps & {
	numOfColumn?: number;
	elementInRow?: number;
};

export function AnimatedGridItem({ ...rest }: AnimatedProps & GridItemProps) {
	return (
		<GridItem
			// colSpan={[
			// 	12,
			// 	12,
			// 	(rest.numOfColumn ?? 12) / rest.elementInRow ?? 1,
			// ]}
			as={motion.li}
			colSpan={[12, 12, 6]}
			listStyleType="none"
			variants={{
				open: {
					y: 0,
					opacity: 1,
				},
				closed: { y: 20, opacity: 0 },
			}}
			{...rest}
		>
			<InfoButton title={rest.title} text={rest.text} icon={rest.icon} />
		</GridItem>
	);
}
