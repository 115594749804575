import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Center,
	Stack,
	Spinner,
	Tooltip,
	Flex,
	Skeleton,
	HStack,
	Button,
	Select,
	Text,
	Grid,
	PopoverTrigger,
	PopoverHeader,
	PopoverBody,
	useColorMode,
	PopoverCloseButton,
	Checkbox,
	ButtonProps,
	Box,
	PopoverContent,
	PopoverArrow,
	Popover,
	useToast,
} from "@chakra-ui/react";
import { TableButton } from "./TableButton";
import { PropsWithChildren } from "react";
import { useUI } from "../UiContext";
import { motion } from "framer-motion";
import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { FileRecordDto, SwaggerResponse } from "../Api";
import { MdNavigateBefore, MdNavigateNext, MdRefresh } from "react-icons/md";
import ActionButton from "./ActionButton";
import useFilter from "./Hooks/useFilter";
import useOrderBy from "./Hooks/useSortBy";
import useLimit from "./Hooks/useLimit";
import useOffset from "./Hooks/useOffset";
import useVisibility from "./Hooks/useVisibility";
import {
	MdKeyboardDoubleArrowRight,
	MdKeyboardDoubleArrowLeft,
} from "react-icons/md";
import { getFileUrl } from "./utils";
import { FaFileDownload } from "react-icons/fa";
import { IoOptions } from "react-icons/io5";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import { SearchBar } from "./UI/Table";

export type Column<T> = {
	id?: keyof T;
	name: string;
	isVisible?: boolean;
	hideOnMobile?: boolean;
	Cell?: (value: T) => JSX.Element | string;
};

export type Paginated<T> = {
	numOfItems: number;
	numOfPages: number;
	items: T[];
};

export type Actions<T> = (
	value: T,
	index: number,
	selected?: boolean
) => JSX.Element | string;

export type BulkActions<T> = (
	selected: T[],
	setSelectedEntities?: React.Dispatch<React.SetStateAction<T[]>>
) => JSX.Element | string;

type Props<T> = {
	tableName?: string;
	columns: Column<T>[];
	selected?: boolean;
	idProperty?: keyof T | string;
	queryKey: unknown[];
	searchPlaceholder?: string;
	emptyMessage?: string;
	options?: {
		fixedPageSize?: number;
		searchable?: boolean;
		refreshable?: boolean;
		sortable?: boolean;
		selectable?: boolean;
		canModifyColumn?: boolean;
		showNumber?: boolean;
	};
	fetchPage: (
		filterBy: string | undefined,
		orderBy: string | undefined,
		limit: number | undefined,
		offset: number | undefined
	) => Promise<SwaggerResponse<Paginated<T>>>;
	exportPage?: (
		fields: (keyof T)[] | undefined,
		filterBy: string | undefined,
		orderBy: string | undefined,
		limit: number | undefined,
		offset: number | undefined
	) => Promise<SwaggerResponse<FileRecordDto>>;
	actions?: Actions<T>;
	bulkActions?: BulkActions<T>;
	extraComponent?: JSX.Element;
	defaultLimitList?: number[];
	defaultSorting?: Sorting<T>;
	defaultFilter?: string;
	persistanceKey?: string;
	phoneTableComponent?: (entity: T, index: number) => JSX.Element;
};

export type Sorting<T> = {
	order: "+" | "-";
	sortBy: keyof T;
};

export function AnimatedTable({
	selected = true,
	index = 0,
	children,
}: { selected?: boolean; index?: number } & PropsWithChildren) {
	return (
		<motion.div
			initial={{ y: 10, opacity: 0 }}
			animate={selected ? { y: 0, opacity: 1 } : {}}
			// exit={{ y: -10, opacity: 0 }}
			transition={{ duration: 0.2, delay: 0.1 * index }}
		>
			{children}
		</motion.div>
	);
}

function OffsetButton<T>({
	data,
	myOffset,
	offset,
	setOffset,
	...rest
}: {
	data: Paginated<T> | undefined;
	myOffset: number;
	offset: number;
	setOffset: (value: number | undefined) => void;
} & ButtonProps) {
	const { color, borderRadius } = useUI();
	const pages = data?.numOfPages != undefined && data?.numOfPages > 1;
	const exists =
		myOffset != 0 &&
		data?.numOfPages != undefined &&
		myOffset <= data?.numOfPages;

	return (
		<Button
			display={pages ? "initial" : "none"}
			visibility={exists ? "initial" : "hidden"}
			_hover={{
				borderColor: `${color.secondary}`,
			}}
			fontWeight={offset + 1 == myOffset ? "bold" : "initial"}
			opacity={offset + 1 == myOffset ? "1" : "0.8"}
			onClick={() => setOffset(myOffset - 1)}
			bg={offset + 1 == myOffset ? color.primary : "transparent"}
			color={offset + 1 == myOffset ? "white" : color.primary}
			borderRadius={borderRadius.primary}
			transition="all 0.5s"
			border="1px solid white"
			borderColor="transparent"
			{...rest}
		>
			{exists ? myOffset : ""}
		</Button>
	);
}

function LimitButton({ myLimit, index }: { myLimit: number; index: number }) {
	return <option value={index}>{myLimit}</option>;
}

export function CsTable<T extends object>({
	tableName,
	columns,
	queryKey: key,
	fetchPage,
	exportPage,
	actions,
	bulkActions,
	emptyMessage = "Nessun Elemento Trovato",
	extraComponent,
	options: notOptions,
	selected = true,
	idProperty,
	searchPlaceholder = "Cerca",
	defaultLimitList,
	defaultFilter,
	defaultSorting,
	persistanceKey,
	phoneTableComponent,
}: Props<T>) {
	const { color, borderRadius } = useUI();
	const limitList = [5, 10, 20, 50];
	const [exportFile, setExportFile] = useState<boolean>(false);
	const { filterBy, setFilterBy } = useFilter(persistanceKey, defaultFilter);
	const { orderBy, setOrderBy } = useOrderBy(persistanceKey, defaultSorting);
	const { limit, setLimit } = useLimit(persistanceKey);
	const { offset, setOffset } = useOffset(persistanceKey);
	const { visibility, setVisibility } = useVisibility(
		persistanceKey,
		columns
	);
	var options = {
		searchable: true,
		refreshable: true,
		sortable: true,
		selectable: true,
		canModifyColumn: true,
		showNumber: true,
		fixedPageSize: undefined,
	};
	for (const key in notOptions) {
		if (notOptions.hasOwnProperty(key)) {
			//@ts-expect-error
			options[key] = notOptions[key];
		}
	}
	const { colorMode } = useColorMode();

	const toast = useToast();
	const [selectedEntities, setSelectedEntities] = useState<T[]>([]);

	const queryClient = useQueryClient();

	const { data, isLoading } = useQuery({
		queryKey: [...key, filterBy, orderBy, limit, offset],
		queryFn: () =>
			fetchPage(
				filterBy,
				orderBy,
				!!options.fixedPageSize
					? options.fixedPageSize
					: !!defaultLimitList
					? defaultLimitList[limit ?? 0]
					: limitList[limit ?? 0],
				offset
			).then((r) => r.result),
	});

	const { isLoading: isExporting } = useQuery({
		queryKey: [...key, "export", orderBy],
		queryFn: async () => {
			const fileds = columns
				.filter((x, i) => visibility[i] && x.id != undefined)
				.map((x) => x.id!);

			await exportPage!(fileds, undefined, orderBy, undefined, undefined)
				.then((r) => r.result)
				.then((r) => {
					const url = getFileUrl(r);
					const date = format(Date.now(), "P", { locale: it });
					if (url != undefined) {
						const a = document.createElement("a");
						a.href = url;
						a.download = (tableName || "download") + "_" + date;
						const clickHandler = () => {
							setTimeout(() => {
								URL.revokeObjectURL(url);
								a.removeEventListener("click", clickHandler);
							}, 150);
						};
						a.addEventListener("click", clickHandler, false);
						a.click();
					}
					setExportFile(false);
				});
		},
		enabled: exportPage != undefined && exportFile,
	});

	function printProperty(property: any) {
		switch (typeof property) {
			case "boolean":
				return property ? "Si" : "No";
			default:
				return property;
		}
	}

	const offsetButtonProps = {
		data: data,
		offset: offset,
		setOffset: setOffset,
	};

	const maxColumn =
		columns.length +
		(actions != undefined ? 1 : 0) +
		(!!options.showNumber || !!options.selectable ? 1 : 0);

	const tdStyle = (entity: T) =>
		selectedEntities.includes(entity)
			? {
					bg: "inherit !important",
			  }
			: {};
	return (
		<>
			<Stack gap="1.5rem">
				<HStack w="100%" gap="1rem">
					<Flex
						gap="1rem"
						flex="1"
						fontSize="sm"
						alignItems={["center", "center", "start"]}
						flexDir={["column", "column", "row"]}
					>
						{!options.fixedPageSize ? (
							<Flex gap="2" alignItems="center">
								<Text key="show">Mostra</Text>
								<Select
									fontSize="inherit"
									w={["50vw", "50vw", "fit-content"]}
									minW="fit-content"
									value={limit}
									onChange={(i) => {
										setOffset(0);
										setLimit(parseInt(i.target.value));
									}}
								>
									{(defaultLimitList != undefined
										? defaultLimitList
										: limitList
									).map((x, i) => (
										<LimitButton
											myLimit={x}
											index={i}
											key={x + i}
										/>
									))}
								</Select>
								<Text>Elementi</Text>
							</Flex>
						) : null}

						{options.searchable ? (
							<Box flex="2" maxW="fit-content">
								<SearchBar
									onChange={(v) =>
										setFilterBy(v.currentTarget.value)
									}
									defaultValue={filterBy ?? ""}
									placeholder={"Cerca evento"}
								/>
							</Box>
						) : null}

						<Flex
							gap="2"
							w="100%"
							justifyContent={["center", "center", "start"]}
						>
							{options.refreshable ? (
								<ActionButton
									tooltip="Ricarica Tabella"
									p="0"
									m="0"
									flex={["1", "1", "initial"]}
									maxW={["100%", "fit-content"]}
									size={["lg", "lg", "sm"]}
									onClick={() => {
										queryClient.invalidateQueries([
											...key,
											filterBy,
											orderBy,
											limit,
											offset,
										]);
										toast({
											title: "Tabella Aggiornata",
											status: "success",
											duration: 1000,
											isClosable: true,
										});
										// setRefetch(!refetch);
									}}
								>
									<MdRefresh />
								</ActionButton>
							) : null}

							{options.canModifyColumn ? (
								<Popover>
									<PopoverTrigger>
										<Tooltip label="Opzioni tabella">
											<Button
												borderRadius="0.5rem"
												variant="outline"
												colorScheme={color.scheme}
												fontSize="1.5rem"
												zIndex="4"
												position="relative"
												p="0"
												m="0"
												flex={["1", "1", "initial"]}
												maxW={["100%", "fit-content"]}
												size={["lg", "lg", "sm"]}
												// tooltip="Opzioni Colonne"
											>
												<IoOptions />
											</Button>
										</Tooltip>
									</PopoverTrigger>
									{/* <Portal> */}
									<PopoverContent boxShadow="2px 2px 10px gray">
										<PopoverArrow />
										<PopoverCloseButton />
										<PopoverHeader>
											Mostra/Nascondi Colonne
										</PopoverHeader>
										<PopoverBody>
											<Stack maxW="100%">
												<HStack pt="0.5rem" maxW="100%">
													<ActionButton
														fontSize="lg"
														flex="1"
														minW="0"
														onClick={() =>
															setVisibility(
																visibility.map(
																	(x) => true
																)
															)
														}
													>
														Seleziona tutti
													</ActionButton>
													<ActionButton
														minW="0"
														flex="1"
														fontSize="lg"
														onClick={() =>
															setVisibility(
																visibility.map(
																	(x) => false
																)
															)
														}
													>
														Deseleziona tutti
													</ActionButton>
												</HStack>
												<ActionButton
													mb="0.5rem"
													fontSize="lg"
													onClick={() =>
														setVisibility(
															columns.map((x) =>
																x.isVisible ==
																undefined
																	? true
																	: x.isVisible
															)
														)
													}
												>
													Reimposta colonne iniziali
												</ActionButton>
												{columns.map((x, i) => {
													return (
														<>
															<Checkbox
																key={
																	"visibility_" +
																	x.name +
																	visibility[
																		i
																	]
																}
																onChange={(
																	v
																) => {
																	var newVisibility =
																		visibility.map(
																			(
																				col,
																				num
																			) =>
																				num ==
																				i
																					? v
																							.currentTarget
																							.checked
																					: col
																		);
																	setVisibility(
																		newVisibility
																	);
																}}
																colorScheme={
																	color.scheme
																}
																defaultChecked={
																	visibility !=
																	undefined
																		? visibility[
																				i
																		  ]
																		: x.isVisible ==
																		  undefined
																		? true
																		: x.isVisible
																}
															>
																{x.name}
															</Checkbox>
														</>
													);
												})}
											</Stack>
										</PopoverBody>
									</PopoverContent>
									{/* </Portal> */}
								</Popover>
							) : null}

							{exportPage != undefined ? (
								<ActionButton
									tooltip={
										visibility.filter((x) => x).length <= 0
											? "Selezionare almeno 1 colonna"
											: "Esporta come excel"
									}
									flex={["1", "1", "initial"]}
									maxW={["100%", "fit-content"]}
									disabled={
										visibility.filter((x) => x).length <= 0
									}
									onClick={() => setExportFile(true)}
									isLoading={isExporting && exportFile}
									// fontSize="inherit"
									p="0"
									size={["lg", "lg", "sm"]}
								>
									<FaFileDownload />
								</ActionButton>
							) : null}
						</Flex>
						<HStack
							w="fit-content"
							maxW="100vw"
							flexWrap={["wrap", "wrap", "initial"]}
							gap="0rem"
							rowGap="1rem"
						>
							{!!bulkActions
								? bulkActions(
										selectedEntities,
										setSelectedEntities
								  )
								: null}

							{!!extraComponent ? extraComponent : null}
						</HStack>
					</Flex>
				</HStack>

				<TableContainer
					display={["none", "none", "initial"]}
					borderRadius="0.5rem"
					boxShadow={
						colorMode == "light"
							? "2px 2px 10px #e0e0e0"
							: "2px 2px 10px #101010"
					}
				>
					<Table
						// variant="striped"
						colorScheme="cyan"
						size="md"
						w="100%"
					>
						<Thead>
							<Tr
								bg={
									colorMode == "light" ? "#f7f7f7" : "#030303"
								}
							>
								<>
									{options.selectable ||
									options.showNumber ? (
										<Th>
											<HStack>
												{options.selectable ? (
													<Checkbox
														isChecked={
															data?.items
																.length ==
																selectedEntities.length &&
															data?.items
																.length != 0
														}
														transformOrigin="center center"
														borderColor="gray"
														onChange={(v) => {
															if (
																v.currentTarget
																	.checked
															)
																setSelectedEntities(
																	data?.items
																		? [
																				...data?.items,
																		  ]
																		: []
																);
															else
																setSelectedEntities(
																	[]
																);
														}}
													/>
												) : null}
												{options.showNumber ? (
													<Text p="0.2rem">N.</Text>
												) : null}
											</HStack>
										</Th>
									) : null}
									{columns
										.filter((x, i) => visibility[i])
										.map((x) => {
											return (
												<>
													{options.sortable ? (
														<TableButton
															key={
																(x.id ??
																	x.name) as string
															}
															id={x.id}
															setSort={(
																id:
																	| string
																	| undefined
															) => setOrderBy(id)}
															label={x.name}
															sortIndex={orderBy}
														/>
													) : (
														<Th textAlign="center">
															{x.name}
														</Th>
													)}
												</>
											);
										})}

									{!!actions ? <Th>Opzioni</Th> : null}
								</>
							</Tr>
						</Thead>

						<Tbody>
							<>
								{isLoading || data?.numOfItems! <= 0 ? (
									<Tr>
										<Td colSpan={maxColumn}>
											<Center
												fontSize="md"
												fontWeight="bold"
											>
												{isLoading ? (
													<Spinner />
												) : (
													emptyMessage
												)}
											</Center>
										</Td>
									</Tr>
								) : (
									data?.items.map((entity: any, i) => (
										<Tr
											{...(selectedEntities.includes(
												entity
											)
												? {
														color: "white",
														bg:
															color.primary.split(
																"."
															)[0] +
															".500 !important",
														// border: "2px solid gray",
														// fontWeight: "bold",
														// fontStyle: "italic",
												  }
												: {})}
											key={entity[idProperty]}
										>
											{options.selectable ||
											options.showNumber ? (
												<Td {...tdStyle(entity)}>
													<HStack gap="1">
														{options.selectable ? (
															<Checkbox
																isChecked={selectedEntities.includes(
																	entity
																)}
																transformOrigin="center center"
																borderColor="gray"
																onChange={(
																	v
																) => {
																	var index =
																		selectedEntities.indexOf(
																			entity
																		);
																	if (
																		index ==
																			-1 &&
																		v
																			.currentTarget
																			.checked
																	)
																		setSelectedEntities(
																			[
																				...selectedEntities,
																				entity,
																			]
																		);
																	else if (
																		!v
																			.currentTarget
																			.checked &&
																		index !=
																			-1
																	) {
																		var removed =
																			selectedEntities.splice(
																				index,
																				1
																			);
																		setSelectedEntities(
																			selectedEntities.filter(
																				(
																					x
																				) =>
																					!removed.includes(
																						x
																					)
																			)
																		);
																	}
																}}
															/>
														) : null}
														{options.showNumber ? (
															<Text>
																{i +
																	1 +
																	(!!defaultLimitList
																		? defaultLimitList[
																				limit ??
																					0
																		  ]
																		: limitList[
																				limit ??
																					0
																		  ]) *
																		offset}
															</Text>
														) : null}
													</HStack>
												</Td>
											) : null}

											{columns
												.filter((x, i) => visibility[i])
												.map((x, j) => {
													if (x.id == undefined)
														return (
															<Td
																{...tdStyle(
																	entity
																)}
																key={
																	"visibility" +
																	(x.id ??
																		x.name)
																}
															>
																<AnimatedTable
																	index={
																		i +
																		j / 3
																	}
																>
																	{x.Cell !=
																	undefined
																		? x.Cell(
																				entity
																		  )
																		: "-"}
																</AnimatedTable>
															</Td>
														);

													var ids = (
														x.id as string
													).split(".");

													var property =
														entity[ids[0]];
													if (
														property != undefined &&
														ids.length > 1
													)
														var subProperty =
															property[ids[1]];
													return (
														<Td
															{...tdStyle(entity)}
														>
															<AnimatedTable
																index={
																	i + j / 3
																}
															>
																{x.Cell !=
																undefined
																	? x.Cell(
																			entity
																	  )
																	: printProperty(
																			subProperty ??
																				entity[
																					x
																						.id
																				]
																	  ) ?? "-"}
															</AnimatedTable>
														</Td>
													);
												})}
											{!!actions ? (
												<Td {...tdStyle(entity)}>
													{actions(
														entity,
														i,
														selectedEntities.includes(
															entity
														)
													)}
												</Td>
											) : null}
										</Tr>
									))
								)}
							</>
						</Tbody>
					</Table>
				</TableContainer>

				{/* TABLE ON MOBILE -------------------------------------------------------------*/}
				<Skeleton
					isLoaded={!isLoading}
					borderRadius={"1rem"}
					overflow="hidden"
					display={["initial", "initial", "none"]}
				>
					<Box
						bg={color.primary}
						color="white"
						fontSize="xl"
						borderRadius="full"
						fontWeight="bold"
						textAlign="center"
						p="0.5rem"
					>
						{tableName ?? "Inizio"}
					</Box>
					<Flex
						flexDir="column"
						boxShadow={
							colorMode == "light"
								? "2px 2px 10px #e0e0e0"
								: "2px 2px 10px #101010"
						}
					>
						{!data?.numOfItems ||
						(!!data?.numOfItems && data?.numOfItems <= 0) ? (
							<Grid
								bg="blue.100"
								placeItems={"center"}
								fontWeight="bold"
								fontSize="md"
								style={{ aspectRatio: "3/1" }}
							>
								Nessun Elemento Trovato
							</Grid>
						) : (
							data?.items.map((entity, i) => {
								const index =
									i +
									1 +
									(!!defaultLimitList
										? defaultLimitList[limit ?? 0]
										: limitList[limit ?? 0]) *
										offset;
								return (
									<Flex
										key={"table" + i}
										borderRadius={"1rem"}
										flexDir="column"
										gap="4"
										p="3"
										mb="0.5rem"
										alignItems="center"
										bg={
											i % 2 == 0 ? "blue.100" : "gray.100"
										}
									>
										{!!phoneTableComponent ? (
											phoneTableComponent(entity, index)
										) : (
											<>
												<Flex
													w="100%"
													justifyContent="end"
												>
													{options.showNumber ? (
														<Box
															justifySelf={
																"start"
															}
															flex="1"
														>
															N.
															{index}
														</Box>
													) : null}
													{!!actions ? (
														<Flex
															justifySelf={"end"}
															flex="1"
															justifyContent="end"
														>
															{actions(entity, i)}
														</Flex>
													) : null}
												</Flex>
												{columns
													.filter(
														(x) => !x.hideOnMobile
													)
													.map((col, i) => (
														<Flex
															key={
																col.name +
																"filter"
															}
															py="0.3rem"
															w="100%"
															// justifyContent="space-between"
															// borderBottom="1px solid gray"
															alignItems="center"
															borderColor="gray.300"
														>
															<Box
																flex="1"
																justifySelf="start"
															>
																{col.name}
															</Box>
															<Flex
																flex="1"
																// textAlign="start"
																justifySelf="end"
																justifyContent="end"
															>
																{col.Cell ? (
																	col.Cell(
																		entity
																	)
																) : (
																	<>
																		{!!col.id
																			? entity[
																					col
																						.id
																			  ]
																			: "-"}
																	</>
																)}
															</Flex>
														</Flex>
													))}
											</>
										)}
									</Flex>
								);
							})
						)}
					</Flex>
					<Box
						bg={color.primary}
						color="white"
						fontSize="xl"
						borderRadius="full"
						fontWeight="bold"
						textAlign="center"
						p="0.5rem"
					>
						Fine Pagina
					</Box>
				</Skeleton>

				<HStack
					display={
						!!data?.numOfPages && data?.numOfPages <= 0
							? "none"
							: "initial"
					}
					justifyContent="center"
					borderBottomRadius={borderRadius.primary}
				>
					<NavButton
						visibility={
							offset == 0 || offset - 1 == 0
								? "hidden"
								: "initial"
						}
						onClick={() => setOffset(0)}
					>
						<MdKeyboardDoubleArrowLeft />
					</NavButton>
					<NavButton
						visibility={
							data?.numOfPages != undefined &&
							(data?.numOfPages < 1 || offset - 1 < 0)
								? "hidden"
								: "initial"
						}
						onClick={() => setOffset(offset - 1)}
					>
						<MdNavigateBefore />
					</NavButton>

					<OffsetButton myOffset={offset} {...offsetButtonProps} />
					<OffsetButton
						myOffset={offset + 1}
						{...offsetButtonProps}
					/>
					<OffsetButton
						myOffset={offset + 2}
						{...offsetButtonProps}
					/>

					<NavButton
						visibility={
							data?.numOfPages != undefined &&
							(data?.numOfPages < 1 ||
								offset + 2 > data?.numOfPages)
								? "hidden"
								: "initial"
						}
						onClick={() => setOffset(offset + 1)}
					>
						<MdNavigateNext />
					</NavButton>
					{data?.numOfPages != undefined && data?.numOfPages > 0 ? (
						<NavButton
							visibility={
								data?.numOfPages != undefined &&
								(data?.numOfPages < 1 ||
									offset + 2 >= data?.numOfPages)
									? "hidden"
									: "initial"
							}
							onClick={() => setOffset(data?.numOfPages - 1)}
						>
							<MdKeyboardDoubleArrowRight />
						</NavButton>
					) : null}
				</HStack>
			</Stack>
		</>
	);
}

export function NavButton({
	children,
	...rest
}: PropsWithChildren & ButtonProps) {
	const { color } = useUI();
	return (
		<Button
			_hover={{
				borderColor: `${color.secondary}`,
			}}
			border="1px solid white"
			borderColor="transparent"
			color={color.secondary}
			bg="transparent"
			fontSize="1.4rem"
			fontWeight="bold"
			transition="border-color 0.5s"
			borderRadius="full"
			{...rest}
		>
			{children}
		</Button>
	);
}
