import { useLocation, Link, useNavigate } from "react-router-dom";
import { useClient } from "./UseClient";
import {
	AthletesClient,
	ContestsClient,
	UsersClient,
	FamiliesClient,
	ItemsClient,
	CoursesClient,
} from "../Api";
import { useQuery } from "@tanstack/react-query";
import { useUI } from "../UiContext";
import { Icons } from "../utilities/icons";

export function CSBreadcrumbs({ className }: { className?: string }) {
	const location = useLocation();

	const athletesClient = useClient(AthletesClient);
	const contestClient = useClient(ContestsClient);
	const usersClient = useClient(UsersClient);
	const familyClient = useClient(FamiliesClient);
	const itemsClient = useClient(ItemsClient);
	const coursesClient = useClient(CoursesClient);

	let currentLink = "";
	const crumbsList = location.pathname
		.split("/")
		.filter((crumb) => crumb !== "");

	const crumbs = crumbsList.map((crumb, i) => {
		currentLink += `/${crumb}`;
		if (crumb !== "create" && crumb !== "edit")
			var fn =
				// crumb.length == 36 &&
				crumbsList[i - 1] != undefined &&
				crumbsList[i - 1] == "athletes"
					? () =>
							athletesClient
								.getAthlete(crumb)
								.then(
									(r) =>
										r.result.surname + " " + r.result.name
								)
					: crumbsList[i - 1] == "contests"
					? () =>
							contestClient
								.getContest(crumb)
								.then((r) => r.result.name)
					: crumbsList[i - 1] == "accounts"
					? () =>
							usersClient
								.getUser(crumb)
								.then((r) => r.result.email)
					: crumbsList[i - 1] == "families"
					? () =>
							familyClient
								.getFamily(crumb)
								.then((r) => r.result.familyName)
					: crumbsList[i - 1] == "items"
					? () =>
							itemsClient
								.getItem(crumb)
								.then((r) => r.result.title)
					: crumbsList[i - 1] == "courses"
					? () =>
							coursesClient
								.getCourse(crumb)
								.then((r) => r.result.name)
					: undefined;

		return (
			<Crumb
				crumb={crumb}
				currentLink={currentLink}
				index={i + 1}
				fn={fn}
			/>
		);
	});

	return (
		<nav className="flex  h-full " aria-label="Breadcrumb">
			<ol
				role="list"
				className="mx-auto flex w-full max-w-screen-xl space-x-4 pr-4 sm:pr-6 lg:pr-8"
			>
				<li className="flex">
					<div className="flex items-center">
						<Crumb index={0} currentLink="/" crumb={Icons.Home} />
					</div>
				</li>
				{crumbs}
			</ol>
		</nav>
	);
}

function Crumb({
	crumb,
	currentLink,
	index,
	fn,
}: {
	crumb: string | JSX.Element;
	currentLink: string;
	index: number;
	fn?: () => Promise<string>;
}) {
	const navigate = useNavigate();
	const { color } = useUI();
	const { data, isLoading } = useQuery({
		queryKey: ["breadcrumbs", crumb],
		queryFn: fn,
		enabled: !!fn,
	});
	const name = !!fn
		? data
		: typeof crumb == "string"
		? translate(crumb as string)
		: crumb;
	return (
		<li key={"crumb" + crumb} className="flex">
			<div className="flex items-center">
				{index != 0 ? (
					<svg
						className="h-full w-6 flex-shrink-0 text-gray-200 dark:text-gray-400 "
						viewBox="0 0 24 44"
						preserveAspectRatio="none"
						fill="currentColor"
						aria-hidden="true"
					>
						<path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
					</svg>
				) : null}
				{!(!!fn && isLoading) ? (
					<Link
						onClick={
							crumb == "contests"
								? () => localStorage.removeItem("ContestId")
								: undefined
						}
						to={currentLink}
						className="ml-4 text-sm  text-gray-500 dark:text-gray-300 hover:text-indigo-500 font-bold dark:hover:text-indigo-400"
						// aria-current={page.current ? "page" : undefined}
					>
						{name}
					</Link>
				) : (
					<div className="bg-gray-200 w-20 rounded-md h-10 animate-pulse"></div>
				)}
			</div>
		</li>
	);
}

function translate(v: string) {
	switch (v) {
		case "contests":
			return "Eventi";
		case "accounts":
			return "Accounts";
		case "athletes":
			return "Atleti";
		case "schedule":
			return "Orario";
		case "associations":
			return "Palestre";
		case "myprofile":
			return "Profilo";
		case "families":
			return "Famiglie";
		case "subsidiaries":
			return "Sedi";
		case "items":
			return "Articoli";
		case "courses":
			return "Corsi";
		case "create":
			return "Crea";
		case "edit":
			return "Modifica";
		case "createGuardians":
			return "Crea guardiano";
		case "createAthletes":
			return "Crea atleta";

		default:
			return v;
	}
}
