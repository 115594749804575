import {
	ManageItemRequestDto,
	ItemRequestsClient,
	ItemDto,
	ItemRequestDto,
} from "../../../Api";
import { useClient } from "../../../components/UseClient";
import CsModal from "../../../components/CsModal";
import ItemRequestForm from "./ItemRequestForm";
import { ModalProps } from "../../../utilities/typeUtils";
import useToastMutation from "../../../components/Hooks/useToastMutation";

export default function ItemRequestModal({
	isOpen,
	onClose,
	request,
	item,
}: ModalProps & { request?: ItemRequestDto; item?: ItemDto }) {
	const client = useClient(ItemRequestsClient);

	const { mutate: createRequest, isLoading: isRequesting } = useToastMutation(
		(values: ManageItemRequestDto) => client.createItemRequest(values),
		onClose,
		["itemRequests"],
		"Richiesta Inviata"
	);

	return (
		<CsModal
			title={!!request ? "Modifica Richiesta" : "Crea Richiesta"}
			isOpen={isOpen}
			onClose={onClose}
		>
			<ItemRequestForm
				item={item}
				action={"create"}
				initialValues={{ quantity: 1, userId: "", itemId: item?.id! }}
				isLoading={isRequesting}
				onSubmit={createRequest}
			/>
		</CsModal>
	);
}
