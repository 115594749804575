import GetUser, { AccessLevelType } from "../../components/GetUser";
import UsersTab from "./comp/UsersTab";
import { useNavigate } from "react-router-dom";

export default function UsersPage() {
	const { AccessLevel } = GetUser();
	const navigate = useNavigate();

	if (AccessLevel < AccessLevelType.Admin) navigate(-1);

	return (
		<>
			<UsersTab />
		</>
	);
}
