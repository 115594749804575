import {
	ContestRequestClient,
	RequestFrom,
	FamilyDto,
	ContestRequestDto,
} from "../../../Api";
import { useClient } from "../../../components/UseClient";
import { State } from "../../../EnumChanger/State";
import { useState } from "react";
import { Column, CsTable } from "../../../components/CsTable";

import { format } from "date-fns";
import { AthletesSelect } from "../../../components/Fields/AthletesField";
import { ContestLink } from "../../../components/TextLink";
import { TlTable } from "../../../components/UI/Table";

type Props = {
	athleteId?: string;
	family?: FamilyDto;
};

export default function ProfileRequestTab({ athleteId, family }: Props) {
	const client = useClient(ContestRequestClient);

	const [id, setId] = useState(
		athleteId ?? family?.athletes[0]?.athleteId ?? ""
	);

	const columns: Column<ContestRequestDto>[] = [
		{
			id: "contest",
			name: "Nome Evento",
			Cell: ({ contest }) => <ContestLink contest={contest} />,
		},
		{
			id: "date",
			name: "Data Richiesta",
			Cell: ({ date }) => format(new Date(date), "dd/MM/yyyy"),
		},
		{
			id: "state",
			name: "Stato Richiesta",
			Cell: ({ state }) => (
				<State state={state} isLoading={false} type="onlyIcon" />
			),
		},
	];

	const extraComponent = (
		<>
			{!!family && family.athletes.length > 0 ? (
				<AthletesSelect
					athlete={id}
					onSetAthlete={(x) => setId(x?.athleteId!)}
					customAthletes={family?.athletes!}
				/>
			) : null}
		</>
	);

	return (
		<div className="relative">
			{family?.athletes.length == 0 ? (
				<div className="font-semibold text-gray-500">
					Non sono presenti atleti in questo account
					<br /> aggiungere degli atleti prima di continuare
				</div>
			) : (
				<TlTable<ContestRequestDto>
					columns={columns}
					queryKey={["requests", id]}
					fetchPage={async (filter, sort, limit, offset) =>
						await client.getRequests(
							id,
							filter,
							sort,
							limit,
							offset,
							RequestFrom.User
						)
					}
					extraComponent={extraComponent}
					persistanceKey="requests"
					defaultSorting={{ order: "-", sortBy: "date" }}
					idProperty="contestId"
					searchPlaceholder="Cerca Richiesta"
				/>
			)}
		</div>
	);
}
