import {
	CourseDto,
	AthleteDto,
	CourseIscritionClient,
	CourseIscritionDto,
} from "../../../Api";
import { useToast } from "@chakra-ui/react";
import { BulkActions, Column, CsTable } from "../../../components/CsTable";

import { useClient } from "../../../components/UseClient";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Icons } from "../../../utilities/icons";
import AthleteCourseModal from "./AthleteCourseModal";
import { AthleteLink, CourseLink } from "../../../components/TextLink";
import Button from "../../../components/UI/Button";
import { TlTable } from "../../../components/UI/Table";
import useDisclosure from "../../../components/Hooks/useDisclosure";

type Props = {
	course?: CourseDto;
	athlete?: AthleteDto;
};

export default function AthleteCourseTab({ course, athlete }: Props) {
	const client = useClient(CourseIscritionClient);

	const { isOpen, onOpen, onClose } = useDisclosure();

	const toast = useToast();
	const queryClient = useQueryClient();
	const queryKey = ["coursesIscrition", course?.id, athlete?.athleteId];

	const athleteColumns: Column<CourseIscritionDto>[] = [
		{
			//@ts-expect-error
			id: "course.name",
			name: "Nome Corso",
			Cell: ({ course }) => <CourseLink course={course} />,
		},
		{
			id: "createdAt",
			name: "Data",
			Cell: ({ createdAt }) =>
				format(new Date(createdAt), "P", { locale: it }),
		},
	];
	const courseColumns: Column<CourseIscritionDto>[] = [
		{
			//@ts-expect-error
			id: "athlete.name",
			name: "Atleta",
			Cell: ({ athlete }) => <AthleteLink athlete={athlete} />,
		},
		{
			id: "createdAt",
			name: "Data",
			Cell: ({ createdAt }) =>
				format(new Date(createdAt), "P", { locale: it }),
		},
	];

	const { mutate: deleteMutation, isLoading } = useMutation(
		(selected: CourseIscritionDto[]) =>
			client.deleteIscritions(selected.map((x) => x.id)),
		{
			onSuccess: () => {
				toast({
					title: `Iscrizioni Eliminate`,
					status: "success",
					duration: 3000,
					isClosable: true,
				});
				queryClient.invalidateQueries(queryKey);
				queryClient.invalidateQueries(["coursesIscrition"]);
			},
			onError: () => {
				toast({
					title: "Si è verificato un errore",
					status: "error",
					duration: 3000,
					isClosable: true,
				});
				queryClient.invalidateQueries(queryKey);
				queryClient.invalidateQueries(["coursesIscrition"]);
			},
		}
	);

	const extraComponent = (
		<>
			<Button onClick={onOpen}>Iscrivi atleta</Button>
		</>
	);

	// const actions: Actions<CourseDto> = (request, index) => (
	// 	<>
	// 		{request.user.id == currentUser?.id ? (
	// 			<CsTableButton
	// 				index={index}
	// 				text={Icons.Delete}
	// 				tooltip="Info"
	// 				onClick={() => deleteRequest(request.id)}
	// 			/>
	// 		) : null}
	// 		{!!user ? (
	// 			<CsTableButton
	// 				index={index}
	// 				text={Icons.RightArrow}
	// 				tooltip="Info"
	// 				onClick={() => navigate("/items/" + request.item.id)}
	// 			/>
	// 		) : null}
	// 	</>
	// );

	const placeholder = "Cerca Richiesta";

	const bulkActions: BulkActions<CourseIscritionDto> = (
		selected,
		setSelectedEntities
	) => (
		<>
			<Button
				type="secondary"
				disabled={selected.length == 0}
				isLoading={isLoading}
				onClick={() => {
					deleteMutation(selected);
					if (setSelectedEntities) setSelectedEntities([]);
				}}
				icon={Icons.Delete}
				tooltip="Elimina Selezionati"
				className="px-2"
			>
				Elimina selezionati
			</Button>
		</>
	);

	return (
		<>
			<AthleteCourseModal
				isOpen={isOpen}
				onClose={onClose}
				athlete={athlete}
				course={course}
			/>
			<TlTable<CourseIscritionDto>
				columns={course ? courseColumns : athleteColumns}
				queryKey={queryKey}
				fetchPage={async (filter, sort, limit, offset) =>
					await (course != undefined
						? client.getAllIscritionsFromCourse(
								course?.id!,
								filter,
								sort,
								limit,
								offset
						  )
						: client.getAllIscritionsFromAthlete(
								athlete?.athleteId!,
								filter,
								sort,
								limit,
								offset
						  ))
				}
				extraComponent={extraComponent}
				options={{
					showNumber: false,
					refreshable: false,
					canModifyColumn: false,
				}}
				persistanceKey={
					"coursesIscrition" + course?.id + "_" + athlete?.athleteId
				}
				bulkActions={bulkActions}
				defaultSorting={{ order: "-", sortBy: "createdAt" }}
				// actions={actions}
				idProperty="id"
				searchPlaceholder={placeholder}
			/>
		</>
	);
}
