import React from "react";
import { classNames } from "../utils";
import { IoPerson } from "react-icons/io5";
import { FaUser } from "react-icons/fa6";
import { MdEmojiEvents } from "react-icons/md";
import { PictureType } from "../InputFormikFiled";

export default function Avatar({
	src,
	alt,
	className,
	type = "user",
}: {
	src?: string;
	alt?: string;
	className?: string;
	type?: PictureType;
}) {
	return (
		<>
			{src ? (
				<img
					src={src}
					alt={alt}
					className={classNames(
						"h-24 w-24 flex-none rounded-full ring-1 ring-gray-900/10",
						className ?? ""
					)}
				/>
			) : (
				<div
					className={classNames(
						"h-24 w-24 flex-none rounded-full ring-1 ring-gray-900/10 grid place-items-center",
						className ?? ""
					)}
				>
					{type == "user" ? (
						<FaUser className="h-10 w-10 flex-none ring-gray-900/10" />
					) : (
						<MdEmojiEvents className="h-10 w-10 flex-none ring-gray-900/10" />
					)}
				</div>
			)}
		</>
	);
}
