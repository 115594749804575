import { useContext, createContext, useState } from "react";
import { BsBorderStyle } from "react-icons/bs";

type UiContextValue = {
	color: Color;
	fontColor: string;
	tabBorderStyle: string;
	requestColor: RequestColor;
	borderRadius: borderRadius;
};

type borderRadius = {
	primary: string;
};

type Color = {
	scheme: string;
	primary: string;
	secondary: string;
	footer: string;
};

type RequestColor = {
	submitted: string;
	confirmed: string;
	rejected: string;
	notSubmitted: string;
};

var main = "blue"; //gray,red,yellow,orange,teal,green,blue,cyan,purple,pink
var b = 0; //if u want black put gray and this variable to 100

var intensity = {
	primary: 600 + b,
	secondary: 500 + b,
	footer: 800 + b,
};

const UiValue = {
	color: {
		scheme: main,
		primary: main + "." + intensity.primary,
		secondary: main + "." + intensity.secondary,
		footer: main + "." + intensity.footer,
	},
	fontColor: "white",
	tabBorderStyle: "enclosed-colored",
	requestColor: {
		confirmed: "green",
		rejected: "red",
		submitted: "yellow",
		notSubmitted: "yellow",
	},
	borderRadius: { primary: "1.5rem" },
};

const UiContext = createContext<UiContextValue>(UiValue);

export function UiContextProvider({ children }: React.PropsWithChildren<{}>) {
	return <UiContext.Provider value={UiValue}>{children}</UiContext.Provider>;
}

export function useUI() {
	return useContext(UiContext);
}
