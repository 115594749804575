import { AccountClient } from "../Api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import LoginForm, { LoginValues } from "./LoginForm";
import { useClient } from "../components/UseClient";
import { useSearchParams } from "react-router-dom";
import { useUI } from "../UiContext";
import { Logo } from "../SideMenuLayout";
import { motion } from "framer-motion";
import DarkLightButton, {
	darkClasses,
	useDarkTheme,
} from "../components/DarkLightButton";
import { classNames } from "../components/utils";

export type tokenResponse = {
	Id: string;
	Role: string[];
	aud: string;
	exp: number;
	iss: string;
};

export type logProps = {
	handleLogIn: () => void;
};

export default function Login({ login }: { login: (token: string) => void }) {
	const toast = useToast();
	const client = useClient(AccountClient);
	const queryClient = useQueryClient();
	const { color } = useUI();
	const [searchParams, setSearchParams] = useSearchParams();
	const id = searchParams.get("id");
	const token = searchParams.get("token");
	const { isDark } = useDarkTheme();

	const { mutate: loginMutation, isLoading } = useMutation(
		(values: LoginValues) => client.loginUser(values),
		{
			onSuccess: async (data) => {
				console.log(data?.result);
				var token: string = data?.result!;
				toast({
					title: `Login avvenuto con successo,\n benvenuto`,
					status: "success",
					duration: 3000,
					isClosable: true,
				});
				login(token);
				// navigate("/");
			},
			onError: (e) => {
				toast({
					title:
						typeof e == "string"
							? (e as string)
							: "Impossibile raggiungere il server",
					status: "error",
					duration: 3000,
					isClosable: true,
				});
			},
			onSettled: () => {
				queryClient.invalidateQueries(["login"]);
			},
		}
	);

	return (
		<>
			{/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
			<motion.div
				className={classNames(
					"flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 h-screen ",
					darkClasses.bg,
					darkClasses.text
				)}
				initial={{ y: 10, opacity: 0 }}
				animate={{ y: 0, opacity: 1 }}
				// exit={{ y: -10, opacity: 0 }}
				transition={{ duration: 0.2, delay: 0.1 }}
			>
				<div className="sm:mx-auto sm:w-full sm:max-w-sm">
					<Logo className="mx-auto w-20 h-20" />

					<h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight ">
						Entra su{" "}
						<span className="text-indigo-500 ">Bamboo</span> Manager
					</h2>
				</div>

				<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
					{/* <form className="space-y-6" action="#" method="POST">
						<div>
							<label
								htmlFor="email"
								className="block text-sm font-medium leading-6 text-gray-900"
							>
								Email address
							</label>
							<div className="mt-2">
								<input
									id="email"
									name="email"
									type="email"
									autoComplete="email"
									required
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div>
							<div className="flex items-center justify-between">
								<label
									htmlFor="password"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Password
								</label>
								<div className="text-sm">
									<a
										href="#"
										className="font-semibold text-indigo-600 hover:text-indigo-500"
									>
										Forgot password?
									</a>
								</div>
							</div>
							<div className="mt-2">
								<input
									id="password"
									name="password"
									type="password"
									autoComplete="current-password"
									required
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div>
							<button
								type="submit"
								className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							>
								Sign in
							</button>
						</div>
					</form> */}
					<LoginForm
						isLoading={isLoading}
						onSubmit={loginMutation}
						initialValues={{
							email: "",
							password: "",
						}}
					/>

					<p className="mt-10 text-center text-sm text-gray-400 font-semibold ">
						Non sei un membro?
						<br /> Richiedi l'account alla tua associazione
						sportiva!
					</p>
					<div className="w-full flex justify-center py-4">
						<DarkLightButton className="mx-auto  bg-red-200" />
					</div>
				</div>
			</motion.div>

			
		</>
	);
}
