import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useClient } from "../../components/UseClient";
import { ItemDto, ItemsClient } from "../../Api";
import {
	Box,
	Center,
	Grid,
	GridItem,
	Flex,
	HStack,
	Spinner,
	Stack,
	useDisclosure,
	Heading,
} from "@chakra-ui/react";
import ActionButton from "../../components/ActionButton";
import GetUser, { AccessLevelType } from "../../components/GetUser";
import ItemsModal from "./components/ItemsModal";
import { useUI } from "../../UiContext";
import { getFileUrl } from "../../components/utils";
import { FaCartShopping } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { CsItemButton } from "../../components/CsElements";
import { SearchBar } from "../../components/UI/Table";
export default function ItemsPage() {
	const client = useClient(ItemsClient);
	const { AccessLevel } = GetUser();
	const [filter, setFilter] = useState<string | undefined>(undefined);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [item, setItem] = useState<ItemDto | undefined>(undefined);

	const { data: items, isLoading } = useQuery({
		queryKey: ["items", filter],
		queryFn: () =>
			client
				.getAllItems(filter, undefined, undefined, undefined)
				.then((r) => r.result),
	});

	return (
		<Stack>
			<ItemsModal
				isOpen={isOpen}
				onClose={() => {
					setItem(undefined);
					onClose();
				}}
				item={item}
			/>
			<Flex
				flexDir={["column", "column", "row"]}
				justifyContent="center"
				alignItems="center"
				minWidth="max-content"
				w="100%"
				gap="1.5rem"

				// minWidth="max-content"
			>
				{AccessLevel >= AccessLevelType.Admin ? (
					<ActionButton onClick={onOpen}>Crea Oggetto</ActionButton>
				) : null}
				<SearchBar
					onChange={(v) => setFilter(v.currentTarget.value)}
					defaultValue={filter ?? ""}
					placeholder={"Cerca evento"}
				/>
			</Flex>
			{isLoading ? (
				<Center w="100%" h="100%" pt="5rem">
					<Spinner />
				</Center>
			) : (
				<Grid
					p="5%"
					placeItems={"center"}
					templateColumns={`repeat(3,1fr)`}
					gap="1rem"
					w="100%"
					h="100%"
				>
					{!!items?.numOfItems && items?.numOfItems > 0 ? (
						items?.items.map((x) => (
							<GridItem
								colSpan={[3, 3, 1]}
								rowSpan={1}
								key={x.id}
							>
								<ItemData
									item={x}
									onOpen={onOpen}
									setItem={setItem}
								/>
							</GridItem>
						))
					) : (
						<GridItem colSpan={3}>
							<Heading>Non sono presenti articoli</Heading>
						</GridItem>
					)}
				</Grid>
			)}
		</Stack>
	);
}

function ItemData({
	item,
	onOpen,
	setItem,
}: {
	item: ItemDto;
	onOpen: () => void;
	setItem: React.Dispatch<React.SetStateAction<ItemDto | undefined>>;
}) {
	const { borderRadius } = useUI();
	const navigate = useNavigate();
	const { AccessLevel } = GetUser();
	return (
		<Stack gap="0">
			<Box
				placeItems={"center"}
				bg="gray.200"
				borderRadius={"1rem"}
				w="100%"
				onClick={() => navigate(item.id)}
				boxShadow="2px 2px 10px gray"
				// overflow="hidden"
				position="relative"
				h="20rem"
				cursor="pointer"
				border="1px solid gray"
				borderColor="gray.400"
				style={{ aspectRatio: "1/1" }}
				backgroundImage={getFileUrl(item.itemPhoto)}
				backgroundPosition="center"
				backgroundRepeat="no-repeat"
				backgroundSize="cover"
			>
				{item.itemPhoto ? (
					<Box
						w="100%"
						h="20rem"
						style={{ aspectRatio: "1/1" }}
						borderRadius={"inherit"}
						backgroundImage={getFileUrl(item.itemPhoto)}
						backgroundPosition="center"
						backgroundRepeat="no-repeat"
						backgroundSize="cover"
						filter="brightness(1)"
						_before={{
							content: `""`,
							borderRadius: "inherit",
							position: "absolute",
							w: "100%",
							h: "20%",
							bg: "linear-gradient(to top, rgba(0, 0, 0, 1.0), transparent)",
							bottom: "0",
						}}
					></Box>
				) : (
					<Grid
						w="100%"
						h="20rem"
						// style={{ aspectRatio: "1/1" }}
						bg="#302020"
						color="white"
						fontSize="6xl"
						borderRadius={"1rem"}
						placeItems="center"
					>
						<FaCartShopping />
					</Grid>
				)}

				<HStack
					position="absolute"
					px="5%"
					py="4%"
					w="100%"
					bottom={0}
					color="white"
					fontWeight={"bold"}
					justifyContent={"space-between"}
					alignItems="end"
				>
					<Box fontSize="xl">{item.title}</Box>
					<Box position="relative" fontSize="2xl">
						{item.price}€
					</Box>
					{/* <Box opacity="0.5">{item.createdBy}</Box> */}
				</HStack>
			</Box>
			<CsItemButton
				entity={item}
				editFunction={() => {
					setItem(item);
					onOpen();
				}}
			/>
			{/* {AccessLevel >= AccessLevelType.Admin ? (
				<Box
					bg="gray.200"
					h="10%"
					w="fit-content"
					justifyContent="end"
					alignItems="end"
					zIndex="2"
					borderRadius="1rem"
				>
					<Button
						boxShadow="1px 1px 5px gray"
						borderRadius="1rem"
						onClick={() => {
							setItem(item);
							onOpen();
						}}
					>
						{Icons.Edit}
					</Button>
				</Box>
			) : null} */}
		</Stack>
	);
}
