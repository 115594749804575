import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { AthleteDto, ContestDto, CourseDto, ItemDto, UserDto } from "../Api";
import { getIdentifier } from "./utils";

export default function EntityLink({
	to,
	children,
	...rest
}: { to: string } & PropsWithChildren) {
	const navigate = useNavigate();
	return (
		<div
			className="pointer font-bold text-orange-400 relativa w-fit"
			onClick={() => navigate(to)}
		>
			<div className="absolute hover:w-full transition-all left-0 bottom-o w-0 h-[2px] bg-orange-400"></div>
			{children}
		</div>
	);
}

export function UserLink({ user }: { user: UserDto | undefined }) {
	return !user ? (
		<>-</>
	) : (
		<EntityLink to={`/accounts/${user.id}`}>
			{getIdentifier(user)}
		</EntityLink>
	);
}
export function ItemLink({ item }: { item: ItemDto | undefined }) {
	return !item ? (
		<>-</>
	) : (
		<EntityLink to={`/items/${item.id}`}>{item.title}</EntityLink>
	);
}
export function AthleteLink({ athlete }: { athlete: AthleteDto | undefined }) {
	return !athlete ? (
		<>-</>
	) : (
		<EntityLink
			to={`/athletes/${athlete.athleteId}`}
		>{`${athlete.surname} ${athlete.name}`}</EntityLink>
	);
}
export function ContestLink({ contest }: { contest: ContestDto | undefined }) {
	return !contest ? (
		<>-</>
	) : (
		<EntityLink
			to={`/contests/${contest.id}`}
		>{`${contest.name}`}</EntityLink>
	);
}
export function CourseLink({ course }: { course: CourseDto | undefined }) {
	return !course ? (
		<>-</>
	) : (
		<EntityLink to={`/courses/${course.id}`}>{course.name}</EntityLink>
	);
}
