import { CoursesClient } from "../../Api";
import { Center } from "@chakra-ui/react";

import { useClient } from "../../components/UseClient";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import AthleteCourseTab from "./components/AthleteCourseTab";
import { AnimatedGridItem } from "../../components/AnimatedGridItem";
import { SectionTitle } from "../Home/Home";

export default function SingleCoursePage() {
	const { id } = useParams();

	const client = useClient(CoursesClient);

	const { data, isLoading: isLoadingAthlete } = useQuery({
		queryKey: ["courses", id],
		queryFn: () => client.getCourse(id!).then((r) => r.result),
		enabled: !!id,
	});

	return (
		<>
			<Center>
				<AnimatedGridItem
					w="15rem"
					style={{ aspectRatio: "2/1" }}
					colSpan={3}
					title={"Membri"}
					text={data?.membersCount.toString()}
				/>
			</Center>

			<SectionTitle>Iscritti</SectionTitle>

			<AthleteCourseTab course={data} />
		</>
	);
}
