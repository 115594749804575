import React, { PropsWithChildren } from "react";

export default function Badge({
	color,
	children,
}: {
	color: string;
} & PropsWithChildren) {
	return (
		<span
			className={`inline-flex items-center rounded-md ${color}  px-2 py-1 text-xs font-semibold w-fit text-gray-600 ring-1 ring-inset ring-gray-500/10`}
		>
			{children}
		</span>
	);
}
