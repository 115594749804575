
import { RoleType, AccountType } from "../../../Api";
import { useQuery } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useClient } from "../../../components/UseClient";
import GetUser from "../../../components/GetUser";
import {
	AthleteDto,
	ContestDto,
	ContestRequestClient,
	EventType,
	RequestState,
} from "../../../Api";
import {
	DateOnlyValues,
	GetAvatarInitial,
	classNames,
	getCategory,
	getFileUrl,
} from "../../../components/utils";
import { State } from "../../../EnumChanger/State";
import { parse } from "date-fns";
import { ModalProps } from "../../../utilities/typeUtils";
import useToastMutation from "../../../components/Hooks/useToastMutation";
import { Modal } from "../../../components/UI/Modal";
import Button from "../../../components/UI/Button";
import { Icons } from "../../../utilities/icons";

export default function RegisterManagerModal({
	isOpen,
	onClose,
	contest,
	athletes,
}: ModalProps & {
	contest: ContestDto;
	athletes?: AthleteDto[] | undefined;
}) {
	const { currentUser } = GetUser();

	const info = (
		<>
			<div className="flex flex-col text-gray-400  font-bold text-sm">
				<span>Non Iscritto: Clicca per mandare la richiesta</span>
				<span>In Attessa: la richiesta sta venendo analizzata</span>
				<span>Iscritto: Clicca per annullare la richiesta</span>
			</div>
		</>
	);
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title="Manager Iscrizioni"
			icon={
				<div className="rounded-full bg-blue-100 p-2">
					{Icons.ManageAccount}
				</div>
			}
		>
			{(athletes == undefined || athletes?.length == 0) &&
			currentUser?.accountType == AccountType.Family ? (
				<div className="mx-auto">
					Non sono presenti atleti su questo account
				</div>
			) : currentUser?.accountType == AccountType.SingleUser &&
			  currentUser.athlete ? (
				<div className="flex flex-col gap-4">
					{info}
					<div className="mx-auto">
						<RegisterButton
							athlete={currentUser.athlete}
							contest={contest}
						/>
					</div>
				</div>
			) : (
				<>
					{info}
					<div className="flex gap-2 items-center justify-items-center">
						{athletes?.map((x) => {
							return (
								<div>
									<RegisterButton
										athlete={x}
										contest={contest}
									/>
								</div>
							);
						})}
					</div>
				</>
			)}
		</Modal>
	);
}

export function RegisterButton({
	athlete,
	contest,
}: {
	athlete: AthleteDto;
	contest: ContestDto;
}) {
	const signClient = useClient(ContestRequestClient);
	const toast = useToast();

	const invalidate = ["contest", "requests"];

	const errors: string[] = [];

	const { mutate: signMutation, isLoading: isSignLoading } = useToastMutation(
		() => signClient.singContest(athlete?.athleteId!, contest.id),
		undefined,
		invalidate,
		"Richiesta inviata"
	);
	const { mutate: unSignMutation, isLoading: isUnSignLoading } =
		useToastMutation(
			() => signClient.unSingContest(athlete?.athleteId!, contest.id),
			undefined,
			invalidate,
			"Iscrizione Cancellata"
		);

	const { data, isLoading: isLoadingState } = useQuery(
		["contest", athlete?.athleteId, contest.id, "state"],
		async () =>
			await signClient
				.getRequest(athlete?.athleteId!, contest.id!)
				.then((r) => {
					if (r.result.state == RequestState.Rejected)
						errors.push(" La tua iscrizione è stata rifiutata");
					return r.result;
				}),
		{ enabled: !!athlete?.athleteId && !!contest.id }
	);

	if (athlete?.userRoleType == RoleType.Athlete) {
		if (
			contest.eventType == EventType.Contest &&
			!contest.category.includes(getCategory(athlete?.birth).name)
		)
			errors.push(
				`${athlete.surname} ${athlete.name}: La tua categoria non è presente in questa gara`
			);

		if (
			athlete.quoteDeadline &&
			parse(athlete.quoteDeadline, "yyyy-MM-dd", new Date()).getTime() <
				Date.now() &&
			athlete.quoteDeadline != DateOnlyValues.MinValue
		)
			errors.push(`${athlete.surname} ${athlete.name}: Quota Scaduta`);

		if (athlete.quoteDeadline == DateOnlyValues.MinValue)
			errors.push(
				`${athlete.surname} ${athlete.name}: Non ha pagato la quota`
			);

		if (
			athlete.medicalCertificateDeadline == DateOnlyValues.MinValue ||
			athlete.medicalCertificateDeadline == null
		)
			errors.push(
				`${athlete.surname} ${athlete.name}: Non ha il certificato medico`
			);

		if (
			athlete.medicalCertificateDeadline != undefined &&
			parse(
				athlete.medicalCertificateDeadline,
				"yyyy-MM-dd",
				new Date()
			).getTime() < Date.now()
		)
			errors.push(
				`${athlete.surname} ${athlete.name}: Certificato medico scaduto`
			);
	}

	if (new Date(contest.deadlineDate).getTime() < Date.now())
		errors.push("Iscrizioni Chiuse");

	function ShowErrors() {
		errors.forEach((x, i) => {
			const id = "error-toast-" + x;
			if (!toast.isActive(id))
				toast({
					id: id,
					title: x,
					status: "error",
					duration: 5000,
					isClosable: true,
				});
		});
	}

	return (
		<div
			key={athlete.surname + athlete.name}
			className="col-span-1 flex flex-col  divide-gray-200 rounded-lg bg-white text-center shadow "
		>
			<div className="flex flex-1 flex-col p-4">
				{athlete.profilePicture ? (
					<img
						className="mx-auto h-16 w-16 flex-shrink-0 rounded-full"
						src={getFileUrl(athlete.profilePicture)}
						alt=""
					/>
				) : (
					<GetAvatarInitial
						athlete={athlete}
						className="w-16 h-16 mx-auto flex-shrink-0"
					/>
				)}
				<h3 className="mt-2 text-sm font-medium text-gray-900">
					{athlete.surname + " " + athlete.name}
				</h3>
				<dl className="mt-1 flex flex-grow flex-col justify-between">
					<State type="both" state={data?.state} isLoading={false} />
				</dl>
			</div>
			<div className="w-full flex justify-content-center">
				{/* <div className="-mt-px flex divide-x divide-gray-200">
					<div className=" flex-1 justify-items-center w-full"> */}
				<Button
					containerClass="w-full"
					disabled={data?.state == RequestState.Rejected}
					className={classNames(
						"w-full",
						data?.state == RequestState.Rejected
							? "bg-red-500 hover:bg-red-400"
							: data?.state == RequestState.NotSubmitted ||
							  data == undefined
							? ""
							: "bg-amber-600 hover:bg-amber-500"
					)}
					onClick={() =>
						data?.state == RequestState.Rejected
							? {}
							: errors.length > 0
							? ShowErrors()
							: data?.state == RequestState.NotSubmitted ||
							  data == undefined
							? signMutation()
							: unSignMutation()
					}
				>
					<span className="mx-auto">
						{data?.state == RequestState.Rejected
							? "Rifiutata"
							: data?.state == RequestState.NotSubmitted ||
							  data == undefined
							? "Iscrivi"
							: data?.state == RequestState.Submitted
							? "Annulla Richiesta"
							: "Disicrivi"}
					</span>
				</Button>
				{/* <a
							href={`mailto:${person.email}`}
							className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
						>
							<EnvelopeIcon
								className="h-5 w-5 text-gray-400"
								aria-hidden="true"
							/>
							Email
						</a> */}
			</div>
			{/* <div className="-ml-px flex w-0 flex-1">
						<a
							// href={`tel:${person.telephone}`}
							className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
						>
							 <PhoneIcon
								className="h-5 w-5 text-gray-400"
								aria-hidden="true"
							/> 
							Call
						</a>
					</div> */}
			{/* </div>
			</div> */}
		</div>
		// <Stack gap="0.5rem">
		// 	{isLoadingState ? (
		// 		<Spinner w="100%" h="100%" />
		// 	) : (
		// 		<>
		// 			<Box
		// 				w="10rem"
		// 				style={{ aspectRatio: "1/1" }}
		// 				backgroundImage={getFileUrl(athlete.profilePicture)}
		// 				backgroundPosition="center"
		// 				backgroundRepeat="no-repeat"
		// 				backgroundSize="cover"
		// 				border="2px solid gray"
		// 				overflow={"hidden"}
		// 				transition="all 0.5s"
		// 				_active={{ transform: "scale(0.95)" }}
		// 				borderColor={
		// 					data?.state == RequestState.Rejected
		// 						? "red.400"
		// 						: data?.state == RequestState.Approved
		// 						? "green.400"
		// 						: data?.state == RequestState.Submitted
		// 						? "yellow.400"
		// 						: "gray.400"
		// 				}
		// 				borderRadius={"1rem"}
		// 				// color="white"
		// 				fontWeight={"bold"}
		// 				cursor={
		// 					data?.state != RequestState.Rejected
		// 						? "pointer"
		// 						: "not-allowed"
		// 				}
		// 				position="relative"
		// 				onClick={() =>
		// 					data?.state == RequestState.Rejected
		// 						? {}
		// 						: errors.length > 0
		// 						? ShowErrors()
		// 						: data?.state == RequestState.NotSubmitted ||
		// 						  data == undefined
		// 						? signMutation()
		// 						: unSignMutation()
		// 				}
		// 			>
		// 				{((data?.state == RequestState.NotSubmitted ||
		// 					data == undefined) &&
		// 					isSignLoading) ||
		// 				isUnSignLoading ? (
		// 					<Grid
		// 						position="absolute"
		// 						w="100%"
		// 						h="100%"
		// 						placeItems="center"
		// 					>
		// 						<Box
		// 							bg="blackAlpha.600"
		// 							position="absolute"
		// 							w="100%"
		// 							h="100%"
		// 						/>
		// 						<Spinner color="white" />
		// 					</Grid>
		// 				) : null}

		// 				{!athlete.profilePicture ? (
		// 					<Avatar
		// 						top="0"
		// 						w="100%"
		// 						h="100%"
		// 						// bg="gray.300"
		// 						icon={<AiOutlineUser fontSize="4rem" />}
		// 						name={athlete.surname + " " + athlete.name}
		// 						position="absolute"
		// 						zIndex="-1"
		// 						borderRadius={"none"}
		// 					/>
		// 				) : null}
		// 				<Stack
		// 					h="100%"
		// 					justifyContent={"space-between"}
		// 					pt="0.3rem"
		// 				>
		// 					<Box>
		// 						<State
		// 							type="both"
		// 							state={data?.state}
		// 							isLoading={false}
		// 						/>
		// 					</Box>
		// 					<Badge
		// 						alignSelf={"center"}
		// 						// color="white"
		// 						borderRadius={"full"}
		// 						px="2"
		// 						border="1px solid gray"
		// 					>{`${athlete.surname} ${athlete.name}`}</Badge>
		// 				</Stack>
		// 			</Box>
		// 		</>
		// 	)}
		// </Stack>
	);
}
