

import { useUI } from "./UiContext";

import { useState } from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
	const { color } = useUI();
	const [loading, setLoading] = useState(true);
	setTimeout(() => {
		setLoading(false);
	}, 1500);
	return (
		<>
			<main className="grid min-h-full place-items-center  px-6 py-24 sm:py-32 lg:px-8">
				<div className="text-center">
					<p className="text-base font-semibold text-indigo-600">
						404
					</p>
					<h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
						Pagina non trovata
					</h1>
					<p className="mt-6 text-base leading-7 text-gray-600">
						Ci dispiace, non siamo riusciti a trovare la pagina che
						stavi cercando.
					</p>
					<div className="mt-10 flex items-center justify-center gap-x-6">
						<Link
							to=""
							className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						>
							Ritorna alla Home
						</Link>
						<a
							href="mailto:cronosport@alice.it"
							className="text-sm font-semibold text-gray-900"
						>
							Contatta il supporto
							<span aria-hidden="true">&rarr;</span>
						</a>
					</div>
				</div>
			</main>
		</>
	);
}
