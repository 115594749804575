import React, { useState } from "react";

import { classNames } from "./utils";
import { IoMoon, IoSunny } from "react-icons/io5";

export default function DarkLightButton({ className }: { className?: string }) {
	const { isDark, toggleDark } = useDarkTheme();

	return (
		<button
			className={classNames(
				"relative ",
				isDark ? "text-white" : "",
				className ?? ""
			)}
			onClick={() => {
				toggleDark();
			}}
		>
			<span
				className={classNames(
					"transition-all absolute translate-x-[-50%] translate-y-[-50%]",
					isDark
						? "opacity-100 translate-y-[-50%]"
						: "opacity-0 translate-y-[50%]"
				)}
			>
				<IoSunny />
			</span>
			<span
				className={classNames(
					"transition-all absolute translate-x-[-50%] translate-y-[-50%]",
					!isDark
						? "opacity-100 translate-y-[-50%]"
						: "opacity-0 translate-y-[50%]"
				)}
			>
				<IoMoon />
			</span>
		</button>
	);
}

export const darkClasses = {
	all: "text-gray-800 dark:text-gray-200 bg-gray-200  dark:bg-slate-800",
	text: "text-gray-800 dark:text-gray-200",
	bg: "bg-gray-100  dark:bg-slate-800",
};

export function useDarkTheme() {
	var html = document.getElementById("html");
	const [isDark, setIsDark] = useState<boolean | undefined>(
		html?.classList.contains("dark")
	);

	const setDark = () => {
		if (!isDark) {
			html?.classList.add("dark");
			setIsDark(true);
		}
	};
	const setLight = () => {
		if (isDark) {
			html?.classList.remove("dark");
			setIsDark(false);
		}
	};

	const toggleDark = () => {
		if (isDark) setLight();
		else setDark();
	};

	return { isDark, setDark, setLight, toggleDark };
}
