import GetUser from "../../components/GetUser";
import Spinner from "../../components/UI/Spinner";
import ProfileInfoTab from "./components/ProfileInfoTab";

export default function ProfilePage() {
	const { isLoading } = GetUser();

	return (
		<>
			{isLoading ? (
				<Spinner />
			) : (
				<div className="relative">
					<ProfileInfoTab />
				</div>
			)}
		</>
	);
}
