import {
	AiOutlineFieldNumber,
	AiOutlineNumber,
	AiOutlineTrophy,
} from "react-icons/ai";
import { BiPhone, BiWorld, BiDna } from "react-icons/bi";
import {
	BsCalendarDate,
	BsCurrencyEuro,
	BsMailbox,
	BsPeopleFill,
	BsSun,
	BsTag,
	BsViewList,
	BsGenderMale,
	BsGenderFemale,
	BsPersonVcardFill,
} from "react-icons/bs";
import { CgListTree } from "react-icons/cg";
import {
	FaBuilding,
	FaCity,
	FaDesktop,
	FaDumbbell,
	FaBirthdayCake,
	FaMedal,
	FaUserCog,
	FaChild,
	FaHome,
	FaPiggyBank,
	FaWeight,
} from "react-icons/fa";
import {
	FaCartShopping,
	FaGear,
	FaMessage,
	FaArrowRight,
	FaGg,
	FaPlus,
	FaListCheck,
} from "react-icons/fa6";
import { FiEdit3, FiInfo, FiTrash2 } from "react-icons/fi";
import { GiBlackBelt, GiSandsOfTime } from "react-icons/gi";
import {
	IoPerson,
	IoMaleFemale,
	IoPeopleSharp,
	IoWarning,
	IoPeople,
} from "react-icons/io5";
import {
	MdAlternateEmail,
	MdColorLens,
	MdEmojiEvents,
	MdFamilyRestroom,
	MdManageAccounts,
	MdOutlinePlace,
	MdOutlineSchedule,
	MdOutlineSubtitles,
	MdPassword,
	MdTitle,
	MdVisibility,
	MdVisibilityOff,
} from "react-icons/md";
import { RiVipCrown2Line } from "react-icons/ri";

export const Icons = {
	Upgrade: <RiVipCrown2Line />,
	Delete: <FiTrash2 />, // <RiDeleteBin6Line />,
	Cancel: <FiTrash2 />,
	Edit: <FiEdit3 />,
	Info: <FiInfo />,
	Children: <FaChild />,
	People: <BsPeopleFill />,
	Warning: <IoWarning />,
	Email: <MdAlternateEmail />,
	Password: <MdPassword />,
	Category: <CgListTree />,
	Families: <MdFamilyRestroom />,
	FamilyAthleteCounter: <FaChild />,
	AssociationAthleteCounter: <IoPeopleSharp />,
	Name: <IoPerson />,
	Surname: <BiDna />,
	Birth: <FaBirthdayCake />,
	Gender: <IoMaleFemale />,
	AccountType: <FaUserCog />,
	Belt: <GiBlackBelt />,
	Point: <FaMedal />,
	AssociationName: <FaDumbbell />,
	PhoneNumber: <BiPhone />,
	Website: <FaDesktop />,
	Street: <FaBuilding />,
	City: <FaCity />,
	PostalCode: <BsMailbox />,
	Country: <BiWorld />,
	Price: <FaPiggyBank />,
	Show: <MdVisibility />,
	Hidden: <MdVisibilityOff />,
	Deadline: <GiSandsOfTime />,
	Count: <AiOutlineFieldNumber />,
	Color: <MdColorLens />,
	Home: <FaHome />,
	Message: <FaMessage />,
	Title: <MdTitle />,
	Contest: <AiOutlineTrophy />,
	Place: <MdOutlinePlace />,
	EventTag: <BsTag />,
	Euro: <BsCurrencyEuro />,
	Calendar: <BsCalendarDate />,
	Day: <BsSun />,
	Admin: <FaGear />,
	Events: <MdEmojiEvents />,
	Athletes: <IoPeople />,
	Schedule: <MdOutlineSchedule />,
	Shop: <FaCartShopping />,
	Description: <MdOutlineSubtitles />,
	Quantity: <AiOutlineNumber />,
	RightArrow: <FaArrowRight />,
	Connect: <FaGg />,
	Course: <BsViewList />,
	Add: <FaPlus />,
	Features: <FaListCheck />,
	Male: <BsGenderMale />,
	Female: <BsGenderFemale />,
	Weight: <FaWeight />,
	Taxid: <BsPersonVcardFill />,
	ManageAccount: <MdManageAccounts />,
};
