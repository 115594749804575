import { useState } from "react";
import { AthletesClient, AthleteDto } from "../../../Api";
import { Actions, BulkActions, Column } from "../../../components/CsTable";
import { useClient } from "../../../components/UseClient";
import GetUser, { AccessLevelType } from "../../../components/GetUser";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../../utilities/icons";
import { classNames, getCategory, getFileUrl } from "../../../components/utils";
import {
	beltColumn,
	birthColumn,
	genderColumn,
	roleTypeColumn,
	beltPoint,
} from "../../../utilities/ColumnsUtils";

import { DeleteAthleteModal } from "./DeleteAthleteModal";
import { FaPlus } from "react-icons/fa";
import useToastMutation from "../../../components/Hooks/useToastMutation";

import { ActionText, TlTable } from "../../../components/UI/Table";
import Button, { ActionButtonContainer } from "../../../components/UI/Button";
import useDisclosure from "../../../components/Hooks/useDisclosure";

export const athleteColumns: Column<AthleteDto>[] = [
	{
		id: "surname",
		name: "Cognome",
	},
	{
		id: "name",
		name: "Nome",
	},
	{
		name: "Profilo",
		Cell: ({ profilePicture, surname, name }) =>
			profilePicture ? (
				<img
					className="h-10 w-10 rounded-full bg-gray-50 border-2 border-gray-700"
					src={getFileUrl(profilePicture)}
					alt=""
				/>
			) : (
				<span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-indigo-500">
					<span className="font-medium leading-none text-white">
						{surname.substring(0, 1) + name.substring(0, 1)}
					</span>
				</span>
			),
	},
	{
		// @ts-expect-error
		id: "gender" as keyof AthleteDto,
		name: "Sesso",
		...genderColumn(true),
	},
	{
		// @ts-expect-error
		id: "birth",
		name: "Data di nascità",
		...birthColumn(),
		hideOnMobile: true,
	},
	{
		// @ts-expect-error
		id: "userRoleType",
		name: "Ruolo",
		isVisible: false,
		...roleTypeColumn(),
		hideOnMobile: true,
	},
	{
		// @ts-expect-error
		id: "belt",
		name: "Cintura",
		isVisible: false,
		...beltColumn(),
		hideOnMobile: true,
	},
	{
		id: "userPoint",
		name: "Punti",
		isVisible: false,
		Cell: ({ userPoint, belt, userRoleType }) =>
			belt != undefined ? (
				<div
					className={classNames(
						userPoint ?? 0 > beltPoint(belt!)
							? "font-bold"
							: "font-normal"
					)}
				>
					{`${userPoint ?? 0}/${beltPoint(belt!)}`}
				</div>
			) : (
				"-"
			),
		hideOnMobile: true,
	},
	{
		name: "Categoria",
		Cell: ({ birth }) => getCategory(birth).name,
		hideOnMobile: true,
	},
	{
		name: "Account Personale",
		Cell: ({ userId }) => (userId != null ? "Si" : "No"),
		hideOnMobile: true,
	},
];

export default function AthleteTab() {
	const client = useClient(AthletesClient);
	const navigate = useNavigate();
	const [changeEntity, setchangeEntity] = useState<AthleteDto | undefined>(
		undefined
	);
	const { AccessLevel } = GetUser();

	const {
		isOpen: isDeleteOpen,
		onOpen: onDeleteOpen,
		onClose: onDeleteClose,
	} = useDisclosure();

	const { mutate: deleteMutation, isLoading } = useToastMutation(
		(selected: AthleteDto[]) =>
			client.deleteAthletes(selected.map((x) => x.athleteId)),
		undefined,
		["athletes", "users"],
		"Athleti Eliminati"
	);

	const actions: Actions<AthleteDto> = (athlete, index) => (
		<ActionButtonContainer>
			<Button
				// icon={Icons.Info}
				type="table"
				onClick={() => navigate(`/athletes/${athlete.athleteId}`)}
				className={classNames(
					"text-indigo-500 hover:text-indigo-800 rounded-full text-lg p-2  flex items-center gap-1",
					athlete.active ? "opacity-100" : "opacity-50"
				)}
				tooltip={"Info"}
			>
				{Icons.Info}
				<ActionText>Info</ActionText>
			</Button>
			{AccessLevel >= AccessLevelType.Admin ? (
				<>
					<Button
						// icon={Icons.Info}
						type="table"
						className={classNames(
							"text-indigo-500 hover:text-indigo-800 rounded-full text-lg p-2  flex items-center gap-1",
							athlete.active ? "opacity-100" : "opacity-50"
						)}
						onClick={() =>
							navigate(`/athletes/${athlete.athleteId}/edit`)
						}
						tooltip={"Modifica"}
					>
						{Icons.Edit}{" "}
						<ActionText>
							Modifica
						</ActionText>
					</Button>
					<Button
						// icon={Icons.Info}
						type="table"
						className={classNames(
							"text-indigo-500 hover:text-indigo-800 rounded-full text-lg p-2  flex items-center gap-1",
							athlete.active ? "opacity-100" : "opacity-50"
						)}
						onClick={() => {
							setchangeEntity(athlete);
							onDeleteOpen();
						}}
						tooltip={"Elimina"}
					>
						{Icons.Delete}{" "}
						<ActionText>
							Elimina
						</ActionText>
					</Button>
				</>
			) : null}
		</ActionButtonContainer>
	);

	const bulkActions: BulkActions<AthleteDto> = (selected) => (
		<>
			{AccessLevel >= AccessLevelType.Admin ? (
				<>
					<Button
						// icon={Icons.Info}
						type="table"
						isLoading={isLoading}
						className={classNames(
							"text-gray-500 hover:text-indigo-500 rounded-full text-sm p-2  flex items-center gap-1"
						)}
						onClick={() => deleteMutation(selected)}
					>
						{Icons.Delete}
						<span>Elimina selezionati</span>
					</Button>
				</>
			) : null}
		</>
	);

	const extraComponent = (
		<>
			{AccessLevel >= AccessLevelType.Admin ? (
				<Button onClick={() => navigate("create")} icon={<FaPlus />}>
					Crea
				</Button>
			) : null}
		</>
	);

	return (
		<div className="relative">
			<TlTable<AthleteDto>
				columns={athleteColumns}
				queryKey={["athletes"]}
				tableName="Atleti"
				fetchPage={async (filter, sort, limit, offset) =>
					await client.getAllAthletes(filter, sort, limit, offset)
				}
				exportPage={async (fields, filter, sort, limit, offset) =>
					await client.exportAllAthletes(
						fields as string[],
						filter,
						sort,
						limit,
						offset
					)
				}
				persistanceKey="athletes"
				defaultSorting={{ order: "-", sortBy: "name" }}
				extraComponent={extraComponent}
				actions={actions}
				bulkActions={bulkActions}
				options={{ selectable: AccessLevel >= AccessLevelType.Admin }}
				idProperty="athleteId"
				searchPlaceholder="Cerca atleta"
			/>

			{changeEntity ? (
				<DeleteAthleteModal
					isOpen={isDeleteOpen}
					entity={changeEntity!}
					onClose={onDeleteClose}
				/>
			) : null}
		</div>
	);
}
