import { useUI } from "../../UiContext";
import useFilter from "../Hooks/useFilter";
import useOrderBy from "../Hooks/useSortBy";
import useLimit from "../Hooks/useLimit";
import useOffset from "../Hooks/useOffset";
import useVisibility from "../Hooks/useVisibility";
import { useToast } from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { classNames, getFileUrl } from "../utils";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import { FileRecordDto, SwaggerResponse } from "../../Api";
import {
	Actions,
	AnimatedTable,
	BulkActions,
	Column,
	Paginated,
	Sorting,
} from "../CsTable";
import {  useColorMode } from "@chakra-ui/react";
import {
	useState,
	useEffect,
	useRef,
	PropsWithChildren,
} from "react";
import { TbArrowsSort } from "react-icons/tb";
import { FaArrowUp, FaFileDownload } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { motion } from "framer-motion";
import { Fragment } from "react";
import { Menu, Transition, RadioGroup } from "@headlessui/react";
import {
	ChevronDownIcon,
	MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { useDebounce } from "../Hooks/useDebounce";
import { MdRefresh } from "react-icons/md";
import { Modal } from "./Modal";
import useDisclosure from "../Hooks/useDisclosure";
import { IoOptions, IoOptionsSharp } from "react-icons/io5";
import Popover from "./Popover";
import Button from "./Button";

type Props<T> = {
	tableName?: string;
	columns: Column<T>[];
	selected?: boolean;
	idProperty?: keyof T | string;
	queryKey: unknown[];
	searchPlaceholder?: string;
	emptyMessage?: string;
	options?: {
		fixedPageSize?: number;
		searchable?: boolean;
		refreshable?: boolean;
		sortable?: boolean;
		selectable?: boolean;
		canModifyColumn?: boolean;
		showNumber?: boolean;
	};
	fetchPage: (
		filterBy: string | undefined,
		orderBy: string | undefined,
		limit: number | undefined,
		offset: number | undefined
	) => Promise<SwaggerResponse<Paginated<T>>>;
	exportPage?: (
		fields: (keyof T)[] | undefined,
		filterBy: string | undefined,
		orderBy: string | undefined,
		limit: number | undefined,
		offset: number | undefined
	) => Promise<SwaggerResponse<FileRecordDto>>;
	actions?: Actions<T>;
	bulkActions?: BulkActions<T>;
	extraComponent?: JSX.Element;
	defaultLimitList?: number[];
	description?: string;
	defaultSorting?: Sorting<T>;
	defaultFilter?: string;
	persistanceKey?: string;
};

function printProperty(property: any) {
	switch (typeof property) {
		case "boolean":
			return property ? "Si" : "No";
		default:
			return property;
	}
}

export function ActionText({ children }: PropsWithChildren) {
	return <span className="md:hidden text-md text-xs">{children}</span>;
}

export function TlTable<T extends object>({
	tableName,
	columns,
	queryKey: key,
	fetchPage,
	exportPage,
	actions,
	bulkActions,
	emptyMessage = "Nessun Elemento Trovato",
	extraComponent,
	options: notOptions,
	selected = true,
	idProperty,
	searchPlaceholder = "Cerca",
	defaultLimitList,
	description,
	defaultFilter,
	defaultSorting,
	persistanceKey,
}: Props<T>) {
	const limitList = [5, 10, 20, 50];
	const [exportFile, setExportFile] = useState<boolean>(false);
	const { filterBy, setFilterBy } = useFilter(persistanceKey, defaultFilter);
	const { orderBy, setOrderBy } = useOrderBy(persistanceKey, defaultSorting);
	const { limit, setLimit } = useLimit(persistanceKey);
	const { offset, setOffset } = useOffset(persistanceKey);
	const { visibility, setVisibility } = useVisibility(
		persistanceKey,
		columns
	);
	var options = {
		searchable: true,
		refreshable: true,
		sortable: true,
		selectable: true,
		canModifyColumn: true,
		showNumber: true,
		fixedPageSize: undefined,
	};
	for (const key in notOptions) {
		if (notOptions.hasOwnProperty(key)) {
			//@ts-expect-error
			options[key] = notOptions[key];
		}
	}
	const [selectColumnMobile, setColumnMobile] = useState<boolean>(false);

	const toast = useToast();
	const [selectedEntities, setSelectedEntities] = useState<T[]>([]);

	const queryClient = useQueryClient();

	const { data, isLoading } = useQuery({
		queryKey: [...key, filterBy, orderBy, limit, offset],
		queryFn: () =>
			fetchPage(
				filterBy,
				orderBy,
				!!options.fixedPageSize
					? options.fixedPageSize
					: !!defaultLimitList
					? defaultLimitList[limit ?? 0]
					: limitList[limit ?? 0],
				offset
			).then((r) => r.result),
	});

	const { isLoading: isExporting } = useQuery({
		queryKey: [...key, "export", orderBy],
		queryFn: async () => {
			const fileds = columns
				.filter((x, i) => visibility[i] && x.id != undefined)
				.map((x) => x.id!);

			await exportPage!(fileds, undefined, orderBy, undefined, undefined)
				.then((r) => r.result)
				.then((r) => {
					const url = getFileUrl(r);
					const date = format(Date.now(), "P", { locale: it });
					if (url != undefined) {
						const a = document.createElement("a");
						a.href = url;
						a.download = (tableName || "download") + "_" + date;
						const clickHandler = () => {
							setTimeout(() => {
								URL.revokeObjectURL(url);
								a.removeEventListener("click", clickHandler);
							}, 150);
						};
						a.addEventListener("click", clickHandler, false);
						a.click();
					}
					setExportFile(false);
				});
		},
		enabled: exportPage != undefined && exportFile,
	});

	const maxColumn =
		columns.length +
		(actions != undefined ? 1 : 0) +
		(!!options.showNumber || (!!options.selectable && !!bulkActions)
			? 1
			: 0);

	const { isOpen, onClose, onOpen } = useDisclosure();

	const cellClass = "whitespace-nowrap   py-2 text-sm text-gray-700";
	const commonThClass = "px-4";
	const commonTdClass = "px-4";
	const checkbox = useRef();
	const offsetButtonProps = {
		data: data,
		offset: offset,
		setOffset: setOffset,
	};
	const handleSearch = useDebounce(
		(v: React.ChangeEvent<HTMLInputElement>) => {
			// Perform search operation with the debounced term
			setFilterBy(
				v.target.value ? v.target.value.replaceAll(" ", "") : undefined
			);
		},
		300
	);

	return (
		<div className="px-4 sm:px-6 lg:px-8 max-w-screen ">
			<header className="flex gap-2 flex-col">
				<div className="sm:flex sm:items-center gap-2">
					<div className="sm:flex-auto">
						<h1 className="text-base font-semibold leading-6 text-gray-900">
							{tableName}
						</h1>
						{description ? (
							<p className="mt-2 text-sm text-gray-700">
								{description}
							</p>
						) : null}
					</div>
					<div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
						{!!extraComponent ? extraComponent : null}
					</div>
				</div>
				<div className="flex lg:gap-2 max-lg:gap-4 max-lg:flex-col max-md:hidden">
					{!options.fixedPageSize ? (
						<div className="flex gap-1 items-center">
							<span>Mostra </span>{" "}
							<Menu
								as="div"
								className="relative inline-block text-left"
							>
								<div>
									<Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
										{!!defaultLimitList
											? defaultLimitList[limit ?? 0]
											: limitList[limit ?? 0]}
										<ChevronDownIcon
											className="-mr-1 h-5 w-5 text-gray-400"
											aria-hidden="true"
										/>
									</Menu.Button>
								</div>{" "}
								<Transition
									as={Fragment}
									enter="transition ease-out duration-100"
									enterFrom="transform opacity-0 scale-95"
									enterTo="transform opacity-100 scale-100"
									leave="transition ease-in duration-75"
									leaveFrom="transform opacity-100 scale-100"
									leaveTo="transform opacity-0 scale-95"
								>
									<Menu.Items className="absolute left-0 z-[1000] mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
										<div className="py-1">
											{(defaultLimitList != undefined
												? defaultLimitList
												: limitList
											).map((x, i) => (
												<Menu.Item>
													{({ active }) => (
														<div
															key={
																"limit" + x + i
															}
															onClick={() =>
																setLimit(i)
															}
															className={classNames(
																active
																	? "bg-gray-100 text-gray-900"
																	: "text-gray-700",
																"block px-4 py-2 text-sm"
															)}
														>
															{x}
														</div>
													)}
												</Menu.Item>
											))}
										</div>
									</Menu.Items>
								</Transition>
							</Menu>
							<span>elementi</span>
						</div>
					) : null}

					{options.searchable ? (
						<SearchBar
							defaultValue={filterBy ?? ""}
							onChange={handleSearch}
							placeholder={searchPlaceholder}
						/>
					) : null}
					<div className="flex gap-2 w-full">
						{options.refreshable ? (
							<Button
								type="table"
								tooltip="Ricarica Tabella"
								onClick={() => {
									queryClient.invalidateQueries([
										...key,
										filterBy,
										orderBy,
										limit,
										offset,
									]);
									toast({
										title: "Tabella Aggiornata",
										status: "success",
										duration: 1000,
										isClosable: true,
									});
									// setRefetch(!refetch);
								}}
							>
								<MdRefresh />
							</Button>
						) : null}
						{options.canModifyColumn ? (
							<Popover
								title="Mostra/Nascondi Colonne"
								triggerTooltip={"Seleziona colonne"}
								triggerName={<IoOptions />}
							>
								<div className="flex flex-col gap-2">
									<div className="font-bold">
										Opzioni colonne
									</div>
									<div className="flex gap-2">
										<Button
											onClick={() =>
												setVisibility(
													visibility.map((x) => true)
												)
											}
										>
											Tutte
										</Button>
										<Button
											onClick={() =>
												setVisibility(
													visibility.map((x) => false)
												)
											}
										>
											Nessuna
										</Button>
									</div>
									<Button
										className="w-full"
										onClick={() =>
											setVisibility(
												columns.map((x) =>
													x.isVisible == undefined
														? true
														: x.isVisible
												)
											)
										}
									>
										<span className="text-center w-full">
											Reimposta colonne
										</span>
									</Button>
									{columns.map((x, i) => {
										return (
											<span className="flex gap-2 items-center">
												<input
													type="checkbox"
													className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
													key={
														"visibility_" +
														x.name +
														visibility[i]
													}
													onChange={(v) => {
														var newVisibility =
															visibility.map(
																(col, num) =>
																	num == i
																		? v
																				.currentTarget
																				.checked
																		: col
															);
														setVisibility(
															newVisibility
														);
													}}
													defaultChecked={
														visibility != undefined
															? visibility[i]
															: x.isVisible ==
															  undefined
															? true
															: x.isVisible
													}
												/>
												{x.name}
											</span>
										);
									})}
								</div>
							</Popover>
						) : null}
						{exportPage != undefined ? (
							<Button
								type="table"
								tooltip={
									<span className="min-w-fit">
										{visibility.filter((x) => x).length <= 0
											? "Selezionare almeno 1 colonna"
											: "Esporta .xlsx"}
									</span>
								}
								disabled={
									visibility.filter((x) => x).length <= 0
								}
								onClick={() => setExportFile(true)}
								isLoading={isExporting && exportFile}
							>
								<FaFileDownload />
							</Button>
						) : null}
					</div>
				</div>
				<div className="flex justify-start w-full ">
					<Button className="md:hidden  flex-1" onClick={onOpen}>
						<IoOptionsSharp />
						<span>Opzioni</span>
					</Button>
				</div>
				<Modal title="Opzioni" onClose={onClose} isOpen={isOpen}>
					<div className="flex flex-col gap-4">
						<TableLimitRadio
							onChange={setLimit}
							limitList={
								defaultLimitList != undefined
									? defaultLimitList
									: limitList
							}
							limit={limit}
						/>
						<SearchBar
							defaultValue={filterBy ?? ""}
							onChange={handleSearch}
						/>
						<div className="flex w-full justify-between">
							{options.refreshable ? (
								<Button
									type="primary"
									tooltip="Ricarica Tabella"
									className="flex-1 w-full"
									onClick={() => {
										queryClient.invalidateQueries([
											...key,
											filterBy,
											orderBy,
											limit,
											offset,
										]);
										toast({
											title: "Tabella Aggiornata",
											status: "success",
											duration: 1000,
											isClosable: true,
										});
										// setRefetch(!refetch);
									}}
								>
									<MdRefresh />
									<span>Ricarica</span>
								</Button>
							) : null}

							{options.canModifyColumn ? (
								<Button
									className="w-full flex-1"
									tooltip={"Seleziona colonne"}
									onClick={() =>
										setColumnMobile(!selectColumnMobile)
									}
								>
									<IoOptions />
									<span className="text-center w-full">
										Seleziona colonne
									</span>
								</Button>
							) : null}

							{exportPage != undefined ? (
								<Button
									type="primary"
									className="flex-1"
									tooltip={
										<span className="min-w-fit">
											{visibility.filter((x) => x)
												.length <= 0
												? "Selezionare almeno 1 colonna"
												: "Esporta .xlsx"}
										</span>
									}
									disabled={
										visibility.filter((x) => x).length <= 0
									}
									onClick={() => setExportFile(true)}
									isLoading={isExporting && exportFile}
								>
									<FaFileDownload />
									<span>Downlaod</span>
								</Button>
							) : null}
						</div>

						<Transition
							show={selectColumnMobile}
							as={Fragment}
							enter="transition ease-out duration-200"
							enterFrom="opacity-0 translate-y-1"
							enterTo="opacity-100 translate-y-0"
							leave="transition ease-in duration-150"
							leaveFrom="opacity-100 translate-y-0"
							leaveTo="opacity-0 translate-y-1"
						>
							<Transition.Child>
								<>
									<div className="flex w-full justify-between">
										<Button
											className="w-full flex-1"
											onClick={() =>
												setVisibility(
													visibility.map((x) => true)
												)
											}
										>
											Tutte
										</Button>
										<Button
											className="w-full flex-1"
											onClick={() =>
												setVisibility(
													visibility.map((x) => false)
												)
											}
										>
											Nessuna
										</Button>
										<Button
											className="w-full flex-1"
											onClick={() =>
												setVisibility(
													columns.map((x) =>
														x.isVisible == undefined
															? true
															: x.isVisible
													)
												)
											}
										>
											<span className="text-center w-full">
												Reimposta colonne
											</span>
										</Button>
									</div>
									<div className="flex flex-col gap-2 pt-4">
										{columns
											.filter((x) => !x.hideOnMobile)
											.map((x, i) => {
												return (
													<span className="flex gap-2 items-center">
														<input
															type="checkbox"
															className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
															key={
																"visibility_" +
																x.name +
																visibility[i]
															}
															onChange={(v) => {
																var newVisibility =
																	visibility.map(
																		(
																			col,
																			num
																		) =>
																			num ==
																			i
																				? v
																						.currentTarget
																						.checked
																				: col
																	);
																setVisibility(
																	newVisibility
																);
															}}
															defaultChecked={
																visibility !=
																undefined
																	? visibility[
																			i
																	  ]
																	: x.isVisible ==
																	  undefined
																	? true
																	: x.isVisible
															}
														/>
														{x.name}
													</span>
												);
											})}
									</div>
								</>
							</Transition.Child>
						</Transition>

						<Button
							type="secondary"
							className=" w-full text-right "
							onClick={onClose}
						>
							Chiudi
						</Button>
					</div>
				</Modal>
			</header>

			<div className="-mx-4 mt-8 sm:-mx-0 relative">
				{selectedEntities.length > 0 && !!bulkActions ? (
					<motion.div
						initial={{ y: 10, opacity: 0 }}
						animate={selected ? { y: 0, opacity: 1 } : {}}
						exit={{ y: -10, opacity: 0 }}
						transition={{ duration: 0.2 }}
						className="absolute left-14 z-10 top-[-20px] flex h-12 items-center space-x-3 border-2 border-gray-300 rounded-lg bg-white sm:left-12"
					>
						{bulkActions(selectedEntities, setSelectedEntities)}
					</motion.div>
				) : null}

				<table className="min-w-full divide-y  divide-gray-300">
					<thead>
						<tr>
							{options.selectable && !!bulkActions ? (
								<th
									scope="col"
									className={classNames(
										"relative sm:w-12 max-md:hidden",
										commonThClass
									)}
								>
									<input
										//@ts-ignore
										ref={checkbox}
										aria-describedby="table-description"
										type="checkbox"
										className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
										id={tableName + "Checkbox"}
										name={tableName + "Checkbox"}
										checked={
											data?.items.length ==
												selectedEntities.length &&
											data?.items.length != 0
										}
										onChange={(
											v: React.ChangeEvent<HTMLInputElement>
										) => {
											if (v.currentTarget.checked)
												setSelectedEntities(
													data?.items
														? [...data?.items]
														: []
												);
											else setSelectedEntities([]);
										}}
									/>
								</th>
							) : null}

							<th className="md:hidden text-center">
								{columns.filter((x, i) => visibility[i])
									.length > 0
									? columns.filter((x, i) => visibility[i])[0]
											.name
									: null}
							</th>
							{columns
								.filter((x, i) => visibility[i])
								.map((x) => {
									return (
										<>
											{options.sortable ? (
												<TableButton
													key={
														(x.id ??
															x.name) as string
													}
													id={x.id}
													setSort={(
														id: string | undefined
													) => setOrderBy(id)}
													label={x.name}
													sortIndex={orderBy}
													className={classNames(
														commonThClass,
														"max-md:hidden"
													)}
												/>
											) : (
												<th className={commonThClass}>
													{x.name}
												</th>
											)}
										</>
									);
								})}

							{!!actions ? (
								<th
									className={classNames(
										commonThClass,
										"max-md:text-center"
									)}
								>
									Opzioni
								</th>
							) : null}
						</tr>
					</thead>
					<tbody className="divide-y divide-gray-200 bg-white">
						{isLoading || data?.numOfItems! <= 0 ? (
							<tr>
								<td
									className={classNames(
										"col-span-full",
										commonThClass
									)}
									colSpan={maxColumn}
									scope="colgroup"
								>
									{isLoading ? (
										<div className="mx-auto w-fit p-4 flex gap-2 items-center font-bold">
											<div className="animate-spin w-fit">
												<AiOutlineLoading3Quarters />
											</div>
											<div className="  animate-pulse w-full ">
												Caricamento Dati...
											</div>
										</div>
									) : (
										<div className="mx-auto w-fit py-4 flex gap-2 items-center font-bold">
											{emptyMessage}
										</div>
									)}
								</td>
							</tr>
						) : (
							data?.items.map((entity: any, i) => (
								<tr
									key={entity.id}
									className={classNames(
										selectedEntities.includes(entity)
											? "bg-gray-100"
											: ""
									)}
								>
									{options.selectable && !!bulkActions ? (
										<td
											className={classNames(
												"relative w-full  max-w-0 py-8 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0 max-md:hidden",
												commonThClass
											)}
										>
											{selectedEntities.includes(
												entity
											) && (
												<div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
											)}
											<input
												type="checkbox"
												className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
												value={entity.email}
												checked={selectedEntities.includes(
													entity
												)}
												onChange={(e) => {
													setSelectedEntities(
														e.target.checked
															? [
																	...selectedEntities,
																	entity,
															  ]
															: selectedEntities.filter(
																	(p) =>
																		p !==
																		entity
															  )
													);
												}}
											/>
										</td>
									) : null}

									<th className="md:hidden flex flex-col py-4 text-sm gap-1 items-center">
										{columns
											.filter(
												(x, i) =>
													visibility[i] &&
													!x.hideOnMobile
											)
											.map((x, i) => {
												if (x.id == undefined)
													return (
														<div>
															{x.Cell != undefined
																? x.Cell(entity)
																: "-"}
														</div>
													);
												var ids = (
													x.id as string
												).split(".");

												var property = entity[ids[0]];
												if (
													property != undefined &&
													ids.length > 1
												)
													var subProperty =
														property[ids[1]];
												return (
													<div>
														{x.Cell != undefined
															? x.Cell(entity)
															: printProperty(
																	subProperty ??
																		entity[
																			x.id
																		]
															  ) ?? "-"}
													</div>
												);
											})}
									</th>

									{columns
										.filter((x, i) => visibility[i])
										.map((x, j) => {
											if (x.id == undefined)
												return (
													<td
														className={classNames(
															cellClass,
															commonThClass,
															"max-md:hidden"
														)}
														key={
															"visibility" +
															(x.id ?? x.name)
														}
													>
														<AnimatedTable
															index={i + j / 3}
														>
															{x.Cell != undefined
																? x.Cell(entity)
																: "-"}
														</AnimatedTable>
													</td>
												);

											var ids = (x.id as string).split(
												"."
											);

											var property = entity[ids[0]];
											if (
												property != undefined &&
												ids.length > 1
											)
												var subProperty =
													property[ids[1]];
											return (
												<td
													className={classNames(
														cellClass,
														commonThClass,
														"max-md:hidden"
													)}
												>
													<AnimatedTable
														index={i + j / 3}
													>
														{x.Cell != undefined
															? x.Cell(entity)
															: printProperty(
																	subProperty ??
																		entity[
																			x.id
																		]
															  ) ?? "-"}
													</AnimatedTable>
												</td>
											);
										})}

									{!!actions ? (
										<td
											className={classNames(
												cellClass,
												commonThClass
											)}
										>
											{actions(
												entity,
												i,
												selectedEntities.includes(
													entity
												)
											)}
										</td>
									) : null}
								</tr>
							))
						)}
					</tbody>
					<tfoot>
						<tr>
							<td
								className="col-span-full"
								colSpan={maxColumn}
								scope="colgroup"
							>
								<div className=" sm:flex sm:flex-1  gap-3 sm:items-center sm:justify-between">
									<div>
										<p className="text-sm text-gray-700">
											Pagina{" "}
											<span className="font-medium">
												{offset + 1}
											</span>{" "}
											su{" "}
											<span className="font-medium">
												{data?.numOfPages}
											</span>{" "}
											di{" "}
											<span className="font-medium">
												{data?.numOfItems}
											</span>{" "}
											risultati
										</p>
									</div>
									<div>
										<nav
											className="isolate inline-flex -space-x-px rounded-md shadow-sm"
											aria-label="Pagination"
										>
											{offset != 0 ? (
												<OffsetButton
													myOffset={offset}
													{...offsetButtonProps}
												/>
											) : null}
											<OffsetButton
												myOffset={offset + 1}
												{...offsetButtonProps}
											/>
											{!!data?.numOfPages &&
											offset + 2 <= data?.numOfPages ? (
												<OffsetButton
													myOffset={offset + 2}
													{...offsetButtonProps}
												/>
											) : null}
										</nav>
									</div>
								</div>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	);
}

function OffsetButton<T>({
	data,
	myOffset,
	offset,
	setOffset,
	...rest
}: {
	data: Paginated<T> | undefined;
	myOffset: number;
	offset: number;
	setOffset: (value: number | undefined) => void;
}) {
	const { color, borderRadius } = useUI();
	const pages = data?.numOfPages != undefined && data?.numOfPages > 1;
	const exists =
		myOffset != 0 &&
		data?.numOfPages != undefined &&
		myOffset <= data?.numOfPages;

	return (
		<button
			aria-current="page"
			onClick={() => setOffset(myOffset - 1)}
			className={classNames(
				"relative z-10 inline-flex items-center rounded-md  px-3 py-1 text-sm font-semibold border-[1px] border-gray-300 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
				offset + 1 == myOffset ? "opacity-100" : "opacity-80",
				offset + 1 == myOffset
					? "bg-indigo-600 text-white"
					: "bg-white text-gray-900",
				pages ? "show" : "hidden"
			)}
		>
			{exists ? myOffset : ""}
		</button>
	);
}

export type Toggle = {
	state: "flat" | "down" | "up";
};

type TBProps<T> = {
	id?: keyof T;
	toggle?: Toggle;
	setSort?: (property: string | undefined) => void;
	sortIndex?: string;
	label: string;
	defaultSorting?: Sorting<T>;
	width?: number;
	className?: string;
};

export function TableButton<T>({
	id,
	setSort,
	sortIndex,
	label,
	width,
	defaultSorting,
	className,
}: TBProps<T>) {
	const state = sortIndex?.charAt(0);
	const [toggle, setToggle] = useState<Toggle>({
		state: state == "+" ? "up" : state == "-" ? "down" : "flat",
	});

	const { colorMode } = useColorMode();

	const isMine = id == sortIndex?.substring(1);
	function toggleState(dontToggle?: boolean) {
		if (setSort != undefined) {
			switch (toggle.state) {
				case "flat":
					setSort("+" + (id as string));
					setToggle({ state: "up" });
					break;
				case "up":
					setSort("-" + (id as string));
					setToggle({ state: "down" });
					break;
				case "down":
					setSort(undefined);
					setToggle({ state: "flat" });
					break;
			}
		}
	}
	function click() {
		if (toggleState != undefined) toggleState();
	}

	useEffect(() => {
		if (!isMine) {
			setToggle({ state: "flat" });
		}
	}, [sortIndex]);

	const [startDrag, setStartDrag] = useState(0);
	const [size, setSize] = useState(0);

	if (id == undefined)
		return (
			<>
				<th className={className}>{label as string}</th>
			</>
		);

	return (
		<>
			<th className={className}>
				<div
					className="flex cursor-pointer bg-transparent items-center gap-1 justify-start user-select-none"
					onClick={click}
				>
					<span>{label}</span>
					<div
						className={classNames(
							"grid place-items-center transition-all  ",
							isMine
								? toggle?.state === "flat"
									? "text-gray-400 rotate-180"
									: toggle?.state === "up"
									? "text-green-400 rotate-0"
									: "text-red-400 rotate-180"
								: "text-gray-400"
						)}
					>
						{toggle?.state == "flat" ? (
							<TbArrowsSort />
						) : (
							<FaArrowUp />
						)}
					</div>
				</div>
			</th>
		</>
	);
}

export default function TableLimitRadio({
	limit,
	limitList,
	onChange,
}: {
	limit: number;
	limitList: number[];
	onChange?: (v: any) => void;
}) {
	const [mem, setMem] = useState(limit);

	return (
		<div>
			<div className="flex items-center justify-between">
				<h2 className="text-sm font-medium leading-6 text-gray-900">
					Numero elementi
				</h2>
				<span className="text-sm font-medium leading-6 text-indigo-600 hover:text-indigo-500">
					per pagina
				</span>
			</div>

			<RadioGroup
				value={mem}
				onChange={(v) => {
					setMem(v);
					if (!!onChange) onChange(v);
				}}
				className="mt-2"
			>
				<RadioGroup.Label className="sr-only">
					Choose a memory option
				</RadioGroup.Label>
				<div className="grid grid-cols-4 gap-4 sm:grid-cols-6">
					{limitList.map((option, i) => (
						<RadioGroup.Option
							key={"radio-limit" + option}
							value={i}
							className={({ active, checked }) =>
								classNames(
									"cursor-pointer focus:outline-none",
									active
										? "ring-2 ring-indigo-600 ring-offset-2"
										: "",
									checked
										? "bg-indigo-600 text-white hover:bg-indigo-500"
										: "ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50",
									"flex items-center justify-center rounded-md py-1 px-1 text-sm font-semibold uppercase sm:flex-1"
								)
							}
						>
							<RadioGroup.Label as="span">
								{limitList[i]}
							</RadioGroup.Label>
						</RadioGroup.Option>
					))}
				</div>
			</RadioGroup>
		</div>
	);
}

export function SearchBar({
	defaultValue,
	onChange,
	className,
	containerClass,
	placeholder,
}: {
	defaultValue: string;
	onChange: (v: React.ChangeEvent<HTMLInputElement>) => void;
	className?: string;
	containerClass?: string;
	placeholder?: string;
}) {
	return (
		<div className={classNames(" flex gap-2 w-full", containerClass ?? "")}>
			<label htmlFor="search-field" className="sr-only">
				{placeholder ?? "Cerca elemento"}
			</label>
			<MagnifyingGlassIcon
				className="pointer-events-none  h-full w-5 text-gray-400"
				aria-hidden="true"
			/>
			<input
				onChange={(v) => onChange(v)}
				id="search-field"
				className={classNames(
					"block h-full w-full border-0 py-0 rounded text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm",
					className ?? ""
				)}
				placeholder="Cerca nella pagina"
				type="search"
				name="search"
				defaultValue={defaultValue}
			/>
		</div>
	);
}
