import { useState } from "react";
import { UsersClient, UserDto } from "../../../Api";
import { Actions, Column } from "../../../components/CsTable";
import { useClient } from "../../../components/UseClient";
import { DeleteUserModal } from "../DeleteUserModal";
import { UpgradeUserModal } from "../UpgradeUserModal";
import GetUser, { AccessLevelType } from "../../../components/GetUser";
import { useNavigate } from "react-router-dom";
import { CsTableButton } from "../../../components/CsElements";
import { Icons } from "../../../utilities/icons";
import { getIdentifier } from "../../../components/utils";
import { accountTypeColumn } from "../../../utilities/ColumnsUtils";
import { FaPlus } from "react-icons/fa";
import ActionButton from "../../../components/ActionButton";
import Button, { ActionButtonContainer } from "../../../components/UI/Button";
import { ActionText, TlTable } from "../../../components/UI/Table";
import useDisclosure from "../../../components/Hooks/useDisclosure";

export default function UserTab() {
	const userClient = useClient(UsersClient);
	const navigate = useNavigate();
	const [changeUser, setChangeUser] = useState<UserDto | undefined>(
		undefined
	);
	const { AccessLevel } = GetUser();

	const {
		isOpen: isUserOpen,
		onOpen: onUserOpen,
		onClose: onUserClose,
	} = useDisclosure();

	const {
		isOpen: isUpgradeUserOpen,
		onOpen: onUpgradeUserOpen,
		onClose: onUpgradeUserClose,
	} = useDisclosure();

	const {
		isOpen: isDeleteUserOpen,
		onOpen: onDeleteUserOpen,
		onClose: onDeleteUserClose,
	} = useDisclosure();

	const columns: Column<UserDto>[] = [
		{
			id: "email",
			name: "Email",
		},
		{
			// @ts-expect-error
			id: "accountType",
			name: "Tipo di Account",
			...accountTypeColumn(),
		},
		{
			id: "firstLogin",
			name: "Primo Login Effettuato",
		},
		{
			// id: "athlete.surname",
			name: "Nome",
			Cell: (user) => getIdentifier(user),
		},
	];

	const actions: Actions<UserDto> = (user, index, selected) => (
		<ActionButtonContainer>
			<Button
				type="table"
				tooltip="Info"
				onClick={() => navigate(`${user.id}`)}
				selected={selected}
			>
				{Icons.Info}
				<ActionText>Info</ActionText>
			</Button>
			{AccessLevel >= AccessLevelType.Admin ? (
				<>
					{/* <CsTableButton
						index={index}
						text={Icons.Edit}
						tooltip="Modifica"
						onClick={() => {
							setChangeUser(user);
							onUserOpen();
						}}
						selected={selected}
					/> */}
					<Button
						type="table"
						tooltip="Modifica"
						onClick={() => navigate(`/accounts/${user.id}/edit`)}
						selected={selected}
					>
						{Icons.Edit}
						<ActionText>Modifica</ActionText>
					</Button>
					<Button
						type="table"
						tooltip="Rendi Aministratore"
						onClick={() => {
							setChangeUser(user);
							onUpgradeUserOpen();
						}}
						selected={selected}
					>
						{Icons.Upgrade}
						<ActionText>Rendi Aministratore</ActionText>
					</Button>

					<Button
						type="table"
						tooltip="Elimina"
						onClick={() => {
							setChangeUser(user);
							onDeleteUserOpen();
						}}
						selected={selected}
					>
						{Icons.Delete}
						<ActionText>Elimina</ActionText>
					</Button>
				</>
			) : null}
		</ActionButtonContainer>
	);
	const actionsAdmins: Actions<UserDto> = (user, index, selected) => (
		<ActionButtonContainer>
			<Button
				type="table"
				tooltip="Info"
				onClick={() => navigate(`${user.id}`)}
				selected={selected}
			>
				{Icons.Info}
				<ActionText>Info</ActionText>
			</Button>
			{AccessLevel >= AccessLevelType.Admin ? (
				<>
					{/* <CsTableButton
						index={index}
						text={Icons.Edit}
						tooltip="Modifica"
						onClick={() => {
							setChangeUser(user);
							onUserOpen();
						}}
						selected={selected}
					/> */}
					<Button
						type="table"
						tooltip="Modifica"
						onClick={() => navigate(`/accounts/${user.id}/edit`)}
						selected={selected}
					>
						{Icons.Edit}
						<ActionText>Modifica</ActionText>
					</Button>
					<Button
						type="table"
						tooltip="Declassa a utente"
						onClick={() => {
							setChangeUser(user);
							onUpgradeUserOpen();
						}}
						selected={selected}
					>
						{Icons.Upgrade}
						<ActionText>Rendi Aministratore</ActionText>
					</Button>

					<Button
						type="table"
						tooltip="Elimina"
						onClick={() => {
							setChangeUser(user);
							onDeleteUserOpen();
						}}
						selected={selected}
					>
						{Icons.Delete}
						<ActionText>Elimina</ActionText>
					</Button>
				</>
			) : null}
		</ActionButtonContainer>
	);

	const extraComponent = (
		<>
			{AccessLevel >= AccessLevelType.Admin ? (
				<Button
					onClick={() => navigate("create")}
					icon={<FaPlus fontSize="0.8rem" />}
				>
					Crea
				</Button>
			) : null}
		</>
	);

	return (
		<div>
			{/* <TlTable
				columns={columns}
				queryKey={["admins"]}
				tableName="Amministratori"
				fetchPage={async (filter, sort, limit, offset) =>
					await userClient.getAdmins(filter, sort, limit, offset)
				}
				persistanceKey="admins"
				defaultSorting={{ order: "-", sortBy: "email" }}
				actions={actions}
				idProperty="id"
				searchPlaceholder="Cerca utente"
				extraComponent={extraComponent}
			/> */}
			<TlTable
				columns={columns}
				queryKey={["users"]}
				tableName="Utenti"
				fetchPage={async (filter, sort, limit, offset) =>
					await userClient.getUsers(filter, sort, limit, offset)
				}
				persistanceKey="users"
				defaultSorting={{ order: "-", sortBy: "email" }}
				actions={actions}
				idProperty="id"
				searchPlaceholder="Cerca utente"
				extraComponent={extraComponent}
			/>

			{changeUser ? (
				<DeleteUserModal
					isOpen={isDeleteUserOpen}
					entity={changeUser!}
					onClose={onDeleteUserClose}
				/>
			) : null}

			{changeUser ? (
				<UpgradeUserModal
					isOpen={isUpgradeUserOpen}
					user={changeUser!}
					onClose={onUpgradeUserClose}
				/>
			) : null}
			{/* <UserModal
				isOpen={isUserOpen}
				onClose={() => {
					setChangeUser(undefined);
					onUserClose();
				}}
				user={changeUser} 
			/>*/}
		</div>
	);
}
