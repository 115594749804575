import React from "react";
import { classNames } from "../utils";

export default function Separator({ className }: { className?: string }) {
	const cl = "hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200 " + className;
	return <div className={cl} aria-hidden="true" />;
}
export function HSeparator({
	className,
	line,
}: {
	className?: string;
	line?: string;
}) {
	const cl = "hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200 " + className;
	return (
		<div className={classNames("relative", className ?? "")}>
			<div
				className="absolute inset-0 flex items-center"
				aria-hidden="true"
			>
				<div
					className={classNames(
						"w-full border-t border-gray-300",
						line ?? ""
					)}
				/>
			</div>
		</div>
	);
}
