import { useRef, useState } from "react";

import { useApiField } from "../useApiField";
import { useClient } from "../UseClient";
import { FilesClient } from "../../Api";
import { FormControl, FormControlProps } from "../FormControl2";
import { Icons } from "../../utilities/icons";
import ActionButton from "../ActionButton";
import Button from "../UI/Button";
import Avatar from "../UI/Avatar";
import { GetAvatarInitial } from "../utils";
import { PictureType } from "../InputFormikFiled";

type PictrureFieldProps = {
	onChange: (files: File[]) => void | Promise<void>;
	defaultValue?: string;
};

export async function getFile<T>(
	client: FilesClient,
	values: T,
	picture: File | undefined,
	propertyName: string
) {
	if (!!picture) {
		var pictureFile = await client
			.addFile(
				{
					data: picture,
					fileName: picture.name,
				},
				picture.type
			)
			.then((r) => r.result);
		return (values = {
			...values,
			[propertyName]: pictureFile.id,
		});
	}
	return values;
}
export async function submitWithFile<T extends object>(
	client: FilesClient,
	values: T,
	picture: File | undefined,
	propertyName: keyof T,
	onSubmit: (values: T) => void
) {
	if (picture !== undefined) {
		await client
			.addFile(
				{
					data: picture,
					fileName: picture.name,
				},
				picture.type
			)
			.then((r) => {
				onSubmit({
					...values,
					[propertyName]: r.result.id,
				});
				return r.result;
			})
			.catch((r) => onSubmit(values));
	} else onSubmit(values);
}

export function PictureSelect({
	defaultValue,
	onChange,
	onRemove,
	isLoading,
	type = "user",
	...props
}: FormControlProps &
	PictrureFieldProps & {
		onRemove?: () => void;
		isLoading?: boolean;
		type?: PictureType;
	}) {
	const ref = useRef<HTMLInputElement>();
	const [changed, setChanged] = useState(false);

	const [field, meta, helpers] = useApiField({
		name: props.name as string,
	});

	const filesClient = useClient(FilesClient);

	const [src, setSrc] = useState<string | undefined>(defaultValue);

	const reader = new FileReader();

	return (
		<FormControl {...props}>
			{({ field, helper, meta }) => (
				<>
					<div className="flex gap-4 items-center ">
						<Avatar
							type={type}
							src={src}
							alt="profile-image-form"
							className={props.className}
						/>

						<input
							type="file"
							accept="image/png, image/jpeg"
							className="hidden"
							style={{ display: "none" }}
							id={props.id ?? "buttonPicture"}
							{...props}
							// value={field.value}
							name={field.name}
							onBlur={field.onBlur}
							// {...field}
							required={props.required}
							onChange={(e: any) => {
								onChange(e.target.files);
								// console.log(e.target.files[0]);
								reader.readAsDataURL(e.target.files[0]);
								reader.addEventListener("load", () => {
									setSrc(reader.result as string);
								});
							}}
						/>
						<div className="flex flex-col gap-2">
							<Button
								type="table"
								onClick={(e: any) => {
									var button = document.getElementById(
										props.id ?? "buttonPicture"
									);

									if (button != null) button.click();
								}}
								tooltip="seleziona immagine"
							>
								{Icons.Edit}
							</Button>

							<Button
								type="table"
								onClick={() => {
									if (onRemove != undefined) onRemove();
									setSrc(undefined);
								}}
								disabled={src == undefined}
								tooltip="rimuovi immagine"
							>
								{Icons.Cancel}
							</Button>
						</div>
					</div>
					{/* )} */}
				</>
			)}
		</FormControl>
	);
}
