import { useQuery } from "@tanstack/react-query";
import { AthleteDto, AthletesClient } from "../../Api";
import { useClient } from "../UseClient";
import { classNames } from "../utils";

export function AthletesSelect({
	athlete,
	onSetAthlete,
	dontInclude,
	customAthletes,
	placeholderText,
	className,
	id,
	required = false,
}: {
	athlete: AthleteDto | string | undefined;
	onSetAthlete: (athlete: AthleteDto | undefined) => void;
	dontInclude?: string[];
	customAthletes?: AthleteDto[];
	placeholderText?: string;
	className?: string;
	required?: boolean;
	id?: string;
}) {
	const athletesClient = useClient(AthletesClient);

	const { data: athletes, isLoading: isLoadingAthletes } = useQuery({
		queryKey: ["athletes"],
		queryFn: () =>
			athletesClient
				.getAllAthletes(undefined, undefined, undefined, undefined)
				.then((r) => r.result),
	});
	return (
		<select
			id={id}
			name={id}
			value={
				athlete
					? typeof athlete == "string"
						? athlete
						: athlete?.athleteId
					: "placeholder"
			}
			onChange={(x) =>
				onSetAthlete(
					(customAthletes ?? athletes?.items ?? []).find(
						(y) => y.athleteId == x.currentTarget.value
					)
				)
			}
			className={classNames(
				"py-1 rounded px-2 border-gray-300 text-black focus:ring-indigo-600 w-full",
				className ?? ""
			)}
		>
			<option value="placeholder" disabled={required}>
				{placeholderText ?? "Seleziona corso"}
			</option>
			{(customAthletes ?? athletes?.items ?? [])
				.filter((x) => !dontInclude?.includes(x.athleteId))
				.map((x) => (
					<option value={x.athleteId} key={"select" + x.athleteId}>
						{`${x?.surname} ${x?.name}`}
					</option>
				))}
		</select>
		// <Select
		// 	minW="fit-content"
		// 	value={
		// 		athlete
		// 			? typeof athlete == "string"
		// 				? athlete
		// 				: athlete?.athleteId
		// 			: "placeholder"
		// 	}
		// 	onChange={(x) => {
		// 		var at = (customAthletes ?? athletes?.items ?? []).find(
		// 			(y) => y.athleteId == x.currentTarget.value
		// 		);
		// 		onSetAthlete(at!);
		// 	}}
		// 	{...rest}
		// >
		// 	<option value="placeholder" disabled>
		// 		{placeholderText ?? "Seleziona atleta"}
		// 	</option>
		// 	{(customAthletes ?? athletes?.items ?? [])
		// 		.filter((x) => !dontInclude?.includes(x.athleteId))
		// 		.map((x) => (
		// 			<option value={x.athleteId} key={"select" + x.athleteId}>
		// 				{`${x?.surname} ${x?.name}`}
		// 			</option>
		// 		))}
		// </Select>
	);
}
