import { Formik, Form } from "formik";
import { useUI } from "../UiContext";
import { UserModal } from "./users/UserModal";
import { InputField, PasswordField } from "../components/InputFormikFiled";
import Button from "../components/UI/Button";
import useDisclosure from "../components/Hooks/useDisclosure";

export type LoginValues = { email: string; password: string };

type LoginFormProps = {
	isLoading: boolean;
	initialValues: LoginValues;
	onSubmit: (values: LoginValues) => void;
};

export default function LoginForm({
	isLoading,
	onSubmit,
	initialValues,
}: LoginFormProps) {
	const { color } = useUI();

	const {
		isOpen: isCreateUserOpen,
		onOpen: onCreateUserOpen,
		onClose: onCreateUserClose,
	} = useDisclosure();

	return (
		<>
			<Formik<LoginValues>
				initialValues={{ ...initialValues }}
				onSubmit={onSubmit}
			>
				{({ values, setFieldValue, isSubmitting }) => (
					<>
						<Form>
							<div className="flex flex-col items-center gap-6 px-18">
								<span className="flex-1 w-full">
									<InputField
										id="email"
										name="email"
										label="Email"
										placeholder="Inserisci email"
										required
										className=" w-full"
									/>
								</span>
								<span className="flex-1 w-full">
									<PasswordField
										id="password"
										name="password"
										label="Password"
										format={(v) => v.trim()}
										placeholder="Inserire Password"
										required
										color={"black"}
									/>
								</span>

								<Button submit isLoading={isLoading}>
									Login
								</Button>
								{/* <HStack h=".5rem">
									<Divider
										bg="black"
										h=".1rem"
										borderRadius="full"
									/>
									<Text>O</Text>
									<Divider
										bg="black"
										h=".1rem"
										borderRadius="full"
									/>
								</HStack> */}
								{/* <CsButton
									text="Registrati"
									onClick={onCreateUserOpen}
									variant="ghost"
								/> */}
							</div>
						</Form>
					</>
				)}
			</Formik>
			<UserModal
				isOpen={isCreateUserOpen}
				onClose={onCreateUserClose}
				from="login"
			/>

		</>
	);
}
