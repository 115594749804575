import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
	ContestRequestDto,
	ContestRequestClient,
	ContestsClient,
	RequestState,
	ContestDto,
	EventType,
} from "../../Api";
import { useClient } from "../../components/UseClient";
import GetUser, { AccessLevelType } from "../../components/GetUser";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { CsTabs2, TabProp } from "../../components/CsTabs";

import DefaultRequestTab from "./Tabs/DefaultRequestTab";
import { it } from "date-fns/locale";

import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

import { EventTypeTrans, tran } from "../../utilities/Translations";
import {
	canPartecipate,
	classNames,
	getAccessLevlRole,
	getFileUrl,
} from "../../components/utils";
import RegisterManagerModal from "./components/RegisterManagerModal";
import { DeleteContestModal } from "./DeleteContestModal";
import useDisclosure from "../../components/Hooks/useDisclosure";
import Button from "../../components/UI/Button";
import { addDays, differenceInBusinessDays, format, isAfter } from "date-fns";
import { Icons } from "../../utilities/icons";
import Badge from "../../components/UI/Badge";

export type ContestAction =
	| "update"
	| "delete"
	| "entrant"
	| "signup"
	| "unsignup";

export default function SingleContestPage() {
	const { id } = useParams();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const client = useClient(ContestsClient);
	const signClient = useClient(ContestRequestClient);
	const toast = useToast();
	const { data, isLoading, error } = useQuery(
		["contests", id],
		async () => await client.getContest(id!).then((r) => r.result)
	);
	const { AccessLevel } = GetUser();

	const { mutate: acceptMutation, isLoading: isAcceptLoading } = useMutation(
		(request: ContestRequestDto) => signClient.acceptRequest(request.id),
		{
			onSuccess: () => {
				toast({
					title: "Utente Accettato",
					status: "success",
					duration: 3000,
					isClosable: true,
				});
			},
			onError: () => {
				toast({
					title: "Si è verificato un errore",
					status: "error",
					duration: 3000,
					isClosable: true,
				});
			},
			onSettled: () => {
				queryClient.invalidateQueries(["contests"]);
				queryClient.invalidateQueries(["requests"]);
			},
		}
	);

	const { mutate: rejectMutation, isLoading: isRejectLoading } = useMutation(
		(request: ContestRequestDto) => signClient.rejectRequest(request.id),
		{
			onSuccess: () => {
				toast({
					title: "Utente Rifiutato",
					status: "success",
					duration: 3000,
					isClosable: true,
				});
			},
			onError: () => {
				toast({
					title: "Si è verificato un errore",
					status: "error",
					duration: 3000,
					isClosable: true,
				});
			},
			onSettled: () => {
				queryClient.invalidateQueries(["contests"]);
				queryClient.invalidateQueries(["requests"]);
			},
		}
	);

	function handleChange(
		request: ContestRequestDto,
		action: "accept" | "reject"
	) {
		switch (action) {
			case "accept":
				acceptMutation(request);
				break;
			case "reject":
				rejectMutation(request);
				break;
		}
	}

	if (error) {
		toast({
			title: "Evento Non Trovato",
			status: "warning",
			duration: 3000,
			isClosable: true,
		});
		localStorage.removeItem("ContestId");
		navigate("/contests");
	}

	const UserTabs: TabProp[] = [
		{
			name: "Iscritti",
			tab: (
				<DefaultRequestTab
					contest={data}
					handleChange={handleChange}
					RejectLoading={isRejectLoading}
					type={RequestState.Approved}
				/>
			),
		},
		...(AccessLevel >= AccessLevelType.Admin
			? [
					{
						name: "Richieste",
						tab: (
							<DefaultRequestTab
								contest={data}
								handleChange={handleChange}
								AcceptLoading={isAcceptLoading}
								RejectLoading={isRejectLoading}
								type={RequestState.Submitted}
							/>
						),
					},
					{
						name: "Rifiutati",
						tab: (
							<DefaultRequestTab
								contest={data}
								handleChange={handleChange}
								AcceptLoading={isAcceptLoading}
								type={RequestState.Rejected}
							/>
						),
					},
			  ]
			: []),
	];

	return (
		<>
			<div className="relative">
				{isLoading ? <div>Loading...</div> : <Example contest={data} />}
				{/* <InfoTab contest={data} isLoading={isLoading} />
				<Spacer h="5rem" /> */}
				<CsTabs2 tabs={UserTabs} />
			</div>
		</>
	);
}

export function Example({
	contest,
	isLoading = false,
}: {
	contest: ContestDto | undefined;
	isLoading?: boolean;
}) {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const navigate = useNavigate();
	const toast = useToast();

	var image = "";
	switch (contest?.eventType) {
		case EventType.Contest:
			image = "/competition.jpg";
			break;
		case EventType.Stage:
			image = "/Stage.jpg";
			break;
		case EventType.Event:
			image = "/competition.jpg";
			break;
		case EventType.Seminar:
			image = "/Stage.jpg";
			break;
	}
	const { currentUser, AccessLevel } = GetUser();

	const {
		isOpen: isDeleteContestOpen,
		onOpen: onDeleteContestOpen,
		onClose: onDeleteContestClose,
	} = useDisclosure();

	const {
		isOpen: isManagerOpen,
		onOpen: onManagerOpen,
		onClose: onManagerClose,
	} = useDisclosure();

	const {
		isOpen: isUpdateContestOpen,
		onOpen: onUpdateContestOpen,
		onClose: onUpdateContestClose,
	} = useDisclosure();

	const stillOpen = !!contest && isAfter(new Date(contest?.date), new Date());

	return (
		<>
			<main>
				<header className="relative isolate ">
					<div
						className="absolute inset-0 -z-10 overflow-hidden"
						aria-hidden="true"
					>
						<div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
							<div
								className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
								style={{
									clipPath:
										"polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
								}}
							/>
						</div>
						<div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
					</div>

					<div className="mx-auto max-w-7xl px-4 pb-10 sm:px-6 lg:px-8">
						<div className="mx-auto flex max-w-2xl items-center lg:justify-between lg:gap-2 gap-6 justify-center gap-x-8 lg:mx-0 lg:max-w-none flex-wrap">
							<div className="lg:flex max-lg:flex-col lg:justify-start   items-center gap-x-6  ">
								<img
									src={
										!!contest?.contestPhoto
											? getFileUrl(contest?.contestPhoto)
											: image
									}
									alt=""
									className="h-32 w-32 flex-none rounded-full ring-1 ring-gray-900/10"
								/>
								<h1>
									<div className="text-sm leading-6 text-gray-500">
										{tran(
											EventTypeTrans,
											contest?.eventType
										)}
									</div>
									<div className="mt-1 leading-6 text-gray-900 font-bold text-lg">
										{contest?.name}
									</div>
									<div className="text-sm leading-6 text-gray-500 flex items-center gap-1">
										<span>{Icons.People}</span>
										<span>
											{contest?.registeredCounter}{" "}
											Iscritti
										</span>
									</div>
								</h1>
							</div>
							<div className="flex items-center gap-x-4 sm:gap-x-6">
								<Button
									icon={Icons.ManageAccount}
									onClick={onManagerOpen}
								>
									Iscriviti
								</Button>
								{AccessLevel >= AccessLevelType.Admin ? (
									<>
										<Button
											type="secondary"
											// icon={Icons.Edit}
											className="max-md:hidden "
											onClick={() =>
												isLoading || !stillOpen
													? toast({
															title: "Evento Concluso",
															status: "error",
															duration: 3000,
															isClosable: true,
													  })
													: navigate("edit")
											}
										>
											Modifica
										</Button>
										<Button
											type="secondary"
											// icon={Icons.Edit}
											disabled={isLoading}
											onClick={onDeleteContestOpen}
											className="max-md:hidden"
										>
											Elimina
										</Button>{" "}
										<Menu
											as="div"
											className="relative md:hidden"
										>
											<Menu.Button className="-m-3 block p-3">
												<span className="sr-only">
													More
												</span>
												<EllipsisVerticalIcon
													className="h-5 w-5 text-gray-500"
													aria-hidden="true"
												/>
											</Menu.Button>

											<Transition
												as={Fragment}
												enter="transition ease-out duration-100"
												enterFrom="transform opacity-0 scale-95"
												enterTo="transform opacity-100 scale-100"
												leave="transition ease-in duration-75"
												leaveFrom="transform opacity-100 scale-100"
												leaveTo="transform opacity-0 scale-95"
											>
												<Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 px-2  shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
													<Menu.Item>
														{({ active }) => (
															<Button
																type="secondary"
																// icon={Icons.Edit}
																className="py-2"
																onClick={() =>
																	isLoading ||
																	!stillOpen
																		? toast(
																				{
																					title: "Evento Concluso",
																					status: "error",
																					duration: 3000,
																					isClosable:
																						true,
																				}
																		  )
																		: navigate(
																				"edit"
																		  )
																}
															>
																Modifica
															</Button>
														)}
													</Menu.Item>
													<Menu.Item>
														{({ active }) => (
															<Button
																type="secondary"
																// icon={Icons.Edit}
																disabled={
																	isLoading
																}
																onClick={
																	onDeleteContestOpen
																}
																className="py-2"
															>
																Elimina
															</Button>
														)}
													</Menu.Item>
												</Menu.Items>
											</Transition>
										</Menu>{" "}
									</>
								) : null}
							</div>
						</div>
					</div>
				</header>

				<div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
					<div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
						{/* Invoice summary */}
						<div className="lg:col-start-3 lg:row-end-1">
							<h2 className="sr-only">Summary</h2>
							<div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
								<dl className="flex flex-wrap">
									<div className="flex-auto pl-6 pt-6">
										<dt className="text-sm font-bold leading-6 text-gray-500 flex items-center gap-1">
											<span>{Icons.Price}</span>
											<span>Prezzo</span>
										</dt>
										<dd className="mt-1  font-bold leading-6 text-gray-900 text-md">
											{contest?.price.toFixed(2)}€
										</dd>
									</div>
									{/* <div className="flex-none self-end px-6 pt-4">
										<dt className="sr-only">Status</dt>
										<dd className="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20">
											Paid
										</dd>
									</div> */}
									<div className="mt-6 flex flex-col w-full flex-none gap-2 border-t text-gray-500 border-gray-900/5 px-6 pt-6">
										<dt className="text-sm font-bold leading-6 text-gray-500 flex items-center gap-1">
											<span>{Icons.Place}</span>
											<span>Luogo</span>
										</dt>
										<dd className="mt-1  font-bold leading-6 text-gray-900 text-md">
											{contest?.position}
										</dd>
									</div>
									{/* <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
										<dt className="flex-none">
											<span className="sr-only">
												Due date
											</span>
											<CalendarDaysIcon
												className="h-6 w-5 text-gray-400"
												aria-hidden="true"
											/>
										</dt>
										<dd className="text-sm leading-6 text-gray-500">
											<time dateTime="2023-01-31">
												January 31, 2023
											</time>
										</dd>
									</div>
									<div className="mt-4 flex w-full flex-none gap-x-4 px-6">
										<dt className="flex-none">
											<span className="sr-only">
												Status
											</span>
											<CreditCardIcon
												className="h-6 w-5 text-gray-400"
												aria-hidden="true"
											/>
										</dt>
										<dd className="text-sm leading-6 text-gray-500">
											Paid with MasterCard
										</dd>
									</div> */}
								</dl>
								<div className="mt-6 flex flex-col w-full flex-none gap-2 border-t text-gray-500 border-gray-900/5 px-6 pt-6">
									<dt className="text-sm font-bold leading-6 text-gray-500 flex items-center gap-1">
										<span>{Icons.Calendar}</span>
										<span>Data</span>
									</dt>
									<dd className="mt-1  font-bold leading-6 text-gray-900 text-md max-lg:flex-col flex lg:gap-4">
										<span>
											{!!contest && contest?.numOfDay > 1
												? format(
														new Date(contest?.date),
														"P",
														{
															locale: it,
														}
												  ) +
												  "\n-\n" +
												  format(
														addDays(
															new Date(
																contest?.date
															),
															contest?.numOfDay
														),
														"P",
														{
															locale: it,
														}
												  )
												: format(
														new Date(
															contest?.date!
														),
														"P",
														{
															locale: it,
														}
												  )}
										</span>
										{!!contest ? (
											<span className="text-gray-400">
												Mancano{" "}
												{differenceInBusinessDays(
													new Date(contest?.date),
													new Date()
												)}{" "}
												giorni
											</span>
										) : null}
									</dd>
								</div>
								<div className="mt-6 border-t border-gray-900/5 px-6 py-6 flex flex-col gap-2">
									<dt className="text-sm font-bold leading-6 text-gray-500 flex items-center gap-1">
										<span>{Icons.Deadline}</span>
										<span>Termine Iscrisione</span>
									</dt>
									<dd className="mt-1  font-bold leading-6 text-gray-900 text-md max-lg:flex-col flex lg:gap-4 ">
										<span>
											{format(
												new Date(
													contest?.deadlineDate ??
														contest?.date ??
														new Date()
												),
												"P",
												{
													locale: it,
												}
											)}
										</span>
										<span className="text-gray-400">
											{!!contest?.deadlineDate ? (
												new Date(
													contest?.deadlineDate
												).getTime() <
												new Date().getTime() ? (
													<span>
														Iscrizioni chiuse
													</span>
												) : (
													<span>
														Hai{" "}
														{differenceInBusinessDays(
															new Date(
																contest?.deadlineDate
															),
															new Date()
														)}{" "}
														giorni per iscriverti
													</span>
												)
											) : null}
										</span>
									</dd>
								</div>
							</div>
						</div>

						{/* Invoice */}
						<div className="-mx-4 px-4 py-8 shadow-sm ring-1  ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-6">
							<h2 className="text-base font-semibold leading-6 text-gray-900 ">
								Descrizione
							</h2>
							<dl className="mt-6 grid grid-cols-1 text-sm text-gray-500 leading-6 sm:grid-cols-1 ">
								<div className="sm:pr-4 ">
									{contest?.description ??
										"Nessuna descrizione disponibile"}
								</div>
							</dl>
							{contest?.eventType == EventType.Contest ? (
								<>
									<h2 className="text-base font-semibold leading-6 text-gray-900 pt-6">
										Categorie
									</h2>
									<dl className="mt-6 grid grid-cols-1 text-sm text-gray-500 leading-6 sm:grid-cols-2">
										<div className="flex gap-1 w-full flex-wrap ">
											{contest?.category.map((x) => (
												<Badge
													color={classNames(
														"bg-indigo-500 text-white",
														canPartecipate(
															currentUser,
															x
														)
															? " font-bold border-2 border-black"
															: ""
													)}
												>
													{x}
												</Badge>
											))}
										</div>
									</dl>
								</>
							) : null}
						</div>
					</div>
				</div>
				{!!contest ? (
					<>
						<RegisterManagerModal
							isOpen={isManagerOpen}
							contest={contest}
							onClose={onManagerClose}
							athletes={currentUser?.family?.athletes}
						/>
						{AccessLevel >= AccessLevelType.Admin ? (
							<>
								<DeleteContestModal
									isOpen={isDeleteContestOpen}
									entity={contest}
									onClose={() => {
										onDeleteContestClose();
									}}
								/>
							</>
						) : null}
					</>
				) : null}
			</main>
		</>
	);
}

type InfoProps = {
	title: JSX.Element | string;
	data: JSX.Element | string;
	accessLevel?: AccessLevelType;
};

export function PageSkeleton({
	actions,
	titleBox,
	menuItmes,
	info,
	main,
}: {
	actions?: JSX.Element;
	titleBox: JSX.Element;
	menuItmes?: JSX.Element[];
	info?: InfoProps[];
	main: InfoProps[];
}) {
	const { AccessLevel } = GetUser();
	return (
		<main>
			<header className="relative isolate ">
				<div
					className="absolute inset-0 -z-10 overflow-hidden"
					aria-hidden="true"
				>
					<div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
						<div
							className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
							style={{
								clipPath:
									"polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
							}}
						/>
					</div>
					<div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
				</div>

				<div className="mx-auto max-w-7xl px-4 pb-10 sm:px-6 lg:px-8">
					<div className="mx-auto flex max-w-2xl items-center lg:justify-between lg:gap-2 gap-6 justify-center gap-x-8 lg:mx-0 lg:max-w-none flex-wrap">
						<div className="lg:flex max-lg:flex-col lg:justify-start   items-center gap-x-6  ">
							{titleBox}
						</div>
						<div className="flex max-md:items-start md:items-center gap-x-4 sm:gap-x-6">
							{actions ?? null}
							{menuItmes && menuItmes?.length > 0 ? (
								<Menu as="div" className="relative sm:hidden">
									<Menu.Button className="-m-3 block p-3">
										<span className="sr-only">More</span>
										<EllipsisVerticalIcon
											className="h-5 w-5 text-gray-500"
											aria-hidden="true"
										/>
									</Menu.Button>

									<Transition
										as={Fragment}
										enter="transition ease-out duration-100"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										<Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
											{menuItmes.map((item) => (
												<Menu.Item>
													<>
														{({
															active,
														}: {
															active: boolean;
														}) => ({ item })}
													</>
												</Menu.Item>
											))}
										</Menu.Items>
									</Transition>
								</Menu>
							) : null}
						</div>
					</div>
				</div>
			</header>

			<div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
				<div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
					{/* Invoice summary */}
					{info ? (
						<div className="lg:col-start-3 lg:row-end-1">
							<h2 className="sr-only">Summary</h2>
							<div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
								{info.map((x) => {
									return (
										<dl className="flex flex-wrap">
											<div className="flex-auto pl-6 py-3 border-t border-gray-900/5 ">
												<dt className="text-sm font-bold leading-6 text-gray-500 flex items-center gap-1">
													{x.title}
												</dt>
												<dd className="mt-1  font-bold leading-6 text-gray-900 text-md max-lg:flex-col flex lg:gap-4">
													{x.data}
												</dd>
											</div>
										</dl>
									);
								})}
							</div>
						</div>
					) : null}

					<div
						className={classNames(
							"-mx-4 px-4 py-8 flex flex-col gap-4 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14  lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-10 xl:py-6",
							!info ? "col-span-full" : "lg:col-span-2"
						)}
					>
						{main
							.filter(
								(x) =>
									!x.accessLevel ||
									AccessLevel >= x.accessLevel
							)
							.map((x) => {
								return (
									<>
										<h2 className="text-base font-semibold leading-6 text-gray-900 border-b-2 flex justify-between max-md:flex-col">
											<span>{x.title}</span>
											{x.accessLevel ? (
												<span className="text-xs text-gray-400">
													visibile da account{" "}
													{getAccessLevlRole(
														x.accessLevel
													)}
												</span>
											) : null}
										</h2>
										<dl className="mb-6 grid grid-cols-1 text-sm text-gray-500 leading-6 sm:grid-cols-1">
											<div className="sm:pr-4">
												{x.data}
											</div>
										</dl>
									</>
								);
							})}
					</div>
				</div>
			</div>
		</main>
	);
}
