import CsModal from "../../../components/CsModal";
import { ItemDto, ItemsClient, ManageItemDto } from "../../../Api";
import ItemsForm from "./ItemsForm";
import { useClient } from "../../../components/UseClient";
import { ModalProps } from "../../../utilities/typeUtils";
import useToastMutation from "../../../components/Hooks/useToastMutation";

export default function ItemsModal({
	isOpen,
	onClose,
	action = "create",
	item,
	familyId,
}: ModalProps & { familyId?: string; item?: ItemDto }) {
	const client = useClient(ItemsClient);

	const { mutate: createMutation, isLoading: isCreating } = useToastMutation(
		(values: ManageItemDto) => client.createItem(values),
		onClose,
		["items"],
		"Articolo Creato"
	);
	const { mutate: deleteItem, isLoading: isDeleting } = useToastMutation(
		() => client.deleteItem(item?.id!),
		onClose,
		["items"],
		"Articolo Eliminato"
	);
	const { mutate: updateMutation, isLoading: isUpdating } = useToastMutation(
		(values: ManageItemDto) => client.updateItem(item?.id ?? null, values),
		onClose,
		["items"],
		"Articolo Modificato"
	);

	return (
		<CsModal
			onClose={onClose}
			isOpen={isOpen}
			title={(!item ? "Crea" : "Modifica") + " Oggetto"}
		>
			<ItemsForm
				isLoading={isCreating || isUpdating}
				action={!item ? "create" : "update"}
				initialValues={{
					title: !!item ? item.title : "",
					description: !!item ? item.description : undefined,
					price: !!item ? item.price : 0,
					quantity: !!item ? item.quantity : undefined,
					itemPhotoId: !!item ? item.itemPhotoId : undefined,
					// itemPhoto: 	!!item ? item.itemPhoto :undefined,
				}}
				deleteMutation={deleteItem}
				isDeleting={isDeleting}
				onSubmit={!item ? createMutation : updateMutation}
			/>
		</CsModal>
	);
}
