import  { useState } from "react";

export default function useOffset(persistanceKey: string | undefined) {
	const offsetKey = persistanceKey + "_offset";
	const localOffset = Number(localStorage.getItem(offsetKey));
	const [offset, _setOffset] = useState<number>(localOffset);

	const setOffset = (value: number | undefined) => {
		if (value == undefined) {
			localStorage.removeItem(offsetKey);
			_setOffset(0);
		} else {
			localStorage.setItem(offsetKey, value.toString());
			_setOffset(value);
		}
	};

	return { offset: offset, setOffset: setOffset };
}
