import {
	ContestDto,
	ContestRequestClient,
	RequestState,
	ContestRequestDto,
	RequestFrom,
	EventType,
} from "../../../Api";

import { CsTableButton } from "../../../components/CsElements";
import { FiTrash2 } from "react-icons/fi";
import { AiOutlineCheck } from "react-icons/ai";
import GetUser, { AccessLevelType } from "../../../components/GetUser";
import { GiFishingHook } from "react-icons/gi";
import { useClient } from "../../../components/UseClient";
import { AthleteLink } from "../../../components/TextLink";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import { Icons } from "../../../utilities/icons";
import { ActionText, TlTable } from "../../../components/UI/Table";
import { Actions, Column } from "../../../components/CsTable";
import Button, { ActionButtonContainer } from "../../../components/UI/Button";
import { FaX } from "react-icons/fa6";
import { MatchesModal } from "../components/MatchesModal";
import useDisclosure from "../../../components/Hooks/useDisclosure";
import { useState } from "react";

type Props = {
	contest?: ContestDto;
	handleChange: (
		requets: ContestRequestDto,
		action: "accept" | "reject"
	) => void;
	RejectLoading?: boolean;
	AcceptLoading?: boolean;
	// columns: Column[];
	type: RequestState;
};

export default function DefaultRequestTab({
	contest,
	handleChange,
	type,
}: Props) {
	const { AccessLevel, currentUser } = GetUser();

	const client = useClient(ContestRequestClient);
	const expired =
		!!contest && new Date(contest?.deadlineDate).getTime() < Date.now();
	const {
		isOpen: isCreateUserOpen,
		onOpen: onCreateUserOpen,
		onClose: onCreateUserClose,
	} = useDisclosure();

	const [changeEntity, setchangeEntity] = useState<
		ContestRequestDto | undefined
	>(undefined);
	const actions: Actions<ContestRequestDto> = (request, index) => (
		<ActionButtonContainer>
			{type == RequestState.Submitted || type == RequestState.Rejected ? (
				<Button
					type="table"
					tooltip={
						type == RequestState.Rejected ? "Ripesca" : "Accetta"
					}
					onClick={() => handleChange(request, "accept")}
				>
					{type == RequestState.Rejected ? (
						<>
							<GiFishingHook />
							<ActionText>Ripesca</ActionText>
						</>
					) : (
						<>
							<AiOutlineCheck />
							<ActionText>Accetta</ActionText>
						</>
					)}
				</Button>
			) : null}

			{type == RequestState.Approved || type == RequestState.Submitted ? (
				<>
					{type == RequestState.Approved &&
					request.athlete.associationId ==
						currentUser?.associationId &&
					AccessLevel >= AccessLevelType.Admin &&
					contest?.eventType == EventType.Contest ? (
						<Button
							type="table"
							tooltip={"Compila Incontri"}
							onClick={() => {
								setchangeEntity(request);
								onCreateUserOpen();
							}}
						>
							{Icons.Edit}
							<ActionText>Compila Incontri</ActionText>
						</Button>
					) : null}
					<Button
						type="table"
						tooltip={
							type == RequestState.Approved ? "Scarta" : "Rifiuta"
						}
						onClick={() => handleChange(request, "reject")}
					>
						<FaX />
						<ActionText>
							{type == RequestState.Approved
								? "Scarta"
								: "Rifiuta"}
						</ActionText>
					</Button>
				</>
			) : null}
		</ActionButtonContainer>
	);

	const placeholder =
		type == RequestState.Submitted
			? "Cerca Richiesta"
			: type == RequestState.Approved
			? "Cerca Partecipante"
			: "Cerca Scartato";

	const columns: Column<ContestRequestDto>[] = [
		{
			id: "athlete",
			name: "Atleta",
			Cell: ({ athlete }) => <AthleteLink athlete={athlete} />,
		},

		{
			id: "date",
			name: "Data richiesta",
			Cell: ({ date }) => format(new Date(date), "P", { locale: it }),
		},
		...(type == RequestState.Approved &&
		contest?.eventType == EventType.Contest
			? ([
					{
						id: "playedMatches",
						name: "Incontri svolti",
						Cell: ({ playedMatches, athlete }) =>
							athlete.associationId ==
								currentUser?.associationId &&
							!!playedMatches ? (
								<>{playedMatches}</>
							) : (
								"-"
							),
					},
					{
						id: "wonMatches",
						name: "Incontri vinti",
						Cell: ({ wonMatches, athlete }) =>
							athlete.associationId ==
								currentUser?.associationId && !!wonMatches ? (
								<>{wonMatches}</>
							) : (
								"-"
							),
					},
					{
						id: "ranking",
						name: "Classificato/a",
						Cell: ({ ranking, athlete }) =>
							athlete.associationId ==
								currentUser?.associationId && !!ranking ? (
								<>{ranking}°</>
							) : (
								"-"
							),
					},
					{
						name: "Totale punti",
						Cell: ({
							ranking,
							wonMatches,
							playedMatches,
							athlete,
						}) => {
							var point = 0;
							point += (wonMatches ?? 0) * 3;
							point += playedMatches ?? 0;
							point +=
								ranking == 1
									? 4
									: ranking == 2
									? 3
									: ranking == 3
									? 2
									: 0;
							return point + " punti";
						},
					},
			  ] as Column<ContestRequestDto>[])
			: []),
	];

	return (
		<>
			<TlTable<ContestRequestDto>
				columns={columns}
				queryKey={["requests", type, contest?.id]}
				fetchPage={async (filter, sort, limit, offset) =>
					await client.getRequests(
						contest?.id!,
						"state:eq:" + (type as number),
						sort,
						limit,
						offset,
						RequestFrom.Contest
					)
				}
				persistanceKey={"athletesRequest" + type}
				// defaultSorting={{ order: "-", sortBy: "date" }}
				actions={
					AccessLevel >= AccessLevelType.Admin && !expired
						? actions
						: undefined
				}
				idProperty="id"
				searchPlaceholder={placeholder}
			/>

			{changeEntity ? (
				<MatchesModal
					isOpen={isCreateUserOpen}
					onClose={() => {
						onCreateUserClose();
						// setchangeEntity(undefined);
					}}
					action={changeEntity ? "update" : "create"}
					request={changeEntity}
				/>
			) : null}
		</>
	);
}
