import * as Yup from "yup";
import { RoleType } from "../Api";

export const addressSchema = Yup.object({
	street: Yup.string().required("Questo campo è obbligatorio"),
	city: Yup.string().required("Questo campo è obbligatorio"),
	postalCode: Yup.string()
		.matches(/^[0-9]{5}$/, "Deve essere un numero di 5 cifre")
		.required("Questo campo è obbligatorio"),
	country: Yup.string().required("Questo campo è obbligatorio"),
	province: Yup.string().required("Questo campo è obbligatorio"),
	region: Yup.string().required("Questo campo è obbligatorio"),
});

export const phoneSchema = Yup.string().matches(
	/^[+][0-9]{2} [0-9]{3} [0-9]{3} [0-9]{4}$/,
	"Formato non valido, esempio: '+39 234 345 2345'"
);
// .required("Questo campo è obbligatorio");

export const athleteSchema = Yup.object({
	name: Yup.string().required("Questo campo è obbligatorio"),
	surname: Yup.string().required("Questo campo è obbligatorio"),
	taxId: Yup.string().required("Questo campo è obbligatorio"),
	birth: Yup.date().required("Questo campo è obbligatorio"),
	medicalCertificateDeadline: Yup.date().nullable(),
	weight: Yup.number().when("userRoleType", {
		is: RoleType.Athlete,
		then: Yup.number().min(0).required(),
	}),
	userPoint: Yup.number().when("userRoleType", {
		is: RoleType.Athlete,
		then: Yup.number()
			.min(0, "Questo campo non può essere negativo")
			.required("Questo campo è obbligatorio"),
	}),
	phoneNumber: phoneSchema.nullable(),
	residenceAddress: addressSchema,
});

export const passwordSchema = Yup.string()
	//   .matches(/^\S*$/, "Non deve contenere spazi")
	.min(8, "Deve Essere almeno 8 caratteri")
	.matches(/[A-Z]/, "Deve contenere almeno una maiuscola")
	.matches(/\d/, "Deve contenere almeno un numero")
	.matches(/\W/, "Deve contenere almeno un carattere speciale")
	.required("Questo campo è obbligatorio");
