import ContestForm from "./ContestForm";
import { ContestDto, ContestsClient, ManageContestDto } from "../../Api";
import { useClient } from "../../components/UseClient";
import CsModal from "../../components/CsModal";
import { ModalProps } from "../../utilities/typeUtils";
import useToastMutation from "../../components/Hooks/useToastMutation";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Skeleton, useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { classNames } from "../../components/utils";

export default function CreateContestPage() {
	const { id } = useParams();
	const client = useClient(ContestsClient);
	const toast = useToast();
	const { data: contest, isLoading: isLoadingContest } = useQuery({
		queryKey: ["contests", id],
		queryFn: async () => await client.getContest(id!).then((r) => r.result),
		enabled: id != undefined,
	});
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const invalidate = ["contests", "dashboard"];
	function invalidateStrings() {
		if (invalidate)
			invalidate.forEach((i) => {
				queryClient.invalidateQueries([i]);
			});
	}

	const { mutate: createMutation, isLoading } = useMutation(
		(values: ManageContestDto) => client.createContest(values),
		{
			onSuccess: (i) => {
				toast({
					title: `Evento ${i.result.name} Creato`,
					status: "success",
					duration: 3000,
					isClosable: true,
				});
				invalidateStrings();
				navigate(`/contests${!!contest ? "/" + contest.id : ""}`);
			},
			onError: (e) => {
				toast({
					title: (e as any).message ?? "Si è verificato un errore",
					status: "error",
					duration: 3000,
					isClosable: true,
				});
			},
		}
	);

	const { mutate: updateMutation, isLoading: isUpdating } = useMutation(
		(values: ManageContestDto) =>
			client.updateContest(contest?.id!, values),
		{
			onSuccess: () => {
				toast({
					title: `Evento ${contest?.name ?? ""} modificato`,
					status: "success",
					duration: 3000,
					isClosable: true,
				});
				invalidateStrings();
				navigate(`/contests${!!contest ? "/" + contest.id : ""}`);
			},
			onError: (e) => {
				toast({
					title: (e as any).message ?? "Si è verificato un errore",
					status: "error",
					duration: 3000,
					isClosable: true,
				});
			},
		}
	);

	const isContestDefined = !!id && !!contest;

	return (
		<div
			className={classNames(
				id == undefined || !isLoadingContest
					? ""
					: "bg-gray-400 animate-pulse w-full h-full rounded-md"
			)}
		>
			{id == undefined || !isLoadingContest ? (
				<ContestForm
					isLoading={isLoading || isUpdating}
					action={isContestDefined ? "update" : "create"}
					initialValues={{
						name: isContestDefined ? contest.name : "",
						date: isContestDefined ? contest.date : "",
						description: isContestDefined
							? contest.description
							: undefined,
						isInternal: isContestDefined
							? contest.isInternal
							: true,
						numOfDay: isContestDefined ? contest.numOfDay : 1,
						deadlineDate: isContestDefined
							? contest.deadlineDate
							: "",
						position: isContestDefined ? contest.position : "",
						eventType: isContestDefined ? contest.eventType : 0,
						price: isContestDefined ? contest.price : 0,
						category: isContestDefined ? contest.category : [],
						courseId: isContestDefined
							? contest.course?.id
							: undefined,
						contestPhotoId: isContestDefined
							? contest.contestPhoto?.id
							: undefined,
					}}
					onSubmit={!!contest ? updateMutation : createMutation}
				/>
			) : null}
		</div>
	);
}
