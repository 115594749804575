import { Fragment, PropsWithChildren, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
	CheckIcon,
	ExclamationTriangleIcon,
	XMarkIcon,
} from "@heroicons/react/24/outline";
import { DeleteModalProps, ModalProps } from "../../utilities/typeUtils";
import Button from "./Button";

export function Modal({
	isOpen,
	onClose,
	title,
	actions,
	children,
	icon,
}: {
	isOpen: boolean;
	onClose: () => void;
	title: string;
	icon?: JSX.Element;
	actions?: JSX.Element;
} & PropsWithChildren) {
	return (
		<Transition.Root show={isOpen} as={Fragment}>
			<Dialog as="div" className="relative z-[50]" onClose={onClose}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 w-screen overflow-y-auto ">
					<div className="flex min-h-full  items-end justify-center py-4 text-center sm:items-center sm:p-0 ">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white max-md:w-screen mx-2 p-4 w-fit md:max-w-xl transition-all shadow-xl ">
								{/* px-4 pb-4 pt-5 text-left   sm:my-8 sm:w-full  sm:py-6 */}
								<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
									<button
										type="button"
										className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
										onClick={onClose}
									>
										<span className="sr-only">Close</span>
										<XMarkIcon
											className="h-6 w-6"
											aria-hidden="true"
										/>
									</button>
								</div>
								<div>
									<div className="text-left pr-4">
										<div className="flex gap-2 items-center justify-start border-b-2 pb-3 border-gray-900/5">
											{icon}
											{/* <div className="rounded-full bg-green-100 p-1">
												<CheckIcon
													className="h-6 w-6 text-green-600 "
													aria-hidden="true"
												/>
											</div> */}
											<Dialog.Title
												as="h3"
												className="text-lg font-semibold leading-6 text-gray-900 "
											>
												{title}
											</Dialog.Title>
										</div>
										<div className="mt-2">
											<p className="text-sm text-gray-500">
												{children}
											</p>
										</div>
									</div>
								</div>
								{!!actions ? (
									<div className="mt-0 sm:mt-0 sm:flex sm:flex-row-reverse">
										{actions}
									</div>
								) : null}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}

export function DeleteModal({
	name,
	children,
	isLoading,
	deleteMutation,
	isOpen,
	onClose,
}: ModalProps & {
	name: string;
	isLoading?: boolean;
	deleteMutation: () => void;
} & PropsWithChildren) {
	return (
		<Modal
			title={"Elimina " + name}
			icon={
				<div className="rounded-full bg-red-100 p-2">
					<ExclamationTriangleIcon
						className="h-6 w-6 text-red-600"
						aria-hidden="true"
					/>
				</div>
			}
			isOpen={isOpen}
			onClose={onClose}
		>
			<div className="flex flex-col gap-3">
				<p>{children}</p>
				<div className="flex w-full justify-end gap-3 items-center">
					<Button onClick={onClose} type="secondary">
						Annulla
					</Button>
					<Button
						isLoading={isLoading ?? false}
						onClick={() => deleteMutation()}
						className="bg-red-500 hover:bg-red-400"
					>
						Elimina
					</Button>
				</div>
			</div>
		</Modal>
	);
}
