import React from "react";
import { FiAlertTriangle } from "react-icons/fi";

export function ErrorAlert() {
	return (
		<>
			<div className="grid place-items-center w-full h-[80vh] ">
				<div className="flex flex-col gap-2 bg-red-100  max-w-lg  rounded-lg p-5 shadow-xl">
					<div className="flex gap-3 items-center">
						<FiAlertTriangle className="w-7 h-7 text-red-500" />
						<span className="text-xl font-bold">
							E' avvenuto un Errore!
						</span>
					</div>
					<span>
						Provare a cambiare pagina e riavviare, se il problema
						persiste, contattare{" "}
						<a href="mailto:cronosport@alice.it">
							cronosport@alice.it
						</a>
					</span>
				</div>
			</div>
		</>
	);
}

export class ErrorBoundary extends React.Component<any, any> {
	state = { hasError: false };

	static getDerivedStateFromError(error: any) {
		return { hasError: true };
	}

	//   componentDidCatch(error: any, errorInfo: any) {
	//     if (isProduction() || isStaging()) {
	//       apm.captureError(error);
	//     }
	//   }
	render() {
		if (this.state.hasError) {
			return <ErrorAlert />;
		}

		return this.props.children;
	}
}
