import { PropsWithChildren } from "react";
import { motion } from "framer-motion";

export default function TransitionPage({ children }: PropsWithChildren) {
	return (
		<motion.div
			// initial={{ opacity: 0, scale: 0.5 }}
			// animate={{ opacity: 1, scale: 1 }}
			// transition={{
			// 	duration: 0.8,
			// 	delay: 0,
			// 	ease: [0, 0.71, 0.2, 1.01],
			// }}

			initial={{ originY: 0, opacity: 0, scale: 0.5 }}
			animate={{ originY: 0, opacity: 1, scale: 1 }}
			transition={{
				duration: 0.8,
				delay: 0,
				ease: [0, 0.71, 0.2, 1.01],
			}}
		>
			{children}
		</motion.div>
	);
}
