import { classNames } from "../../components/utils";
import { AthletesClient, ManageAthleteDto } from "../../Api";
import { useClient } from "../../components/UseClient";
import { emptyAthleteFormValues } from "../../utilities/Constant";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { AthleteForm } from "../SignUp/AthleteForm";

export default function CreateAthletePage({ family }: { family?: boolean }) {
	const client = useClient(AthletesClient);
	// const adminClient = useClient(AdminsClient);
	const { id } = useParams();
	const navigate = useNavigate();
	const toast = useToast();
	const invalidate = ["users", "athletes", "families"];
	const { data: athlete, isLoading: isLoadingAthlete } = useQuery({
		queryKey: ["athletes", id],
		queryFn: async () => await client.getAthlete(id!).then((r) => r.result),
		enabled: !family && id != undefined,
	});
	const queryClient = useQueryClient();

	function invalidateStrings() {
		if (invalidate)
			invalidate.forEach((i) => {
				queryClient.invalidateQueries([i]);
			});
	}

	const { mutate: createMutation, isLoading } = useMutation(
		(values: ManageAthleteDto) =>
			!family
				? client.createAthlete(values, null)
				: client.createFamilyAthlete(values),
		{
			onSuccess: (i) => {
				toast({
					title: `Atleta creato`,
					status: "success",
					duration: 3000,
					isClosable: true,
				});
				invalidateStrings();
				navigate(
					!family
						? `/athletes${!!athlete ? "/" + athlete.athleteId : ""}`
						: `/families/${id}`
				);
			},
			onError: (e) => {
				toast({
					title: (e as any).message ?? "Si è verificato un errore",
					status: "error",
					duration: 3000,
					isClosable: true,
				});
			},
		}
	);
	const { mutate: updateMutation, isLoading: isUpdating } = useMutation(
		(values: ManageAthleteDto) =>
			client.updateAthlete(athlete?.athleteId!, values),
		{
			onSuccess: () => {
				toast({
					title: `Atleta modificato`,
					status: "success",
					duration: 3000,
					isClosable: true,
				});
				invalidateStrings();
				navigate(
					`/athletes${!!athlete ? "/" + athlete.athleteId : ""}`
				);
			},
			onError: (e: any) => {
				toast({
					title: (e as any).message ?? "Si è verificato un errore",
					status: "error",
					duration: 3000,
					isClosable: true,
				});
			},
		}
	);

	return (
		<div
			className={classNames(
				!!family || id == undefined || !isLoadingAthlete
					? ""
					: "bg-gray-400 animate-pulse w-full h-full rounded-md"
			)}
		>
			{!!family || id == undefined || !isLoadingAthlete ? (
				<AthleteForm
					isLoading={isLoading || isUpdating}
					action={!athlete ? "create" : "update"}
					initialValues={{
						...(athlete
							? athlete
							: { ...emptyAthleteFormValues, familyId: id }),
					}}
					onSubmit={!athlete ? createMutation : updateMutation}
				/>
			) : null}
		</div>
	);
}
