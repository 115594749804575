import { useState } from "react";
import { CoursesClient, CourseDto } from "../../../Api";
import { Actions, Column, CsTable } from "../../../components/CsTable";
import { useClient } from "../../../components/UseClient";
import { useNavigate } from "react-router-dom";

//icons---------------------

import { FaPlus } from "react-icons/fa";

import GetUser, { AccessLevelType } from "../../../components/GetUser";
import { useUI } from "../../../UiContext";
import { CsTableButton } from "../../../components/CsElements";
import { Icons } from "../../../utilities/icons";
import ActionButton from "../../../components/ActionButton";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import CourseModal from "./CourseModal";
import { DeleteCourseModal } from "./DeleteCourseModal";
import useDisclosure from "../../../components/Hooks/useDisclosure";

export const familyColumns: Column<CourseDto>[] = [
	{
		id: "name",
		name: "Nome sede",
	},

	{
		id: "createdAt",
		name: "Creato il",
		Cell: ({ createdAt }) =>
			format(new Date(createdAt), "P", { locale: it }),
	},
	{
		id: "description",
		name: "Descrizione",
		Cell: ({ description }) => (
			<div className="w-[50%] whitespace-normal">
				{description ?? "-"}
			</div>
		),
	},
];

export default function CourseTab() {
	const client = useClient(CoursesClient);
	const navigate = useNavigate();
	const [changeEntity, setChangeEntity] = useState<CourseDto | undefined>(
		undefined
	);
	const { AccessLevel } = GetUser();
	const { color } = useUI();

	const {
		isOpen: isCreateOpen,
		onOpen: onCreateOpen,
		onClose: onCreateClose,
	} = useDisclosure();

	const {
		isOpen: isDeleteOpen,
		onOpen: onDeleteOpen,
		onClose: onDeleteClose,
	} = useDisclosure();

	const actions: Actions<CourseDto> = (course: CourseDto, index: any) => (
		<>
			<CsTableButton
				index={index}
				text={Icons.Info}
				tooltip="Info"
				onClick={() => navigate(`/courses/${course.id}`)}
			/>
			{AccessLevel >= AccessLevelType.Admin ? (
				<>
					<CsTableButton
						text={Icons.Edit}
						tooltip="Modifica"
						index={index}
						onClick={() => {
							setChangeEntity(course);
							onCreateOpen();
						}}
					/>
					<CsTableButton
						text={Icons.Delete}
						tooltip="Elimina"
						index={index}
						onClick={() => {
							setChangeEntity(course);
							onDeleteOpen();
						}}
					/>
				</>
			) : null}
		</>
	);

	const extraComponent = (
		<>
			{AccessLevel >= AccessLevelType.Admin ? (
				<ActionButton
					onClick={onCreateOpen}
					icon={<FaPlus fontSize="1rem" />}
				>
					Crea
				</ActionButton>
			) : null}
		</>
	);

	return (
		<>
			<CsTable<CourseDto>
				columns={familyColumns}
				queryKey={["courses"]}
				fetchPage={async (
					filter: any,
					sort: any,
					limit: any,
					offset: any
				) => await client.getAllCourses(filter, sort, limit, offset)}
				persistanceKey="courses"
				defaultSorting={{ order: "-", sortBy: "name" }}
				actions={actions}
				idProperty="id"
				searchPlaceholder="Cerca Corso"
				extraComponent={extraComponent}
			/>

			{changeEntity ? (
				<DeleteCourseModal
					isOpen={isDeleteOpen}
					course={changeEntity!}
					onClose={onDeleteClose}
				/>
			) : null}

			<CourseModal
				isOpen={isCreateOpen}
				onClose={() => {
					setChangeEntity(undefined);
					onCreateClose();
				}}
				subsidiary={changeEntity}
			/>
		</>
	);
}
