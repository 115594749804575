import { UsersClient, ManagePassword } from "../../Api";
import { useClient } from "../../components/UseClient";
import GetUser from "../../components/GetUser";
import PasswordForm from "./PasswordForm";
import { ModalProps } from "../../utilities/typeUtils";
import useToastMutation from "../../components/Hooks/useToastMutation";
import CsModal from "../../components/CsModal";
import { Modal } from "../../components/UI/Modal";
import { MdPassword } from "react-icons/md";

export function ChangePasswordModal({ isOpen, onClose }: ModalProps) {
	const client = useClient(UsersClient);
	const { currentUser, isLoading: LoadingUser } = GetUser();

	const { mutate: changePasswordMutation, isLoading } = useToastMutation(
		(values: ManagePassword) =>
			client.changePassword(currentUser?.id!, values),
		onClose,
		["users"],
		"Password Modificata"
	);

	return (
		<>
			<Modal
				icon={
					<div className="rounded-full bg-indigo-100 p-2">
						<MdPassword
							className="h-6 w-6 text-indigo-600"
							aria-hidden="true"
						/>
					</div>
				}
				isOpen={isOpen}
				onClose={onClose}
				title="Modifica Password"
			>
				<PasswordForm
					isLoading={isLoading && LoadingUser}
					initialValues={{
						oldPassword: "",
						newPassword: "",
						repeatNewPassword: "",
					}}
					onSubmit={changePasswordMutation}
				/>
			</Modal>
		</>
	);
}
