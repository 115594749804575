import { useState } from "react";
import { Formik, Form } from "formik";
import { ManageContestDto, FilesClient, Feature } from "../../Api";
import * as Yup from "yup";
import { category } from "../../components/utils";

import { Icons } from "../../utilities/icons";
import { useClient } from "../../components/UseClient";
import { submitWithFile } from "../../components/Fields/PictureField";
import GetUser from "../../components/GetUser";

import {
	CheckboxField,
	DateField,
	InputField,
	MultiSelectField,
	NumberField,
	PictureField,
	SelectField,
	TextareaField,
} from "../../components/InputFormikFiled";
import { EventTypeTrans } from "../../utilities/Translations";
import { CourseField } from "../../components/Fields/CourseField";
import { FormProps } from "../../utilities/typeUtils";
import FormLayout, { FormSection } from "../../components/UI/FormLayout";
import Button from "../../components/UI/Button";

export default function ContestForm({
	isLoading,
	action,
	onSubmit,
	initialValues,
}: FormProps<ManageContestDto>) {
	const schema = Yup.object().shape({
		name: Yup.string()
			.required("Questo campo è obbligatorio")
			.min(3, "Deve essere lungo almeno 3 caratteri"),
		position: Yup.string().required("Questo campo è obbligatorio"),
		date: Yup.string().required("Questo campo è obbligatorio"),
		deadlineDate: Yup.string(),
		numOfDay: Yup.number()
			.positive()
			.integer()
			.min(1)
			.max(3)
			.required("Questo campo è obbligatorio")
			.typeError("Inserire un numero"),
		eventType: Yup.number()
			.positive()
			.integer()
			.min(0)
			.max(3)
			.required("Questo campo è obbligatorio"),
		price: Yup.number()
			.positive()
			.min(0)
			.required("Questo campo è obbligatorio")
			.typeError("Inserire un numero"),
		category: Yup.array().when("eventType", {
			is: 0,
			then: Yup.array()
				.min(1, "Deve esserci almeno 1 categoria")
				.required("Questo campo è obbligatorio"),
		}),
	});
	const { features } = GetUser();

	const client = useClient(FilesClient);

	const [contestPhoto, setContestPhoto] = useState<File>();

	return (
		<Formik<ManageContestDto>
			validationSchema={schema}
			initialValues={{ ...initialValues }}
			onSubmit={async (values) => {
				await submitWithFile(
					client,
					values,
					contestPhoto,
					"contestPhotoId",
					onSubmit
				);
			}}
		>
			{({
				values,
				setFieldValue,
				errors,
				touched,
				handleBlur,
				isSubmitting,
			}) => (
				<Form>
					<FormLayout
						actions={
							<>
								<Button submit isLoading={isLoading}>
									{action == "create" ? "Crea" : "Modifica"}
								</Button>
							</>
						}
					>
						<FormSection title="Informazioni Generali">
							<div className="flex flex-col gap-4">
								<PictureField
									label=""
									type="contest"
									id={"contestPhotoId"}
									name={"contestPhotoId"}
									setFile={setContestPhoto!}
								/>
								<InputField
									icon={Icons.Contest}
									id="name"
									name="name"
									label="Nome Evento"
									placeholder="Inserisci Nome"
									required
								/>
								<InputField
									id="position"
									name="position"
									label="Luogo dell'Evento"
									icon={Icons.Place}
									placeholder="Inserisci Luogo"
									required
								/>
								<TextareaField
									icon={Icons.Description}
									id="description"
									name="description"
									label="Descrizione"
									placeholder="Inserisci descrizione"
								/>
								<CheckboxField
									// icon={Icons.Description}
									id="isInternal"
									name="isInternal"
									label=""
									defaultChecked={initialValues.isInternal}
									// checked={
									// 	// values.isInternal ??
									// 	initialValues.isInternal
									// }
									text="Interna"
									hint={
										<>
											Selezionata: visibile solo alla tua
											associazione
											<br /> Non selezionata: visibile a
											tutte le associazioni
										</>
									}
									placeholder="Inserisci descrizione"
								/>
							</div>
						</FormSection>
						<FormSection title="Tipologia e prezzo">
							<div className="flex flex-col gap-4">
								<SelectField
									id="eventType"
									name="eventType"
									label="Tipologia dell'Evento"
									icon={Icons.EventTag}
									options={EventTypeTrans}
									required
									isInt
								/>
								{features?.includes(Feature.Courses) ? (
									<CourseField
										icon={Icons.Course}
										id="courseId"
										name="courseId"
										label="Corso"
									/>
								) : null}
								<NumberField
									id="price"
									name="price"
									label="Prezzo"
									icon={Icons.Euro}
									required
									rightElement={"€"}
									min={0}
									precision={2}
									// commands
								/>

								{values.eventType == 0 ? (
									<>
										<MultiSelectField
											id="category"
											name="category"
											label="Categoria"
											icon={Icons.Features}
											defaultValue={category.filter((x) =>
												initialValues.category.includes(
													x.name
												)
											)}
											placeholder="Inserisci le fetures"
											data={category}
											textField="name"
											dataKey="name"
											groupBy="type"
										/>
									</>
								) : null}
							</div>
						</FormSection>
						<FormSection title="Tempistiche">
							<div className="flex flex-col gap-4">
								<DateField
									required
									id="date"
									name="date"
									label="Data"
									icon={Icons.Calendar}
									fromDate={new Date()}
									// placeholder="Inserisci Data"
									// min={new Date()}
									// includeTime
									// defaultValue={
									// 	initialValues.date != ""
									// 		? new Date(initialValues.date)
									// 		: null
									// }
									onChange={(day: Date | undefined) => {
										console.log(day);
										console.log(values.deadlineDate);
										if (!day)
											setFieldValue(
												"deadlineDate",
												undefined
											);
										else if (
											day.getTime() <
												new Date(
													values.deadlineDate
												).getTime() ||
											!values.deadlineDate
										)
											setFieldValue("deadlineDate", day);
									}}
								/>
								<DateField
									required
									id="deadlineDate"
									name="deadlineDate"
									label="Scadenza Iscrizioni"
									icon={Icons.Deadline}
									fromDate={new Date()}
									toDate={new Date(values.date)}
									// includeTime
									disabled={values.date ? false : true}
								/>
								<NumberField
									id="numOfDay"
									name="numOfDay"
									label="Durata"
									icon={Icons.Day}
									required
									// commands
									step={1}
									min={1}
									max={3}
								/>
							</div>
						</FormSection>
					</FormLayout>

					{/* <GridItem
								colSpan={isLargerThanMd ? 6 : 1}
								rowSpan={2}
							>
								<Box flex="2">
									<PictureField
										// type="image"
										label=""
										avatarProps={{
											borderRadius: "2rem",
											size: "2xl",
											minW: "100%",
											minH: "15rem",
											bg: "#302020",
											color: "white",
											icon: Icons.Events,
										}}
										id={"contestPhotoId"}
										name={"contestPhotoId"}
										setFile={setContestPhoto}
									/>
								</Box>
							</GridItem>
							<GridItem
								colSpan={isLargerThanMd ? numOfColumn / 2 : 1}
							>
								<InputField
									icon={Icons.Contest}
									id="name"
									name="name"
									label="Nome Evento"
									placeholder="Inserisci Nome"
									isRequired
								/>
							</GridItem>

							<GridItem
								colSpan={isLargerThanMd ? numOfColumn / 2 : 1}
							>
								<InputField
									id="position"
									name="position"
									label="Luogo dell'Evento"
									icon={Icons.Place}
									placeholder="Inserisci Luogo"
									isRequired
								/>
							</GridItem>
							<GridItem
								colSpan={isLargerThanMd ? numOfColumn / 2 : 1}
							>
								<TextareaField
									icon={Icons.Description}
									id="description"
									name="description"
									label="Descrizione"
									placeholder="Inserisci descrizione"
								/>
							</GridItem>
							<GridItem
								colSpan={isLargerThanMd ? numOfColumn / 2 : 1}
							>
								<CheckboxField
									// icon={Icons.Description}
									id="isInternal"
									name="isInternal"
									label=""
									defaultChecked={initialValues.isInternal}
									// checked={
									// 	// values.isInternal ??
									// 	initialValues.isInternal
									// }
									text="Interna"
									hint={
										<>
											Selezionata: visibile solo alla tua
											associazione
											<br /> Non selezionata: visibile a
											tutte le associazioni
										</>
									}
									placeholder="Inserisci descrizione"
								/>
							</GridItem>

							<GridItem
								colSpan={isLargerThanMd ? numOfColumn / 2 : 1}
							>
								<SelectField
									id="eventType"
									name="eventType"
									label="Tipologia dell'Evento"
									icon={Icons.EventTag}
									options={EventTypeTrans}
									isRequired
									isInt
								/>
							</GridItem>
							{features?.includes(Feature.Courses) ? (
								<GridItem
									colSpan={
										isLargerThanMd ? numOfColumn / 2 : 1
									}
								>
									<CourseField
										icon={Icons.Course}
										id="courseId"
										name="courseId"
										label="Corso"
									/>
								</GridItem>
							) : null}
							<GridItem
								colSpan={isLargerThanMd ? numOfColumn / 4 : 1}
							>
								<NumberField
									id="price"
									name="price"
									label="Prezzo"
									icon={Icons.Euro}
									isRequired
									rightElement={"€"}
									min={0}
									maxW={isLargerThanMd ? 24 : undefined}
									precision={2}
								/>
							</GridItem>

							<RowTitleForm
								title="Tempistica"
								column={isLargerThanMd ? numOfColumn : 1}
							/>

							<GridItem colSpan={isLargerThanMd ? 5 : 1}>
								<DateField
									isRequired
									id="date"
									name="date"
									label="Data"
									icon={Icons.Calendar}
									placeholder="Inserisci Data"
									min={new Date()}
									// includeTime
									defaultValue={
										initialValues.date != ""
											? new Date(initialValues.date)
											: null
									}
									onChange={(e: any) => {
										if (
											e.getTime() <
												new Date(
													values.deadlineDate
												).getTime() ||
											values.deadlineDate == ""
										)
											setFieldValue(
												"deadlineDate",
												e.toJSON()
											);
									}}
								/>
							</GridItem>

						
							<GridItem colSpan={isLargerThanMd ? 5 : 1}>
								<DateField
									isRequired
									id="deadlineDate"
									name="deadlineDate"
									label="Scadenza Iscrizioni"
									icon={Icons.Deadline}
									min={new Date()}
									max={new Date(values.date)}
									// includeTime
									disabled={values.date ? false : true}
								/>
							</GridItem>

			
							<GridItem colSpan={isLargerThanMd ? 2 : 1}>
								<NumberField
									id="numOfDay"
									name="numOfDay"
									label="Durata"
									icon={Icons.Day}
									isRequired
									commands
									step={1}
									min={1}
									max={3}
								/>
							</GridItem>

							{values.eventType == 0 ? (
								<>
									<RowTitleForm
										title="Categoria"
										column={
											isLargerThanMd ? numOfColumn : 1
										}
									/>
								
									<GridItem colSpan={isLargerThanMd ? 12 : 1}>
										<MultiSelectField
											id="category"
											name="category"
											label="Categoria"
											icon={Icons.Features}
											defaultValue={category.filter((x) =>
												initialValues.category.includes(
													x.name
												)
											)}
											placeholder="Inserisci le fetures"
											data={category}
											textField="name"
											dataKey="name"
											groupBy="type"
										/>
									</GridItem>
								</>
							) : null}
						</CsGrid> */}
					{/* <CsActionStack>
							<CsSubmitButton isLoading={isLoading}>
								{action == "create" ? "Crea" : "Modifica"}
							</CsSubmitButton>
						</CsActionStack> */}
				</Form>
			)}
		</Formik>
	);
}
