import { AthleteDto, CourseDto, CourseIscritionClient } from "../../../Api";
import { Box, HStack, Stack } from "@chakra-ui/react";
import { useClient } from "../../../components/UseClient";
import { useQuery } from "@tanstack/react-query";
import CsModal from "../../../components/CsModal";
import ActionButton from "../../../components/ActionButton";
import { useState } from "react";
import { CourseSelect } from "../../../components/Fields/CourseField";
import { AthletesSelect } from "../../../components/Fields/AthletesField";
import { ModalProps } from "../../../utilities/typeUtils";
import useToastMutation from "../../../components/Hooks/useToastMutation";
import { CsActionStack } from "../../../components/CsElements";

export default function AthleteCourseModal({
	isOpen,
	onClose,
	athlete: atl,
	course: crs,
}: ModalProps & {
	athlete?: AthleteDto;
	course?: CourseDto;
}) {
	return (
		<CsModal title={"Iscrivi"} isOpen={isOpen} onClose={onClose}>
			<Stack alignItems="center" justifyContent={"center"} p="5%">
				{!!crs ? (
					<ModalForCourse
						isOpen={isOpen}
						onClose={onClose}
						course={crs}
					/>
				) : !!atl ? (
					<ModalForAthlete
						isOpen={isOpen}
						onClose={onClose}
						athlete={atl}
					/>
				) : (
					<></>
				)}
			</Stack>
		</CsModal>
	);
}

function ModalForAthlete({
	athlete,
	onClose,
	isOpen,
}: {
	athlete: AthleteDto;
	onClose: () => void;
	isOpen: boolean;
}) {
	const client = useClient(CourseIscritionClient);
	const [course, setCourse] = useState<CourseDto | undefined>(undefined);

	const { data: alreadySingFromAthlete, isLoading: isLoading2 } = useQuery({
		queryKey: ["coursesIscrition", athlete?.athleteId],
		queryFn: () =>
			client.getAllIscritionsFromAthlete(
				athlete?.athleteId!,
				undefined,
				undefined,
				undefined,
				undefined
			),
	});

	const { mutate: createCourse, isLoading: isCourseing } = useToastMutation(
		() =>
			client.createCourseIscrition({
				athleteId: athlete.athleteId!,
				courseId: course?.id!,
			}),
		() => {
			setCourse(undefined);
			onClose();
		},
		["coursesIscrition"],
		"Iscritto"
	);

	return (
		<>
			<HStack>
				<Box>Vuoi iscrivere</Box>
				<Box fontWeight="bold">
					{`${athlete?.surname} ${athlete?.name}`}
				</Box>
				<Box> al corso di</Box>
				{
					<CourseSelect
						course={course}
						dontInclude={alreadySingFromAthlete?.result.items.map(
							(x) => x.course.id
						)}
						setCourse={(x) => setCourse(x)}
					/>
				}
			</HStack>
			<CsActionStack>
				<ActionButton
					isLoading={isCourseing}
					onClick={() => createCourse()}
					disabled={course == undefined}
				>
					Iscrivi
				</ActionButton>
			</CsActionStack>
		</>
	);
}

function ModalForCourse({
	course,
	onClose,
	isOpen,
}: {
	course: CourseDto;
	onClose: () => void;
	isOpen: boolean;
}) {
	const client = useClient(CourseIscritionClient);
	const [athlete, setAthlete] = useState<AthleteDto | undefined>(undefined);

	const { data: alreadySingFromCourse, isLoading: isLoading3 } = useQuery({
		queryKey: ["coursesIscrition", course?.id],
		queryFn: () =>
			client
				.getAllIscritionsFromCourse(
					course?.id!,
					undefined,
					undefined,
					undefined,
					undefined
				)
				.then((r) => r.result),
		enabled: !!course,
	});

	const { mutate: createCourse, isLoading: isCourseing } = useToastMutation(
		() =>
			client.createCourseIscrition({
				athleteId: athlete?.athleteId!,
				courseId: course?.id!,
			}),
		() => {
			setAthlete(undefined);
			onClose();
		},
		["coursesIscrition"],
		"Iscritto"
	);

	return (
		<>
			<HStack>
				<Box>Vuoi iscrivere</Box>
				<Box fontWeight="bold">
					<AthletesSelect
						className="w-fit"
						athlete={athlete}
						dontInclude={alreadySingFromCourse?.items.map(
							(x) => x.athlete.athleteId
						)}
						onSetAthlete={(x) => setAthlete(x)}
					/>
				</Box>
				<Box> al corso di</Box>
				<Box fontWeight="bold">{course.name}</Box>
			</HStack>
			<CsActionStack>
				<ActionButton
					isLoading={isCourseing}
					onClick={() => createCourse()}
					disabled={athlete == undefined}
				>
					Iscrivi
				</ActionButton>
			</CsActionStack>
		</>
	);
}
