import ContestPage from "../pages/contests/ContestPage";

import Home from "../pages/Home/Home";
import { AccessLevelType } from "./GetUser";
import SingleContestPage from "../pages/contests/SingleContestPage";
import ProfilePage from "../pages/Profile/ProfilePage";

import { AnimatePresence } from "framer-motion";
import TransitionPage from "./TransitionPage";
import { SingleUserPage } from "../pages/users/SingleUserPage";
import NotFound from "../NotFound";
import { SingleAthletePage } from "../pages/Athletes/SingleAthletePage";
import { SingleFamilyPage } from "../pages/Families/SingleFamilyPage";
import { Calendar } from "../pages/Calendar/CalendarPage";
import { Icons } from "../utilities/icons";
import AssociationsPage from "../pages/Associations/AssociationsPage";
import UsersPage from "../pages/users/UsersPage";
import AthletesPage from "../pages/Athletes/AthletesPage";
import FamiliesPage from "../pages/Families/FamiliesPage";
import { AssociationDto } from "../Api";
import { Feature } from "./utils";
import SubsidiariesPage from "../pages/Subsidiaries/SubsidiariesPage";
import { SingleSubsidiaryPage } from "../pages/Subsidiaries/SingleSubsidiaryPage";
import ItemsPage from "../pages/Items/ItemsPage";
import SingleItemPage from "../pages/Items/SingleItemPage";
import CoursePage from "../pages/Courses/CoursePage";
import SingleCoursePage from "../pages/Courses/SingleCoursePage";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import CreateContestPage from "../pages/contests/CreateContestPage";
import CreateUserPage from "../pages/users/CreateUserPage";
import CreateAthletePage from "../pages/Athletes/CreateAthletePage";
import CreateAssociationPage from "../pages/Associations/CreateAssociationPage";
import CreateGuardianPage from "../pages/Families/components/CreateGuardianPage";

type Props = {
	AccessLevel: AccessLevelType;
	association?: AssociationDto;
};

export type RouteType = {
	name: string;
	url: string;
	icon: JSX.Element;
	page?: JSX.Element;
	AccessLevel?: AccessLevelType;
	children?: {
		page: JSX.Element;
	};
};
type RouteFn = (association?: AssociationDto) => RouteType[];

export const CsRoutes: RouteFn = (association?: AssociationDto) => {
	return [
		{
			name: "Home",
			url: "/",
			icon: Icons.Home,
			page: <Home />,
		},
		{
			name: "Palestre",
			url: "/associations",
			icon: Icons.AssociationName,
			AccessLevel: AccessLevelType.SuperAdmin,
		},
		...(!!association &&
		association.features.includes(Feature.AccountsController)
			? [
					{
						name: "Accounts",
						url: "/accounts",
						icon: Icons.Admin,
						AccessLevel: AccessLevelType.Admin,
					},
			  ]
			: []),
		...(!!association && association.features.includes(Feature.Events)
			? [
					{
						name: "Eventi",
						url: "/contests",
						icon: Icons.Events,
					},
			  ]
			: []),
		{
			name: "Atleti",
			url: "/athletes",
			icon: Icons.Athletes,
		},
		...(!!association &&
		association.features.includes(Feature.FamilyAccount)
			? [
					{
						name: "Famiglie",
						url: "/families",
						icon: Icons.Families,
					},
			  ]
			: []),
		...(!!association && association.features.includes(Feature.Calendar)
			? [
					{
						name: "Orario",
						url: "/schedule",
						icon: Icons.Schedule,
					},
			  ]
			: []),
		...(!!association && association.features.includes(Feature.Subsidiary)
			? [
					{
						name: "Sedi",
						url: "/subsidiaries",
						icon: Icons.Street,
					},
			  ]
			: []),
		...(!!association && association.features.includes(Feature.Items)
			? [
					{
						name: "Articoli",
						url: "/items",
						icon: Icons.Shop,
					},
			  ]
			: []),
		...(!!association && association.features.includes(Feature.Courses)
			? [
					{
						name: "Corsi",
						url: "/courses",
						icon: Icons.Course,
					},
			  ]
			: []),
	];
};

export default function AppRoutes({ AccessLevel, association }: Props) {
	const location = useLocation();
	return (
		<div className="h-full z-0">
			<AnimatePresence>
				<Routes location={location} key={location.pathname}>
					<Route
						path="/"
						element={
							<>
								<Outlet />
							</>
						}
					>
						<Route
							index
							element={
								<TransitionPage>
									<Home />
								</TransitionPage>
							}
						/>
						{AccessLevel >= AccessLevelType.Admin ? (
							!!association &&
							association.features.includes(
								Feature.AccountsController
							) ? (
								<Route
									path="accounts"
									element={
										<TransitionPage>
											<Outlet />
										</TransitionPage>
									}
								>
									<Route index element={<UsersPage />} />
									<Route
										path="create"
										element={<CreateUserPage />}
									/>
									<Route
										path=":id"
										element={<SingleUserPage />}
									/>

									<Route
										path=":id/edit"
										element={<CreateUserPage />}
									/>
								</Route>
							) : null
						) : null}
						{AccessLevel >= AccessLevelType.SuperAdmin ? (
							<>
								<Route
									path="associations"
									element={
										<TransitionPage>
											<Outlet />
										</TransitionPage>
									}
								>
									<Route
										index
										element={<AssociationsPage />}
									/>
									<Route
										path="create"
										element={<CreateAssociationPage />}
									/>
									{/* <Route
										path=":id"
										element={<SingleContestPage />}
									/> */}
									<Route
										path=":id/edit"
										element={<CreateAssociationPage />}
									/>
									{/* <Route
									path=":id"
									element={
										<TransitionPage>
											<AssociationPage />
										</TransitionPage>
									}
								/> */}
								</Route>
							</>
						) : null}

						<Route
							path="myprofile"
							element={
								<TransitionPage>
									<ProfilePage />
								</TransitionPage>
							}
						/>
						{!!association &&
						association.features.includes(Feature.Calendar) ? (
							<Route
								path="schedule"
								element={
									<TransitionPage>
										<Calendar />
									</TransitionPage>
								}
							/>
						) : null}
						{!!association &&
						association.features.includes(Feature.Subsidiary) ? (
							<Route
								path="subsidiaries"
								element={
									<TransitionPage>
										<Outlet />
									</TransitionPage>
								}
							>
								<Route index element={<SubsidiariesPage />} />
								<Route
									path=":id"
									element={<SingleSubsidiaryPage />}
								/>
							</Route>
						) : null}

						{!!association &&
						association.features.includes(Feature.Events) ? (
							<Route
								path="contests"
								element={
									<TransitionPage>
										<Outlet />
									</TransitionPage>
								}
							>
								<Route index element={<ContestPage />} />
								<Route
									path="create"
									element={<CreateContestPage />}
								/>
								<Route
									path=":id"
									element={<SingleContestPage />}
								/>
								<Route
									path=":id/edit"
									element={<CreateContestPage />}
								/>
							</Route>
						) : null}

						{!!association &&
						association.features.includes(Feature.Items) ? (
							<Route
								path="items"
								element={
									<TransitionPage>
										<Outlet />
									</TransitionPage>
								}
							>
								<Route index element={<ItemsPage />} />
								<Route
									path=":id"
									element={<SingleItemPage />}
								/>
							</Route>
						) : null}

						<Route
							path="athletes"
							element={
								<TransitionPage>
									<Outlet />
								</TransitionPage>
							}
						>
							<Route index element={<AthletesPage />} />

							<Route
								path="create"
								element={<CreateAthletePage />}
							/>
							<Route path=":id" element={<SingleAthletePage />} />
							<Route
								path=":id/edit"
								element={<CreateAthletePage />}
							/>
						</Route>

						{!!association &&
						association.features.includes(Feature.FamilyAccount) ? (
							<Route
								path="families"
								element={
									<TransitionPage>
										<Outlet />
									</TransitionPage>
								}
							>
								<Route index element={<FamiliesPage />} />
								<Route
									path=":id"
									element={
										<TransitionPage>
											<Outlet />
										</TransitionPage>
									}
								>
									<Route
										index
										element={<SingleFamilyPage />}
									/>
									<Route
										path="edit"
										element={<SingleFamilyPage />}
									/>

									<Route
										path="createGuardians"
										element={<CreateGuardianPage />}
									/>

									<Route
										path="createAthletes"
										element={<CreateAthletePage family />}
									/>
								</Route>
							</Route>
						) : null}

						{!!association &&
						association.features.includes(Feature.Courses) ? (
							<Route
								path="courses"
								element={
									<TransitionPage>
										<Outlet />
									</TransitionPage>
								}
							>
								<Route index element={<CoursePage />} />
								<Route
									path=":id"
									element={<SingleCoursePage />}
								/>
							</Route>
						) : null}

						<Route path="*" element={<NotFound />} />
					</Route>

					{/* <Route path="/login" element={<Login />} /> */}
					{/* <Route path="/registration" element={<Registration />} /> */}
					{/* <Route path="/confirm" element={<Confirm />} /> */}
				</Routes>
			</AnimatePresence>
		</div>
	);
}
