import { UsersClient, UserDto } from "../../Api";
import { useClient } from "../../components/UseClient";
import { useUI } from "../../UiContext";
import { getIdentifier } from "../../components/utils";
import { DeleteModalProps } from "../../utilities/typeUtils";
import useToastMutation from "../../components/Hooks/useToastMutation";
import { DeleteModal } from "../../components/UI/Modal";

export function DeleteUserModal({
	isOpen,
	entity,
	onClose,
}: DeleteModalProps<UserDto>) {
	const client = useClient(UsersClient);
	const { color } = useUI();

	const invalidate = ["users", "athletes"];

	const { mutate: deleteMutation, isLoading } = useToastMutation(
		() => client.deleteUser(entity.id),
		onClose,
		invalidate,
		"Utente Rimosso"
	);

	return (
		<>
			<DeleteModal
				deleteMutation={deleteMutation}
				isLoading={isLoading}
				name="Account"
				isOpen={isOpen}
				onClose={onClose}
			>
				Sei sicuro di voler eliminare l'utente{" "}
				<span className="font-bold">{getIdentifier(entity)}</span>?
			</DeleteModal>
		</>
	);
}
