import { GuardianDto, UsersClient, FamiliesClient } from "../../../Api";
import { useClient } from "../../../components/UseClient";
import { DeleteModalProps } from "../../../utilities/typeUtils";
import useToastMutation from "../../../components/Hooks/useToastMutation";
import { DeleteModal } from "../../../components/UI/Modal";

export function DeleteGuardianModal({
	isOpen,
	entity,
	onClose,
}: DeleteModalProps<GuardianDto>) {
	const client = useClient(FamiliesClient);

	const invalidate = ["families"];
	const { mutate: deleteMutation, isLoading } = useToastMutation(
		() => client.deleteGuardian(entity.id),
		onClose,
		invalidate,
		"Guardiano Eliminato"
	);

	return (
		<>
			<DeleteModal
				name="Guardiano"
				deleteMutation={deleteMutation}
				isOpen={isOpen}
				onClose={onClose}
				isLoading={isLoading}
			>
				Sei sicuro di voler eliminare il guardiano{" "}
				<span className="font-bold">
					{entity.surname} {entity.name}
				</span>{" "}
				?
			</DeleteModal>
		</>
	);
}
