
import { CoursesClient, CourseDto } from "../../../Api";
import { useClient } from "../../../components/UseClient";
import useToastMutation from "../../../components/Hooks/useToastMutation";
import { ModalProps } from "../../../utilities/typeUtils";
import { useUI } from "../../../UiContext";

export function DeleteCourseModal({
	isOpen,
	course,
	onClose,
}: ModalProps & { course: CourseDto }) {
	const client = useClient(CoursesClient);
	const { color } = useUI();

	const { mutate: deleteMutation, isLoading } = useToastMutation(
		() => client.deleteCourse(course.id),
		onClose,
		["courses", "users"],
		"Corso Rimosso"
	);

	return (
		<>
			{/* <AlertModal
				title="Elimina Corso"
				message={
					<Highlight
						query={[`${course.name}`]}
						styles={{
							color: `${color.scheme}`,
							fontWeight: "bold",
						}}
					>
						{`Sei sicuro di voler eliminare il corso ${course.name}?`}
					</Highlight>
				}
				btnColor="red"
				btnMsg="Elimina"
				onClick={() => deleteMutation()}
				isOpen={isOpen}
				onClose={onClose}
				isLoading={isLoading}
			/> */}
		</>
	);
}
