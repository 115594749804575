import { useQuery } from "@tanstack/react-query";
import {
	AccountType,
	UserDto,
	AthletesClient,
	RoleType,
	Feature,
} from "../../Api";
import { useClient } from "../../components/UseClient";
import GetUser, { AccessLevelType } from "../../components/GetUser";
import { useNavigate, useParams } from "react-router-dom";
import {
	classNames,
	formatDate,
	getCategory,
	getFileUrl,
} from "../../components/utils";
import {
	BeltTrans,
	GenderTrans,
	RoleTypeTrans,
	tran,
} from "../../utilities/Translations";
import { Icons } from "../../utilities/icons";
import { CsTabs2, TabProp } from "../../components/CsTabs";
import QuotesTab from "../Profile/components/QuotesTab";
import ProfileRequestTab from "../Profile/components/ProfileRequestTab";
import ItemRequestsTab from "../Items/components/ItemRequestsTab";
import AthleteCourseTab from "../Courses/components/AthleteCourseTab";
import { PageSkeleton } from "../contests/SingleContestPage";
import { FaQuestion } from "react-icons/fa6";
import Button from "../../components/UI/Button";
import { AthleteInfo, Status } from "../Home/Home";
import { addressToString } from "../../utilities/ColumnsUtils";
import useDisclosure from "../../components/Hooks/useDisclosure";

export enum ShowType {
	Account,
	User,
	Athlete,
	Quotes,
	Family,
	Guardian,
}

export function SingleAthletePage({
	athleteId,
	extraTabs,
	extraOptions,
	user,
}: {
	athleteId?: string;
	extraTabs?: TabProp[];
	extraOptions?: JSX.Element;
	user?: UserDto;
}) {
	const { id } = useParams();
	const { AccessLevel, currentUser } = GetUser();
	const navigate = useNavigate();
	const client = useClient(AthletesClient);
	const { data: athlete, isLoading: isLoadingAthlete } = useQuery({
		queryKey: ["athletes", athleteId ?? id],
		queryFn: () =>
			client.getAthlete(athleteId ?? id!).then((r) => r.result),
		enabled: !!id || !!athleteId,
	});

	const tabs: TabProp[] = [
		...(AccessLevel >= AccessLevelType.Admin || user?.id == currentUser?.id
			? [
					{
						name: "Quote",
						tab: <QuotesTab athleteId={athleteId ?? id} />,
					},
			  ]
			: []),
		...(!!currentUser?.association &&
		currentUser?.association.features.includes(Feature.Events)
			? [
					{
						name: "Richieste",
						tab: <ProfileRequestTab athleteId={athleteId ?? id} />,
					},
			  ]
			: []),
		...(!!currentUser?.association &&
		currentUser?.association.features.includes(Feature.Courses)
			? [
					{
						name: "Corsi",
						tab: <AthleteCourseTab athlete={athlete} />,
					},
			  ]
			: []),
		...(AccessLevel >= AccessLevelType.Admin &&
		user != undefined &&
		!!currentUser?.association &&
		currentUser?.association.features.includes(Feature.Items)
			? [
					{
						name: "Richeste Articoli",
						tab: <ItemRequestsTab user={user} />,
					},
			  ]
			: []),
	];

	const {
		isOpen: isUpdateOpen,
		onOpen: onUpdateOpen,
		onClose: onUpdateClose,
	} = useDisclosure();

	const actions = (
		<>
			{athlete?.familyId ? (
				<Button
					onClick={() => navigate("/families/" + athlete?.familyId)}
				>
					Info Famiglia
				</Button>
			) : null}
			{AccessLevel >= AccessLevelType.Admin ? (
				<Button
					onClick={() =>
						navigate(`/athletes/${athlete?.athleteId}/edit`)
					}
					icon={Icons.Edit}
				>
					Modifica
				</Button>
			) : null}
			{extraOptions ?? null}
		</>
	);

	const titleBox = (
		<div className="lg:flex max-lg:flex-col lg:justify-start   items-center gap-x-6  ">
			{!!athlete?.profilePicture ? (
				<img
					src={getFileUrl(athlete?.profilePicture)}
					alt=""
					className="h-24 w-24 flex-none rounded-full ring-1 ring-gray-900/10"
				/>
			) : (
				<span
					className={classNames(
						"inline-flex h-24 w-24 items-center justify-center rounded-full bg-indigo-500",
						isLoadingAthlete ? "animate-pulse bg-gray-400" : ""
					)}
				>
					<span className="font-medium leading-none text-white">
						{athlete ? (
							athlete?.surname?.substring(0, 1) +
							athlete?.name.substring(0, 1)
						) : (
							<FaQuestion />
						)}
					</span>
				</span>
			)}
			<h1>
				<div className="text-sm leading-6 text-gray-500">
					{tran(RoleTypeTrans, athlete?.userRoleType)}
				</div>
				<div className="mt-1 leading-6 text-gray-900 font-bold text-lg">
					{athlete?.surname + " " + athlete?.name}
				</div>
				{athlete?.userRoleType == RoleType.Athlete ? (
					<div className="text-sm leading-6 text-gray-500 flex md:items-center max-md:items-start gap-1 pt-1 max-md:flex-col md:flex-row">
						<Status athlete={athlete} />
					</div>
				) : null}
			</h1>
		</div>
	);

	const info = [
		{
			title: "Data di nascita",
			data: (
				<>
					{athlete?.birth
						? formatDate(new Date(athlete?.birth), false, "date")
						: "-"}
				</>
			),
		},

		{
			title: "Sesso",
			data: <>{tran(GenderTrans, athlete?.gender)}</>,
		},
	];

	if (athlete?.userRoleType == RoleType.Athlete) {
		info.push({
			title: "Categoria / Peso",
			data: (
				<>
					{(athlete?.birth ? getCategory(athlete?.birth).name : "-") +
						` / ${athlete?.weight ?? "-"} kg`}
				</>
			),
		});
		info.push({
			title: "Cintura",
			data: <>{tran(BeltTrans, athlete?.belt)}</>,
		});
	}

	const main = [
		{
			title: "Info sulla promozione di cintura",
			data: (
				<div className="w-full ">
					<AthleteInfo athlete={athlete} singlePage />
				</div>
			),
		},
		{
			title: "Indirizzo",
			data: (
				<div className="w-full ">
					{athlete?.residenceAddress
						? addressToString(athlete?.residenceAddress)
						: "Nessun indirizzo specificato"}
				</div>
			),
			accessLevel: AccessLevelType.Admin,
		},
		{
			title: "Numero di Telefono",
			data: (
				<div className="w-full ">
					{athlete?.phoneNumber ??
						"Nessun numero di telefono specificato"}
				</div>
			),
			accessLevel: AccessLevelType.Admin,
		},
		{
			title: "Codice Fiscale",
			data: (
				<div className="w-full ">
					{athlete?.taxId ?? "Nessun codice fiscale specificato"}
				</div>
			),
			accessLevel: AccessLevelType.User,
		},
	];

	const menuItem = [];
	if (!!athlete?.familyId)
		menuItem.push(
			<Button onClick={() => navigate("/families/" + athlete?.familyId)}>
				Info Famiglia
			</Button>
		);

	return (
		<>
			<PageSkeleton
				actions={actions}
				titleBox={titleBox}
				info={info}
				menuItmes={menuItem}
				main={main}
			/>
			{AccessLevel >= AccessLevelType.Admin ||
			!!athleteId ||
			(currentUser?.accountType == AccountType.SingleUser &&
				id == currentUser?.athlete?.athleteId) ? (
				<CsTabs2 tabs={tabs} />
			) : null}
		</>
	);
}
