
import { SubsidiariesClient, SubsidiaryDto } from "../../../Api";
import { useClient } from "../../../components/UseClient";
import { useUI } from "../../../UiContext";
import useToastMutation from "../../../components/Hooks/useToastMutation";
import { ModalProps } from "../../../utilities/typeUtils";

export function DeleteSubsidiaryModal({
	isOpen,
	subsidiary,
	onClose,
}: ModalProps & { subsidiary: SubsidiaryDto }) {
	const client = useClient(SubsidiariesClient);
	const { color } = useUI();

	const { mutate: deleteMutation, isLoading } = useToastMutation(
		() => client.deleteSubsidiary(subsidiary.id),
		onClose,
		["subsidiaries", "users"],
		"Sede Rimossa"
	);

	return (
		<>
			{/* <AlertModal
				title="Elimina Sede"
				message={
					<Highlight
						query={[`${subsidiary.name}`]}
						styles={{
							color: `${color.scheme}`,
							fontWeight: "bold",
						}}
					>
						{`Sei sicuro di voler eliminare la sede ${subsidiary.name}?`}
					</Highlight>
				}
				btnColor="red"
				btnMsg="Elimina"
				onClick={() => deleteMutation()}
				isOpen={isOpen}
				onClose={onClose}
				isLoading={isLoading}
			/> */}
		</>
	);
}
