import { AccountType } from "../../../Api";
import GetUser from "../../../components/GetUser";
import { ChangePasswordModal } from "../ChangePasswordModal";
import { SingleAthletePage } from "../../Athletes/SingleAthletePage";
import { SingleFamilyPage } from "../../Families/SingleFamilyPage";
import { TabProp } from "../../../components/CsTabs";
import Button from "../../../components/UI/Button";
import useDisclosure from "../../../components/Hooks/useDisclosure";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Spinner from "../../../components/UI/Spinner";

export default function ProfileInfoTab({
	extraTabs,
}: {
	extraTabs?: TabProp[];
}) {
	const { currentUser, isLoading } = GetUser();

	const {
		isOpen: isChangePasswordOpen,
		onOpen: onChangePasswordOpen,
		onClose: onChangePasswordClose,
	} = useDisclosure();

	const extraOptions = (
		<>
			<Button onClick={onChangePasswordOpen} type="secondary">
				Cambia Password
			</Button>
		</>
	);

	return (
		<>
			{isLoading ? (
				<Spinner />
			) : (
				<>
					{currentUser?.accountType == AccountType.SingleUser ? (
						<SingleAthletePage
							user={currentUser}
							extraOptions={extraOptions}
							athleteId={currentUser.athlete!.athleteId}
						/>
					) : (
						<SingleFamilyPage
							extraOptions={extraOptions}
							familyId={currentUser?.family?.familyId}
						/>
					)}
				</>
			)}
			<ChangePasswordModal
				isOpen={isChangePasswordOpen}
				onClose={onChangePasswordClose}
			/>
		</>
	);
}
