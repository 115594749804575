import { AthletesClient, AthleteDto, UsersClient } from "../../../Api";
import { useClient } from "../../../components/UseClient";
import { DeleteModalProps } from "../../../utilities/typeUtils";
import useToastMutation from "../../../components/Hooks/useToastMutation";
import { DeleteModal } from "../../../components/UI/Modal";

export function DeleteAthleteModal({
	isOpen,
	entity,
	onClose,
}: DeleteModalProps<AthleteDto>) {
	const client = useClient(AthletesClient);
	const userClient = useClient(UsersClient);

	const invalidate = ["athletes", "users", "families"];
	const { mutate: deleteMutation, isLoading } = useToastMutation(
		() =>
			!!entity.userId
				? userClient.deleteUser(entity.userId!)
				: client.deleteAthlete(entity.athleteId),
		onClose,
		invalidate,
		"Articolo Eliminato"
	);

	return (
		<>
			<DeleteModal
				name="Atleta"
				deleteMutation={deleteMutation}
				isOpen={isOpen}
				onClose={onClose}
				isLoading={isLoading}
			>
				Sei sicuro di voler eliminare l'atleta{" "}
				<span className="font-bold">
					{entity.surname} {entity.name}
				</span>{" "}
				?
			</DeleteModal>
		</>
	);
}
