import { useApiField } from "./useApiField";
import { FieldInputProps, FieldHelperProps } from "formik";
import { ApiFieldMetaProps } from "./useApiField";
import * as React from "react";
import { FiAlertTriangle } from "react-icons/fi";
import { FaAsterisk } from "react-icons/fa6";
import { classNames } from "./utils";

export type FormControlProps = {
	id?: string;
	name?: string;
	disabled?: boolean;
	required?: boolean;
	hint?: string | JSX.Element;
	label: string;
	icon?: JSX.Element;
	format?: (v: string) => string;
	className?: string;
	canHaveError?: boolean;
};
export function FormControl<Val = any>({
	id,
	name,
	disabled,
	required,
	hint,
	label,
	icon,
	children,
	canHaveError = true,
}: FormControlProps & {
	children: (bag: {
		field: FieldInputProps<Val>;
		meta: ApiFieldMetaProps<Val>;
		helper: FieldHelperProps<Val>;
	}) => React.ReactNode;
}) {
	const [field, meta, helper] = useApiField({
		name: (name ?? id) as string,
	});

	const hasError = (!!meta.error && meta.touched) || meta.apiError || false;

	return (
		<div
			className={classNames(
				"flex flex-col gap-2 ",
				disabled
					? "pointer-events-none cursor-not-allowed opacity-60"
					: ""
			)}
		>
			<label
				htmlFor={id}
				className={classNames(
					"flex items-center gap-2 ",
					hasError && canHaveError ? "text-red-400" : ""
				)}
			>
				<span className="flex-0">{icon}</span>
				<span className="w-fit flex-2">{label}</span>
				{required ? (
					<span className="text-red-400 flex-1 text-[0.5rem]">
						<FaAsterisk />
					</span>
				) : null}
			</label>
			<div>
				{children({ field, meta, helper })}
				{hint ? (
					<div className="text-xs text-gray-400">{hint}</div>
				) : null}
			</div>
			{canHaveError && hasError ? (
				<div className="flex text-red-400 text-xs items-center  gap-1">
					<FiAlertTriangle className="flex-0 h-3 w-3" />
					<span>
						{(typeof meta.error == "string"
							? meta.error
							: undefined) || meta.apiError}
					</span>
				</div>
			) : null}
		</div>
	);
}
