import { useQuery } from "@tanstack/react-query";
import { ItemsClient } from "../../Api";
import {
	Box,
	Center,
	Heading,
	Text,
	Spinner,
	Stack,
	Flex,
	Avatar,
} from "@chakra-ui/react";
import { useClient } from "../../components/UseClient";
import { useParams } from "react-router-dom";

import { FaShoppingCart } from "react-icons/fa";
import ItemRequestsTab from "./components/ItemRequestsTab";
import { getFileUrl } from "../../components/utils";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import { SectionTitle } from "../Home/Home";

export type ContestAction =
	| "update"
	| "delete"
	| "entrant"
	| "signup"
	| "unsignup";

export default function SingleItemPage() {
	const { id } = useParams();

	const client = useClient(ItemsClient);
	const { data, isLoading } = useQuery(
		["items", id],
		async () => await client.getItem(id!).then((r) => r.result)
	);

	return (
		<>
			{isLoading ? (
				<Center>
					<Spinner />
				</Center>
			) : (
				<Box>
					<Flex
						flexDir={["column", "column", "row"]}
						bg="gray.200"
						w="100%"
						h="20%"
						gap="1rem"
						borderRadius="1rem"
					>
						<Avatar
							flex="1"
							src={getFileUrl(data?.itemPhoto)}
							h="100%"
							borderRadius="1rem"
							border="1px solid gray"
							borderColor="gray.200"
							size="2xl"
							icon={<FaShoppingCart />}
							style={{
								background: "#302020",
								color: "white",
								aspectRatio: data?.itemPhoto ? "auto" : "3/2",
							}}
						/>
						<Stack flex="2">
							<Text>Titolo</Text>
							<Heading>{data?.title}</Heading>
						</Stack>
						<Stack flex="1">
							<Stack>
								<Text>Quantità</Text>
								<Heading>{data?.quantity}</Heading>
							</Stack>
							<Box>
								<Text>Prezzo</Text>
								<Heading>{data?.price}€</Heading>
							</Box>
						</Stack>
						<Stack flex="1">
							<Box>
								<Text>Creato da</Text>
								<Heading>{data?.createdBy}</Heading>
							</Box>
							<Box>
								<Text>il</Text>
								<Heading>
									{format(new Date(data?.createdAt!), "P", {
										locale: it,
									})}
								</Heading>
							</Box>
						</Stack>
					</Flex>

					<SectionTitle>Richieste</SectionTitle>
					<ItemRequestsTab item={data} />
					{/* <CsTabs2 tabs={UserTabs} /> */}
				</Box>
			)}
		</>
	);
}
