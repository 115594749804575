
import { FamilyDto, UsersClient } from "../../../Api";
import { useClient } from "../../../components/UseClient";
import { useUI } from "../../../UiContext";
import { ModalProps } from "../../../utilities/typeUtils";
import useToastMutation from "../../../components/Hooks/useToastMutation";
import { DeleteModal } from "../../../components/UI/Modal";

export function DeleteFamilyModal({
	isOpen,
	family,
	onClose,
}: ModalProps & { family: FamilyDto }) {
	const userClient = useClient(UsersClient);
	const { color } = useUI();

	const { mutate: deleteMutation, isLoading } = useToastMutation(
		() => userClient.deleteUser(family.userId),
		onClose,
		["athletes", "users", "families"],
		"Utente Rimosso"
	);

	return (
		<>
			<DeleteModal
				deleteMutation={deleteMutation}
				isLoading={isLoading}
				name="Famiglia"
				isOpen={isOpen}
				onClose={onClose}
			>
				Sei sicuro di voler eliminare la famiglia{" "}
				<span className="font-bold">{family.familyName}</span> (verranno
				eliminati anche gli utenti connessi)?
			</DeleteModal>
		</>
	);
}
