import { Belt, Gender, RoleType } from "../Api";

const API_BASE_URL = "https://45.137.150.108:80";
const API_BASE_URL_PRODUCTION = "https://appname.azurewebsites.net";

const ENDPOINTS = {
	GET_USERS: "users",
	POST_USER: "users",
	GET_USER: "users/",
	PUT_USER: "users/",
	DELETE_USER: "users/",
};

const METHOD = {
	GET: "GET",
	POST: "POST",
	PUT: "PUT",
	DELETE: "DELETE",
};

const dev = {
	API_URL_GET_USERS: `${API_BASE_URL}/${ENDPOINTS.GET_USERS}`,
	API_URL_POST_USER: `${API_BASE_URL}/${ENDPOINTS.POST_USER}`,
	API_URL_GET_USER: `${API_BASE_URL}/${ENDPOINTS.GET_USER}`,
	API_URL_PUT_USER: `${API_BASE_URL}/${ENDPOINTS.PUT_USER}`,
	API_URL_DELETE_USER: `${API_BASE_URL}/${ENDPOINTS.DELETE_USER}`,
};

const prod = {
	API_URL_GET_USERS: `${API_BASE_URL_PRODUCTION}/${ENDPOINTS.GET_USERS}`,
	API_URL_POST_USER: `${API_BASE_URL_PRODUCTION}/${ENDPOINTS.POST_USER}`,
	API_URL_GET_USER: `${API_BASE_URL_PRODUCTION}/${ENDPOINTS.GET_USER}`,
	API_URL_PUT_USER: `${API_BASE_URL_PRODUCTION}/${ENDPOINTS.PUT_USER}`,
	API_URL_DELETE_USER: `${API_BASE_URL_PRODUCTION}/${ENDPOINTS.DELETE_USER}`,
};

const Constants = process.env.NODE_ENV === "development" ? dev : prod;

export const emptyAthleteFormValues = {
	name: "",
	surname: "",
	gender: Gender.Male,
	birth: "",
	userRoleType: RoleType.Athlete,
	userPoint: undefined,
	belt: Belt.White,
	weight: undefined,
	taxId: "",
	phoneNumber: undefined,
	medicalCertificateDeadline: undefined,
	familyId: undefined,
	residenceAddress: {
		street: "",
		city: "",
		postalCode: "",
		country: "",
		province: "",
		region: "",
	},
};

const gino = [
	"7",
	"8",
	"9",
	"10",
	"11",
	"12",
	"13",
	"14",
	"15",
	"16",
	"17",
	"18",
	"19",
	"20",
	"21",
	"22",
	"23",
];
const minutes = ["00", "30"];
export const hours = gino.flatMap((d) => minutes.map((v) => `${d}:${v}`));

export default Constants;
