import React, { useState } from "react";

export default function useDisclosure() {
	const [open, setOpen] = useState(false);
	return {
		isOpen: open,
		onOpen: () => setOpen(true),
		onClose: () => setOpen(false),
	};
}
