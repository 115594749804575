import { RequestState } from "../Api";
import { FiCheck, FiXOctagon, FiSend, FiMail } from "react-icons/fi";
import Badge from "../components/UI/Badge";
import { classNames } from "../components/utils";

type Props = {
	state: RequestState | undefined;
	isLoading?: boolean;
	type?: "onlyIcon" | "onlyText" | "both";
};

export function State({ state, isLoading, type = "both" }: Props): JSX.Element {
	// const { requestColor } = useUI();
	var stateText = "";
	var color = "";
	var icon = <></>;
	if (!isLoading) {
		switch (state) {
			case RequestState.Approved:
				stateText = "Iscritto/a";
				color = "bg-green-200";
				icon = <FiCheck />;
				break;
			case RequestState.Rejected:
				stateText = "Rifiutato/a";
				color = "bg-red-200";
				icon = <FiXOctagon />;
				break;
			case RequestState.Submitted:
				stateText = "In Attesa";

				color = "bg-yellow-200";
				icon = <FiSend />;
				break;
			default:
				stateText = "Non Iscritto/a";
				color = "bg-yellow-200";
				icon = <FiMail />;
				break;
		}
	}

	return (
		<div className="mx-auto">
			{/* <HStack
				fontSize={type == "onlyIcon" ? iconSize : "lg"}
				color="white"
				bg={color}
				borderRadius="full"
				{...(type == "onlyIcon" ? { p: "0.3rem" } : { px: ".7rem" })}
			> */}
			{/* {type == "onlyIcon" || type == "both" ? (
					<Tooltip label={type == "onlyIcon" ? stateText : ""}>
						<Text>{icon}</Text>
					</Tooltip>
				) : null} */}
			{/* {type == "onlyText" || type == "both" ? ( */}
			<Badge
				color={classNames(color, "flex gap-1")}
				// variant="subtle"
				// border={"2px solid blue"}
				// borderColor={`${color}.400`}
				// bg={`${color}.100`}
				// borderRadius="full"
			>
				<span>{icon}</span>
				{stateText}
			</Badge>
			{/* ) : null} */}
			{/* </HStack> */}
		</div>
	);
}
