import { Feature, ManageAssociationDto } from "../../Api";
import { Form, Formik } from "formik";
import {
	InputField as InputFormikField,
	MultiSelectField,
} from "../../components/InputFormikFiled";
import * as Yup from "yup";
import { formatPhoneNumber, formatProperName } from "../../components/utils";
import { Icons } from "../../utilities/icons";
import { FeatureTrans } from "../../utilities/Translations";

import { FormProps } from "../../utilities/typeUtils";
import { addressSchema } from "../../utilities/yupUtils";
import { AddressFields } from "../../components/CsForm";
import Button from "../../components/UI/Button";
import FormLayout, { FormSection } from "../../components/UI/FormLayout";

export default function AssociationForm({
	initialValues,
	action = "create",
	isLoading,
	onSubmit,
}: FormProps<ManageAssociationDto>) {


	const schema = Yup.object().shape({
		name: Yup.string()
			.min(3, "Deve essere lungo almeno 3 caratteri")
			.required("Questo campo è obbligatorio"),
		// website: Yup.url(),
		phoneNumber: Yup.string()
			.matches(
				/^[+][0-9]{2} [0-9]{3} [0-9]{3} [0-9]{4}$/,
				"Formato non valido, esempio: '+39 234 345 2345'"
			)
			.required("Questo campo è obbligatorio"),
		address: addressSchema,
	});

	return (
		<Formik<ManageAssociationDto>
			validationSchema={schema}
			initialValues={{ ...initialValues }}
			onSubmit={onSubmit}
		>
			{({
				values,
				
			}) => (
				<Form>
					<FormLayout
						actions={
							<>
								<Button submit isLoading={isLoading}>
									{action == "create" ? "Crea" : "Modifica"}
								</Button>
							</>
						}
					>
						<FormSection title="Informazioni Generali">
							<div className="flex flex-col gap-4">
								<InputFormikField
									icon={Icons.AssociationName}
									id="name"
									name="name"
									label="Nome Associazione"
									placeholder="Inserisci Nome"
									required
									format={formatProperName}
								/>
								<InputFormikField
									icon={Icons.PhoneNumber}
									id="phoneNumber"
									name="phoneNumber"
									label="Numero di telefono"
									placeholder="+39 111 222 3333"
									required
									type="numeric"
									format={formatPhoneNumber}
								/>
								<InputFormikField
									icon={Icons.Website}
									id="website"
									name="website"
									placeholder="https://sito.web"
									label="Sito Web"
									type="url"
									// format={(v) => (v ? "https://" + v : v)}
									value={values.website}
									// inputLeftElement={
									// 	<InputLeftAddon children="https://" />
									// }
								/>
								<MultiSelectField<Feature>
									id="features"
									name="features"
									label="Features"
									icon={Icons.Features}
									defaultValue={initialValues.features}
									placeholder="Inserisci le fetures"
									data={FeatureTrans}
									textField="label"
									dataKey="value"
								/>
							</div>
						</FormSection>
						<FormSection title="Indirizzo">
							<AddressFields />
						</FormSection>
					</FormLayout>
				</Form>
			)}
		</Formik>
	);
}
