import {
	Flex,
	Heading,
	Stack,
	Center,
	Skeleton,
	HStack,
} from "@chakra-ui/react";
import { useUI } from "../../../UiContext";

export type ContestAction =
	| "update"
	| "delete"
	| "entrant"
	| "signup"
	| "unsignup";

export type InfoButtonProps = {
	icon?: JSX.Element;
	element?: JSX.Element;
	text?: string | JSX.Element;
	title: string | JSX.Element;
	onClick?: () => void;
	isLoading?: boolean;
	disabled?: boolean;
	button?: boolean;
};

export default function InfoButton({
	icon,
	text,
	element,
	title,
	onClick,
	isLoading,
	button = false,
	disabled = false,
}: InfoButtonProps) {
	const { color, borderRadius } = useUI();

	return (
		<>
			<Flex
				alignItems="center"
				justifyContent="center"
				alignContent="center"
				fontSize="3xl"
				h="100%"
				borderRadius={button ? "full" : borderRadius.primary}
				color={button ? "white" : "black"}
				borderColor={color.secondary}
				backgroundColor={
					button ? color.secondary : `${color.scheme}.100`
				}
				p="1rem"
				style={
					disabled
						? {
								pointerEvents: "none",
								opacity: 0.4,
								cursor: "not-allowed",
						  }
						: {
								opacity: 1,
								cursor: "cursor",
						  }
				}
				_active={{ transform: `${button ? "scale(0.99)" : ""}` }}
				_hover={{
					cursor: `${button ? "pointer" : "default"}`,
				}}
				onClick={!disabled ? onClick : () => {}}
			>
				<Stack textAlign="center">
					<Center>
						<HStack>
							<Center>{icon ?? null}</Center>
							<Center fontSize="lg">{title}</Center>
						</HStack>
					</Center>
					<Center>
						<Skeleton isLoaded={!isLoading}>
							<Heading>{text ?? "-"}</Heading>
						</Skeleton>
						<Heading fontSize="2xl">{element ?? null}</Heading>
					</Center>
				</Stack>
			</Flex>
		</>
	);
}
