import GetUser, { AccessLevelType } from "../../components/GetUser";
import { PropsWithChildren, useState } from "react";
import { useUI } from "../../UiContext";
import { useQuery } from "@tanstack/react-query";
import {
	AthleteDto,
	Belt,
	DashboardClient,
	AthletesClient,
	NotificationsClient,
	NotificationDto,
	ContestDto,
	AccountType,
	RoleType,
} from "../../Api";
import { useClient } from "../../components/UseClient";

import { Actions, Column, CsTable } from "../../components/CsTable";
import { beltPoint } from "../../utilities/ColumnsUtils";
import ContestData from "../contests/ContestData";
import { BeltTrans, tran } from "../../utilities/Translations";
import {
	DateOnlyValues,
	Feature,
	classNames,
	getIdentifier,
} from "../../components/utils";
import { format } from "date-fns";
import { be, it } from "date-fns/locale";
import Badge from "../../components/UI/Badge";
import { Icons } from "../../utilities/icons";
import { useNavigate } from "react-router-dom";
import NotificationModal from "./components/NotificationModal";
import { FaArrowRight } from "react-icons/fa";
import { AthleteLink } from "../../components/TextLink";
import { MdOutlineEmail } from "react-icons/md";
import useToastMutation from "../../components/Hooks/useToastMutation";
import { FaArrowRightLong, FaCircleInfo, FaCrown } from "react-icons/fa6";
import Button, {
	ActionButtonContainer,
	Tooltip,
} from "../../components/UI/Button";
import { ActionText, TlTable } from "../../components/UI/Table";
import useDisclosure from "../../components/Hooks/useDisclosure";
import { HSeparator } from "../../components/UI/Separator";
import { AiFillEye } from "react-icons/ai";

export type LoginValues = {
	email: string;
	password: string;
};

export default function Home() {
	const { user, currentUser, AccessLevel } = GetUser();
	const { color } = useUI();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const client = useClient(DashboardClient);
	const aclient = useClient(AthletesClient);
	const { data, isLoading } = useQuery(["dashboard"], () =>
		client.getDashboard().then((r) => r.result)
	);

	const navigate = useNavigate();

	const config = {
		type: "doughnut",
		data: data,
		options: {
			responsive: true,
			plugins: {
				legend: {
					position: "top",
				},
				title: {
					display: true,
					text: "Chart.js Doughnut Chart",
				},
			},
		},
	};

	// const data1 = {
	// 	labels: ["Con Account", "Senza Account"],
	// 	datasets: [
	// 		{
	// 			label: "Atleti",
	// 			data: [
	// 				data?.singleUserWithAccount,
	// 				data?.singleUserWithoutAccount,
	// 			],
	// 			backgroundColor: ["red", "orange"],
	// 		},
	// 	],
	// };

	const beltss = data?.beltNumbers?.map((x) => Number(x.belt)).sort();

	const data2 = {
		labels: data?.beltNumbers?.map((x) => tran(BeltTrans, x.belt)),
		datasets: [
			{
				label: "Atleti",
				data: data?.beltNumbers
					?.sort((x) => Number(x.belt))
					.map((x) => x.number),
				backgroundColor: Object.keys(Belt)
					.filter((x) => x.length > 2)
					.filter((x, i) => beltss?.includes(i))
					.map((x) => (x == "White" ? "#e7e7e7" : x)),
				border: "1px solid black",
			},
		],
	};

	var areAthletes =
		(currentUser?.accountType == AccountType.SingleUser &&
			currentUser.athlete?.userRoleType == RoleType.Athlete) ||
		(currentUser?.family?.athletes &&
			currentUser?.family?.athletes.length > 0);

	return (
		<>
			<div
				className={classNames(
					"flex  max-md:flex-col gap-5",
					areAthletes ? "justify-between" : "justify-center"
				)}
			>
				{areAthletes ? (
					<div className="flex flex-col items-start gap-5 justify-center">
						{currentUser?.accountType == AccountType.SingleUser &&
						currentUser.athlete?.userRoleType ==
							RoleType.Athlete ? (
							<>
								<AthleteInfo athlete={currentUser?.athlete} />
								{/* <HSeparator className="py-10" /> */}
							</>
						) : (
							currentUser?.family?.athletes
								.filter(
									(x) => x.userRoleType == RoleType.Athlete
								)
								.map((x) => (
									<>
										<AthleteInfo
											athlete={x}
											name={x.name}
										/>
										{/* <HSeparator className="py-10" /> */}
									</>
								))
						)}
					</div>
				) : null}
				{data?.hallOfFame ? (
					<div className=" border-2 border-gray-200 shadow-xl  w-fit h-fit p-3 rounded-md ">
						<span className="font-bold italic  flex justify-center w-full gap-2 items-center">
							<span className="w-fit">Hall of Fame</span>
							<span>
								<Tooltip
									tooltip="Classifica dei punti ottenuti dalle gare"
									tooltipClass="translate-y-[30%] w-[6rem]"
								>
									<FaCircleInfo />
								</Tooltip>
							</span>
						</span>
						{data?.hallOfFame?.map((x, i) => (
							<div className="flex gap-1 w-full items-center ">
								<span className="font-semibold">{i + 1}°</span>
								<div
									className={classNames(
										"rounded px-1",
										i == 0
											? "shadow-sm bg-[#d4af37] font-bold text-xl text-white ml-[5px] my-1 py-[3px]"
											: i == 1
											? "shadow-sm bg-[#c0c0c0] font-semibold text-lg text-white ml-[15px]  my-1 py-[2px]"
											: i == 2
											? "shadow-sm bg-[#cd7f32] font-medium text-base text-white ml-[25px] my-1 py-[1px]"
											: "ml-[40px]"
									)}
								>
									{x}
								</div>
								{i == 0 ? (
									<div className="text-[#d4af37] pl-2  grid place-items-center">
										<FaCrown className="w-6 h-6" />
									</div>
								) : null}
							</div>
						))}
					</div>
				) : null}
			</div>
			<HSeparator className="py-10" />

			<div className="grid grid-col-12 gap-2 place-items-start">
				{currentUser?.association?.features.includes(Feature.Events) ? (
					<div className="col-span-12 row-span-2 flex max-lg:flex-col w-full pb-4 ">
						<div className="flex-1">
							<ContestHomeSection
								isLoading={isLoading}
								contest={data?.prevContest}
								title="Evento Precedente"
							/>
						</div>
						<div className="flex-1">
							<ContestHomeSection
								isLoading={isLoading}
								contest={data?.nextContest}
								title="Prossimo Evento"
							/>
						</div>
					</div>
				) : null}

				<div className="col-span-12">
					<HSeparator
						className="py-10 w-full bg-red-200 h-12"
						line="border-gray-500"
					/>
				</div>

				{/* {currentUser?.association?.features.includes(Feature.Events) ? (
					<div className="col-span-5 lg:col-span-4 col-start-5 row-span-2">
						
					</div>
				) : null} */}
				<div className="col-span-12 w-full lg:col-span-12 row-span-3">
					<NotificationHub />
				</div>
				{AccessLevel >= AccessLevelType.Admin ? (
					<>
						<HSeparator className="py-10 md:hidden" />
						<div className="col-span-12 w-full lg:col-span-6 ">
							<BeltPromotionHub />
						</div>
						<HSeparator className="py-10 md:hidden" />
						<div className="col-span-12 w-full lg:col-span-6 ">
							<MedicalCertificateHub />
						</div>
					</>
				) : null}
			</div>
		</>
	);
}

export function Status({ athlete }: { athlete?: AthleteDto }) {
	const quotePaid =
		!!athlete?.quoteDeadline &&
		athlete?.quoteDeadline != DateOnlyValues.MinValue;
	const mcDelivered = !!athlete?.medicalCertificateDeadline;
	const quoteExpired =
		!!athlete &&
		quotePaid &&
		new Date(athlete?.quoteDeadline!).getTime() < new Date().getTime();
	const mcExpired =
		!!athlete &&
		mcDelivered &&
		new Date(athlete?.medicalCertificateDeadline!).getTime() <
			new Date().getTime();
	return (
		<>
			<Badge
				color={
					!!athlete
						? quotePaid
							? quoteExpired
								? "bg-red-100"
								: "bg-green-100"
							: "bg-yellow-100"
						: "bg-gray-100"
				}
			>
				Stato quota:
				<span className="font-bold pl-1">
					{!!athlete
						? quotePaid
							? !quoteExpired
								? " Pagata"
								: " Scaduta"
							: " Non Pagata"
						: " -"}
				</span>
			</Badge>
			<Badge
				color={
					!!athlete
						? mcDelivered
							? mcExpired
								? "bg-red-100"
								: "bg-green-100"
							: "bg-yellow-100"
						: "bg-gray-100"
				}
			>
				Certificato medico:
				<span className="font-bold pl-1">
					{!!athlete
						? mcDelivered
							? !mcExpired
								? "Valido"
								: "Scaduto"
							: "Non Consegnato"
						: " -"}
				</span>
			</Badge>
		</>
	);
}

export function AthleteInfo({
	athlete,
	name,
	singlePage,
}: {
	athlete: AthleteDto | undefined;
	name?: string;
	singlePage?: boolean;
}) {
	const { color } = useUI();
	const quotePaid =
		!!athlete?.quoteDeadline &&
		athlete?.quoteDeadline != DateOnlyValues.MinValue;
	const mcDelivered = !!athlete?.medicalCertificateDeadline;
	const quoteExpired =
		!!athlete &&
		quotePaid &&
		new Date(athlete?.quoteDeadline!).getTime() < new Date().getTime();
	const mcExpired =
		!!athlete &&
		mcDelivered &&
		new Date(athlete?.medicalCertificateDeadline!).getTime() <
			new Date().getTime();
	const stats = [
		{
			name: "Stato quota",
			value: quotePaid
				? !quoteExpired
					? "Pagata"
					: "Scaduta"
				: "Non Pagata",
		},
		{
			name: "Certificato medico",
			value: mcDelivered
				? !mcExpired
					? "Valido"
					: "Scaduto"
				: "Non Consegnato",
		},
		// { name: "Number of servers", value: "3" },
		// { name: "Success rate", value: "98.5%" },
	];

	const valid = true; //mcDelivered && quotePaid && !mcExpired && !quoteExpired;
	const beltPerc = (athlete?.userPoint! / beltPoint(athlete?.belt!)) * 100;
	console.log(beltPerc);
	return (
		<div
			className={classNames(
				"flex flex-col gap-1",
				!!singlePage ? "max-w-full" : "max-w-[80%]"
			)}
		>
			<div className={`flex  flex-col w-full `}>
				{!singlePage ? (
					<>
						<div
							className={`justify-start flex-1 font-bold ${
								valid ? "" : "opacity-40"
							}`}
						>
							{!!athlete
								? athlete.surname + " " + athlete.name
								: "-"}
						</div>
						<div className="flex gap-2 py-1">
							<Status athlete={athlete} />
						</div>
					</>
				) : null}

				{!!athlete &&
				athlete?.belt != undefined &&
				!!athlete?.userPoint ? (
					<div className=" text-xs font-bold flex align-items-center gap-2 text-gray-400 dark:text-gray-200">
						<span>Punti atleta </span>
						<span
							className={`text-sm ml-2 align-items-center  font-bold w-fit text-w border-gray-300 px-2 border-2 rounded-full h-fit `}
						>
							{" "}
							{" " + tran(BeltTrans, athlete?.belt)}
						</span>
						<span>
							<FaArrowRightLong />
						</span>

						<div
							className={`text-sm w-fit flex align-items-center gap-1 font-bold text-w border-gray-300 px-2 border-2 rounded-full h-fit `}
						>
							{athlete?.belt != undefined
								? tran(BeltTrans, athlete?.belt + 1)
								: ""}
						</div>
					</div>
				) : null}
			</div>
			<div
				className={`flex gap-5 max-w-full ${
					valid ? "opacity-100" : "opacity-40"
				}`}
			>
				{!!athlete &&
				athlete?.belt != undefined &&
				!!athlete?.userPoint ? (
					<>
						{athlete?.belt == Belt.Black ? (
							<div className="font-bold italic">
								Cintura Massima
							</div>
						) : (
							<>
								<div className="flex gap-1 align-items-center max-w-full flex-wrap ">
									<div className="flex items-center w-full gap-1 -skew-x-12">
										<div
											className={
												"flex-1  h-5 bg-gray-100  relative border-2 border-gray-300"
											}
											style={{
												width:
													beltPoint(athlete?.belt!) *
														20 +
													"px",
											}}
										>
											<div
												className="absolute h-full bg-green-200 flex items-center justify-end pr-1 font-bold"
												style={{
													width: beltPerc + "%",
												}}
											>
												{athlete?.userPoint ?? 0}
											</div>
										</div>
										<span className="flex-0">
											MAX{" "}
											<span>
												{beltPoint(athlete?.belt!)}
											</span>
										</span>
									</div>
									{/* {Array.from(
										Array(beltPoint(athlete?.belt!)).keys()
									).map((i) => (
										<div
											className={`${
												i <=
												(athlete?.userPoint ?? 0) - 1
													? "bg-green-200 dark:bg-green-600"
													: "bg-white"
											} w-10 h-5 -skew-x-12 border-gray-300  dark:border-gray-400 border-2 grid place-content-center font-bold`}
										>
											{i == (athlete?.userPoint ?? 0) - 1
												? athlete?.userPoint
												: ""}
										</div>
									))}
									/{beltPoint(athlete?.belt!)} */}
								</div>
							</>
						)}
					</>
				) : (
					"Non un'atleta"
				)}
			</div>
		</div>
	);
}

function ContestHomeSection({
	title,
	contest,
	isLoading = false,
}: {
	title: string;
	contest?: ContestDto;
	isLoading?: boolean;
}) {
	return (
		<div className="flex flex-col  justify-center ">
			<div>
				<SectionTitle>{title}</SectionTitle>
			</div>
			{!isLoading ? (
				contest ? (
					<span className=" flex justify-center max-lg:justify-center">
						<ContestData contest={contest} column={6} home />
					</span>
				) : (
					<div className="md:w-[50%] max-md:w-full bg-gray-400 h-[30%] rounded-lg grid place-items-center aspect-square mx-auto">
						<div className="opacity-50 font-bold text-xl">
							Nessun Evento Trovato
						</div>
					</div>
				)
			) : (
				<div className="aspect-[3/2] animate-pulse bg-gray-400 rounded-lg w-full"></div>
			)}
		</div>
	);
}

export function SectionTitle({
	actions,
	children,
	...rest
}: { actions?: JSX.Element } & PropsWithChildren) {
	const { color } = useUI();
	return (
		<div className="text-white relative gap-1 flex md:justify-center justify-start">
			<div className="text-xl p-1 font-bold rounded-lg text-indigo-500">
				{children}
			</div>
			{actions ? <div className="flex gap-1">{actions}</div> : null}
		</div>
	);
}

function NotificationHub() {
	const { color } = useUI();
	const { AccessLevel } = GetUser();
	const client = useClient(NotificationsClient);
	const [notification, setNotification] = useState<
		NotificationDto | undefined
	>(undefined);
	const [show, setShow] = useState(false);

	const columns: Column<NotificationDto>[] = [
		{
			id: "title",
			name: "Titolo",
		},
		{
			name: "Da parte di",
			Cell: ({ fromUser }) => getIdentifier(fromUser),
		},
		{
			id: "createdAt",
			name: "Creato il",
			Cell: ({ createdAt }) =>
				format(new Date(createdAt), "P", { locale: it }),
		},
		// {
		// 	id: "message",
		// 	name: "Messagio",
		// 	Cell: (notification) => (
		// 		<Button
		// 			onClick={() => {
		// 				setNotification(notification);
		// 				setShow(true);
		// 				onOpen();
		// 			}}
		// 		>
		// 			Visualizza
		// 		</Button>
		// 	),
		// },
	];

	const { mutate: send, isLoading } = useToastMutation(
		(id: string) => client.sendNotification(id),
		undefined,
		undefined,
		"Notifica spedita"
	);

	const { isOpen, onClose, onOpen } = useDisclosure();
	const sectionActions = (
		<>
			{AccessLevel >= AccessLevelType.Admin ? (
				<>
					<Button
						className="bg-indigo-500 flex items-center gap-1 rounded-lg px-2"
						onClick={onOpen}
						icon={Icons.Add}
					>
						Aggiungi
					</Button>
				</>
			) : null}
		</>
	);

	const actions: Actions<NotificationDto> = (not, index) => (
		<ActionButtonContainer>
			<Button
				onClick={() => {
					setNotification(not);
					setShow(true);
					onOpen();
				}}
				tooltip="Mostra"
				type="table"
			>
				<AiFillEye />
				<ActionText>Mostra</ActionText>
			</Button>
			{AccessLevel >= AccessLevelType.Admin ? (
				<>
					<Button
						type="table"
						tooltip={
							<div className="w-32">
								Invia tramite email a tutti gli utenti
							</div>
						}
						isLoading={isLoading}
						onClick={() => {
							send(not.id);
						}}
					>
						<MdOutlineEmail />
						<ActionText>Invia mail</ActionText>
					</Button>
					<Button
						type="table"
						tooltip="Modifica"
						onClick={() => {
							setNotification(not);
							onOpen();
						}}
					>
						{Icons.Edit}
						<ActionText>Modifica</ActionText>
					</Button>
				</>
			) : null}
		</ActionButtonContainer>
	);
	return (
		<>
			<NotificationModal
				isOpen={isOpen}
				onClose={() => {
					onClose();
					setNotification(undefined);
					if (show) setShow(false);
				}}
				show={show}
				notification={notification}
			/>

			{/* <Modal
				isOpen={isOpen}
				onClose={onClose}
				title={notification?.title ?? "Notifica"}
			>
				ciaoooo
			</Modal> */}

			<SectionTitle>Bacheca Notifiche</SectionTitle>
			<TlTable<NotificationDto>
				columns={columns}
				queryKey={["notifications"]}
				persistanceKey="notifications"
				actions={actions}
				fetchPage={async (filterBy, sortBy, limit, offset) =>
					await client.getAllNotifications(
						filterBy,
						sortBy,
						limit,
						offset
					)
				}
				extraComponent={sectionActions}
				emptyMessage="Bacheca Vuota"
				// defaultSorting={{
				// 	order: "-",
				// 	sortBy: "createdAt",
				// }}
				options={{
					selectable: false,
					searchable: false,
					refreshable: false,
					canModifyColumn: false,
					fixedPageSize: 3,
					showNumber: false,
				}}
				idProperty="id"
			/>
		</>
	);
}

function BeltPromotionHub() {
	const client = useClient(DashboardClient);
	const beltsColumns: Column<AthleteDto>[] = [
		{
			name: "Atleta",
			Cell: (athlete) => <AthleteLink athlete={athlete} />,
		},
		{
			id: "userPoint",
			name: "Punti",
			Cell: ({ userPoint, belt, userRoleType }) => (
				<div
					className={classNames(
						(userPoint ?? 0) > beltPoint(belt!)
							? "font-bold"
							: "font-normal"
					)}
				>
					{`${userPoint ?? 0}/${beltPoint(belt!)}`}
				</div>
			),
		},
		{
			id: "belt",
			name: "Cintura",
			Cell: ({ belt }) =>
				!!belt ? (
					<div className="flex items-center gap-2">
						<span>{tran(BeltTrans, belt)}</span>
						<FaArrowRight />
						<span>{tran(BeltTrans, belt + 1)}</span>
					</div>
				) : (
					"-"
				),
		},
	];
	return (
		<>
			<SectionTitle>Promozioni Cinture</SectionTitle>
			<TlTable<AthleteDto>
				columns={beltsColumns}
				queryKey={["athletes", "belts"]}
				// actions={actions}
				fetchPage={async (filterBy, sortBy, limit, offset) =>
					await client.getAthletesToPromote(
						undefined,
						sortBy,
						limit,
						offset
					)
				}
				defaultSorting={{
					order: "-",
					sortBy: "userPoint",
				}}
				// extraComponent={extraComponent}
				// actions={actions}
				// bulkActions={bulkActions}
				persistanceKey="athletesBelt"
				options={{
					selectable: false,
					searchable: false,
					refreshable: false,
					canModifyColumn: false,
					fixedPageSize: 5,
					showNumber: false,
				}}
				idProperty="athleteId"
				searchPlaceholder="Cerca atleta"
			/>
		</>
	);
}

function MedicalCertificateHub() {
	const client = useClient(DashboardClient);

	const expireColumns: Column<AthleteDto>[] = [
		{
			name: "Atleta",
			Cell: (athlete) => <AthleteLink athlete={athlete} />,
		},
		{
			id: "medicalCertificateDeadline",
			name: "Scadenza certificato medico",
			Cell: ({ medicalCertificateDeadline }) =>
				!!medicalCertificateDeadline
					? format(new Date(medicalCertificateDeadline), "P", {
							locale: it,
					  })
					: "Senza certificato",
		},
	];
	return (
		<>
			<SectionTitle>Scadenza certificati medici</SectionTitle>

			<TlTable<AthleteDto>
				columns={expireColumns}
				queryKey={["athletes", "medicalCertificate"]}
				// actions={actions}
				fetchPage={async (filterBy, sortBy, limit, offset) =>
					await client.getAthletesWithExpiredMC(
						undefined,
						sortBy,
						limit,
						offset
					)
				}
				defaultSorting={{
					order: "-",
					sortBy: "medicalCertificateDeadline",
				}}
				persistanceKey="medicalCertificateDeadline"
				options={{
					selectable: false,
					searchable: false,
					fixedPageSize: 5,
					refreshable: false,
					canModifyColumn: false,
					showNumber: false,
				}}
				idProperty="athleteId"
			/>
		</>
	);
}
