import { useQuery } from "@tanstack/react-query";
import { AccountType, UsersClient } from "../../Api";
import { useClient } from "../../components/UseClient";
import GetUser from "../../components/GetUser";
import { useParams } from "react-router-dom";
import { SingleAthletePage } from "../Athletes/SingleAthletePage";
import { SingleFamilyPage } from "../Families/SingleFamilyPage";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export function SingleUserPage({ athleteId }: { athleteId?: string }) {
	const { id } = useParams();
	const { isLoading } = GetUser();
	const client = useClient(UsersClient);

	const { data: user, isLoading: isLoadingUser } = useQuery({
		queryKey: ["users", id],
		queryFn: () => client.getUser(id!).then((r) => r.result),
		enabled: !!id,
	});

	return (
		<div>
			{isLoading || isLoadingUser ? (
				<div>
					<AiOutlineLoading3Quarters className="animate-spin w-14 h-14" />
				</div>
			) : (
				<>
					{user?.accountType == AccountType.SingleUser ? (
						<SingleAthletePage
							user={user}
							athleteId={user?.athlete?.athleteId}
						/>
					) : (
						<SingleFamilyPage
							familyId={user?.family?.familyId}
							userAccount={user}
						/>
					)}
				</>
			)}
		</div>
	);
}
