import { ItemDto, ManageItemRequestDto } from "../../../Api";
import { Stack, GridItem, Heading, Box } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { NumberField } from "../../../components/InputFormikFiled";
import * as Yup from "yup";

import { Icons } from "../../../utilities/icons";
import {
	CsActionStack,
	CsGrid,
	CsSubmitButton,
} from "../../../components/CsElements";
import GetUser from "../../../components/GetUser";
import { FormProps } from "../../../utilities/typeUtils";

export default function ItemRequestForm({
	initialValues,
	action = "create",
	isLoading,
	onSubmit,
	item,
}: FormProps<ManageItemRequestDto> & {
	item?: ItemDto;
}) {
	const { currentUser } = GetUser();
	const minH = "6.2rem";
	const numOfColumn = 12;

	const schema = Yup.object().shape({
		quantity: Yup.number()
			.positive()
			.min(1, "Devi richiedere almeno 1 articolo")
			.max(
				item?.quantity ?? 0,
				"Non puoi richere più della quantità massima"
			)
			.required("Questo campo è obbligatorio"),
	});

	return (
		<Formik<ManageItemRequestDto>
			validationSchema={schema}
			initialValues={{ ...initialValues }}
			onSubmit={(values) =>
				onSubmit({
					...values,
					userId: currentUser?.id!,
				})
			}
		>
			{({
				values,
				errors,
				touched,
				isSubmitting,
				handleBlur,
				setFieldValue,
			}) => (
				<Form>
					<CsGrid templateColumns={`repeat(${numOfColumn}, 1fr)`}>
						<GridItem colSpan={6} minH={minH}>
							<NumberField
								icon={Icons.AssociationName}
								id="quantity"
								name="quantity"
								label="Quantità"
								max={item?.quantity}
								min={1}
								placeholder="Inserisci quantità"
								required
							/>
						</GridItem>
						<GridItem colSpan={6} minH={minH}>
							<Stack>
								<Box>Totale: </Box>
								<Heading>
									{!!item?.price
										? item?.price * values.quantity
										: "-"}
									€
								</Heading>
							</Stack>
						</GridItem>
					</CsGrid>

					<CsActionStack>
						<CsSubmitButton isLoading={isLoading || isSubmitting}>
							{action == "update" ? "Modifica" : "Crea"}
						</CsSubmitButton>
					</CsActionStack>
				</Form>
			)}
		</Formik>
	);
}
