
import { AssociationDto, AssociationsClient } from "../../../Api";
import { useClient } from "../../../components/UseClient";
import { useUI } from "../../../UiContext";
import { DeleteModalProps } from "../../../utilities/typeUtils";
import useToastMutation from "../../../components/Hooks/useToastMutation";
import { DeleteModal } from "../../../components/UI/Modal";

export function DeleteAssociationModal({
	isOpen,
	entity,
	onClose,
}: DeleteModalProps<AssociationDto>) {
	const client = useClient(AssociationsClient);
	const { color } = useUI();

	const { mutate: deleteMutation, isLoading } = useToastMutation(
		() => client.deleteAssociation(entity?.associationId!),
		onClose,
		["associations"],
		"Associazione Rimossa"
	);

	return (
		<>
			<DeleteModal
				deleteMutation={deleteMutation}
				isLoading={isLoading}
				name="Associazione"
				isOpen={isOpen}
				onClose={onClose}
			>
				Sei sicuro di voler eliminare l'associazione{" "}
				<span className="font-bold">{entity?.name}</span> (verranno
				eliminati tutti i dati associati a questa associazione)?
			</DeleteModal>
		</>
	);
}
