import { Feature, ManageCalendarMarkDto } from "../../../Api";
import { GridItem } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import {
	InputField,
	SelectField,
	ColorField,
	NumberField,
} from "../../../components/InputFormikFiled";
import * as Yup from "yup";

import { formatProperName } from "../../../components/utils";
import { Icons } from "../../../utilities/icons";
import {
	CsActionStack,
	CsDeleteButton,
	CsGrid,
	CsSubmitButton,
} from "../../../components/CsElements";
import { hours } from "../../../utilities/Constant";
import { CourseField } from "../../../components/Fields/CourseField";
import { SubsidiaryField } from "../../../components/Fields/SubsidiariyField";
import GetUser from "../../../components/GetUser";
import { DaysTrans } from "../../../utilities/Translations";
import { DeletingButtonProps, FormProps } from "../../../utilities/typeUtils";

export default function CalendarMarkForm({
	initialValues,
	action = "create",
	isLoading,
	onSubmit,
	isDeleting,
	deleteMutation,
}: FormProps<ManageCalendarMarkDto> & DeletingButtonProps) {
	const { features } = GetUser();
	const minH = "6.2rem";
	const numOfColumn = 12;

	const schema = Yup.object().shape({
		title: Yup.string()
			.min(3, "Deve essere lungo almeno 3 caratteri")
			.required("Questo campo è obbligatorio"),
		color: Yup.string().required("Questo campo è obbligatorio"),
		hour: Yup.string()
			.min(4, "Seleziona Ora")
			.required("Questo campo è obbligatorio"),
		duration: Yup.number()
			.positive()
			.min(0.5, "Il minimo è 0.5")
			.required("Questo campo è obbligatorio"),
	});

	return (
		<Formik<ManageCalendarMarkDto>
			validationSchema={schema}
			initialValues={{ ...initialValues }}
			onSubmit={onSubmit}
		>
			{({
				values,
				errors,
				touched,
				isSubmitting,
				handleBlur,
				setFieldValue,
			}) => (
				<Form>
					<CsGrid templateColumns={`repeat(${numOfColumn}, 1fr)`}>
						{/* nome---------------------------------------- */}
						<GridItem colSpan={6} minH={minH}>
							<InputField
								icon={Icons.Title}
								id="title"
								name="title"
								label="Titolo"
								placeholder="Inserisci titolo"
								required
								format={formatProperName}
							/>
						</GridItem>

						{/* website---------------------------------------- */}
						<GridItem colSpan={6} minH={minH}>
							<SelectField
								id="day"
								name="day"
								label="Giorno"
								icon={Icons.Day}
								options={DaysTrans}
								required
								isInt
							/>
						</GridItem>

						{/* phoneNumber---------------------------------------- */}

						<GridItem colSpan={1} minH={minH}>
							<ColorField
								icon={Icons.Color}
								id="color"
								name="color"
								label="Colore"
								required
							/>
						</GridItem>

						{/* nome---------------------------------------- */}
						<GridItem colSpan={5} minH={minH}>
							<SelectField
								id="hour"
								name="hour"
								label="Ora Inizio"
								icon={Icons.Schedule}
								options={hours.map((x) => {
									return {
										value: x,
										label: x,
									};
								})}
								required
							/>
						</GridItem>

						{/* nome---------------------------------------- */}
						<GridItem colSpan={5} minH={minH}>
							<NumberField
								id="duration"
								name="duration"
								label="Durata"
								precision={2}
								icon={Icons.Deadline}
								min={0.5}
								step={0.5}
								max={16.5}
								commands
								required
								hint="1.5 = 1 h 30 m"
							/>
						</GridItem>

						{features?.includes(Feature.Courses) ? (
							<GridItem colSpan={6} minH={minH}>
								<CourseField
									icon={Icons.Course}
									id="courseId"
									name="courseId"
									label="Corso"
								/>
							</GridItem>
						) : null}
						{features?.includes(Feature.Subsidiary) ? (
							<GridItem colSpan={6} minH={minH}>
								<SubsidiaryField
									icon={Icons.Street}
									id="subsidiaryId"
									name="subsidiaryId"
									label="Sede"
								/>
							</GridItem>
						) : null}
					</CsGrid>
					{/* Button---------------------------------------- */}
					<CsActionStack>
						<CsSubmitButton isLoading={isLoading || isSubmitting}>
							{action == "update" ? "Modifica" : "Crea"}
						</CsSubmitButton>
						{action == "update" ? (
							<CsDeleteButton
								onClick={deleteMutation}
								isLoading={isDeleting}
							/>
						) : null}
					</CsActionStack>
				</Form>
			)}
		</Formik>
	);
}
