import { useState } from "react";

import {
	Popover,
	PopoverTrigger,
	Button,
	PopoverContent,
	PopoverArrow,
	PopoverCloseButton,
	PopoverHeader,
	PopoverBody,
	Center,
	SimpleGrid,
	Input,
	Text,
	useToken,
	InputProps,
	HStack,
	Stack,
} from "@chakra-ui/react";

export default function ColorPicker({
	setFieldValue,
	...rest
}: { setFieldValue: (c: string) => void } & InputProps) {
	const [color, setColor] = useState(
		!!rest.value
			? (rest.value as string)
			: !!rest.defaultValue
			? (rest.defaultValue as string)
			: "#E53E3E"
	);

	const [red, gray, green, blue, yellow, orange, purple, pink, teal, cyan] =
		useToken(
			// the key within the theme, in this case `theme.colors`
			"colors",
			// the subkey(s), resolving to `theme.colors.red.100`
			[
				"red.500",
				"gray.500",
				"green.500",
				"blue.500",
				"yellow.500",
				"orange.500",
				"purple.500",
				"pink.500",
				"teal.500",
				"cyan.500",
			]
			// a single fallback or fallback array matching the length of the previous arg
		);

	const colors = [
		red,
		gray,
		green,
		blue,
		yellow,
		orange,
		purple,
		pink,
		teal,
		cyan,
	];

	return (
		<>
			<Stack alignItems="center" h="100%">
				<Popover variant="picker" placement="left-start">
					<PopoverTrigger>
						<HStack w="100%" justifyContent="start">
							<Button
								aria-label={color}
								background={color ?? "transparent"}
								border="1px solid gray"
								borderColor="gray.300"
								height="22px"
								width="22px"
								padding={0}
								minWidth="unset"
								borderRadius={3}
							></Button>
							<Text>{!!color ? color : "-"}</Text>
						</HStack>
					</PopoverTrigger>
					<PopoverContent width="170px">
						<PopoverArrow bg={color} />
						<PopoverCloseButton color="white" />
						<PopoverHeader
							height="100px"
							backgroundColor={color}
							borderTopLeftRadius={5}
							borderTopRightRadius={5}
							color="white"
						>
							<Center height="100%">{color}</Center>
						</PopoverHeader>
						<PopoverBody height="120px">
							<SimpleGrid columns={5} spacing={2}>
								{colors.map((c) => (
									<Button
										key={c}
										aria-label={c}
										background={c}
										height="22px"
										width="22px"
										padding={0}
										minWidth="unset"
										borderRadius={3}
										_hover={{ background: c }}
										onClick={() => {
											setColor(c);
											setFieldValue(c);
										}}
									></Button>
								))}
							</SimpleGrid>
							<Input
								borderRadius={3}
								marginTop={3}
								placeholder="red.100"
								size="sm"
								value={color}
								onChange={(e) => {
									setColor(e.target.value);
									setFieldValue(e.target.value);
								}}
								{...rest}
							/>
						</PopoverBody>
					</PopoverContent>
				</Popover>
			</Stack>
		</>
	);
}
