import { Formik, Form } from "formik";
import { ManageUserDto, AccountType, FilesClient, RoleType } from "../../Api";
import * as Yup from "yup";
import {
	DateField,
	InputField,
	NumberField,
	PasswordField,
	SelectField,
} from "../../components/InputFormikFiled";
import { AthleteFields } from "../SignUp/AthleteForm";
import { useState } from "react";
import { AccountTypeTrans, BeltTrans } from "../../utilities/Translations";
import { Icons } from "../../utilities/icons";
import { useClient } from "../../components/UseClient";
import GetUser, { AccessLevelType } from "../../components/GetUser";
import { FormProps } from "../../utilities/typeUtils";
import { AssociationField } from "../../components/Fields/AssociationField";
import { athleteSchema } from "../../utilities/yupUtils";
import FormLayout, { FormSection } from "../../components/UI/FormLayout";
import Button from "../../components/UI/Button";
import { AddressFields } from "../../components/CsForm";
import { formatProperName } from "../../components/utils";

export default function UserForm({
	isLoading,
	action,
	onSubmit,
	initialValues,
}: FormProps<ManageUserDto>) {
	const { AccessLevel } = GetUser();
	const schema = Yup.object().shape({
		email: Yup.string()
			.email("Inserire una mail valida")
			.required("Questo campo è obbligatorio"),
		password: Yup.string()
			//   .matches(/^\S*$/, "Non deve contenere spazi")
			.min(8, "Deve Essere almeno 8 caratteri")
			.matches(/[A-Z]/, "Deve contenere almeno una maiuscola")
			.matches(/\d/, "Deve contenere almeno un numero")
			.matches(/\W/, "Deve contenere almeno un carattere speciale")
			.required("Questo campo è obbligatorio"),

		athlete: Yup.object().when("accountType", {
			is: AccountType.SingleUser,
			then: athleteSchema,
		}),
		family: Yup.object().when("accountType", {
			is: AccountType.Family,
			then: Yup.object({
				familyName: Yup.string().required(
					"Questo campo è obbligatorio"
				),
			}),
		}),
	});

	const [profilePicture, setProfilePicture] = useState<File>();
	const filesClient = useClient(FilesClient);

	return (
		<Formik<ManageUserDto>
			validationSchema={schema}
			initialValues={{
				...initialValues,
				// associationId:
				// 	AccessLevel < AccessLevelType.SuperAdmin
				// 		? currentUser?.associationId
				// 		: undefined,
			}}
			onSubmit={async (values) => {
				if (values.accountType == AccountType.SingleUser)
					values.family = undefined;
				else if (values.accountType == AccountType.Family)
					values.athlete = undefined;

				if (values.athlete == undefined || profilePicture == undefined)
					onSubmit(values);
				if (profilePicture != undefined) {
					await filesClient
						.addFile(
							{
								data: profilePicture,
								fileName: profilePicture.name,
							},
							profilePicture.type
						)
						.then((r) => {
							onSubmit({
								...values,
								athlete: {
									...values.athlete!,
									profilePictureId: r.result.id,
								},
							});
							return r.result;
						})
						.catch((r) => onSubmit(values));
				}
			}}
		>
			{({ values, errors, touched, isSubmitting, setFieldValue }) => (
				<>
					<Form>
						<FormLayout
							actions={
								<>
									<Button
										submit
										isLoading={isSubmitting || isLoading}
									>
										{action == "create"
											? "Crea"
											: "Modifica"}
									</Button>
								</>
							}
						>
							<FormSection title="Informazioni account">
								<div className="flex flex-col gap-4">
									<InputField
										icon={Icons.Email}
										id="email"
										name="email"
										label="Email"
										placeholder="Inserire Email"
										required
									/>
									<PasswordField
										icon={Icons.Password}
										id="password"
										name="password"
										label="Password"
										format={(v) => v.trim()}
										placeholder="Inserire Password"
										disabled={true}
										required
									/>
									<SelectField
										id="accountType"
										name="accountType"
										label="Tipologia Utente"
										icon={Icons.AccountType}
										options={AccountTypeTrans}
										required
										isInt
									/>
									{AccessLevel >=
									AccessLevelType.SuperAdmin ? (
										<AssociationField
											icon={Icons.Course}
											id="associationId"
											name="associationId"
											label="Associazione"
										/>
									) : null}
									{/* {!!values.athlete && */}
								</div>
							</FormSection>
							{values.accountType == AccountType.SingleUser ? (
								<>
									<FormSection title="Informazioni utente">
										<div className="flex flex-col gap-4">
											<AthleteFields
												values={values.athlete!}
												setPictureValue={
													setProfilePicture
												}
												filedsPrefix="athlete"
											/>
										</div>
									</FormSection>
									{values.athlete?.userRoleType ===
									RoleType.Athlete ? (
										<FormSection title="Informazioni atleta">
											<div className="flex flex-col gap-4">
												<DateField
													id={
														"athlete.medicalCertificateDeadline"
													}
													name={
														"athlete.medicalCertificateDeadline"
													}
													label="Scadenza Certificato Medico"
													icon={Icons.Deadline}
													placeholder="gg/mm/aaaa	"
												/>

												<div className="flex gap-4 max-md:flex-col w-full flex-1 ">
													<span className="flex-1">
														<SelectField
															icon={Icons.Belt}
															id={"athlete.belt"}
															name={
																"athlete.belt"
															}
															label="Grado"
															required
															options={BeltTrans}
															isInt
														/>
													</span>
													<span className="flex-1">
														<NumberField
															icon={Icons.Weight}
															id={
																"athlete.weight"
															}
															name={
																"athlete.weight"
															}
															label="Peso"
															min={0}
															precision={2}
															required
															rightElement="Kg"
														/>
													</span>
													<span className="flex-2">
														<NumberField
															icon={Icons.Point}
															id={
																"athlete.userPoint"
															}
															name={
																"athlete.userPoint"
															}
															label="Punti Atleta"
															min={0}
															required
														/>
													</span>
												</div>
											</div>
										</FormSection>
									) : null}
									<FormSection title="Residenza">
										<div className="flex flex-col gap-4">
											<AddressFields
												property={
													"athlete.residenceAddress"
												}
											/>
										</div>
									</FormSection>
								</>
							) : (
								<>
									<FormSection title="Informazioni famiglia">
										<div className="flex flex-col gap-4">
											<InputField
												icon={Icons.Name}
												id="family.familyName"
												name="family.familyName"
												label="Nome Famiglia"
												placeholder="Inserisci Nome Famiglia"
												format={formatProperName}
												required
											/>
										</div>
									</FormSection>
								</>
							)}
						</FormLayout>

						{/* <Flex flexDir="column" width="100%">
							<Steps
								activeStep={activeStep}
								labelOrientation="vertical"
							>
								<Step
									label={steps[0].label}
									key={steps[0].label}
								>
									<Stack pb={4} px={100}>
										<CsGrid
											templateColumns={`repeat(${numOfColumn}, 1fr)`}
										>
											<RowTitleForm
												title="Generale"
												key="GeneraleTitle"
												column={numOfColumn}
											/>

											<GridItem
												colSpan={numOfColumn / 2}
												minH={minH}
											>
												<InputField
													icon={Icons.Email}
													id="email"
													name="email"
													label="Email"
													placeholder="Inserire Email"
													isRequired
												/>
											</GridItem>

											<GridItem
												colSpan={numOfColumn / 2}
												minH={minH}
											>
												<PasswordField
													icon={Icons.Password}
													id="password"
													name="password"
													label="Password"
													format={(v) => v.trim()}
													placeholder="Inserire Password"
													isDisabled={
														from ==
															"associationTab" &&
														action == "create"
															? true
															: false
													}
													isRequired
												/>
											</GridItem>

											{newAccountType != undefined ? (
												<></>
											) : (
												<GridItem
													colSpan={numOfColumn}
													minH={minH}
												>
													<SelectField
														id="accountType"
														name="accountType"
														label="Tipologia Utente"
														icon={Icons.AccountType}
														options={
															AccountTypeTrans
														}
														isRequired
														isInt
														onChange={(e) => {
															if (
																e.target
																	.value ==
																AccountType.SingleUser.toString()
															)
																setFieldValue(
																	"family",
																	undefined
																);
															else if (
																e.target
																	.value ==
																AccountType.Family.toString()
															)
																setFieldValue(
																	"athlete",
																	undefined
																);
														}}
													/>
												</GridItem>
											)}

											{AccessLevel >=
											AccessLevelType.SuperAdmin ? (
												<GridItem
													colSpan={numOfColumn}
													minH={minH}
												>
													<AssociationField
														icon={Icons.Course}
														id="associationId"
														name="associationId"
														label="Associazione"
													/>
												</GridItem>
											) : null}

										</CsGrid>
										<CsActionStack>
											<StepButton
												step="start"
												disabled={
													(Object.keys(errors)
														.length == 0 &&
														Object.keys(touched)
															.length == 0) ||
													(errors.email
														? true
														: false &&
														  touched.email !=
																true) ||
													(errors.password
														? true
														: false &&
														  touched.password !=
																true)
												}
												nextStep={nextStep}
											/>
										</CsActionStack>
									</Stack>
								</Step>
								<Step
									label={steps[1].label}
									key={steps[1].label}
								>
									{values.accountType == 0 ? (
										<Box w="100%">
											<>
												<Button
													onClick={() =>
														console.log(errors)
													}
												></Button>
												<UserAthleteForm
													errors={errors}
													// touched={touched}
													// schema={schema}
													values={values.athlete!}
													next={nextStep}
													prev={prevStep}
													setPictureValue={
														setProfilePicture
													}
												/>
											</>
										</Box>
									) : (
										<FamilyForm
											errors={errors}
											touched={touched}
											schema={schema}
											values={values.family!}
											next={nextStep}
											prev={prevStep}
										/>
									)}
								</Step>
								<Step
									label={steps[2].label}
									key={steps[2].label}
								>
									<Data
										values={values}
										prevStep={prevStep}
										isSubmitting={isSubmitting && isLoading}
									></Data>
								</Step>
							</Steps> */}
						{/* {activeStep === steps.length ? (
								<Button
									isLoading={isLoading}
									colorScheme={color.scheme}
									variant="solid"
									type="submit"
									p="1.5rem"
									fontSize="1.5rem"
									borderRadius="full"
									position="absolute"
									right="5%"
									key="userFormSubmit"
									transform="translate(0,-50%)"
								>
									{action == "update"
										? "Modifica"
										: action == "create"
										? "Crea"
										: "Registrati"}
								</Button>
							) : null} 
						</Flex>*/}
					</Form>
				</>
			)}
		</Formik>
	);
}
