
import { AccountClient, UserDto } from "../../Api";
import { useClient } from "../../components/UseClient";
import { useUI } from "../../UiContext";
import { getIdentifier } from "../../components/utils";
import useToastMutation from "../../components/Hooks/useToastMutation";
import { ModalProps } from "../../utilities/typeUtils";
import { Modal } from "../../components/UI/Modal";
import { FaCrown } from "react-icons/fa6";
import Button from "../../components/UI/Button";

export function UpgradeUserModal({
	isOpen,
	user,
	onClose,
}: ModalProps & { user: UserDto }) {
	const client = useClient(AccountClient);
	const { color } = useUI();
	const invalidate = ["users", "athletes", "admins"];

	const { mutate: upgradeMutation, isLoading } = useToastMutation(
		() => client.upgradeToAdmin(user.id),
		onClose,
		invalidate,
		"L'utente è diventato amministratore"
	);

	return (
		<>
			<Modal
				title={"Promuovi ad Amministratore"}
				icon={
					<div className="rounded-full bg-green-100 p-2">
						<FaCrown
							className="h-6 w-6 text-green-600"
							aria-hidden="true"
						/>
					</div>
				}
				isOpen={isOpen}
				onClose={onClose}
			>
				<div className="flex flex-col gap-3">
					<p>
						Sei sicuro di voler promuovere l'utente{" "}
						<span className="font-bold">{getIdentifier(user)}</span>{" "}
						ad amministratore?
					</p>
					<div className="flex w-full justify-end gap-2 items-center">
						<Button onClick={onClose} type="secondary">
							Annulla
						</Button>
						<Button
							isLoading={isLoading ?? false}
							onClick={() => upgradeMutation()}
							className="bg-green-500 hover:bg-green-400"
						>
							Promuovi
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
}
