import { useCallback } from "react";
import {
	useField,
	useFormikContext,
	getIn,
	FieldAttributes,
	FieldInputProps,
	FieldMetaProps,
	FieldHelperProps,
} from "formik";

export type ApiFieldMetaProps<Val> = FieldMetaProps<Val> & { apiError: any };

// https://github.com/jaredpalmer/formik/issues/150#issuecomment-549153480
export function useApiField<Val = any>(
	propsOrFieldName: string | FieldAttributes<Val>
): [FieldInputProps<Val>, ApiFieldMetaProps<Val>, FieldHelperProps<Val>] {
	const { status, setStatus } = useFormikContext();
	const [{ onBlur: onBlurFormik, name, ...field }, meta, helper] =
		useField(propsOrFieldName);

	const errors = getIn(status, name);
	const apiError = errors instanceof Array ? errors.join(" ") : errors;

	const onBlurMemo = useCallback(
		(e: any) => {
			// reset API error on blur
			setStatus({
				...status,
				[name]: null,
			});
			onBlurFormik(e);
		},
		[status, name, setStatus, onBlurFormik]
	);

	return [
		{ onBlur: onBlurMemo, name, ...field },
		{ ...meta, apiError },
		helper,
	];
}
