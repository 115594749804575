import  { useState } from "react";

export default function useLimit(persistanceKey: string | undefined) {
	const limitKey = persistanceKey + "_limit";
	const localLimit = Number(localStorage.getItem(limitKey) ?? "0");

	const [limit, _setLimit] = useState<number>(localLimit);

	const setLimit = (value: number | undefined) => {
		if (value == undefined) {
			localStorage.removeItem(limitKey);
			_setLimit(0);
		} else {
			localStorage.setItem(limitKey, value.toString());
			_setLimit(value);
		}
	};

	return { limit: limit, setLimit: setLimit };
}
