import {
	ItemRequestDto,
	ItemRequestsClient,
	UserDto,
	ItemDto,
} from "../../../Api";
import { useToast } from "@chakra-ui/react";
import { Actions, Column, CsTable } from "../../../components/CsTable";
import { CsTableButton } from "../../../components/CsElements";
import GetUser from "../../../components/GetUser";
import { useClient } from "../../../components/UseClient";
import ActionButton from "../../../components/ActionButton";
import ItemRequestModal from "./ItemRequestModal";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Icons } from "../../../utilities/icons";
import { ItemLink, UserLink } from "../../../components/TextLink";
import useDisclosure from "../../../components/Hooks/useDisclosure";
import { TlTable } from "../../../components/UI/Table";

type Props = {
	item?: ItemDto;
	user?: UserDto;
};

export default function ItemRequestsTab({ item, user }: Props) {
	const { currentUser } = GetUser();

	const client = useClient(ItemRequestsClient);

	const { isOpen, onOpen, onClose } = useDisclosure();

	const toast = useToast();
	const queryClient = useQueryClient();

	const columns: Column<ItemRequestDto>[] = [
		{
			name: "Richiedente",
			Cell: ({ user: u }) => <UserLink user={u} />,
			isVisible: item ? true : false,
		},
		{
			name: "Articolo",
			Cell: ({ item }) => <ItemLink item={item} />,
			isVisible: user ? true : false,
		},
		{
			id: "quantity",
			name: "Quantità",
			Cell: ({ quantity }) => quantity.toString(),
		},
		{
			name: "Totale",
			Cell: ({ quantity }) =>
				(quantity * (item?.price ?? 1)).toString() + "€",
		},
		{
			id: "createdAt",
			name: "Data",
			Cell: ({ createdAt }) =>
				format(new Date(createdAt), "P", { locale: it }),
		},
	];

	const { mutate: deleteRequest, isLoading: isDeleting } = useMutation(
		(requestId: string) => client.deleteItemRequest(requestId),
		{
			onSuccess: () => {
				toast({
					title: "Richiesta Eliminata",
					status: "success",
					duration: 3000,
					isClosable: true,
				});
				queryClient.invalidateQueries(["itemRequests"]);
				onClose();
			},
			onError: () => {
				toast({
					title: "Si è verificato un errore",
					status: "error",
					duration: 3000,
					isClosable: true,
				});
			},
		}
	);

	const extraComponent = (
		<>
			{!!item ? (
				<ActionButton onClick={onOpen}>Richiedi Articolo</ActionButton>
			) : null}
		</>
	);

	const actions: Actions<ItemRequestDto> = (request, index) => (
		<>
			{request.user.id == currentUser?.id ? (
				<CsTableButton
					index={index}
					text={Icons.Delete}
					tooltip="Info"
					onClick={() => deleteRequest(request.id)}
				/>
			) : null}
		</>
	);

	const placeholder = "Cerca Richiesta";

	return (
		<>
			<ItemRequestModal isOpen={isOpen} onClose={onClose} item={item} />
			<TlTable<ItemRequestDto>
				columns={columns}
				queryKey={["itemRequests", item?.id, user?.id]}
				fetchPage={async (filter, sort, limit, offset) =>
					await (item != undefined
						? client.getAllRequestsFromItem(
								item?.id!,
								filter,
								sort,
								limit,
								offset
						  )
						: client.getAllRequestsFromUser(
								user?.id!,
								filter,
								sort,
								limit,
								offset
						  ))
				}
				extraComponent={extraComponent}
				options={{ selectable: false, showNumber: false }}
				persistanceKey={"itemRequest" + item?.id + "_" + user?.id}
				defaultSorting={{ order: "-", sortBy: "createdAt" }}
				actions={actions}
				idProperty="id"
				searchPlaceholder={placeholder}
			/>
		</>
	);
}
