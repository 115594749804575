import { PropsWithChildren } from "react";

type FormInfo = {
	title: string;
	description?: string;
};

export default function FormLayout({
	actions,
	children,
}: { actions: JSX.Element } & PropsWithChildren) {
	return (
		<>
			<div className="space-y-12 ">
				<div className="grid grid-cols-1 gap-x-8 gap-y-10  border-b border-gray-900/10 pb-12 md:grid-cols-3">
					{children}
				</div>

				<div className="mt-3 flex items-center justify-end gap-x-6">
					{actions}
				</div>
			</div>
		</>
	);
}

export function FormSection({
	title,
	description,
	children,
}: FormInfo & PropsWithChildren) {
	return (
		<>
			<div className="col-span-1">
				<h2 className="text-base font-semibold  leading-7  text-gray-900">
					{title}
				</h2>
				{!!description ? (
					<p className="mt-1 text-sm leading-6 text-gray-600">
						{description}
					</p>
				) : null}
			</div>
			<div className="col-span-2">{children}</div>
			{/* <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-1">
				{children}
			</div> */}
		</>
	);
}
