import { PropsWithChildren } from "react";
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	Heading,
	ModalHeader,
	ModalOverlay,
	Center,
	ModalProps,
} from "@chakra-ui/react";
import { useUI } from "../UiContext";

type Props = {
	isOpen: boolean;
	onClose: () => void;
	title?: string;
};

export default function CsModal({
	isOpen,
	onClose,
	title,
	children,
	...rest
}: Props & PropsWithChildren & ModalProps) {
	const { color, borderRadius } = useUI();
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size="2xl"
			trapFocus={true}
			{...rest}
		>
			<ModalOverlay />
			<ModalContent borderRadius={borderRadius.primary}>
				<ModalHeader
					bg={color.primary}
					color="white"
					borderTopRadius={borderRadius.primary}
				>
					<Heading>
						<Center>{title}</Center>
					</Heading>
				</ModalHeader>
				<ModalCloseButton px={2} color="white" />
				<ModalBody>{children}</ModalBody>
			</ModalContent>
		</Modal>
	);
}
