import { useState } from "react";
import { AdminsClient, AssociationDto, AssociationsClient } from "../../../Api";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { Actions, Column } from "../../../components/CsTable";
import { useClient } from "../../../components/UseClient";
import { useToast } from "@chakra-ui/react";
import GetUser, { AccessLevelType } from "../../../components/GetUser";
import { useUI } from "../../../UiContext";
import { Icons } from "../../../utilities/icons";
import { FeatureTrans, tran } from "../../../utilities/Translations";
import { addressColumn } from "../../../utilities/ColumnsUtils";
import { DeleteAssociationModal } from "./DeleteAssociationModal";
import Button, { ActionButtonContainer } from "../../../components/UI/Button";
import { ActionText, TlTable } from "../../../components/UI/Table";
import { useNavigate } from "react-router-dom";
import useDisclosure from "../../../components/Hooks/useDisclosure";

export default function AssociationsTab() {
	const client = useClient(AssociationsClient);
	const adminClient = useClient(AdminsClient);
	const [changeEntity, setChangeEntity] = useState<AssociationDto>();

	const { user, AccessLevel, currentUser } = GetUser();

	const { color } = useUI();

	const {
		isOpen: isDeleteOpen,
		onOpen: onDeleteOpen,
		onClose: onDeleteClose,
	} = useDisclosure();

	const toast = useToast();
	const navigate = useNavigate();

	const queryClient = useQueryClient();
	function invalidate() {
		queryClient.invalidateQueries(["user", user?.Id]);

		queryClient.invalidateQueries(["athletes"]);
	}
	const columns: Column<AssociationDto>[] = [
		{
			id: "name",
			name: "Nome",
		},
		{
			id: "phoneNumber",
			name: "Numero di Telefono",
		},
		{
			//@ts-expect-error
			id: "address",
			name: "Indirizzo",
			...addressColumn(),
		},
		{
			id: "website",
			name: "Website",
			Cell: ({ website }) => (
				<>
					{website ? (
						<a
							target="_blank"
							href={website}
							rel="noreferrer"
							color={color.primary}
						>
							{website.split("//")[1]}
						</a>
					) : (
						"-"
					)}
				</>
			),
		},
		{
			id: "features",
			name: "features",
			Cell: ({ features }) => (
				<div className="flex gap-1 flex-wrap">
					{features.sort().map((x) => (
						<div className="bg-gray-200 border-2 border-gray-200 p-1 rounded-md">
							{tran(FeatureTrans, x)}
						</div>
					))}
				</div>
			),
		},
	];

	const { mutate: changeMutation, isLoading } = useMutation(
		(associationId: string) => adminClient.changeAssociation(associationId),
		{
			onSuccess: (response) => {
				toast({
					title: "Connesso al manager di " + response.result.name,
					status: "success",
					duration: 3000,
					isClosable: true,
				});
				invalidate();
			},
			onError: () => {
				toast({
					title: "Si è verificato un errore",
					status: "error",
					duration: 3000,
					isClosable: true,
				});
				invalidate();
			},
		}
	);

	const actions: Actions<AssociationDto> = (entity, index, selected) => (
		<ActionButtonContainer>
			<>
				<Button
					disabled={
						!(
							AccessLevel >= AccessLevelType.SuperAdmin &&
							entity.associationId != currentUser?.associationId
						)
					}
					isLoading={isLoading}
					tooltip="Connetti pannello"
					onClick={() => changeMutation(entity.associationId)}
					selected={selected}
				>
					{Icons.Connect}
					<ActionText>Connetti pannello</ActionText>
				</Button>
			</>

			<Button
				type="table"
				tooltip="Modifica"
				onClick={() => {
					navigate(`/associations/${entity.associationId}/edit`);
				}}
				selected={selected}
			>
				{Icons.Edit}
				<ActionText>Modifica</ActionText>
			</Button>
			{AccessLevel >= AccessLevelType.SuperAdmin ? (
				<>
					<Button
						type="table"
						tooltip="Elimina"
						onClick={() => {
							setChangeEntity(entity);
							onDeleteOpen();
						}}
						selected={selected}
					>
						{Icons.Delete}
						<ActionText>Elimina</ActionText>
					</Button>
				</>
			) : null}
		</ActionButtonContainer>
	);

	const extraComponent = (
		<>
			{AccessLevel >= AccessLevelType.Admin ? (
				<Button onClick={() => navigate("create")} icon={Icons.Add}>
					Crea
				</Button>
			) : null}
		</>
	);

	return (
		<>
			<TlTable<AssociationDto>
				columns={columns}
				queryKey={["associations"]}
				fetchPage={async (filter, sort, limit, offset) =>
					await client.getAllAssociations(filter, sort, limit, offset)
				}
				persistanceKey="associations"
				defaultSorting={{ order: "-", sortBy: "name" }}
				actions={actions}
				idProperty="associationId"
				searchPlaceholder="Cerca associazione"
				extraComponent={extraComponent}
			/>

			{!!changeEntity ? (
				<DeleteAssociationModal
					isOpen={isDeleteOpen}
					onClose={onDeleteClose}
					entity={changeEntity}
				/>
			) : null}
		</>
	);
}
