import React, { HTMLProps, PropsWithChildren, useState } from "react";
import { classNames } from "../utils";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export default function Button({
	children,
	icon,
	className: classes,
	isLoading = false,
	type = "primary",
	tooltip,
	submit,
	containerClass,
	...rest
}: {
	icon?: JSX.Element;
	className?: string;
	containerClass?: string;
	isLoading?: boolean;
	submit?: boolean;
	type?: "primary" | "secondary" | "table" | "nostyle";
	tooltip?: string | JSX.Element;
} & PropsWithChildren &
	HTMLProps<HTMLButtonElement>) {
	const [hover, setHover] = useState(false);
	const common =
		"flex font-semibold focus-visible:outline flex focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 items-center";
	const tableStyle =
		"  leading-6  text-indigo-500 hover:text-indigo-800 rounded-full text-lg p-2 gap-1";
	const primaryStyle =
		"  gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500";
	const secondaryStyle =
		"  text-sm font-semibold leading-6 rounded-md text-gray-900  ";
	const style =
		type == "primary"
			? primaryStyle
			: type == "secondary"
			? secondaryStyle
			: type == "table"
			? tableStyle
			: "";
	return (
		<div className={classNames("relative ", containerClass ?? "")}>
			<button
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
				//@ts-ignore
				type={!!submit ? "submit" : "button"}
				className={classNames(
					type === "nostyle" ? "" : common,
					style,
					classes ?? "",
					rest.disabled ? "cursor-not-allowed opacity-50" : "",
					isLoading
						? "pointer-events-none animate-pulse"
						: "pointer-events-auto"
				)}
				{...rest}
			>
				{tooltip ? (
					<div
						id="tooltip-default"
						role="tooltip"
						className={classNames(
							"absolute left-0 text-white bg-gray-800 p-1 rounded-lg translate-y-[100%] text-xs z-10 pointer-events-none   transition-all ",
							hover ? " opacity-100" : "mt-2 opacity-0"
						)}
					>
						{tooltip}
						{/* <div className="tooltip-arrow" data-popper-arrow></div> */}
					</div>
				) : null}

				<>
					{!!icon ? (
						<span
							className="-ml-0.5 h-5 w-5 grid place-items-center"
							aria-hidden="true"
						>
							{icon}
						</span>
					) : null}
					{children}
					{isLoading ? (
						<div className="animate-spin">
							<AiOutlineLoading3Quarters />
						</div>
					) : null}
				</>
			</button>
		</div>
	);
}

export function Tooltip({
	className,
	children,
	tooltip,
	tooltipClass,
}: {
	className?: string;
	tooltip: string;
	tooltipClass?: string;
} & PropsWithChildren) {
	const [hover, setHover] = useState(false);

	return (
		<div
			className={classNames("relative ", className ?? "")}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<>
				{tooltip ? (
					<div
						id="tooltip-default"
						role="tooltip"
						className={classNames(
							"absolute left-0 top-0 text-white bg-gray-800 p-1 rounded-lg text-xs z-10 pointer-events-none   transition-all ",
							hover ? " opacity-100" : "mt-2 opacity-0",
							tooltipClass ?? " translate-y-[20%]"
						)}
					>
						{tooltip}
						{/* <div className="tooltip-arrow" data-popper-arrow></div> */}
					</div>
				) : null}

				<div className="pointer-events-none">{children}</div>
			</>
		</div>
	);
}

export function ActionButtonContainer({ children }: PropsWithChildren) {
	return (
		<div className="flex md:gap-1 items-center max-md:items-start flex-col md:flex-row max-md:gap-1">
			{children}
		</div>
	);
}
