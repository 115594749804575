import { useState } from "react";
import { SubsidiariesClient, SubsidiaryDto } from "../../../Api";
import { Actions, Column } from "../../../components/CsTable";
import { useClient } from "../../../components/UseClient";
import { FaPlus } from "react-icons/fa";
import GetUser, { AccessLevelType } from "../../../components/GetUser";
import { Icons } from "../../../utilities/icons";
import { DeleteSubsidiaryModal } from "./DeleteSubsidiaryModal";
import { addressColumn } from "../../../utilities/ColumnsUtils";
import SubsidiaryModal from "./SubsidiaryModal";
import Button, { ActionButtonContainer } from "../../../components/UI/Button";
import { ActionText, TlTable } from "../../../components/UI/Table";
import useDisclosure from "../../../components/Hooks/useDisclosure";
import { useNavigate } from "react-router-dom";

export const familyColumns: Column<SubsidiaryDto>[] = [
	{
		id: "name",
		name: "Nome sede",
	},

	{
		id: "capacity",
		name: "Capacità",
		Cell: ({ capacity }) => capacity?.toString() ?? "-",
	},
	{
		//@ts-expect-error
		id: "address",
		name: "Indirizzo",
		...addressColumn(),
	},
	{
		id: "description",
		name: "Descrizione",
		Cell: ({ description }) => (
			<div className="md:w-full whitespace-normal">
				{description ?? "-"}
			</div>
		),
	},
];

export default function SubsidiaryTab() {
	const client = useClient(SubsidiariesClient);

	const [changeEntity, setChangeEntity] = useState<SubsidiaryDto | undefined>(
		undefined
	);
	const { AccessLevel } = GetUser();
	const navigate = useNavigate();
	const {
		isOpen: isCreateOpen,
		onOpen: onCreateOpen,
		onClose: onCreateClose,
	} = useDisclosure();

	const {
		isOpen: isDeleteOpen,
		onOpen: onDeleteOpen,
		onClose: onDeleteClose,
	} = useDisclosure();

	const actions: Actions<SubsidiaryDto> = (subsidiary: any, index: any) => (
		<ActionButtonContainer>
			{/* <CsTableButton
				index={index}
				text={Icons.Info}
				tooltip="Info"
				onClick={() => navigate(`/subsidiaries/${family.familyId}`)}
			/> */}
			<Button
				type="table"
				tooltip="Modifica"
				onClick={() => navigate(`/subsidiaries/${subsidiary.id}`)}
			>
				{Icons.Info}
				<ActionText>Info</ActionText>
			</Button>

			<Button
				type="table"
				tooltip="Modifica"
				onClick={() => {
					setChangeEntity(subsidiary);
					onCreateOpen();
				}}
			>
				{Icons.Edit}
				<ActionText>Modifica</ActionText>
			</Button>
			<Button
				type="table"
				tooltip="Elimina"
				onClick={() => {
					setChangeEntity(subsidiary);
					onDeleteOpen();
				}}
			>
				{Icons.Delete}
				<ActionText>Elimina</ActionText>
			</Button>
		</ActionButtonContainer>
	);

	const extraComponent = (
		<>
			{" "}
			{AccessLevel >= AccessLevelType.Admin ? (
				<Button
					onClick={onCreateOpen}
					icon={<FaPlus fontSize="1rem" />}
				>
					Crea
				</Button>
			) : null}
		</>
	);

	return (
		<div>
			<TlTable<SubsidiaryDto>
				columns={familyColumns}
				queryKey={["subsidiaries"]}
				fetchPage={async (
					filter: any,
					sort: any,
					limit: any,
					offset: any
				) =>
					await client.getAllSubsidiaries(filter, sort, limit, offset)
				}
				persistanceKey="subsidiaries"
				defaultSorting={{ order: "-", sortBy: "name" }}
				actions={
					AccessLevel >= AccessLevelType.Admin ? actions : undefined
				}
				idProperty="id"
				searchPlaceholder="Cerca sede"
				extraComponent={extraComponent}
			/>

			{changeEntity ? (
				<DeleteSubsidiaryModal
					isOpen={isDeleteOpen}
					subsidiary={changeEntity!}
					onClose={onDeleteClose}
				/>
			) : null}

			<SubsidiaryModal
				isOpen={isCreateOpen}
				onClose={() => {
					setChangeEntity(undefined);
					onCreateClose();
				}}
				subsidiary={changeEntity}
			/>
		</div>
	);
}
