import { InputField } from "./InputFormikFiled";
import { Icons } from "../utilities/icons";

export function AddressFields({ property = "address" }: { property?: string }) {
	return (
		<>
			<InputField
				icon={Icons.Street}
				id={`${property}.street`}
				name={`${property}.street`}
				label="Via"
				placeholder="Inserisci Via"
				required
			/>
			<div className="flex gap-4 max-md:flex-col w-full flex-1 ">
				<span className="flex-1">
					<InputField
						icon={Icons.City}
						id={`${property}.city`}
						name={`${property}.city`}
						label="Città"
						placeholder="Inserisci Città"
						required
						className="flex-1"
					/>
				</span>
				<span className="flex-2">
					<InputField
						icon={Icons.Country}
						id={`${property}.province`}
						name={`${property}.province`}
						placeholder="TO"
						label="Provincia"
						required
						format={(s) => s.substring(0, 2).toUpperCase()}
					/>
				</span>
				<span className="flex-2">
					<InputField
						icon={Icons.PostalCode}
						id={`${property}.postalCode`}
						name={`${property}.postalCode`}
						placeholder="Inserisci CAP"
						label="CAP"
						required
						format={(s) => s.substring(0, 5).toUpperCase()}
					/>
				</span>
			</div>
			<div className="flex gap-4 max-md:flex-col w-full flex-1 ">
				<span className="flex-1">
					<InputField
						icon={Icons.Country}
						id={`${property}.region`}
						name={`${property}.region`}
						placeholder="Inserisci Regione"
						label="Regione"
						required
					/>
				</span>
				<span className="flex-1">
					<InputField
						icon={Icons.Country}
						id={`${property}.country`}
						name={`${property}.country`}
						placeholder="Inserisci Stato"
						label="Stato"
						required
					/>
				</span>
			</div>
		</>
	);
}
