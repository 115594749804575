import "./App.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import "react-widgets/styles.css";
import GetUser from "./components/GetUser";
import Layout from "./Layout";
import Chart, {
	BarElement,
	CategoryScale,
	Legend,
	LinearScale,
	Title,
} from "chart.js/auto";
import { useDarkTheme } from "./components/DarkLightButton";
import { classNames } from "./components/utils";
import toast from "react-hot-toast";
import { FaInfoCircle } from "react-icons/fa";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Legend);

export default function App() {
	const navigate = useNavigate();
	const [login, setLogin] = useState(false);
	const { user, AccessLevel } = GetUser();
	// const toast = useToast();
	const { isDark } = useDarkTheme();

	useEffect(() => {
		if (
			user == undefined ||
			(user != undefined && user?.exp < (new Date().getTime() + 1) / 1000)
		) {
			logOff();
		}
	}, [user]);

	function logIn(token: string) {
		localStorage.setItem("UserJWT", token);
		setLogin(true);
		navigate("/");
	}

	function logOff() {
		localStorage.removeItem("UserJWT");
		setLogin(false);
		navigate("/login");
		if (user != undefined && user?.exp < (new Date().getTime() + 1) / 1000)
			toast("Sessione Scaduta", {
				icon: <FaInfoCircle />,
				duration: 3000,
			});
	}

	return (
		<div className={classNames(isDark ? "text-white" : "text-gray-900")}>
			{user == undefined ? (
				<Login login={logIn} />
			) : (
				<Layout LogOff={logOff} />
			)}
		</div>
	);
}
