import { format } from "date-fns";
import {
	AccountType,
	AthleteDto,
	FileRecord,
	FileRecordDto,
	GuardianDto,
	UserDto,
} from "../Api";
import { it } from "date-fns/locale";
import { FaQuestion } from "react-icons/fa6";
import { AccessLevelType } from "./GetUser";

export const category = [
	{ id: 0, type: "PreAgonisti", name: "Bambini", age: "5-7" },
	{ id: 1, type: "PreAgonisti", name: "Fanciulli", age: "8-9" },
	{ id: 2, type: "PreAgonisti", name: "Ragazzi", age: "10-11" },
	{ id: 3, type: "Agonisti", name: "Esordienti A", age: "12" },
	{ id: 4, type: "Agonisti", name: "Esordienti B", age: "13-14" },
	{ id: 5, type: "Agonisti", name: "Cadetti", age: "15-17" },
	{ id: 6, type: "Agonisti", name: "Juniores", age: "18-20" },
	{ id: 7, type: "Agonisti", name: "Seniores", age: "21-35" },
	{ id: 8, type: "OverAgonisti", name: "Masters", age: "35+" },
];

type Props = {
	birth: Date;
};

export function getAccessLevlRole(level: AccessLevelType) {
	return level == AccessLevelType.SuperAdmin
		? "Super Admin"
		: level == AccessLevelType.Admin
		? "Amministratore"
		: level == AccessLevelType.User
		? "Utente"
		: "Visitatore";
}

export function GetAvatarInitial({
	user,
	athlete,
	guardian,
	className,
}: {
	user?: UserDto;
	athlete?: AthleteDto;
	guardian?: GuardianDto;
	className?: string;
}) {
	const loading =
		user == undefined && athlete == undefined && guardian == undefined;
	return (
		<span
			className={classNames(
				"inline-flex h-10 w-10 items-center justify-center rounded-full bg-indigo-500",
				loading ? "animate-pulse bg-gray-400" : "",
				className ?? ""
			)}
		>
			<span className="font-medium leading-none text-white">
				{!loading ? (
					!!user ? (
						user.accountType == AccountType.SingleUser &&
						!!user.athlete ? (
							user.athlete?.surname?.substring(0, 1) +
							user.athlete?.name.substring(0, 1)
						) : (
							"F" + user.family?.familyName?.substring(0, 1)
						)
					) : !!athlete ? (
						athlete.surname?.substring(0, 1) +
						athlete.name.substring(0, 1)
					) : !!guardian ? (
						guardian.surname.substring(0, 1) +
						guardian.name.substring(0, 1)
					) : (
						""
					)
				) : (
					<FaQuestion />
				)}
			</span>
		</span>
	);
}

export enum DateOnlyValues {
	MinValue = "0001-01-01",
}

export function getCategory(birth: string | Date) {
	const birthYear =
		typeof birth == "string"
			? new Date(birth).getFullYear()
			: birth.getFullYear();
	const currentYear = new Date().getFullYear();
	const year = currentYear - birthYear;

	if (year > 35) return category[8];
	else if (year >= 21) return category[7];
	else if (year >= 18) return category[6];
	else if (year >= 15) return category[5];
	else if (year >= 13) return category[4];
	else if (year == 12) return category[3];
	else if (year >= 10) return category[2];
	else if (year >= 8) return category[1];
	else if (year >= 5) return category[0];
	else return { id: -1, type: "", name: "No Category", age: "< 5" };
}

export function formatPhoneNumber(v: string) {
	var c = v
		.match(/[\d]+/g)
		?.reduce((accumulator, currentV) => accumulator + currentV, "");

	if (c != undefined)
		return `+${
			c.substring(0, 2) +
			(c.charAt(2) ? " " : "") +
			c.substring(2, 5) +
			(c.charAt(5) ? " " : "") +
			c.substring(5, 8) +
			(c.charAt(8) ? " " : "") +
			c.substring(8, 12)
		}`;
	else return v;
}

export function formatProperName(v: string) {
	return v.charAt(0).toUpperCase() + v.substring(1);
}

export function getIdentifier(user: UserDto | undefined) {
	if (user == undefined) return "-";
	var ident =
		user?.accountType == AccountType.SingleUser && !!user.athlete
			? user?.athlete?.surname + " " + user?.athlete?.name
			: !!user.family ? "Famiglia " + user?.family!.familyName : "";
	return ident ?? "-";
}

export function getFileUrl(image: FileRecord | FileRecordDto | undefined) {
	if (image == undefined) return undefined;
	var url = `data:${image.mimeType};base64,${image.content}`;
	return url;
}

export function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(" ");
}

export function canPartecipate(user: UserDto | undefined, category: string) {
	if (!user) return false;
	if (user?.accountType == AccountType.SingleUser && !!user?.athlete?.birth)
		return category == getCategory(new Date(user.athlete?.birth)).name;
	else if (user?.accountType == AccountType.Family) {
		return user.family?.athletes
			.filter((x) => !!x.birth)
			.map((x) => getCategory(new Date(x.birth)).name)
			.includes(category);
	}
}

export function formatDate(
	date: string | Date | undefined,
	seconds: boolean = false,
	type?: "time" | "date" | "dateTime"
) {
	if (date == undefined) return "-";
	const d = typeof date == "string" ? new Date(date) : date;

	switch (type) {
		case "time":
			return seconds
				? d.toLocaleTimeString()
				: d.toLocaleTimeString().slice(0, 5);
		case "date":
			return format(d, "dd/MM/yy", { locale: it });
		default:
			return (
				d.toLocaleDateString() +
				" " +
				(seconds
					? d.toLocaleTimeString()
					: d.toLocaleTimeString().slice(0, 5))
			);
	}
}

export function firstToUpperCase(s: string) {
	return s[0].toUpperCase() + s.substring(1);
}

export function StringDate(d: Date, withHour: boolean): string {
	const symbol = "/";
	const date = d.toString();
	const year = date.slice(0, 4);
	const month = date.slice(5, 7);
	const day = date.slice(8, 10);
	const time = date.slice(11, 16);
	return day + symbol + month + symbol + year + " " + (withHour ? time : "");
}

export const Feature = {
	AccountsController: 0,
	Calendar: 1,
	Subsidiary: 2,
	FamilyAccount: 3,
	Events: 4,
	Items: 5,
	Courses: 6,
};
