import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { HashRouter, BrowserRouter as Router } from "react-router-dom";
import { UiContextProvider } from "./UiContext";
import "react-day-picker/dist/style.css";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();
root.render(
	<QueryClientProvider client={queryClient}>
		<UiContextProvider>
			<HashRouter>
				<Toaster />
				<App />
			</HashRouter>
		</UiContextProvider>
	</QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
