import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { useUI } from "../UiContext";
import {
	Box,
	Button,
	ButtonProps,
	Flex,
	Spacer,
	Tooltip,
} from "@chakra-ui/react";

export default function ActionButton({
	icon,
	tooltip,
	children,
	...rest
}: {
	tooltip?: string;
	icon?: JSX.Element;
} & PropsWithChildren &
	ButtonProps) {
	const { color } = useUI();
	const navigate = useNavigate();
	return (
		<>
			{tooltip != undefined ? (
				<Tooltip label={tooltip}>
					<Box>
						<Button
							borderRadius="0.5rem"
							variant="outline"
							colorScheme={color.scheme}
							fontSize="1.5rem"
							zIndex="4"
							minW="fit-content"
							{...(rest.variant == undefined
								? {
										_hover: {
											bg: `${
												rest.colorScheme ??
												color.primary
											}`,
											color: "white",
										},
								  }
								: {})}
							{...rest}
						>
							<Flex
								flexDir="row"
								alignItems="center"
								justifyContent="center"
							>
								{icon ?? ""}
								{/* {icon && children && <Spacer w="0.5rem" />} */}
								{children}
							</Flex>
						</Button>
					</Box>
				</Tooltip>
			) : (
				<Button
					borderRadius="0.5rem"
					variant="outline"
					colorScheme={color.scheme}
					fontSize="1.5rem"
					zIndex="4"
					{...(rest.variant == undefined
						? {
								_hover: {
									bg: `${rest.colorScheme ?? color.primary}`,
									color: "white",
								},
						  }
						: {})}
					{...rest}
				>
					<Flex
						flexDir="row"
						alignItems="center"
						justifyContent="center"
						gap="2"
					>
						{icon ?? ""}
						{/* {icon && children && <Spacer w="0.5rem" />} */}
						{children}
					</Flex>
				</Button>
			)}
		</>
	);
}
