import {
	InputProps,
	SelectProps,
	Radio,
	RadioGroup,
	RadioGroupProps,
	NumberInputProps,
	TextareaProps,
	AvatarProps,
	Skeleton,
	CheckboxProps,
} from "@chakra-ui/react";
import { FormControl, FormControlProps } from "./FormControl2";
import * as React from "react";
import { useEffect, useState } from "react";
import { FiAlertTriangle, FiEye, FiEyeOff } from "react-icons/fi";
import DatePicker, { DatePickerProps } from "react-widgets/DatePicker";
import Multiselect from "react-widgets/cjs/Multiselect";
import { useApiField } from "./useApiField";
import ColorPicker from "./ColorPicker";
import { useClient } from "./UseClient";
import { FilesClient } from "../Api";
import { PictureSelect } from "./Fields/PictureField";
import { useQuery } from "@tanstack/react-query";
import { classNames, getFileUrl } from "./utils";
import {
	daysToWeeks,
	differenceInCalendarDays,
	isMatch,
	isValid,
	parse,
} from "date-fns";
import { it } from "date-fns/locale";
import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns";
import { FaMinus, FaPlus, FaQuestion } from "react-icons/fa6";
import Button from "./UI/Button";
import {
	ActiveModifiers,
	DateFormatter,
	DayPicker,
	DayPickerProps,
	Row,
	RowProps,
	SelectSingleEventHandler,
} from "react-day-picker";
import { Popover, Transition } from "@headlessui/react";
import { useFormikContext } from "formik";

export type InputFieldProps = FormControlProps &
	InputProps & {
		inputRightElement?: JSX.Element;
		inputLeftElement?: JSX.Element;
	};
export function InputField({
	id,
	name,
	label,
	hint,
	icon,
	format,
	inputRightElement,
	inputLeftElement,
	...rest
}: InputFieldProps) {
	return (
		<FormControl
			id={id}
			name={name ?? id}
			label={label}
			hint={hint}
			icon={icon}
			required={rest.required}
			disabled={rest.disabled}
		>
			{({ field, helper }) => (
				<div className="flex gap-2 ">
					<input
						id={id}
						// {...field}
						name={field.name}
						value={field.value}
						// onChange={field.onChange}
						onBlur={field.onBlur}
						required={rest.required}
						disabled={rest.disabled}
						placeholder={rest.placeholder}
						className={classNames(
							"py-1 rounded px-2 border-gray-300 text-black focus:ring-indigo-600 w-full",
							rest.className ?? ""
						)}
						// {...rest}
						onChange={(v) => {
							var value = !!v.target.value
								? format
									? format(v.target.value)
									: v.target.value
								: "";
							helper.setValue(value);
						}}
					/>{" "}
					{inputRightElement ?? null}
				</div>
				// <InputGroup>
				// 	{inputLeftElement ?? null}
				// 	<Input
				// 		id={id}
				// 		border="2px solid gray"
				// 		borderColor="gray.300"
				// 		{...field}
				// 		{...rest}
				// 		// bg="gray.100"
				// 		onChange={(v) =>
				// 			helper.setValue(
				// 				format ? format(v.target.value) : v.target.value
				// 			)
				// 		}
				// 	/>
				// 	{inputRightElement ?? null}
				// </InputGroup>
			)}
		</FormControl>
	);
}

export type CheckboxFieldProps = FormControlProps &
	CheckboxProps & { text?: string };
export function CheckboxField({
	id,
	name,
	label,
	hint,
	icon,
	text,
	className,
	...rest
}: CheckboxFieldProps) {
	return (
		<FormControl
			id={id}
			name={name}
			label={label}
			hint={hint}
			icon={icon}
			required={rest.required}
			disabled={rest.disabled}
		>
			{({ field, helper }) => (
				<div className="flex items-center gap-2">
					<input
						//@ts-ignore
						id={id}
						{...field}
						required={rest.required}
						disabled={rest.disabled}
						placeholder={rest.placeholder}
						className={classNames(
							"py-1 rounded px-2 border-gray-300  focus:ring-indigo-600 ",
							className ?? ""
						)}
						// {...rest}
						type="checkbox"
						checked={field.value}
						onChange={(v) => {
							console.log(v.target.checked);
							helper.setValue(!v.target.checked);
						}}
						onClick={(v) =>
							(v.currentTarget.checked = !v.currentTarget.checked)
						}
					/>
					{/* <Checkbox
							id={id}
							border="2px solid gray"
							borderColor="gray.300"
							checked={field.value}
							{...field}
							{...rest}
							// bg="gray.100"
							onChange={(v) => helper.setValue(v.target.checked)}
						/>
						{text} */}
					{text ?? null}
				</div>
			)}
		</FormControl>
	);
}

export function ColorField({
	id,
	label,
	name,
	hint,
	icon,
	...rest
}: FormControlProps & InputFieldProps) {
	const [show, setShow] = useState(false);
	return (
		<FormControl
			id={id}
			name={name}
			label={label}
			hint={hint}
			icon={icon}
			required={rest.required}
			disabled={rest.disabled}
		>
			{({ field, helper, meta }) => (
				<ColorPicker
					value={field.value}
					id={id}
					name={id}
					{...rest}
					setFieldValue={(c) => {
						if (!meta.touched) helper.setTouched(true);
						helper.setValue(c);
					}}
				/>
			)}
		</FormControl>
	);
}

export type TextareaFieldProps = FormControlProps & TextareaProps;
export function TextareaField({
	id,
	name,
	label,
	hint,
	icon,
	format,
	className,
	...rest
}: TextareaFieldProps) {
	return (
		<FormControl
			id={id}
			name={name}
			label={label}
			hint={hint}
			icon={icon}
			required={rest.required}
			disabled={rest.disabled}
		>
			{({ field, helper }) => (
				<textarea
					id={id}
					{...field}
					required={rest.required}
					disabled={rest.disabled}
					placeholder={rest.placeholder}
					className={classNames(
						"py-1 rounded px-2 border-gray-300 text-black focus:ring-indigo-600 w-full",
						className ?? ""
					)}
					// {...rest}
					onChange={(v) =>
						helper.setValue(
							format ? format(v.target.value) : v.target.value
						)
					}
				>
					{field.value}
				</textarea>
				// <Textarea
				// 	id={id}
				// 	border="2px solid gray"
				// 	borderColor="gray.300"
				// 	{...field}
				// 	{...rest}
				// 	// bg="gray.100"
				// 	onChange={(v) =>
				// 		helper.setValue(
				// 			format ? format(v.target.value) : v.target.value
				// 		)
				// 	}
				// />
			)}
		</FormControl>
	);
}

export function PasswordField({
	id,
	name,
	label,
	hint,
	icon,
	format,
	...rest
}: InputFieldProps) {
	const [show, setShow] = useState(false);

	return (
		<>
			<FormControl
				id={id}
				name={name}
				label={label}
				hint={hint}
				icon={icon}
				required={rest.required}
				disabled={rest.disabled}
			>
				{({ field, helper }) => (
					<div className="flex gap-2 relative">
						<input
							id={id}
							{...field}
							required={rest.required}
							disabled={rest.disabled}
							placeholder={rest.placeholder}
							type={show ? "text" : "password"}
							className={classNames(
								"py-1 rounded px-2 border-gray-300 text-black focus:ring-indigo-600 w-full",
								rest.className ?? ""
							)}
							// {...rest}
							onChange={(v) =>
								helper.setValue(
									format
										? format(v.target.value)
										: v.target.value
								)
							}
						/>
						<button
							// fontSize="md"
							// size="sm"
							// borderRadius="full"
							type="button"
							onClick={() => setShow(!show)}
							// variant="ghost"
							className="absolute right-0 h-full pr-2 text-gray-500 active:scale-95"
						>
							{show ? <FiEyeOff /> : <FiEye />}
						</button>
					</div>

					// <InputGroup>
					// 	<Input
					// 		id={id}
					// 		type={show ? "text" : "password"}
					// 		border="2px solid gray"
					// 		borderColor="gray.300"
					// 		{...field}
					// 		{...rest}
					// 		onChange={(v) =>
					// 			helper.setValue(
					// 				format
					// 					? format(v.target.value)
					// 					: v.target.value
					// 			)
					// 		}
					// 	/>
					// 	<InputRightElement fontSize="sm" mr=".2rem">
					// 		<Button
					// 			fontSize="md"
					// 			size="sm"
					// 			borderRadius="full"
					// 			onClick={() => setShow(!show)}
					// 			variant="ghost"
					// 		>
					// 			{show ? <FiEyeOff /> : <FiEye />}
					// 		</Button>
					// 	</InputRightElement>
					// </InputGroup>
				)}
			</FormControl>
		</>
	);
}

export type NumberFieldProps = FormControlProps &
	NumberInputProps & {
		commands?: boolean;
		rightElement?: JSX.Element | string;
		leftElement?: JSX.Element | string;
	};

export function NumberField({
	id,
	name,
	label,
	hint,
	icon,
	commands = false,
	format,
	rightElement,
	leftElement,
	...rest
}: NumberFieldProps) {
	const buttonsClass =
		"bg-indigo-400 text-white  w-8 h-full hover:bg-indigo-300 grid place-items-center text-xs  active:scale-95";
	return (
		<>
			<FormControl
				id={id}
				name={name}
				label={label}
				hint={hint}
				icon={icon}
				required={rest.required}
				disabled={rest.disabled}
			>
				{({ field, helper }) => (
					<div className="flex gap-1 items-center">
						{leftElement ? (
							<div className="bg-gray-300 h-full py-1 px-2 rounded-s-md">
								{leftElement}
							</div>
						) : null}
						<div className="relative w-full">
							<input
								type="number"
								id={id}
								className={classNames(
									"py-1 rounded px-2 border-gray-300 text-black focus:ring-indigo-600 w-full",
									rest.className ?? ""
								)}
								{...field}
								value={field.value}
								// onChange={(v) =>
								// 	helper.setValue(
								// 		v.currentTarget.value
								// 			? Number(v.currentTarget.value)
								// 			: undefined
								// 	)
								// }
							/>

							{commands ? (
								<div className="absolute right-0 top-0 flex  h-full">
									<button
										type="button"
										onClick={() =>
											helper.setValue(
												field.value - (rest.step ?? 1)
											)
										}
										className={classNames(
											buttonsClass,
											"rounded-s-md border-gray-200 border-r-[1px]"
										)}
									>
										<FaMinus />
									</button>
									<button
										type="button"
										onClick={() =>
											helper.setValue(
												field.value + (rest.step ?? 1)
											)
										}
										className={classNames(
											buttonsClass,
											"rounded-e-md"
										)}
									>
										<FaPlus />
									</button>
								</div>
							) : null}
						</div>
						{rightElement ? (
							<div className="bg-gray-300 h-full py-1 px-2 rounded-e-md">
								{rightElement}
							</div>
						) : null}
					</div>
				)}
			</FormControl>
		</>
	);
}

export type Transl<T> = {
	value: T;
	label: string;
};

export type MultiSelectFieldProps<T> = FormControlProps & {
	data: any;
	groupBy?: string;
	translations?: Transl<T>[];
	dataKey?: string;
	textField?: string;
	defaultValue?: T[];
	onChange?: (e: any[]) => void;
	isRequired?: boolean;
	isDisabled?: boolean;
	placeholder?: string;
};

export function MultiSelectField<T>({
	id,
	name,
	label,
	hint,
	icon,
	data,
	translations,
	groupBy,
	dataKey,
	textField,
	defaultValue,
	required: isRequired,
	disabled: isDisabled,
	placeholder,
	onChange,
	...rest
}: MultiSelectFieldProps<T>) {
	const [field, meta, helper] = useApiField({
		name: name as string,
	});
	const hasError = (meta.error && meta.touched) || meta.apiError || false;

	return (
		<>
			<FormControl
				id={id}
				name={name}
				label={label}
				hint={hint}
				icon={icon}
				required={isRequired}
				disabled={isDisabled}
				canHaveError={false}
			>
				{({ field, helper }) => (
					<Multiselect
						id={id}
						key={"multiSelect" + id}
						defaultValue={
							defaultValue ? (defaultValue as T[]) : undefined
						}
						placeholder={placeholder}
						groupBy={groupBy}
						data={data}
						dataKey={dataKey}
						textField={textField}
						onChange={(e) => {
							helper.setValue(
								e.map((x: any) => x[dataKey ?? "value"])
							);
							if (!!onChange) onChange(e);
						}}
					/>
				)}
			</FormControl>
		</>
	);
}
export type SelectFieldProps = FormControlProps &
	SelectProps & {
		options: Transl<any>[];
		translations?: Transl<any>[];
		isInt?: boolean;
	};

export function SelectField({
	id,
	name,
	label,
	hint,
	icon,
	options,
	translations,
	onChange,
	className,
	isInt,
	...rest
}: SelectFieldProps) {
	return (
		<>
			<FormControl
				id={id}
				name={name}
				label={label}
				hint={hint}
				icon={icon}
				required={rest.required}
				disabled={rest.disabled}
				canHaveError={false}
			>
				{({ field, helper }) => (
					<select
						className={classNames(
							"py-1 rounded px-2 border-gray-300 text-black focus:ring-indigo-600 w-full",
							className ?? ""
						)}
						{...field}
						placeholder={undefined}
						value={field.value ?? "placeholder"}
						onChange={(i) => {
							if (
								i.target.value == "placeholder" &&
								!rest.required
							)
								helper.setValue(undefined);
							helper.setValue(
								!!isInt
									? parseInt(i.target.value)
									: i.target.value
							);
							if (!!onChange) onChange(i);
						}}
					>
						{!!rest.placeholder ? (
							<option
								key={name + "placeholder"}
								value="placeholder"
								disabled={rest.required}
							>
								{rest.placeholder}
							</option>
						) : null}
						{options.map((opt, i) => {
							return (
								<option
									key={name + opt.label}
									value={opt.value}
								>
									{opt.label}
								</option>
							);
						})}
					</select>
					// <Select
					// 	border="2px solid gray"
					// 	borderColor="gray.300"
					// 	{...rest}
					// 	{...field}
					// 	placeholder={undefined}
					// 	value={field.value ?? "placeholder"}
					// 	onChange={(i) => {
					// 		if (
					// 			i.target.value == "placeholder" &&
					// 			!rest.isRequired
					// 		)
					// 			helper.setValue(undefined);
					// 		helper.setValue(
					// 			!!isInt
					// 				? parseInt(i.target.value)
					// 				: i.target.value
					// 		);
					// 		if (!!onChange) onChange(i);
					// 	}}
					// >
					// 	{!!rest.placeholder ? (
					// 		<option
					// 			key={name + "placeholder"}
					// 			value="placeholder"
					// 			disabled={rest.isRequired}
					// 		>
					// 			{rest.placeholder}
					// 		</option>
					// 	) : null}
					// 	{options.map((opt, i) => {
					// 		return (
					// 			<option
					// 				key={name + opt.label}
					// 				value={opt.value}
					// 			>
					// 				{opt.label}
					// 			</option>
					// 		);
					// 	})}
					// </Select>
				)}
			</FormControl>
		</>
	);
}

export type RadioFieldProps = FormControlProps &
	Omit<RadioGroupProps, "children"> & {
		options: any[];
		translations?: Transl<any>[];
	};

export function RadioField({
	id,
	name,
	label,
	hint,
	icon,
	options,
	translations,
	...rest
}: RadioFieldProps) {
	return (
		<>
			<FormControl
				id={id}
				name={name}
				label={label}
				hint={hint}
				icon={icon}
				required={rest.required}
				disabled={rest.disabled}
			>
				{({ field, helper }) => (
					<RadioGroup
						id={id}
						{...rest}
						onChange={(i) => helper.setValue(parseInt(i))}
						value={(field.value ?? rest.defaultValue)?.toString()}
					>
						{options
							.filter((x) => typeof x == "string")
							.map((type: any, i) => {
								return (
									<Radio
										key={name + type}
										value={i.toString()}
										p=".5rem"
										border="2px solid gray"
										borderColor="gray.400"
									>
										{translations
											? translations.find(
													(c) => c.value == i
											  )?.label ?? type
											: type}
									</Radio>
								);
							})}
					</RadioGroup>
				)}
			</FormControl>
		</>
	);
}
export type DateFieldProps = FormControlProps & DatePickerProps;

// export function DateField({
// 	id,
// 	name,
// 	label,
// 	hint,
// 	icon,
// 	...rest
// }: DateFieldProps) {
// 	const [open, setOpen] = useState<boolean>(false);
// 	return (
// 		<FormControl
// 			id={id}
// 			name={name}
// 			label={label}
// 			hint={hint}
// 			icon={icon}
// 			required={rest.required}
// 			disabled={rest.disabled}
// 		>
// 			{({ field, meta, helper }) => (
// 				<>
// 					<DatePicker
// 						valueEditFormat={{
// 							dateStyle: "short",
// 						}}
// 						valueDisplayFormat={{
// 							dateStyle: "long",
// 						}}
// 						valueFormat={{
// 							day: "numeric",
// 							month: "numeric",
// 							year: "numeric",
// 						}}
// 						id={id}
// 						style={
// 							{
// 								// border: "px solid gray",
// 								// borderColor: "#CBD5E0",
// 							}
// 						}
// 						open={open}
// 						{...rest}
// 						focused={open}
// 						onFocus={() => {
// 							setOpen(true);
// 							meta.initialError = "Questo campo è obbligatorio";
// 						}}
// 						onBlur={() => {
// 							setOpen(false);
// 						}}
// 						onChange={(e: Date | null | undefined, rawDate) => {
// 							var date_hour = rawDate.split(",");
// 							var datePart = date_hour[0].split("/");

// 							console.log(rawDate);
// 							// var newDateraw =
// 							// 	datePart[0].replaceAll(/[0-9]{1}/g, "d") +
// 							// 	"/" +
// 							// 	datePart[1].replaceAll(/[0-9]{1}/g, "M") +
// 							// 	"/" +
// 							// 	datePart[2].replaceAll(/[0-9]{1}/g, "y");

// 							// var date = parse(rawDate, newDateraw, new Date(), {
// 							// 	locale: it,
// 							// });
// 							// date.setHours(12);

// 							// if (date_hour.length > 1) {
// 							// 	var hours_minutes = date_hour[1].split(":");

// 							// 	date.setHours(Number(hours_minutes[0]));
// 							// 	date.setMinutes(Number(hours_minutes[1]));
// 							// 	if (hours_minutes.length >= 3) {
// 							// 		date.setSeconds(Number(hours_minutes[2]));
// 							// 	}
// 							// }
// 							// console.log(date.toISOString());
// 							// helper.setValue(date.toISOString());

// 							// meta.touched = true;
// 							// if (rest.required && e?.toJSON() == undefined)
// 							// 	meta.error = "Questo campo è obbligatorio";
// 							// else meta.error = undefined;
// 							// if (rest.onChange) rest.onChange(e, rawDate);
// 						}}
// 						onSelect={(e) => {
// 							if (!!e) helper.setValue(e);
// 						}}
// 						value={!!field.value ? new Date(field.value) : null}
// 					/>
// 				</>
// 			)}
// 		</FormControl>
// 	);
// }

export type DateFieldProps2 = FormControlProps &
	DayPickerProps & {
		placeholder?: string;
		onChange?: (day: Date | undefined) => void;
	};
function isPastDate(date: Date) {
	return differenceInCalendarDays(date, new Date()) < 0;
}
const seasonEmoji: Record<string, string> = {
	winter: "⛄️",
	spring: "🌸",
	summer: "🌻",
	autumn: "🍂",
};

const formatCaption: DateFormatter = (month, options) => {
	const season = getSeason(month);
	return (
		<>
			<span role="img" aria-label={season}>
				{seasonEmoji[season]}
			</span>{" "}
			{format(month, "LLLL", { locale: options?.locale })}
		</>
	);
};

const getSeason = (month: Date): string => {
	const monthNumber = month.getMonth();
	if (monthNumber >= 0 && monthNumber < 3) return "winter";
	if (monthNumber >= 3 && monthNumber < 6) return "spring";
	if (monthNumber >= 6 && monthNumber < 9) return "summer";
	else return "autumn";
};
export function DateField({
	id,
	name,
	label,
	hint,
	icon,
	onChange,
	canHaveError,
	className,
	...rest
}: DateFieldProps2) {
	const { values } = useFormikContext();
	const footer = (selectedDay?: Date | string) => {
		return (
			<>
				{!!selectedDay ? (
					<p>
						{format(
							typeof selectedDay == "string"
								? new Date(selectedDay)
								: selectedDay,
							"P",
							{ locale: it }
						)}
					</p>
				) : (
					<p>Seleziona una data</p>
				)}
			</>
		);
	};

	const [selected, setSelected] = useState<string>(
		(values as any)[id ?? name ?? 0]
			? format(new Date((values as any)[id ?? name ?? 0]), "dd/MM/yyyy", {
					locale: it,
			  })
			: ""
	);
	const [month, setMonth] = useState<Date>();

	return (
		<FormControl
			className="z-100"
			id={id}
			name={name}
			label={label}
			hint={hint}
			icon={icon}
			required={rest.required}
			disabled={rest.disabled}
		>
			{({ field, meta, helper }) => (
				<>
					<Popover className="relative z-[100]">
						{({ open, close }) => (
							<>
								{/* <input
										type="text"
										onFocus={(e) => {
											var trigger =
												document.getElementById(
													"dateTrigger" + id
												);
											if (trigger) trigger.click();
											e.currentTarget.focus();
										}}
										className={classNames(
											"py-1 rounded px-2 border-gray-300 text-black focus:ring-indigo-600 w-full",
											className ?? ""
										)}
									/> */}
								<Popover.Button
									// onBlur={() => close()}
									className={classNames(
										"py-1 rounded px-2 border-gray-300 text-black focus:ring-indigo-600 w-full",
										className ?? ""
									)}
									onClick={() => {}}
									onFocus={() => {}}
									placeholder={
										rest.placeholder ?? "Inserisci data"
									}
									onBlur={() => {
										if (!!field.value) {
											var date = new Date(field.value);
											if (
												!!rest.fromDate &&
												date < rest.fromDate
											) {
												var day = rest.fromDate;
												day.setHours(12);
												helper.setValue(day);
												setSelected(
													format(
														day,
														"dd/MM/yyyy"
														// {
														// 	locale: it,
														// }
													)
												);
												setMonth(day);
												if (!!onChange) onChange(day);
											} else if (
												!!rest.toDate &&
												date > rest.toDate
											) {
												var day = rest.toDate;
												day.setHours(12);
												helper.setValue(day);
												setSelected(
													format(
														day,
														"dd/MM/yyyy"
														// {
														// 	locale: it,
														// }
													)
												);
												setMonth(day);
												if (!!onChange) onChange(day);
											}
										}
									}}
									onChange={(e) => {
										setSelected(e.currentTarget.value);

										if (!e.currentTarget.value)
											helper.setValue(undefined);
										else {
											var date_hour =
												e.target.value.split(",");
											var datePart =
												date_hour[0].split("/");
											var newDateraw =
												datePart[0].replaceAll(
													/[0-9]{1}/g,
													"d"
												) +
												"/" +
												datePart[1].replaceAll(
													/[0-9]{1}/g,
													"M"
												) +
												"/" +
												datePart[2].replaceAll(
													/[0-9]{1}/g,
													"y"
												);

											var date = parse(
												e.target.value,
												newDateraw,
												new Date(),
												{
													locale: it,
												}
											);

											date.setHours(12);

											helper.setValue(date);

											setMonth(date);

											if (!!onChange) onChange(date);
										}

										// 	if (date_hour.length > 1) {
										// 		var hours_minutes =
										// 			date_hour[1].split(":");

										// 		date.setHours(
										// 			Number(hours_minutes[0])
										// 		);
										// 		date.setMinutes(
										// 			Number(hours_minutes[1])
										// 		);
										// 		if (hours_minutes.length >= 3) {
										// 			date.setSeconds(
										// 				Number(hours_minutes[2])
										// 			);
										// 		}
										// 	}

										// 	helper.setValue(date.toISOString());
										// }

										// meta.touched = true;
										// if (
										// 	rest.required &&
										// 	e.target.value?.toJSON() == undefined
										// )
										// 	meta.error =
										// 		"Questo campo è obbligatorio";
										// else meta.error = undefined;
										// if (rest.onChange)
										// 	rest.onChange(e, e.target.value);
									}}
									value={selected}
									// id={"dateTrigger" + id}
									as="input"
								/>
								{/* g
									</Popover.Button> */}

								<Transition
									enter="transition duration-100 ease-out"
									enterFrom="transform scale-95 opacity-0"
									enterTo="transform scale-100 opacity-100"
									leave="transition duration-75 ease-out"
									leaveFrom="transform scale-100 opacity-100"
									leaveTo="transform scale-95 opacity-0"
								>
									<Popover.Panel
										static={true}
										className="absolute bg-white top-0 z-[100] p-2 rounded-md shadow-lg"
									>
										<DayPicker
											className="z-[inherit]"
											mode="single"
											id={"dayPicker" + id}
											selected={field.value}
											onSelect={(day) => {
												if (day) {
													day.setHours(12);
													setSelected(
														format(day, "P", {
															locale: it,
														})
													);

													helper.setValue(day);
													if (!!onChange)
														onChange(day);
												}
											}}
											captionLayout="dropdown"
											locale={it}
											fromDate={rest.fromDate}
											toDate={rest.toDate}
											onMonthChange={setMonth}
											month={month}
											pagedNavigation
											formatters={{ formatCaption }}
											footer={footer(field.value)}
										/>
									</Popover.Panel>
								</Transition>
							</>
						)}
					</Popover>
				</>
			)}
		</FormControl>
	);
}

export type PictureType = "user" | "contest";

export function PictureField({
	setFile,
	className,
	id,
	name,
	label,
	hint,
	icon,
	type = "user",
	...props
}: FormControlProps & {
	className?: string;
	type?: PictureType;
	setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
}) {
	const [changed, setChanged] = useState(false);

	const [field, meta, helpers] = useApiField({
		name: name as string,
	});

	const filesClient = useClient(FilesClient);

	const [src, setSrc] = useState<string | undefined>(undefined);

	const reader = new FileReader();

	const { data, isLoading: isSeraching } = useQuery({
		queryKey: ["files", field.value],
		queryFn: () => filesClient.getFile(field.value).then((r) => r.result),
		enabled: !!field.value,
	});

	var loaded = !isSeraching || !field.value;
	// if (isSeraching)
	// 	return <Skeleton w="20rem" style={{ aspectRatio: "2/1" }} />;
	return (
		<FormControl
			id={id}
			name={name}
			label={label}
			hint={hint}
			icon={icon}
			required={props.required}
			disabled={props.disabled}
		>
			{({ field, helper, meta }) => (
				<span
					className={classNames(
						"",
						!loaded ? "animate-pulse bg-gray-400" : "",
						className ?? ""
					)}
				>
					{loaded ? (
						<PictureSelect
							className={className}
							// type="image"
							label={label}
							type={type}
							isLoading={!loaded}
							defaultValue={
								isSeraching ? undefined : getFileUrl(data)
							}
							id={id}
							name={name}
							onChange={(files) => {
								if (!files || files.length == 0) {
									helper.setValue(undefined);
									setFile(undefined);
								} else {
									setFile(files[0]);
								}
							}}
							onRemove={() => helper.setValue(undefined)}
						/>
					) : (
						<FaQuestion />
					)}
				</span>
			)}
		</FormControl>
	);
}
