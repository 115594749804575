import { ManageFamilyDto, ManageGuardianDto, ManageUserDto } from "../../Api";


// icons-----------------
import { Form, Formik } from "formik";

import {
	InputField as InputFormikField,
	DateField,
} from "../../components/InputFormikFiled";
import { formatPhoneNumber, formatProperName } from "../../components/utils";
import { Icons } from "../../utilities/icons";
import * as Yup from "yup";
import { FormProps, StepFormProps } from "../../utilities/typeUtils";

import FormLayout, { FormSection } from "../../components/UI/FormLayout";
import Button from "../../components/UI/Button";

export default function FamilyForm({
	errors,
	touched,
	values,
	schema,
	next,
	prev,
}: StepFormProps<ManageUserDto, ManageFamilyDto>) {
	const minH = "6.2rem";
	const numOfColumn = 12;
	return (
		<>
			{/* <Stack pb={4} px={100}> */}

			{/* nome---------------------------------------- */}

			<InputFormikField
				icon={Icons.Name}
				id="family.familyName"
				name="family.familyName"
				label="Nome Famiglia"
				placeholder="Inserisci Nome Famiglia"
				format={formatProperName}
				required
			/>
		</>
	);
}

export function GuardianForm({
	initialValues,

	isLoading,
	onSubmit,
	action,
}: FormProps<ManageGuardianDto>) {
	const schema = Yup.object({
		surname: Yup.string().required("Questo campo è obbligatorio"),
		birth: Yup.string(),
		role: Yup.string().required(),
		guardianEmail: Yup.string().email("Email non valida"),
		phoneNumber: Yup.string()
			.matches(
				/^[+][0-9]{2} [0-9]{3} [0-9]{3} [0-9]{4}$/,
				"Formato non valido, esempio: '+39 234 345 2345'"
			)
			.required("Questo campo è obbligatorio"),
	});

	return (
		<Formik<ManageGuardianDto>
			validationSchema={schema}
			initialValues={{ ...initialValues }}
			onSubmit={onSubmit}
		>
			{({ values, errors, touched, isSubmitting }) => (
				<Form>
					<FormLayout
						actions={
							<>
								<Button submit isLoading={isLoading}>
									{action == "create" ? "Crea" : "Modifica"}
								</Button>
							</>
						}
					>
						<FormSection title="Informazioni Generali">
							<div className="flex flex-col gap-4">
								<InputFormikField
									icon={Icons.Name}
									id="name"
									name="name"
									label="Nome Guardiano"
									placeholder="Inserisci Nome"
									format={formatProperName}
									required
								/>
								<InputFormikField
									icon={Icons.Surname}
									id="surname"
									name="surname"
									label="Cognome Guardiano"
									placeholder="Inserisci Cognome"
									format={formatProperName}
									required
								/>
								<InputFormikField
									icon={Icons.AccountType}
									id="role"
									name="role"
									label="Ruolo"
									placeholder="Inserisci Ruolo (es. Madre/Padre/Tutore)"
									format={formatProperName}
									required
								/>
								<DateField
									id="birth"
									name="birth"
									label="Data di nascita"
									icon={Icons.Birth}
									placeholder="Inserisci Data"
									required
								/>
								<InputFormikField
									icon={Icons.PhoneNumber}
									id="phoneNumber"
									name="phoneNumber"
									label="Numero di telefono"
									placeholder="+39 111 222 3333"
									format={formatPhoneNumber}
									required
								/>
								<InputFormikField
									icon={Icons.Email}
									id="guardianEmail"
									name="guardianEmail"
									label="Email"
									placeholder="Inserisci Email"
								/>
							</div>
						</FormSection>
					</FormLayout>
				</Form>
			)}
		</Formik>
	);
}
