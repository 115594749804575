import { format } from "date-fns";
import { AddressDto, Belt, Gender } from "../Api";
import { Column } from "../components/CsTable";
import {
	AccountTypeTrans,
	BeltTrans,
	GenderTrans,
	RoleTypeTrans,
	tran,
} from "./Translations";
import { getCategory } from "../components/utils";

import { Icons } from "./icons";

export function genderColumn(symbol?: boolean): Partial<Column<any>> {
	return {
		Cell: ({ gender }) =>
			!!symbol
				? gender == Gender.Male
					? Icons.Male
					: Icons.Female
				: tran(GenderTrans, gender),
	};
}

export function birthColumn(): Partial<Column<any>> {
	return {
		Cell: ({ birth }) => format(new Date(birth), "dd/MM/yyyy"),
	};
}
export function addressColumn(): Partial<Column<any>> {
	return {
		Cell: ({ address }) => (
			<div className="md:w-full">
				<div>{address.street}</div>
				<div>{`${address.postalCode} ${address.city} (${address.province}-${address.region})`}</div>
			</div>
		),
	};
}

export function addressToString(address: AddressDto) {
	return `${address.street} - ${address.postalCode} ${address.city} (${address.province}-${address.region})`;
}

export function beltPoint(belt: Belt) {
	const beltpoint = [10, 15, 20, 25, 30, 40, 50];
	return beltpoint[belt as number];
}

export function roleTypeColumn(): Partial<Column<any>> {
	return {
		Cell: ({ userRoleType }) => tran(RoleTypeTrans, userRoleType),
	};
}
export function accountTypeColumn(): Partial<Column<any>> {
	return {
		Cell: ({ accountType }) => tran(AccountTypeTrans, accountType),
	};
}
export function beltColumn(): Partial<Column<any>> {
	return {
		Cell: ({ belt }) => tran(BeltTrans, belt),
	};
}

export function categoryColumn(): Partial<Column<any>> {
	return {
		Cell: ({ birth }) => getCategory(birth).name,
	};
}
