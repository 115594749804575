import { useState, useEffect } from "react";
import GetUser from "./components/GetUser";
import AppRoutes from "./components/AppRoutes";
import { ErrorBoundary } from "./ErrorBoundary";
import { DateLocalizer } from "react-widgets/IntlLocalizer";
import Localization from "react-widgets/Localization";
import SideMenuLayout from "./SideMenuLayout";
import { classNames } from "./components/utils";
import { darkClasses } from "./components/DarkLightButton";

export default function Layout({ LogOff }: { LogOff: () => void }) {
	const [id, setId] = useState("");
	const [logged, setLogged] = useState<boolean>();
	const { user, AccessLevel, currentUser } = GetUser();

	useEffect(() => {
		if (localStorage.getItem("ContestId") != ("" || null || undefined))
			setId(localStorage.getItem("ContestId")!);
		else localStorage.removeItem("ContestId");
	}, [localStorage.getItem("ContestId")]);

	return (
		<>
			<div
				className={classNames(
					"flex flex-col min-h-screen",
					darkClasses.bg,
					darkClasses.text
				)}
			>
				<SideMenuLayout logOff={LogOff}>
					<ErrorBoundary>
						<Localization
							date={
								new DateLocalizer({
									culture: "it",
									firstOfWeek: 1,
								})
							}
							messages={{
								moveToday: "Oggi",
								moveBack: "Indietro",
								moveForward: "Avanti",
								emptyList:
									"Non ci sono elementi in questa lista",
								emptyFilter: "Il filtro non ha trovato niente",
							}}
						>
							<AppRoutes
								AccessLevel={AccessLevel}
								association={currentUser?.association}
							/>
						</Localization>
					</ErrorBoundary>
				</SideMenuLayout>

			</div>
		</>
	);
}
