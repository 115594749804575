import {
	CalendarClient,
	CalendarDto,
	ManageCalendarMarkDto,
	CalendarMarkDto,
} from "../../../Api";
import { useClient } from "../../../components/UseClient";
import CalendarMarkForm from "./CalendarMarkForm";
import CsModal from "../../../components/CsModal";
import useToastMutation from "../../../components/Hooks/useToastMutation";

type Props = {
	isOpen: boolean;
	onClose: () => void;
	calendar?: CalendarDto;
	mark?: CalendarMarkDto;
};

export default function MarkModal({ isOpen, onClose, mark, calendar }: Props) {
	const client = useClient(CalendarClient);

	const { mutate: createMark, isLoading: isMarking } = useToastMutation(
		(values: ManageCalendarMarkDto) => client.createMark(values),
		onClose,
		["calendar"],
		"Aggiunto postit"
	);
	const { mutate: updateMark, isLoading: isUpdating } = useToastMutation(
		(values: ManageCalendarMarkDto) => client.updateMark(mark?.id!, values),
		onClose,
		["calendar"],
		"Postit modificato"
	);
	const { mutate: deleteMark, isLoading: isDeleting } = useToastMutation(
		() => client.deleteMark(mark?.id!),
		onClose,
		["calendar"],
		"Postit Eliminato"
	);

	return (
		<CsModal
			title={!!mark ? "Modifica Post" : "Crea Post"}
			isOpen={isOpen}
			onClose={onClose}
		>
			<CalendarMarkForm
				action={mark ? "update" : "create"}
				onSubmit={(values) => {
					if (mark) updateMark(values);
					else createMark(values);
					onClose();
				}}
				deleteMutation={deleteMark}
				isDeleting={isDeleting}
				isLoading={isMarking || isUpdating}
				initialValues={{
					calendarId: calendar?.id!,
					title: mark ? mark.title : "",
					color: mark ? mark.color : "#E53E3E",
					day: mark ? mark.day : 0,
					hour: mark ? mark.hour : "7:00",
					duration: mark ? mark.duration : 1,
					courseId: mark ? mark.course?.id : undefined,
					subsidiaryId: mark ? mark.subsidiary?.id : undefined,
				}}
			/>
		</CsModal>
	);
}
