import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
	UserDto,
	UsersClient,
	FamiliesClient,
	AthleteDto,
	GuardianDto,
} from "../../Api";
import { useClient } from "../../components/UseClient";
import GetUser, { AccessLevelType } from "../../components/GetUser";
import { useNavigate, useParams } from "react-router-dom";
import { GetAvatarInitial, getFileUrl } from "../../components/utils";

import { BeltTrans, GenderTrans, tran } from "../../utilities/Translations";
import { Icons } from "../../utilities/icons";

import { CsTabs2, TabProp } from "../../components/CsTabs";
import ProfileRequestTab from "../Profile/components/ProfileRequestTab";
import QuotesTab from "../Profile/components/QuotesTab";
import { ShowType } from "../Athletes/SingleAthletePage";

import ItemRequestsTab from "../Items/components/ItemRequestsTab";

import { PageSkeleton } from "../contests/SingleContestPage";
import Button, { ActionButtonContainer } from "../../components/UI/Button";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { DeleteAthleteModal } from "../Athletes/components/DeleteAthleteModal";
import useDisclosure from "../../components/Hooks/useDisclosure";
import { DeleteGuardianModal } from "./components/DeleteGuardianModal";

export function SingleFamilyPage({
	familyId,
	extraTabs,
	extraOptions,
	userAccount,
}: {
	familyId?: string;
	extraTabs?: TabProp[];
	extraOptions?: JSX.Element;
	userAccount?: UserDto;
}) {
	const { id } = useParams();
	const { AccessLevel, currentUser } = GetUser();
	const client = useClient(FamiliesClient);
	const userClient = useClient(UsersClient);
	const [type, setType] = useState<ShowType>(ShowType.Family);
	const { data: family, isLoading: isLoadingFamily } = useQuery({
		queryKey: ["families", familyId ?? id],
		queryFn: () => client.getFamily(familyId ?? id!).then((r) => r.result),
		enabled: !!familyId || !!id,
	});
	const navigate = useNavigate();

	const { data: user, isLoading: isLoadingUser } = useQuery({
		queryKey: ["users", family?.userId!],
		queryFn: () =>
			userClient.getUser(family?.userId!).then((r) => r.result),
		enabled: !isLoadingFamily,
	});

	const tabs: TabProp[] = [
		{
			name: "Richieste",
			tab: <ProfileRequestTab family={family} />,
		},
		...(AccessLevel >= AccessLevelType.Admin ||
		currentUser?.id == family?.userId
			? [
					{
						name: "Quote",
						tab: <QuotesTab familyId={familyId ?? id} />,
					},
			  ]
			: []),
		...(AccessLevel >= AccessLevelType.Admin && userAccount != undefined
			? [
					{
						name: "Richeste Articoli",
						tab: <ItemRequestsTab user={user} />,
					},
			  ]
			: []),
	];

	const titleBox = (
		<div className="lg:flex max-lg:flex-col lg:justify-start   items-center gap-x-6  ">
			<GetAvatarInitial user={user} className="w-24 h-24" />

			<h1>
				{/* <div className="text-sm leading-6 text-gray-500">
					{tran(RoleTypeTrans, athlete?.userRoleType)}
				</div> */}
				<div className="mt-1 leading-6 text-gray-900 font-bold text-lg">
					Famiglia {family?.familyName}
				</div>

				<div className="text-sm leading-6 text-gray-500 flex flex-col md:items-start max-md:items-start  pt-1  ">
					<span>Guardiani - {family?.guardians.length ?? 0}</span>
					<span>Atleti - {family?.guardians.length ?? 0}</span>
				</div>
			</h1>
		</div>
	);

	const actions = (
		<>
			{AccessLevel >= AccessLevelType.Admin ||
			family?.userId == currentUser?.id ? (
				<ActionButtonContainer>
					<Button
						onClick={() =>
							navigate(
								"/families/" +
									family?.familyId +
									"/createGuardians"
							)
						}
						icon={Icons.Add}
					>
						Aggiungi Guardiano
					</Button>
					<Button
						onClick={() =>
							navigate(
								`/families/${family?.familyId}/createAthletes`
							)
						}
						icon={Icons.Add}
					>
						Aggiungi atleta
					</Button>
				</ActionButtonContainer>
			) : null}
		</>
	);

	const main = [
		{
			title: "Guardiani",
			data:
				family?.guardians && family?.guardians.length > 0 ? (
					<GuardianCard
						list={family?.guardians}
						familyId={family.familyId}
					/>
				) : (
					"Non sono presenti guardiani in questo account"
				),
		},
		{
			title: "Atleti",
			data:
				family?.athletes && family?.athletes.length > 0 ? (
					<AthletesCard
						list={family?.athletes}
						familyId={family.familyId}
					/>
				) : (
					"Non sono presenti atleti in questo account"
				),
			// accessLevel: AccessLevelType.Admin,
		},
	];

	return (
		<>
			<PageSkeleton
				actions={actions}
				titleBox={titleBox}
				// info={info}
				// menuItmes={menuItem}
				main={main}
			/>
			{/* {AccessLevel >= AccessLevelType.Admin ||
			!!athleteId ||
			(currentUser?.accountType == AccountType.SingleUser &&
				id == currentUser?.athlete?.athleteId) ? ( */}
			<CsTabs2 tabs={tabs} />
		</>
	);
}

export function GuardianCard({
	list,
	familyId,
}: {
	list: GuardianDto[];
	familyId: string;
}) {
	const { AccessLevel, currentUser } = GetUser();
	const [changeGuardian, setChangeGuardian] = useState<
		GuardianDto | undefined
	>();
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<>
			<ul
				role="list"
				className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
			>
				{list.map((guardian, j) => (
					<li
						key={"guardians" + j}
						className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
					>
						<div className="flex w-full items-center justify-between space-x-6 p-6">
							<div className="flex-1 truncate">
								<div className="flex items-center space-x-3 max-md:flex-col max-mx:gap-2 max-md:items-start">
									<h3 className="truncate text-sm font-medium text-gray-900">
										{guardian.surname + " " + guardian.name}
									</h3>
									<span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
										{guardian.role}
									</span>
								</div>
								<p className="mt-1 truncate text-sm text-gray-500">
									{guardian.guardianEmail}
								</p>
								<p className="mt-1 truncate text-sm text-gray-500">
									{guardian.phoneNumber}
								</p>
								<p className="mt-1 truncate text-sm text-gray-500">
									Nato il{" "}
									{format(new Date(guardian.birth), "P", {
										locale: it,
									})}
								</p>
							</div>
							<GetAvatarInitial
								guardian={guardian}
								className="w-16 h-16"
							/>
						</div>
						<div>
							<div className="-mt-px flex divide-x divide-gray-200">
								<div className="flex w-0 flex-1">
									<a
										href={`mailto:${guardian.guardianEmail}`}
										className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
									>
										<EnvelopeIcon
											className="h-5 w-5 text-gray-400"
											aria-hidden="true"
										/>
										Email
									</a>
								</div>
								<div className="-ml-px flex w-0 flex-1">
									<a
										href={`tel:${guardian.phoneNumber}`}
										className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
									>
										<PhoneIcon
											className="h-5 w-5 text-gray-400"
											aria-hidden="true"
										/>
										Chiama
									</a>
								</div>
								{AccessLevel >= AccessLevelType.Admin ||
								currentUser?.family?.familyId === familyId ? (
									<div className="-ml-px flex w-0 flex-1">
										<button
											onClick={() => {
												setChangeGuardian(guardian);
												onOpen();
											}}
											className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
										>
											{Icons.Delete}
											Elimina
										</button>
									</div>
								) : null}
							</div>
						</div>
					</li>
				))}
			</ul>
			{changeGuardian ? (
				<DeleteGuardianModal
					isOpen={isOpen}
					entity={changeGuardian!}
					onClose={() => {
						onClose();
						setChangeGuardian(undefined);
					}}
				/>
			) : null}
		</>
	);
}
function AthletesCard({
	list,
	familyId,
}: {
	list: AthleteDto[];
	familyId: string;
}) {
	const { AccessLevel, currentUser } = GetUser();
	const [changeAthlete, setChangeAthlete] = useState<
		AthleteDto | undefined
	>();
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<>
			<ul
				role="list"
				className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
			>
				{list.map((athlete, j) => (
					<li
						key={"guardians" + j}
						className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
					>
						<div className="flex w-full items-center justify-between space-x-6 p-6">
							<div className="flex-1 truncate">
								<div className="flex items-center space-x-3 max-md:flex-col max-mx:gap-2 max-md:items-start">
									<h3 className="truncate text-sm font-medium text-gray-900">
										{athlete.surname + " " + athlete.name}
									</h3>
									<span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
										{tran(BeltTrans, athlete.belt)}
									</span>
								</div>
								<p className="mt-1 truncate text-sm text-gray-500">
									{tran(GenderTrans, athlete.gender)}
								</p>
								{/* <p className="mt-1 truncate text-sm text-gray-500">
								{athlete.}
							</p> */}
								<p className="mt-1 truncate text-sm text-gray-500">
									Nato il{" "}
									{format(new Date(athlete.birth), "P", {
										locale: it,
									})}
								</p>
							</div>
							{!!athlete.profilePicture ? (
								<img
									className="h-16 w-16 rounded-full bg-gray-50 border-2 border-gray-700"
									src={getFileUrl(athlete.profilePicture)}
									alt=""
								/>
							) : (
								<GetAvatarInitial
									athlete={athlete}
									className="w-16 h-16"
								/>
							)}
							{/* <img
							className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
							src={person.imageUrl}
							alt=""
						/> */}
						</div>
						<div>
							<div className="-mt-px flex divide-x divide-gray-200">
								<div className="-ml-px flex w-0 flex-1">
									<Link
										to={`/athletes/${athlete.athleteId}`}
										className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
									>
										{Icons.Info}
										Info
									</Link>
								</div>

								{AccessLevel >= AccessLevelType.Admin ||
								currentUser?.family?.familyId === familyId ? (
									<>
										<div className="-ml-px flex w-0 flex-1">
											<Link
												to={`/athletes/${athlete.athleteId}/edit`}
												className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
											>
												{Icons.Edit}
												Modifica
											</Link>
										</div>
										<div className="-ml-px flex w-0 flex-1 ">
											<button
												onClick={() => {
													setChangeAthlete(athlete);
													onOpen();
												}}
												className="relative inline-flex flex-1  w-full items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
											>
												{Icons.Delete}
												Elimina
											</button>
										</div>
									</>
								) : null}
							</div>
						</div>
					</li>
				))}
			</ul>
			{changeAthlete ? (
				<DeleteAthleteModal
					isOpen={isOpen}
					entity={changeAthlete!}
					onClose={() => {
						onClose();
						setChangeAthlete(undefined);
					}}
				/>
			) : null}
		</>
	);
}
