import { useState } from "react";
import { Actions, Column } from "../../../components/CsTable";
import { useClient } from "../../../components/UseClient";
import { useNavigate } from "react-router-dom";
import { FamiliesClient, FamilyDto } from "../../../Api";
import GetUser, { AccessLevelType } from "../../../components/GetUser";
import { Icons } from "../../../utilities/icons";
import { DeleteFamilyModal } from "./DeleteFamilyModal";
import Button, { ActionButtonContainer } from "../../../components/UI/Button";
import { TlTable } from "../../../components/UI/Table";
import useDisclosure from "../../../components/Hooks/useDisclosure";

export const familyColumns: Column<FamilyDto>[] = [
	{
		id: "familyName",
		name: "Nome della Famiglia",
	},
	{
		name: "Numero Guardiani",
		Cell: (family) => family.guardians.length.toString(),
	},
	{
		name: "Numero Atleti",
		Cell: (family) => family.athletes.length.toString(),
	},
];

export default function FamilyTab() {
	const client = useClient(FamiliesClient);
	const navigate = useNavigate();
	const [changeEntity, setChangeEntity] = useState<FamilyDto | undefined>(
		undefined
	);
	const { AccessLevel } = GetUser();

	const {
		isOpen: isDeleteOpen,
		onOpen: onDeleteOpen,
		onClose: onDeleteClose,
	} = useDisclosure();

	const actions: Actions<FamilyDto> = (family: any, index: any) => (
		<ActionButtonContainer>
			<Button
				type="table"
				tooltip="Visualizza"
				onClick={() => navigate(`/families/${family.familyId}`)}
			>
				{Icons.Info}
				<span className="text-xs md:hidden font-semibold">
					Visualizza
				</span>
			</Button>
			{AccessLevel >= AccessLevelType.Admin ? (
				<>
					<Button
						type="table"
						tooltip="Modifica"
						onClick={() =>
							navigate(`/accounts/${family.userId}/edit`)
						}
					>
						{Icons.Edit}
						<span className="text-xs md:hidden font-semibold">
							Modifica
						</span>
					</Button>
					<Button
						type="table"
						tooltip="Elimina"
						onClick={() => {
							setChangeEntity(family);
							onDeleteOpen();
						}}
					>
						{Icons.Delete}
						<span className="text-xs md:hidden font-semibold">
							Elimina
						</span>
					</Button>
				</>
			) : null}
		</ActionButtonContainer>
	);

	// const extraComponent = (
	// 	<>
	// 		{AccessLevel >= AccessLevelType.Admin ? (
	// 			<Button
	// 				onClick={() => navigate(`create`)}
	// 				icon={<FaPlus fontSize="1rem" />}
	// 			>
	// 				Crea
	// 			</Button>
	// 		) : null}
	// 	</>
	// );

	return (
		<div>
			{AccessLevel >= AccessLevelType.Admin ? (
				<span className="font-bold text-gray-400 text-sm">
					Per creare delle famiglie andare nella sezione accounts
				</span>
			) : null}
			<TlTable<FamilyDto>
				columns={familyColumns}
				queryKey={["families"]}
				fetchPage={async (
					filter: any,
					sort: any,
					limit: any,
					offset: any
				) => await client.getAllFamilies(filter, sort, limit, offset)}
				persistanceKey="families"
				defaultSorting={{ order: "-", sortBy: "familyName" }}
				actions={actions}
				idProperty="familyId"
				searchPlaceholder="Cerca famiglia"
				// extraComponent={extraComponent}
			/>

			{changeEntity ? (
				<DeleteFamilyModal
					isOpen={isDeleteOpen}
					family={changeEntity!}
					onClose={onDeleteClose}
				/>
			) : null}
		</div>
	);
}
