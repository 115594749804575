import { AthletesClient, QuoteDto, FamiliesClient } from "../../../Api";
import { useClient } from "../../../components/UseClient";
import { useState } from "react";
import { Actions, Column, CsTable } from "../../../components/CsTable";
import { CsTableButton } from "../../../components/CsElements";
import GetUser, { AccessLevelType } from "../../../components/GetUser";
import { format } from "date-fns";
import { Icons } from "../../../utilities/icons";
import ActionButton from "../../../components/ActionButton";
import { DeleteQuoteModal } from "./DeleteQuoteModal";
import { QuoteModal } from "./QuoteModal";
import { useQuery } from "@tanstack/react-query";
import { AthletesSelect } from "../../../components/Fields/AthletesField";
import { ActionText, TlTable } from "../../../components/UI/Table";
import Button, { ActionButtonContainer } from "../../../components/UI/Button";
import useDisclosure from "../../../components/Hooks/useDisclosure";

type Props = {
	athleteId?: string;
	familyId?: string;
};

export default function QuotesTab({ athleteId, familyId }: Props) {
	const client = useClient(AthletesClient);
	const { AccessLevel } = GetUser();
	const familyClient = useClient(FamiliesClient);

	const { data: family, isLoading } = useQuery({
		queryKey: ["families", familyId!],
		queryFn: async () =>
			await familyClient.getFamily(familyId!).then((r) => r.result),
		enabled: !!familyId,
	});

	const [id, setId] = useState(
		athleteId ?? family?.athletes[0]?.athleteId ?? ""
	);

	const [changeEntity, setchangeEntity] = useState<QuoteDto | undefined>(
		undefined
	);

	const {
		isOpen: isCreateUserOpen,
		onOpen: onCreateUserOpen,
		onClose: onCreateUserClose,
	} = useDisclosure();

	const {
		isOpen: isDeleteOpen,
		onOpen: onDeleteOpen,
		onClose: onDeleteClose,
	} = useDisclosure();

	const columns: Column<QuoteDto>[] = [
		{
			id: "importDate",
			name: "Data",
			Cell: ({ importDate }) =>
				format(new Date(importDate), "dd/MM/yyyy"),
		},
		{
			id: "deadline",
			name: "Scadenza",
			Cell: ({ deadline }) => format(new Date(deadline), "dd/MM/yyyy"),
		},
		{
			id: "import",
			name: "Importo",
			Cell: (quote) => <>{quote.import} €</>,
		},
	];

	const actions: Actions<QuoteDto> = (quote, index, selected) => (
		<ActionButtonContainer>
			{AccessLevel >= AccessLevelType.Admin ? (
				<>
					<Button
						type="table"
						tooltip="Modifica"
						selected={selected}
						onClick={() => {
							setchangeEntity(quote);
							onCreateUserOpen();
						}}
					>
						{Icons.Edit}
						<ActionText>Modifica</ActionText>
					</Button>
					<Button
						type="table"
						tooltip="Elimina"
						selected={selected}
						onClick={() => {
							setchangeEntity(quote);
							onDeleteOpen();
						}}
					>
						{Icons.Delete}
						<ActionText>Elimina</ActionText>
					</Button>
				</>
			) : null}
		</ActionButtonContainer>
	);

	const extraComponent = (
		<ActionButtonContainer>
			{AccessLevel >= AccessLevelType.Admin ? (
				<Button onClick={onCreateUserOpen} icon={Icons.Add}>
					Aggiungi
				</Button>
			) : null}
			{!!familyId &&
			!!family?.athletes &&
			family?.athletes?.length > 0 ? (
				<AthletesSelect
					athlete={id}
					onSetAthlete={(x) => setId(x?.athleteId!)}
					customAthletes={family?.athletes!}
				/>
			) : null}
		</ActionButtonContainer>
	);

	return (
		<div className="relative">
			{family?.athletes.length == 0 ? (
				<div className="font-semibold text-gray-500">
					Non sono presenti atleti in questo account
					<br /> aggiungere degli atleti prima di continuare
				</div>
			) : (
				<TlTable<QuoteDto>
					tableName="Quote"
					columns={columns}
					queryKey={["quotes", id]}
					fetchPage={async (filter, sort, limit, offset) =>
						await client.getAllQuotes(
							id,
							filter,
							sort,
							limit,
							offset
						)
					}
					exportPage={async (fields, filter, sort, limit, offset) =>
						await client.exportAllQuotes(
							fields as string[],
							id,
							undefined,
							sort,
							undefined,
							undefined
						)
					}
					extraComponent={extraComponent}
					persistanceKey="quotes"
					defaultSorting={{ order: "+", sortBy: "importDate" }}
					actions={actions}
					idProperty="id"
					searchPlaceholder="Cerca Quota"
				/>
			)}

			{changeEntity ? (
				<DeleteQuoteModal
					isOpen={isDeleteOpen}
					quote={changeEntity!}
					onClose={() => {
						onDeleteClose();
						setchangeEntity(undefined);
					}}
				/>
			) : null}

			<QuoteModal
				athleteId={id}
				isOpen={isCreateUserOpen}
				onClose={() => {
					onCreateUserClose();
				}}
				set={() => setchangeEntity(undefined)}
				action={changeEntity ? "update" : "create"}
				quote={changeEntity ? changeEntity : undefined}
			/>
		</div>
	);
}
