import {
	ManageSubsidiaryDto,
	SubsidiaryDto,
	SubsidiariesClient,
} from "../../../Api";
import { useClient } from "../../../components/UseClient";
import CsModal from "../../../components/CsModal";
import SubsidiaryForm from "./SubsidiaryForm";
import { ModalProps } from "../../../utilities/typeUtils";
import useToastMutation from "../../../components/Hooks/useToastMutation";

export default function SubsidiaryModal({
	isOpen,
	onClose,
	subsidiary,
}: ModalProps & { subsidiary?: SubsidiaryDto }) {
	const client = useClient(SubsidiariesClient);

	const { mutate: createMark, isLoading: isMarking } = useToastMutation(
		(values: ManageSubsidiaryDto) => client.createSubsidiary(values),
		onClose,
		["subsidiaries"],
		"Sede Aggiunta"
	);
	const { mutate: updateMark, isLoading: isUpdating } = useToastMutation(
		(values: ManageSubsidiaryDto) =>
			client.updateSubsidiary(subsidiary?.id!, values),
		onClose,
		["subsidiaries"],
		"Sede Modificata"
	);

	return (
		<CsModal
			title={!!subsidiary ? "Modifica Sede" : "Crea Sede"}
			isOpen={isOpen}
			onClose={onClose}
		>
			<SubsidiaryForm
				action={subsidiary ? "update" : "create"}
				onSubmit={!!subsidiary ? updateMark : createMark}
				isLoading={isMarking || isUpdating}
				initialValues={{
					name: subsidiary ? subsidiary.name : "",
					description: subsidiary
						? subsidiary.description
						: undefined,
					capacity: subsidiary ? subsidiary.capacity : undefined,
					address: subsidiary
						? subsidiary.address
						: {
								street: "",
								city: "",
								postalCode: "",
								country: "",
								province: "",
								region: "",
						  },
				}}
			/>
		</CsModal>
	);
}
