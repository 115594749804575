import jwt_decode from "jwt-decode";
import { tokenResponse } from "../pages/Login";
import { AccountType, Feature, UserDto, UsersClient } from "../Api";
import { useClient } from "./UseClient";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

type userData = {
	user: tokenResponse | undefined;
	AccessLevel: AccessLevelType;
	currentUser: UserDto | undefined;
	isLoading: boolean;
	isAthlete: boolean;
	Role: string;
	features: Feature[] | undefined;
};

export enum AccessLevelType {
	SuperAdmin = 3,
	Admin = 2,
	User = 1,
	NoAccess = 0,
}

export default function GetUser(): userData {
	const client = useClient(UsersClient);
	const user: tokenResponse | undefined = !!localStorage.getItem("UserJWT")
		? jwt_decode(localStorage.getItem("UserJWT")!)
		: undefined;

	const { data, isLoading, status } = useQuery({
		queryKey: ["user", user?.Id],
		queryFn: async () => await client.getUser(user?.Id!),
		enabled: !!localStorage.getItem("UserJWT"),
		retry: 3,
	});
	if (status == "error")
		return {
			user: undefined,
			AccessLevel: 0,
			currentUser: undefined,
			isLoading: true,
			isAthlete: false,
			Role: "Visitatore",
			features: [],
		};
	const AccessLevel: AccessLevelType = user?.Role?.includes("SuperAdmin")
		? AccessLevelType.SuperAdmin
		: user?.Role?.includes("Admin")
		? AccessLevelType.Admin
		: user?.Role?.includes("User")
		? AccessLevelType.User
		: AccessLevelType.NoAccess;

	const Role: string = user?.Role?.includes("SuperAdmin")
		? "Super Admin"
		: user?.Role?.includes("Admin")
		? "Amministratore"
		: user?.Role?.includes("User")
		? "Utente"
		: "Visitatore";

	return {
		user: user,
		AccessLevel: AccessLevel,
		currentUser: data?.result!,
		isLoading: !!data?.result ? false : isLoading,
		isAthlete:
			data?.result.accountType == AccountType.SingleUser &&
			data?.result.athlete != null,
		Role,
		features: data?.result.association?.features,
	};
}
