import { ManageContestRequestDto } from "../../../Api";
import { Form, Formik } from "formik";
import { NumberField } from "../../../components/InputFormikFiled";
import * as Yup from "yup";
import { FormProps } from "../../../utilities/typeUtils";
import FormLayout, { FormSection } from "../../../components/UI/FormLayout";
import Button from "../../../components/UI/Button";

export default function MathesForm({
	initialValues,
	action = "create",
	isLoading,
	onSubmit,
}: FormProps<ManageContestRequestDto>) {
	return (
		<Formik<ManageContestRequestDto>
			// validationSchema={{schema}}
			initialValues={{ ...initialValues }}
			onSubmit={onSubmit}
		>
			{({
				values,
				errors,
				touched,
				isSubmitting,
				handleBlur,
				setFieldValue,
			}) => (
				<Form>
					<FormLayout
						actions={
							<>
								<Button submit isLoading={isLoading}>
									{action == "create" ? "Crea" : "Modifica"}
								</Button>
							</>
						}
					>
						<FormSection title="Incontri e classifica">
							<div className="flex flex-col gap-4">
								<NumberField
									id="playedMatches"
									name="playedMatches"
									label="Incontri svolti"
									min={0}
									precision={0}
								/>{" "}
								<NumberField
									id="wonMatches"
									name="wonMatches"
									label="Incontri vinti"
									min={0}
									precision={0}
								/>
								<NumberField
									id="ranking"
									name="ranking"
									label="Classificato"
									min={1}
									precision={0}
									rightElement={"°"}
								/>
							</div>
						</FormSection>
					</FormLayout>
				</Form>
			)}
		</Formik>
	);
}
