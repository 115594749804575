import {
	Button,
	Center,
	Tooltip,
	ButtonProps,
	HStack,
	useColorMode,
	StackProps,
	GridProps,
	Grid,
	Box,
} from "@chakra-ui/react";
import { useUI } from "../UiContext";
import GetUser, { AccessLevelType } from "./GetUser";
import { Icons } from "../utilities/icons";
import ActionButton from "./ActionButton";
import { PropsWithChildren } from "react";

type CsButtonProps = ButtonProps & {
	text: string | JSX.Element;
	tooltip?: string;
	index: number;
	selected?: boolean;
};

export function CsTableButton({
	text,
	tooltip,
	index,
	selected,
	...rest
}: CsButtonProps) {
	const { colorMode } = useColorMode();

	const white = selected || colorMode == "dark";

	return (
		<Tooltip label={tooltip}>
			<Button
				border="1px solid white"
				bg="transparent"
				fontSize="1.4rem"
				fontWeight="bold"
				color={white ? "white" : "gray.600"}
				borderColor={white ? "white" : "gray.600"}
				transition="all 0.5s"
				position="relative"
				borderRadius="1rem"
				mx="0.25rem"
				size="sm"
				_after={{
					content: `""`,
					position: "absolute",
					transition: "all 0.5s",
					borderRadius: "0.80rem",
					w: "85%",
					h: "85%",
					transform: "scale(0)",
					transformOrigin: "center center",
					border: "2px solid gray",
					borderColor: selected ? "white" : "gray.600",
				}}
				_hover={{
					_after: {
						transform: "scale(1)",
					},
				}}
				{...rest}
			>
				{text}
			</Button>
		</Tooltip>
	);
}

export function CsSubmitButton({
	children,
	...rest
}: PropsWithChildren & ButtonProps) {
	const { color } = useUI();
	return (
		<ActionButton
			// onClick={() => onSubmit(values)}
			bg={color.primary}
			color="white"
			type="submit"
			w={["100%", "100%", "initial"]}
			_hover={{ bg: "white", color: color.primary }}
		>
			{children}
		</ActionButton>
	);
}

export function CsDeleteButton({
	text,
	...rest
}: { text?: string } & ButtonProps) {
	const { color } = useUI();
	return (
		<ActionButton
			color="red.500"
			_hover={{ color: "white", bg: "red.500" }}
			{...rest}
		>
			{text ?? "Elimina"}
		</ActionButton>
	);
}

export function CsGrid({ children, ...rest }: PropsWithChildren & GridProps) {
	const { color, borderRadius } = useUI();
	return (
		<Grid
			templateColumns={`repeat(${2}, 1fr)`}
			bg="white"
			borderRadius={borderRadius.primary}
			gap="1rem"
			p="5%"
			position="relative"
			{...rest}
			// border="3px ridge gray"
			//borderColor={color.primary}
		>
			{children}
		</Grid>
	);
}

export function CsActionStack({
	children,
	...rest
}: PropsWithChildren & StackProps) {
	return (
		<HStack
			justifyContent="end"
			pb="0.5rem"
			pt={["2rem", "2rem", "initial"]}
			{...rest}
		>
			{children}
		</HStack>
	);
}

export default function CsButton({
	text,
	to,
	...rest
}: { text: string | JSX.Element; to?: string } & ButtonProps) {
	const { color } = useUI();

	const hover = {
		color: `${color.secondary}`,
		bg: "white",
		fontWeight: "bold",
	};
	return (
		<Center>
			<Button
				colorScheme={color.scheme}
				// bgColor={color.secondary}
				variant="solid"
				{...(rest.variant == "ghost"
					? {
							_hover: {
								border: "2px solid black",
								borderColor: color.secondary,
								fontWeight: "bold",
							},
					  }
					: {
							border: "2px solid black",
							borderColor: color.secondary,
							_hover: hover,
					  })}
				borderRadius="full"
				w="40%"
				_active={{
					transform: "scale(0.95)",
				}}
				{...rest}
			>
				{text}
			</Button>
		</Center>
	);
}

export function CsItemButton({
	entity,
	editFunction,
	deleteFunction,
	editDisable,
	deleteDisable,
	editTooltip,
	deleteTooltip,
}: {
	entity: any;
	editDisable?: boolean;
	deleteDisable?: boolean;
	editTooltip?: string;
	deleteTooltip?: string;
	editFunction?: (entity: any) => void;
	deleteFunction?: (entity: any) => void;
}) {
	const { AccessLevel } = GetUser();

	const style = {
		w: "fit-content",
		variant: "ghost",
		// zIndex="popover",
		transition: "all 0.5s",
		border: "1px solid gray",
		borderRadius: "full",
		borderColor: "gray.400",
		transform: "scale(1)",
		_hover: {
			transform: "scale(0.95)",
		},
	};

	return (
		<>
			{AccessLevel >= AccessLevelType.Admin ? (
				<div
					className="flex justify-end text-gray-400 gap-2"
					key={entity.id + "-operation"}
				>
					{!!editFunction ? (
						<Tooltip label={editDisable ? editTooltip : "Modifica"}>
							<Box>
								<Button
									disabled={editDisable}
									{...style}
									onClick={(e) => {
										editFunction(entity);
									}}
									key={entity.id + "-edit"}
								>
									{Icons.Edit}
								</Button>
							</Box>
						</Tooltip>
					) : null}

					{!!deleteFunction ? (
						<Tooltip
							label={deleteDisable ? deleteTooltip : "Elimina"}
						>
							<Box>
								<Button
									disabled={deleteDisable}
									{...style}
									key={entity.id + "-delete"}
									onClick={(e) => {
										deleteFunction(entity);
									}}
								>
									{Icons.Delete}
								</Button>
							</Box>
						</Tooltip>
					) : null}
				</div>
			) : null}
			{/* {AccessLevel >= AccessLevelType.Admin ? (
				<HStack
					justifyContent="end"
					fontSize="1.3rem"
					key={entity.id + "-operation"}
					color="gray.400"
					// bg="gray.400"
				>
					{!!editFunction ? (
						<Tooltip label={editDisable ? editTooltip : "Modifica"}>
							<Box>
								<Button
									disabled={editDisable}
									{...style}
									onClick={(e) => {
										editFunction(entity);
									}}
									key={entity.id + "-edit"}
								>
									{Icons.Edit}
								</Button>
							</Box>
						</Tooltip>
					) : null}

					{!!deleteFunction ? (
						<Tooltip
							label={deleteDisable ? deleteTooltip : "Elimina"}
						>
							<Box>
								<Button
									disabled={deleteDisable}
									{...style}
									key={entity.id + "-delete"}
									onClick={(e) => {
										deleteFunction(entity);
									}}
								>
									{Icons.Delete}
								</Button>
							</Box>
						</Tooltip>
					) : null}
				</HStack>
			) : null} */}
		</>
	);
}
