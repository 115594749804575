import { Form, Formik } from "formik";
import { useState } from "react";
import { FilesClient, ManageItemDto } from "../../../Api";
import * as Yup from "yup";
import { submitWithFile } from "../../../components/Fields/PictureField";
import { useClient } from "../../../components/UseClient";
import { Box, GridItem } from "@chakra-ui/react";
import { Icons } from "../../../utilities/icons";
import {
	InputField,
	NumberField,
	PictureField,
	TextareaField,
} from "../../../components/InputFormikFiled";
import { FaShoppingCart } from "react-icons/fa";
import {
	CsActionStack,
	CsDeleteButton,
	CsGrid,
	CsSubmitButton,
} from "../../../components/CsElements";
import { DeletingButtonProps, FormProps } from "../../../utilities/typeUtils";

export default function ItemsForm({
	initialValues,
	onSubmit,
	isLoading,
	action,
	isDeleting,
	deleteMutation,
}: FormProps<ManageItemDto> & DeletingButtonProps) {
	const schema = Yup.object({
		title: Yup.string().required("Questo campo è obbligatorio"),
		description: Yup.string().nullable(),
		price: Yup.number().positive().required(),
		quantity: Yup.number().integer().positive().nullable(),
	});

	const [itemPhoto, setItemPhoto] = useState<File>();
	const client = useClient(FilesClient);
	const numOfColumn = 12;
	const minH = "6.2rem";

	return (
		<Formik<ManageItemDto>
			validationSchema={schema}
			initialValues={{ ...initialValues }}
			onSubmit={async (values) => {
				await submitWithFile(
					client,
					values,
					itemPhoto,
					"itemPhotoId",
					onSubmit
				);
			}}
		>
			{({ values, errors, touched, isSubmitting, setFieldValue }) => (
				<Form>
					<CsGrid templateColumns={`repeat(${numOfColumn}, 1fr)`}>
						<GridItem colSpan={6} minH={minH} rowSpan={2}>
							<Box flex="2">
								<PictureField
									label=""
									className="w-24 h-24"
									// avatarProps={{
									// 	borderRadius: "2rem",
									// 	size: "2xl",
									// 	minW: "100%",
									// 	minH: "15rem",
									// 	bg: "#302020",
									// 	color: "white",
									// 	icon: <FaShoppingCart />,
									// }}
									id={"itemPhotoId"}
									name={"itemPhotoId"}
									setFile={setItemPhoto}
								/>
							</Box>
						</GridItem>
						<GridItem colSpan={6} minH={minH}>
							<InputField
								icon={Icons.Shop}
								id={"title"}
								name={"title"}
								label="Nome Articolo"
								placeholder="Inserisci Nome"
								format={(v: string) =>
									v.charAt(0).toUpperCase() + v.substring(1)
								}
								required
							/>
						</GridItem>
						<GridItem colSpan={3} minH={minH}>
							<NumberField
								icon={Icons.Euro}
								id={"price"}
								name={"price"}
								label="Prezzo"
								precision={2}
								min={0}
								required
							/>
						</GridItem>
						<GridItem colSpan={3} minH={minH}>
							<NumberField
								icon={Icons.Quantity}
								id={"quantity"}
								name={"quantity"}
								label="Quantità"
								min={0}
							/>
						</GridItem>
						<GridItem colSpan={12} minH={minH}>
							<TextareaField
								icon={Icons.Description}
								id="description"
								name="description"
								label="Descrizione"
								placeholder="Inserisci descrizione"
							/>
						</GridItem>

						{/* user point---------------------------------------- */}
					</CsGrid>
					<CsActionStack>
						<CsSubmitButton isLoading={isSubmitting || isLoading}>
							{action == "create" ? "Crea" : "Modifica"}
						</CsSubmitButton>
						{action == "update" ? (
							<CsDeleteButton
								onClick={deleteMutation}
								isLoading={isDeleting}
							/>
						) : null}
					</CsActionStack>
				</Form>
			)}
		</Formik>
	);
}
