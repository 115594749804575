import { FamiliesClient, GuardianDto, ManageGuardianDto } from "../../../Api";
import { useClient } from "../../../components/UseClient";
import { GuardianForm } from "../../SignUp/FamilyForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";

export default function CreateGuardianPage({
	guardian,
}: {
	guardian?: GuardianDto;
}) {
	const client = useClient(FamiliesClient);
	const { id } = useParams();
	const invalidate = ["families", "guardians"];
	const toast = useToast();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	function invalidateStrings() {
		if (invalidate)
			invalidate.forEach((i) => {
				queryClient.invalidateQueries([i]);
			});
	}

	const { mutate: createMutation, isLoading } = useMutation(
		(values: ManageGuardianDto) => client.createGuardian(id!, values),
		{
			onSuccess: (i) => {
				toast({
					title: `Guardiano creato`,
					status: "success",
					duration: 3000,
					isClosable: true,
				});
				invalidateStrings();
				navigate(`/families/${id}`);
			},
			onError: (e) => {
				toast({
					title: (e as any).message ?? "Si è verificato un errore",
					status: "error",
					duration: 3000,
					isClosable: true,
				});
			},
		}
	);
	const { mutate: updateMutation, isLoading: isUpdating } = useMutation(
		(values: ManageGuardianDto) =>
			client.updateGuardian(guardian?.id!, values),
		{
			onSuccess: (i) => {
				toast({
					title: `Guardiano Modificato`,
					status: "success",
					duration: 3000,
					isClosable: true,
				});
				invalidateStrings();
				navigate(`/families/${id}`);
			},
			onError: (e) => {
				toast({
					title: (e as any).message ?? "Si è verificato un errore",
					status: "error",
					duration: 3000,
					isClosable: true,
				});
			},
		}
	);

	return (
		<>
			<div>
				<GuardianForm
					isLoading={isLoading || isUpdating}
					action={!!guardian ? "update" : "create"}
					initialValues={{
						name: !!guardian ? guardian.name : "",
						surname: !!guardian ? guardian.surname : "",
						role: !!guardian ? guardian.role : "",
						birth: !!guardian ? guardian.birth : "",
						guardianEmail: !!guardian
							? guardian.guardianEmail
							: undefined,
						phoneNumber: !!guardian ? guardian.phoneNumber : "",
					}}
					onSubmit={!!guardian ? updateMutation : createMutation}
				/>
			</div>
		</>
	);
}
