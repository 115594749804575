import {
	AccountType,
	Belt,
	Days,
	EventType,
	Feature,
	Gender,
	RoleType,
} from "../Api";

type Translation = {
	value: any;
	label: string;
};

export function tran(translations: Translation[], value: any) {
	return translations.find((x) => x.value == value)?.label ?? "-";
}

export const RoleTypeTrans: Translation[] = [
	{
		value: RoleType.Athlete,
		label: "Atleta",
	},
	{
		value: RoleType.Technician,
		label: "Tecnico",
	},
	{
		value: RoleType.Judge,
		label: "Giudice",
	},
];
export const FeatureTrans: Translation[] = [
	{
		value: Feature.AccountsController,
		label: "Controllo Accounts",
	},
	{
		value: Feature.Calendar,
		label: "Orario Allenamenti",
	},
	{
		value: Feature.Subsidiary,
		label: "Sedi",
	},
	{
		value: Feature.FamilyAccount,
		label: "Account Famiglia",
	},
	{
		value: Feature.Events,
		label: "Gestione Eventi",
	},
	{
		value: Feature.Items,
		label: "Negozio",
	},
	{
		value: Feature.Courses,
		label: "Corsi",
	},
];

export const DaysTrans: Translation[] = [
	{
		value: Days.Lunedì,
		label: "Lunedì",
	},
	{
		value: Days.Martedì,
		label: "Martedì",
	},
	{
		value: Days.Mercoledì,
		label: "Mercoledì",
	},
	{
		value: Days.Giovedì,
		label: "Giovedì",
	},
	{
		value: Days.Venerdì,
		label: "Venerdì",
	},
	{
		value: Days.Sabato,
		label: "Sabato",
	},
	{
		value: Days.Domenica,
		label: "Domenica",
	},
];

export const GenderTrans: Translation[] = [
	{
		value: Gender.Male,
		label: "Uomo",
	},
	{
		value: Gender.Female,
		label: "Donna",
	},
];
export const EventTypeTrans: Translation[] = [
	{
		value: EventType.Contest,
		label: "Gara",
	},
	{
		value: EventType.Event,
		label: "Evento",
	},
	{
		value: EventType.Seminar,
		label: "Seminario",
	},
	{
		value: EventType.Stage,
		label: "Stage",
	},
];
export const AccountTypeTrans: Translation[] = [
	{
		value: AccountType.SingleUser,
		label: "Utente Singolo",
	},
	{
		value: AccountType.Family,
		label: "Famiglia",
	},
];

export const BeltTrans: Translation[] = [
	{
		value: Belt.White,
		label: "Bianca",
	},
	{
		value: Belt.Yellow,
		label: "Gialla",
	},
	{
		value: Belt.Orange,
		label: "Arancio",
	},
	{
		value: Belt.Green,
		label: "Verde",
	},
	{
		value: Belt.Blue,
		label: "Blu",
	},
	{
		value: Belt.Brown,
		label: "Marrone",
	},
	{
		value: Belt.Black,
		label: "Nera",
	},
];
