import {
	Box,
	Heading,
	Center,
	Text,
	HStack,
	useDisclosure,
	Grid,
	GridItem,
	Slider,
	SliderMark,
	SliderFilledTrack,
	Tooltip,
	SliderThumb,
	SliderTrack,
	Stack,
	Select,
	useToast,
	Skeleton,
} from "@chakra-ui/react";
import { PropsWithChildren, useState } from "react";
import { useUI } from "../../UiContext";
import GetUser, { AccessLevelType } from "../../components/GetUser";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useClient } from "../../components/UseClient";
import {
	CalendarClient,
	CalendarMarkDto,
	Days,
	Feature,
	ManageCalendarMarkDto,
} from "../../Api";
import ActionButton from "../../components/ActionButton";
import MarkModal from "./components/MarkModal";
import { hours } from "../../utilities/Constant";
import { CourseSelect } from "../../components/Fields/CourseField";
import { SubsidiarySelect } from "../../components/Fields/SubsidiariyField";

type Pop = CalendarMarkDto;

export function Calendar() {
	// const days = [
	// 	"Lunedì",
	// 	"Martedì",
	// 	"Mercoledì",
	// 	"Giovedì",
	// 	"Venerdì",
	// 	"Sabato",
	// 	"Domenica",
	// ];

	const { currentUser, AccessLevel } = GetUser();
	const client = useClient(CalendarClient);
	const {
		data: calendar,
		isLoading,
		status,
	} = useQuery({
		queryKey: ["calendar", currentUser?.associationId],
		queryFn: () => client.getCalendar(currentUser?.associationId!),
		enabled: !!currentUser?.associationId,
		retry: 1,
	});

	const [data, setData] = useState(calendar?.result.calendarMarks);
	const [height, setHeight] = useState(
		localStorage.getItem("cellHeight") ?? "60%"
	);
	const [tooltip, setTooltip] = useState(false);
	const [minutes, setMinutes] = useState(["00", "30"]);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [mark, setMark] = useState<CalendarMarkDto | undefined>(undefined);
	const [course, setCourse] = useState<string | undefined>(
		localStorage.getItem("marks-course") ?? undefined
	);
	const [subsidiary, setSubsidiary] = useState<string | undefined>(
		localStorage.getItem("marks-subsidiary") ?? undefined
	);
	const [from, setFrom] = useState<string>(
		localStorage.getItem("fromHour") ?? hours[0]
	);
	const [to, setTo] = useState<string>(
		localStorage.getItem("toHour") ?? hours[hours.length - 1]
	);

	const toast = useToast();
	const { color } = useUI();
	const queryClient = useQueryClient();

	const { mutate: createMutation, isLoading: isCreating } = useMutation(
		() =>
			client.createCalendar({
				associationId: currentUser?.associationId!,
			}),
		{
			onSuccess: () => {
				toast({
					title: "Calendario Orario Creato",
					status: "success",
					duration: 3000,
					isClosable: true,
				});
				queryClient.invalidateQueries([
					"calendar",
					currentUser?.associationId,
				]);
			},
			onError: () => {
				toast({
					title: "Si è verificato un errore",
					status: "error",
					duration: 3000,
					isClosable: true,
				});
			},
		}
	);

	const [newId, setNewId] = useState<string | undefined>(undefined);
	// if (isLoading)
	// 	return (
	// 		<Center w="100%">
	// 			<Spinner />
	// 		</Center>
	// 	);

	function CalendarBox({
		day,
		hour,
		i,
		children,
	}: { day?: number; hour?: string; i?: number } & PropsWithChildren) {
		const [style, setStyle] = useState<React.CSSProperties>({});

		function dragOver(ev: any) {
			ev.preventDefault();
			setStyle({
				borderTop: "2px solid blue",
				borderTopColor: "#4299e1",
			});
		}

		const { mutate: updateItem, isLoading } = useMutation(
			(values: ManageCalendarMarkDto) =>
				client.updateMark(newId!, values),
			{
				onSuccess: () => {
					queryClient.invalidateQueries(["calendar"]);
					setNewId(undefined);
				},
				onError: () => {
					toast({
						title: "Si è verificato un errore",
						status: "error",
						duration: 3000,
						isClosable: true,
					});
				},
			}
		);
		function dragLeave(ev: any) {
			ev.preventDefault();
			setStyle({});
		}
		function drop(ev: any) {
			var newd = calendar?.result.calendarMarks?.find(
				(x) => x.id == ev.dataTransfer.getData("text/plain")
			);

			var newItem: ManageCalendarMarkDto = {
				calendarId: calendar?.result.id!,
				title: newd?.title!,
				hour: hour ?? newd?.hour!,
				day: (day! as Days) ?? newd?.day!,
				duration: newd?.duration!,
				color: newd?.color!,
				courseId: newd?.course?.id!,
				subsidiaryId: newd?.subsidiary?.id!,
			};

			updateItem(newItem);
		}
		return (
			<Box
				data-hour={hour}
				data-day={day}
				onDragOver={day != -1 ? dragOver : () => {}}
				onDrop={(e) => {
					drop(e);
				}}
				onDragLeave={dragLeave}
				position="relative"
				flex="1"
				bg={
					i != undefined && (i + (!!day ? Number(day) : 0)) % 2 == 0
						? "gray.50"
						: "gray.200"
				}
				w="100%"
				maxW="100%"
				h={height}
				style={{ aspectRatio: "2/1", ...style }}
				// border="1px solid gray"

				boxSizing="border-box"
				borderColor={
					i != undefined && i % 2 == 0 ? "gray.600" : "gray.200"
				}
				textAlign="start"
			>
				{children}
			</Box>
		);
	}

	function ClendarMark({
		itemsCount,
		index,
		onDragStartHandler,
		item,
	}: {
		itemsCount: number;
		index: number;
		onDragStartHandler: (ev: any, entity: Pop) => void;
		item: Pop;
	}) {
		const [canGrab, setCangrab] = useState(true);
		const hourArray = item.hour.split(":");
		var hr = Number(hourArray[0]) + Math.floor(item.duration);
		var min =
			((hourArray[1] == "30" ? 0.5 : 0) + (item.duration % 1.0)) * 60;

		if (min > 60) {
			min -= 60;
			hr += 1;
		}
		const newHour = hr + ":" + (min < 10 ? "0" + min : min);

		return (
			<>
				<Center
					overflow={"hidden"}
					fontWeight="bold"
					position="absolute"
					textAlign="center"
					// flex="1"
					w={itemsCount > 1 ? `${100 / itemsCount}%` : "100%"}
					ml={(100 / itemsCount) * index + "%"}
					h={
						Number(item.hour.split(":")[0]) + item.duration > 24
							? "100%"
							: item.duration * minutes.length * 100 + "%"
					}
					onDragStart={(e) => {
						if (AccessLevel >= AccessLevelType.Admin)
							onDragStartHandler(e, item);
					}}
					// pb={item.duration * minutes.length - 1 + "px"}
					// boxSizing="content-box"
					onDoubleClick={() => {
						if (AccessLevel >= AccessLevelType.Admin) {
							setMark(item);
							onOpen();
						}
					}}
					_active={{
						cursor:
							AccessLevel >= AccessLevelType.Admin
								? "grabbing"
								: "default",
					}}
					cursor={
						AccessLevel >= AccessLevelType.Admin
							? "grab"
							: "default"
					}
					draggable={
						AccessLevel >= AccessLevelType.Admin ? canGrab : false
					}
					zIndex="1"
					color="white"
					// position="absolute"
					// p="0.1rem"
					bgColor={item.color}
					borderRadius="1rem"
					opacity="0.9"
				>
					<Stack>
						<Text>{`${item.hour} - ${newHour}`}</Text>
						<Text>{item.title}</Text>
						<Text>{`${item.subsidiary?.name ?? ""}${
							item.subsidiary && item.course ? " - " : ""
						}${item.course?.name ?? ""}`}</Text>
					</Stack>
				</Center>
			</>
		);
	}

	function onDragStartHandler(ev: any, entity: Pop) {
		// setDragon(entity);
		var rect = ev.target.getBoundingClientRect();
		ev.dataTransfer.setData("text/plain", entity.id);
		setNewId(entity.id);
		// console.log(ev.target);
	}
	const filteredHours = hours.filter(
		(x, i) =>
			i >= hours.findIndex((y) => y == from) &&
			i <= hours.findIndex((y) => y == to)
	);

	const style = { w: "50%", aspectRatio: "2/1" };
	return (
		<Skeleton isLoaded={!isLoading} borderRadius={"xl"}>
			{!calendar || status == "error" ? (
				<Grid placeItems="center" w="100%" h="100%" minH="80vh">
					{AccessLevel >= AccessLevelType.Admin ? (
						<Stack
							alignItems="center"
							justifyContent="center"
							gap="1rem"
						>
							<Text maxW="50%" textAlign={"center"}>
								Creando l'orario potrai inserire i dettagli dei
								tuoi corsi durante la settimana, in modo che
								tutti gli utenti possano visualizzarli
							</Text>
							<ActionButton
								onClick={() => createMutation()}
								w="fit-content"
								isLoading={isCreating}
							>
								Crea Orario
							</ActionButton>
						</Stack>
					) : (
						<Heading color={color.primary}>
							Feature non implementata
						</Heading>
					)}
				</Grid>
			) : (
				<Stack h="100%" overflow={"hidden"}>
					<HStack
						w={
							AccessLevel >= AccessLevelType.Admin
								? "100%"
								: "40%"
						}
					>
						<HStack flex="1" gap="3rem" maxW="30%">
							<Stack>
								<Text fontWeight="bold">Grandezza Celle</Text>
								<Slider
									id="slider"
									defaultValue={Number(
										height.replace("%", "")
									)}
									min={30}
									w="10%"
									minW="8rem"
									mb="2rem"
									max={100}
									colorScheme="teal"
									onChange={(v) => {
										localStorage.setItem(
											"cellHeight",
											v + "%"
										);
										setHeight(v + "%");
									}}
									onMouseEnter={() => setTooltip(true)}
									onMouseLeave={() => setTooltip(false)}
								>
									<SliderMark
										value={30}
										mt="1"
										ml="-2.5"
										fontSize="sm"
									>
										30%
									</SliderMark>
									<SliderMark
										value={65}
										mt="1"
										ml="-2.5"
										fontSize="sm"
									>
										65%
									</SliderMark>
									<SliderMark
										value={100}
										mt="1"
										ml="-2.5"
										fontSize="sm"
									>
										100%
									</SliderMark>
									<SliderTrack>
										<SliderFilledTrack />
									</SliderTrack>
									<Tooltip
										hasArrow
										bg="teal.500"
										color="white"
										placement="top"
										isOpen={tooltip}
										label={`${height}`}
									>
										<SliderThumb />
									</Tooltip>
								</Slider>
							</Stack>
							<HStack flex="1" minW="17rem">
								<Text>Dalle</Text>
								<Select
									minW="fit-content"
									value={from}
									onChange={(e) => {
										var index = hours.findIndex(
											(x) => x == e.target.value
										);
										localStorage.setItem(
											"fromHour",
											e.target.value
										);
										setFrom(e.target.value);
										if (
											index >
											hours.findIndex((x) => x == to)
										)
											setTo(
												hours[
													index + 1 > hours.length - 1
														? index
														: index + 1
												]
											);
									}}
								>
									{hours.map((x) => (
										<option value={x} key={"from" + x}>
											{x}
										</option>
									))}
								</Select>
								<Text>alle</Text>
								<Select
									minW="fit-content"
									value={to}
									onChange={(e) => {
										localStorage.setItem(
											"toHour",
											e.target.value
										);
										setTo(e.target.value);
									}}
								>
									{hours.map((x) => (
										<option value={x} key={"to" + x}>
											{x}
										</option>
									))}
								</Select>
								{currentUser?.association?.features.includes(
									Feature.Courses
								) ? (
									<CourseSelect
										course={course}
										setCourse={(x) => {
											if (x != undefined)
												localStorage.setItem(
													"marks-course",
													x?.id!
												);
											else
												localStorage.removeItem(
													"marks-course"
												);
											setCourse(x?.id);
										}}
										placeholderText="Tutti i corsi"
									/>
								) : null}
								{currentUser?.association?.features.includes(
									Feature.Subsidiary
								) ? (
									<SubsidiarySelect
										subsidiary={subsidiary}
										setSubsidiary={(x) => {
											if (x != undefined)
												localStorage.setItem(
													"marks-subsidiary",
													x?.id!
												);
											else
												localStorage.removeItem(
													"marks-subsidiary"
												);
											setSubsidiary(x?.id);
										}}
										placeholderText="Tutte le sedi"
									/>
								) : null}
							</HStack>
						</HStack>

						{/* <Select
					value={minutes.join(",")}
					onChange={(x) =>
						setMinutes(x.currentTarget.value.split(","))
					}
				>
					<option value="00,30">Ogni mezz'ora</option>
					<option value="00">Ogni ora</option>
				</Select> */}

						{AccessLevel >= AccessLevelType.Admin ? (
							<HStack
								alignSelf="end"
								flex="1"
								alignItems="end"
								justifyContent="end"
								gap="1rem"
							>
								<Stack
									alignSelf="end"
									fontSize="md"
									opacity="0.5"
									flex="2"
									justifyContent="center"
									alignItems="end"
								>
									<Text>
										- Trascina oggetti per spostarli
									</Text>
									<Text>- Doppio click per modificarli</Text>
								</Stack>

								<ActionButton
									onClick={() => {
										setMark(undefined);
										onOpen();
									}}
								>
									Aggiungi Postit
								</ActionButton>
							</HStack>
						) : null}
					</HStack>

					<MarkModal
						isOpen={isOpen}
						onClose={onClose}
						calendar={calendar.result}
						mark={mark}
					/>

					<Grid
						templateColumns={"repeat(8,1fr)"}
						// templateRows={`repeat(${hours.length},1fr)`}

						h="100%"
						// gap="0 0.5rem"
						// gap="0.5rem"
					>
						<GridItem
							colSpan={1}
							rowSpan={12}
							rowStart={2}
							colStart={1}
							bg="gray"
							style={style}
						>
							{filteredHours.map((y, i) => (
								<CalendarBox
									i={i}
									day={-1}
									key={"calendarBox" + y}
								>
									{y}
								</CalendarBox>
							))}
						</GridItem>

						{Object.values(Days)
							.filter((x) => x.toString().length > 1)
							.map((day, i) => {
								return (
									<>
										<GridItem
											key={"gridItem" + day}
											rowStart={1}
											colStart={i + 2}
											fontSize="xl"
											fontWeight="bold"
										>
											<Center>{day}</Center>
										</GridItem>
										<GridItem
											key={"gridItemHours" + day}
											colSpan={1}
											rowSpan={12}
											rowStart={2}
											style={style}
										>
											{filteredHours.map((hour, j) => (
												<CalendarBox
													key={
														"calendarBoxHour" + hour
													}
													hour={hour}
													day={i}
													i={j}
												>
													{calendar.result.calendarMarks
														?.filter(
															(
																d: CalendarMarkDto
															) =>
																d.day ==
																	(i as Days) &&
																hour ==
																	d.hour &&
																(!course ||
																	d.course
																		?.id ==
																		course) &&
																(!subsidiary ||
																	d.subsidiary
																		?.id ==
																		subsidiary)
														)
														.sort((x) => x.duration)
														.map((item, i) => {
															var simil =
																calendar.result.calendarMarks.filter(
																	(y) =>
																		y.day ==
																			item.day &&
																		y.hour ==
																			item.hour &&
																		(!course ||
																			y
																				.course
																				?.id ==
																				course) &&
																		(!subsidiary ||
																			y
																				.subsidiary
																				?.id ==
																				subsidiary)
																).length;

															return (
																<ClendarMark
																	key={
																		"calendarMark" +
																		item.title
																	}
																	index={i}
																	itemsCount={
																		simil
																	}
																	onDragStartHandler={
																		onDragStartHandler
																	}
																	item={item}
																/>
															);
														})}
												</CalendarBox>
											))}
										</GridItem>
									</>
								);
							})}
					</Grid>
				</Stack>
			)}
		</Skeleton>
	);
}
