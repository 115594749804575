import { Formik, Form } from "formik";
import { ManagePassword } from "../../Api";
import * as Yup from "yup";

import { PasswordField } from "../../components/InputFormikFiled";

import { Icons } from "../../utilities/icons";
import { passwordSchema } from "../../utilities/yupUtils";
import FormLayout, { FormSection } from "../../components/UI/FormLayout";
import Button from "../../components/UI/Button";

type PasswordFormProps = {
	isLoading: boolean;
	initialValues: ChangePasswordType;
	onSubmit: (values: ManagePassword) => void;
};

type ChangePasswordType = ManagePassword & {
	repeatNewPassword: string;
};

export default function PasswordForm({
	isLoading,
	onSubmit,
	initialValues,
}: PasswordFormProps) {
	const schema = Yup.object().shape({
		newPassword: passwordSchema,
		repeatNewPassword: Yup.string().test(
			"isEquals",
			"Deve corrispondere alla nuova password",
			(value, schema) => {
				return !!value && schema.parent.newPassword == value;
			}
		),
	});

	return (
		<Formik<ChangePasswordType>
			validationSchema={schema}
			initialValues={{ ...initialValues }}
			onSubmit={onSubmit}
		>
			{({ values, errors, touched, isSubmitting }) => (
				<>
					<Form>
						<FormLayout
							actions={
								<>
									<Button submit isLoading={isLoading}>
										Modifica
									</Button>
								</>
							}
						>
							<FormSection title="Informazioni passwords">
								<div className="flex flex-col gap-4">
									<PasswordField
										icon={Icons.Password}
										id="oldPassword"
										name="oldPassword"
										label="Vecchia password"
										format={(v) => v.trim()}
										placeholder="Inserire Password"
										required
									/>
									<PasswordField
										icon={Icons.Password}
										id="newPassword"
										name="newPassword"
										label="Nuova password"
										format={(v) => v.trim()}
										placeholder="Inserire Password"
										required
									/>
									<PasswordField
										icon={Icons.Password}
										id="repeatNewPassword"
										name="repeatNewPassword"
										label="Ripeti nuova password"
										format={(v) => v.trim()}
										placeholder="Inserire Password"
										required
									/>
								</div>
							</FormSection>
						</FormLayout>
					</Form>
				</>
			)}
		</Formik>
	);
}
