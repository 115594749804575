export function generateRandom(min: number, max: number) {
	return Math.floor(Math.random() * (max - min) + min);
}

export function generate(num: number | undefined) {
	const genChar = (min: number, max: number) =>
		String.fromCharCode(generateRandom(min, max));
	const genNumber = () => genChar(48, 57);
	const genCharUpperLetter = () => genChar(65, 90);
	const genLowerLetter = () => genChar(97, 122);
	const genSymbol = () => genChar(33, 38);
	switch (num) {
		case 1:
			return genNumber();
		case 2:
			return genCharUpperLetter();
		case 3:
			return genLowerLetter();
		case 4:
			return genSymbol();
	}
}

export function generatePassword(length: number = 10) {
	var pwd = "";
	var counter = [0, 0, 0, 0];
	var maxCounter = [3, 2, 4, 1];
	const canIncrease = (num: number) => {
		return counter[num - 1] < maxCounter[num - 1] ? true : false;
	};
	function genPos(): number {
		const num = generateRandom(1, 5);

		if (canIncrease(num)) return num;
		return genPos();
	}

	const increase = (num: number) => {
		counter[num - 1]++;
	};

	for (var i = 0; i < length; i++) {
		var pos = genPos();

		var char = generate(pos);

		if (pos != undefined) {
			increase(pos);
			pwd += char;
		}
	}
	return pwd;
}
