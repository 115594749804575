import React from "react";
import { classNames } from "../../components/utils";
import { AssociationsClient, ManageAssociationDto } from "../../Api";
import { useClient } from "../../components/UseClient";
import GetUser from "../../components/GetUser";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import AssociationForm from "../SignUp/AssociationForm";

export default function CreateAssociationPage() {
	const client = useClient(AssociationsClient);
	const { id } = useParams();
	const navigate = useNavigate();
	const toast = useToast();
	const invalidate = ["associations"];
	const { data: association, isLoading: isLoadingAsso } = useQuery({
		queryKey: ["associations", id],
		queryFn: async () =>
			await client.getAssociation(id!).then((r) => r.result),
		enabled: id != undefined,
	});
	const queryClient = useQueryClient();

	function invalidateStrings() {
		if (invalidate)
			invalidate.forEach((i) => {
				queryClient.invalidateQueries([i]);
			});
	}

	const { mutate: createMutation, isLoading } = useMutation(
		(values: ManageAssociationDto) => client.createAssociation(values),
		{
			onSuccess: (i) => {
				toast({
					title: `Associazione creata`,
					status: "success",
					duration: 3000,
					isClosable: true,
				});
				invalidateStrings();
				navigate(
					`/associations${
						!!association ? "/" + association.associationId : ""
					}`
				);
			},
			onError: (e) => {
				toast({
					title: (e as any).message ?? "Si è verificato un errore",
					status: "error",
					duration: 3000,
					isClosable: true,
				});
			},
		}
	);
	const { mutate: updateMutation, isLoading: isUpdating } = useMutation(
		(values: ManageAssociationDto) =>
			client.updateAssociation(association?.associationId!, values),
		{
			onSuccess: () => {
				toast({
					title: `Associazione modificata`,
					status: "success",
					duration: 3000,
					isClosable: true,
				});
				invalidateStrings();
				navigate(
					`/associations${
						!!association ? "/" + association.associationId : ""
					}`
				);
			},
			onError: (e: any) => {
				toast({
					title: (e as any).message ?? "Si è verificato un errore",
					status: "error",
					duration: 3000,
					isClosable: true,
				});
			},
		}
	);
	return (
		<div
			className={classNames(
				id == undefined || !isLoadingAsso
					? ""
					: "bg-gray-400 animate-pulse w-full h-full rounded-md"
			)}
		>
			{id == undefined || !isLoadingAsso ? (
				<AssociationForm
					isLoading={isLoading || isUpdating}
					action={!!association ? "update" : "create"}
					initialValues={{
						name: !!association ? association.name : "",
						website: !!association
							? association.website
							: undefined,
						phoneNumber: !!association
							? association.phoneNumber
							: "",
						features: !!association ? association.features : [],
						address: !!association
							? association.address
							: {
									street: "",
									city: "",
									postalCode: "",
									country: "Italia",
									province: "",
									region: "",
							  },
					}}
					onSubmit={!!association ? updateMutation : createMutation}
				/>
			) : null}
		</div>
	);
}
