import  { useState } from "react";

export default function useFilter(
	persistanceKey: string | undefined,
	defaultFilter?: string
) {
	const filterKey = persistanceKey + "_filter";
	const localFilter = localStorage.getItem(filterKey) ?? defaultFilter;

	const [filterBy, _setFilterBy] = useState<string | undefined>(localFilter);

	const setFilterBy = (value: string | undefined) => {
		if (value == undefined) {
			localStorage.removeItem(filterKey);
			_setFilterBy(undefined);
		} else {
			localStorage.setItem(filterKey, value);
			_setFilterBy(value);
		}
	};

	return { filterBy: filterBy, setFilterBy: setFilterBy };
}
