import { classNames } from "../../components/utils";
import UserForm from "./UserForm";
import {
	UsersClient,
	AdminsClient,
	ManageUserDto,
	AccountType,
} from "../../Api";
import { useClient } from "../../components/UseClient";
import { generatePassword } from "../../utilities/Password";
import GetUser, { AccessLevelType } from "../../components/GetUser";
import { emptyAthleteFormValues } from "../../utilities/Constant";

import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";

export default function CreateUserPage() {
	const client = useClient(UsersClient);
	const adminClient = useClient(AdminsClient);
	const { currentUser, AccessLevel } = GetUser();
	const { id } = useParams();
	const navigate = useNavigate();
	const toast = useToast();
	const invalidate = ["users", "athletes"];
	const { data: user, isLoading: isLoadingUser } = useQuery({
		queryKey: ["users", id],
		queryFn: async () => await client.getUser(id!).then((r) => r.result),
		enabled: id != undefined,
	});
	const queryClient = useQueryClient();

	function invalidateStrings() {
		if (invalidate)
			invalidate.forEach((i) => {
				queryClient.invalidateQueries([i]);
			});
	}

	const { mutate: createMutation, isLoading } = useMutation(
		(values: ManageUserDto) =>
			AccessLevel >= AccessLevelType.SuperAdmin
				? adminClient.createUser(values)
				: client.createUser(values),
		{
			onSuccess: (i) => {
				toast({
					title: `Utente creato`,
					status: "success",
					duration: 3000,
					isClosable: true,
				});
				invalidateStrings();
				navigate(`/accounts${!!user ? "/" + user.id : ""}`);
			},
			onError: (e) => {
				toast({
					title: (e as any).message ?? "Si è verificato un errore",
					status: "error",
					duration: 3000,
					isClosable: true,
				});
			},
		}
	);
	const { mutate: updateMutation, isLoading: isUpdating } = useMutation(
		(values: ManageUserDto) =>
			AccessLevel >= AccessLevelType.SuperAdmin
				? adminClient.updateUser(user?.id!, values)
				: client.updateUser(user?.id!, values),
		{
			onSuccess: () => {
				toast({
					title: `Utente modificato`,
					status: "success",
					duration: 3000,
					isClosable: true,
				});
				invalidateStrings();
				navigate(`/accounts${!!user ? "/" + user.id : ""}`);
			},
			onError: (e: any) => {
				toast({
					title: (e as any).message ?? "Si è verificato un errore",
					status: "error",
					duration: 3000,
					isClosable: true,
				});
			},
		}
	);

	return (
		<div
			className={classNames(
				id == undefined || !isLoadingUser
					? ""
					: "bg-gray-400 animate-pulse w-full h-full rounded-md"
			)}
		>
			{id == undefined || !isLoadingUser ? (
				<UserForm
					isLoading={isLoading || isUpdating}
					action={!user ? "create" : "update"}
					initialValues={{
						accountType: !!user
							? user.accountType
							: AccountType.SingleUser,
						email: !!user ? user.email : "",
						password: !!user ? "" : generatePassword(10),
						athlete: !!user
							? user.accountType == AccountType.SingleUser
								? user.athlete
								: undefined
							: emptyAthleteFormValues,

						family: !!user
							? user.accountType == AccountType.Family
								? user.family
								: undefined
							: {
									familyName: "",
									familyId: "",
									userId: "",
									guardians: [],
									athletes: [],
							  },
						associationId: !!user
							? user.associationId
							: AccessLevel >= AccessLevelType.SuperAdmin
							? currentUser?.associationId
							: undefined,
					}}
					// from={from}
					// newAccountType={newAccountType}
					onSubmit={!user ? createMutation : updateMutation}
				/>
			) : null}
		</div>
	);
}
