import { AthletesClient, QuoteDto } from "../../../Api";
import { useClient } from "../../../components/UseClient";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import useToastMutation from "../../../components/Hooks/useToastMutation";
import { ModalProps } from "../../../utilities/typeUtils";
import { Modal } from "../../../components/UI/Modal";
import Button from "../../../components/UI/Button";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export function DeleteQuoteModal({
	isOpen,
	quote,
	onClose,
}: ModalProps & { quote: QuoteDto }) {
	const client = useClient(AthletesClient);

	const { mutate: deleteMutation, isLoading } = useToastMutation(
		() => client.deleteQuote(quote.id!),
		onClose,
		["athletes", "quotes"],
		"Quota Rimossa"
	);

	return (
		<>
			<Modal
				title="Elimina Quota"
				isOpen={isOpen}
				onClose={onClose}
				icon={
					<div className="rounded-full bg-red-100 p-2">
						<ExclamationTriangleIcon
							className="h-6 w-6 text-red-600"
							aria-hidden="true"
						/>
					</div>
				}
			>
				<div className="flex flex-col gap-3">
					<p>
						Sei sicuro di voler eliminare la quota versata il{" "}
						<span className="font-bold">
							{format(new Date(quote.importDate), "P", {
								locale: it,
							})}
						</span>{" "}
						di importo{" "}
						<span className="font-bold">{quote.import}</span>€?
					</p>
					<div className="flex w-full justify-end gap-2 items-center">
						<Button onClick={onClose} type="secondary">
							Annulla
						</Button>
						<Button
							isLoading={isLoading}
							onClick={() => deleteMutation()}
							className="bg-red-500 hover:bg-red-400"
						>
							Elimina
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
}
