import React, { Fragment, PropsWithChildren } from "react";
import { Dialog, Popover as PopoverUI, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import useDisclosure from "../Hooks/useDisclosure";
import { classNames } from "../utils";
import Button from "./Button";

export default function Popover({
	title,
	triggerName = "trigger",
	triggerClass = "",
	triggerTooltip,
	children,
}: // actions,

// icon,
{
	title: string;
	triggerName?: string | JSX.Element;
	triggerClass?: string;
	triggerTooltip?: string | JSX.Element;
	// icon?: JSX.Element;
	// actions?: JSX.Element;
} & PropsWithChildren) {
	return (
		<div>
			<PopoverUI className="relative">
				<PopoverUI.Button
					as={Button}
					className={classNames(triggerClass)}
					type="table"
					tooltip={triggerTooltip}
				>
					{triggerName}
				</PopoverUI.Button>
				<Transition
					as={Fragment}
					enter="transition ease-out duration-200"
					enterFrom="opacity-0 translate-y-1"
					enterTo="opacity-100 translate-y-0"
					leave="transition ease-in duration-150"
					leaveFrom="opacity-100 translate-y-0"
					leaveTo="opacity-0 translate-y-1"
				>
					<PopoverUI.Panel className="absolute z-10 border-2 border-gray-400 bg-white shadow-md p-4 rounded-md">
						{children}
					</PopoverUI.Panel>
				</Transition>
			</PopoverUI>
		</div>
	);
}
