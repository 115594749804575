import  { useState } from "react";
import { Sorting } from "../CsTable";

export default function useOrderBy<T>(
	persistanceKey: string | undefined,
	defaultSorting?: Sorting<T> | undefined
) {
	const sortKey = (persistanceKey ?? "") + "_sort";
	const localItem = localStorage.getItem(sortKey);
	const localSort =
		!persistanceKey && !!localItem
			? localItem
			: defaultSorting != undefined
			? defaultSorting?.order + (defaultSorting?.sortBy as string)
			: undefined;

	const [orderBy, _setOrderBy] = useState<string | undefined>(localSort);

	const setOrderBy = (value: string | undefined) => {
		if (value == undefined) {
			localStorage.removeItem(sortKey);
			_setOrderBy(undefined);
		} else {
			localStorage.setItem(sortKey, value);
			_setOrderBy(value);
		}
	};

	return { orderBy: orderBy, setOrderBy: setOrderBy };
}
