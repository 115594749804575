import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { UserDto, UsersClient, FamilyDto, FamiliesClient } from "../../Api";
import { useClient } from "../../components/UseClient";
import GetUser from "../../components/GetUser";
import { useNavigate, useParams } from "react-router-dom";

import { AccountTypeTrans, tran } from "../../utilities/Translations";
import { Icons } from "../../utilities/icons";
import { TabProp } from "../../components/CsTabs";

import { ShowType } from "../Athletes/SingleAthletePage";

export function SingleSubsidiaryPage({
	subsidiaryId,
	extraTabs,
	extraOptions,
}: {
	subsidiaryId?: string;
	extraTabs?: TabProp[];
	extraOptions?: JSX.Element;
}) {
	const { id } = useParams();
	const { AccessLevel, currentUser } = GetUser();
	const client = useClient(FamiliesClient);
	const userClient = useClient(UsersClient);
	const navigate = useNavigate();
	const [type, setType] = useState<ShowType>(ShowType.Family);
	const to = "/association";
	const { data: subsidiary, isLoading: isLoadingFamily } = useQuery({
		queryKey: ["subsidiaries", subsidiaryId ?? id],
		queryFn: () =>
			client.getFamily(subsidiaryId ?? id!).then((r) => r.result),
		enabled: !!subsidiaryId || !!id,
	});

	return (
		<></>
		// <Stack gap="2rem">
		// 	<HStack gap="0" justifyContent="end" alignItems="end">
		// 		{extraOptions ?? null}
		// 	</HStack>
		// 	<HStack
		// 		gap="2rem"
		// 		justifyContent="center"
		// 		alignItems="center"
		// 		position="relative"
		// 	>
		// 		<Stack
		// 			alignSelf="start"
		// 			// alignItems="center"
		// 			justifyContent="center"
		// 			gap="1.5rem"
		// 			{...sectionStyle}
		// 		>
		// 			<InfoSectionButton
		// 				sectionType={type.toString()}
		// 				onClick={() => setType(ShowType.Family)}
		// 				name={ShowType.Family.toString()}
		// 			>
		// 				Famiglia
		// 			</InfoSectionButton>
		// 			<InfoSectionButton
		// 				sectionType={type.toString()}
		// 				onClick={() => setType(ShowType.Account)}
		// 				name={ShowType.Account.toString()}
		// 			>
		// 				Account
		// 			</InfoSectionButton>

		// 			{subsidiary?.guardians[0] != undefined ? (
		// 				<InfoSectionButton
		// 					sectionType={type.toString()}
		// 					onClick={() => setType(ShowType.Guardian)}
		// 					name={ShowType.Guardian.toString()}
		// 				>
		// 					Guardiano
		// 				</InfoSectionButton>
		// 			) : null}
		// 			{subsidiary?.athletes != undefined &&
		// 			subsidiary?.athletes.length > 0 ? (
		// 				<InfoSectionButton
		// 					sectionType={type.toString()}
		// 					onClick={() => setType(ShowType.Quotes)}
		// 					name={ShowType.Quotes.toString()}
		// 				>
		// 					Info Quotes
		// 				</InfoSectionButton>
		// 			) : null}
		// 		</Stack>

		// 		<Box
		// 			w="100%"
		// 			flex="2"
		// 			// minH="100%"
		// 			alignSelf="start"
		// 			{...{ ...sectionStyle, p: "1rem" }}
		// 		>
		// 			<ShowData user={user} type={type} family={subsidiary} />
		// 		</Box>
		// 	</HStack>
		// 	<Spacer />
		// 	<CsTabs2 tabs={tabs} />
		// </Stack>
	);
}

// function ShowData({
// 	user,
// 	type,
// 	family,
// }: {
// 	user: UserDto | undefined;
// 	type: ShowType;
// 	family: FamilyDto | undefined;
// }) {
// 	const { AccessLevel, currentUser } = GetUser();
// 	const { color, borderRadius } = useUI();
// 	const numOfColumn = 12;
// 	const [athleteId, setAthleteId] = useState<string | undefined>(undefined);

// 	return (
// 		<ShowInfoGrid>
// 			{type == ShowType.Account ? (
// 				<>
// 					<RowTitle title="Account" column={numOfColumn} />
// 					<AnimatedGridItem
// 						title="Email"
// 						text={user?.email}
// 						icon={Icons.Email}
// 					/>
// 					<AnimatedGridItem
// 						title="Tipo Account"
// 						text={tran(AccountTypeTrans, user?.accountType!)}
// 						icon={Icons.AccountType}
// 					/>{" "}
// 				</>
// 			) : type == ShowType.Family ? (
// 				<>
// 					<RowTitle title="Utente" column={numOfColumn} />

// 					<AnimatedGridItem
// 						title="Nome Famiglia"
// 						text={family?.familyName}
// 						icon={Icons.Name}
// 					/>

// 					<AnimatedGridItem
// 						colSpan={3}
// 						title="Numero Guardiani"
// 						text={family?.guardians.length.toString()}
// 						icon={Icons.People}
// 					/>
// 					<AnimatedGridItem
// 						colSpan={3}
// 						title="Numero Atleti"
// 						text={family?.athletes.length.toString()}
// 						icon={Icons.Children}
// 					/>
// 				</>
// 			) : type == ShowType.Guardian &&
// 			  family?.guardians[0] != undefined ? (
// 				<>
// 					<RowTitle
// 						title="Guardiano Principale"
// 						column={numOfColumn}
// 					/>
// 					<AnimatedGridItem
// 						title="Nome"
// 						text={family?.guardians[0]?.name}
// 						icon={Icons.Name}
// 					/>
// 					<AnimatedGridItem
// 						title="Cognome"
// 						text={family?.guardians[0]?.surname}
// 						icon={Icons.Surname}
// 					/>
// 					<AnimatedGridItem
// 						title="Email"
// 						text={family?.guardians[0]?.guardianEmail}
// 						icon={Icons.Email}
// 					/>
// 					<AnimatedGridItem
// 						title="Telefono"
// 						text={family?.guardians[0]?.phoneNumber}
// 						icon={Icons.PhoneNumber}
// 					/>
// 					<AnimatedGridItem
// 						title="Ruolo"
// 						text={family?.guardians[0]?.role}
// 						icon={Icons.AccountType}
// 					/>
// 					<AnimatedGridItem
// 						title="Data di Nascita"
// 						text={formatDate(
// 							family?.guardians[0]?.birth,
// 							false,
// 							"date"
// 						)}
// 						icon={Icons.Birth}
// 					/>
// 				</>
// 			) : type == ShowType.Quotes ? (
// 				<>
// 					<AthletesSelect
// 						athlete={athleteId}
// 						onSetAthlete={(x) => setAthleteId(x?.athleteId!)}
// 						customAthletes={family?.athletes!}
// 					/>

// 					{athleteId != undefined &&
// 					family?.athletes.filter(
// 						(x) => x.athleteId == athleteId
// 					)[0] != undefined ? (
// 						<QuoteChart
// 							athlete={
// 								family?.athletes.filter(
// 									(x) => x.athleteId == athleteId
// 								)[0]!
// 							}
// 						/>
// 					) : null}
// 				</>
// 			) : null}
// 		</ShowInfoGrid>
// 	);
// }
