import { ContestDto, EventType, Feature } from "../../Api";

import GetUser from "../../components/GetUser";
import { useNavigate } from "react-router-dom";
import { BsPersonFill } from "react-icons/bs";
import { GiSandsOfTime } from "react-icons/gi";
import { getFileUrl } from "../../components/utils";
import { addDays, differenceInBusinessDays, format, isAfter } from "date-fns";
import { it } from "date-fns/locale";
import { useState } from "react";
import Badge from "../../components/UI/Badge";
import { EventTypeTrans, tran } from "../../utilities/Translations";

type ContestDataProps = {
	contest: ContestDto;
	column: number;
	home?: boolean;
};

export default function ContestData({
	contest,
	column,
	home,
}: ContestDataProps) {
	const { features } = GetUser();
	const navigate = useNavigate();

	const [hover, setHover] = useState(false);
	const [action, setAction] = useState("info");

	function ChangeContest(id: string) {
		localStorage.setItem("ContestId", id);
	}

	function handleClick(e: any, contest: ContestDto) {
		e.preventDefault();
		navigate(`/contests/${contest.id}`);
	}
	var image;

	const limitDays = differenceInBusinessDays(
		new Date(contest.deadlineDate),
		new Date()
	);

	switch (contest.eventType) {
		case EventType.Contest:
			image = "/competition.jpg";
			break;
		case EventType.Stage:
			image = "/Stage.jpg";
			break;
		case EventType.Event:
			image = "/competition.jpg";
			break;
		case EventType.Seminar:
			image = "/Stage.jpg";
			break;
	}

	const ended = !isAfter(new Date(contest.date), new Date());

	return (
		<>
			<div
				className={`flex relative cursor-pointer text-white shadow-lg overflow-hidden rounded-lg max-md:w-full ${
					!home ? " aspect-square " : " w-[50%] aspect-[1/1]"
				}`}
				key={contest.id}
				onMouseEnter={() => {
					setHover(true);
					setAction("info");
				}}
				onMouseLeave={() => setHover(false)}
				onClick={(e) => {
					ChangeContest(contest.id);
					handleClick(e, contest);
				}}
			>
				<img
					className={`h-full w-full  absolute bg-gray-50  ${
						ended
							? " brightness-50 blur-[2px] "
							: hover
							? " brightness-[0.70] "
							: " brightness-[0.50] "
					}  bg-no-repeat bg-cover bg-center transition-all ${
						hover ? " scale-110 " : " scale-100 "
					}`}
					src={
						contest.contestPhoto
							? getFileUrl(contest.contestPhoto)
							: image
					}
					alt=""
				/>

				<div className="w-full h-full p-2 z-[1] font-semibold">
					<div className="flex flex-col justify-between items-center h-full">
						<div className="flex text-lg w-full justify-between flex-wrap">
							<div key={contest.registeredCounter}>
								<div className="flex items-center gap-1">
									<div className="text-xl">
										<BsPersonFill />
									</div>
									<div>{contest.registeredCounter}</div>
								</div>
								<div className="opacity-70">
									{contest.price + "€"}
								</div>
							</div>

							<div className="flex flex-col justify-end items-end">
								<span>
									{contest.numOfDay > 1
										? `${format(
												new Date(contest.date),
												"dd/MM/yyyy",
												{ locale: it }
										  )} - ${format(
												addDays(
													new Date(contest.date),
													contest.numOfDay
												),
												"dd/MM/yyyy",
												{ locale: it }
										  )}
												`
										: format(
												new Date(contest.date),
												"dd/MM/yy",
												{ locale: it }
										  )}
								</span>
								<span className="flex items-center gap-1">
									<GiSandsOfTime />
									<>
										{limitDays < 0
											? "Iscrizioni chiuse"
											: "-" +
											  Math.trunc(limitDays) +
											  (limitDays < 2 ? "" : "")}
									</>
								</span>
							</div>
						</div>

						<div className="justify-start flex flex-col w-full gap-2 ">
							<div
								className="text-3xl w-fit font-bold"
								key={contest.id + "-title"}
							>
								{contest.name}
							</div>

							<div className="justify-between flex  w-full gap-2 items-center">
								<div className="flex-1 gap-1 flex items-center">
									<Badge color="bg-indigo-200">
										{tran(
											EventTypeTrans,
											contest.eventType
										)}
									</Badge>
									{contest.course &&
									features?.includes(Feature.Courses) ? (
										<Badge color="bg-red-200">
											{contest.course.name}
										</Badge>
									) : null}
								</div>
								{ended ? (
									<div className="absolute top-50 left-50  text-xl w-full justify-self-end text-red-500 font-bold bg-white bg-opacity-5 rounded-xl pl-5">
										Evento Concluso
									</div>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
