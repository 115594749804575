import GetUser, { AccessLevelType } from "../../components/GetUser";
import { useNavigate } from "react-router-dom";
import AssociationsTab from "./components/AssociationsTab";

export default function AssociationsPage() {
	const { AccessLevel } = GetUser();
	const navigate = useNavigate();

	if (AccessLevel < AccessLevelType.SuperAdmin) navigate(-1);

	return (
		<>
			<AssociationsTab />
		</>
	);
}
