import { useToast } from "@chakra-ui/react";
import {
	MutationFunction,
	useMutation,
	useQueryClient,
} from "@tanstack/react-query";


export default function useToastMutation<T, TVariables = void>(
	fn: MutationFunction<T, TVariables>,
	onClose?: () => void,
	invalidate?: string[],
	successMessage?: string,
	errorMessage?: string
) {
	const toast = useToast();
	const queryClient = useQueryClient();

	function invalidateStrings() {
		if (invalidate)
			invalidate.forEach((i) => {
				queryClient.invalidateQueries([i]);
			});
		if (onClose) onClose();
	}

	const { mutate, isLoading } = useMutation(fn, {
		onSuccess: () => {
			toast({
				title: successMessage ?? "Azione avvenuta con successo",
				status: "success",
				duration: 3000,
				isClosable: true,
			});
			invalidateStrings();
		},
		onError: (e) => {
			toast({
				title:
					errorMessage ??
					(e as any).message ??
					"Si è verificato un errore",
				status: "error",
				duration: 3000,
				isClosable: true,
			});
			if (onClose) onClose();
		},
	});
	return { mutate, isLoading };
}
