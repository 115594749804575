import UserForm from "./UserForm";
import {
	UsersClient,
	AdminsClient,
	ManageUserDto,
	AccountType,
	UserDto,
} from "../../Api";
import { useClient } from "../../components/UseClient";
import { generatePassword } from "../../utilities/Password";
import GetUser, { AccessLevelType } from "../../components/GetUser";
import { emptyAthleteFormValues } from "../../utilities/Constant";
import { ModalProps } from "../../utilities/typeUtils";
import CsModal from "../../components/CsModal";
import useToastMutation from "../../components/Hooks/useToastMutation";

export type UserFromType = "associationTab" | "login" | "others";

export function UserModal({
	isOpen,
	onClose,
	action = "create",
	newAccountType,
	familyId,
	user,
	from = "associationTab",
}: ModalProps & {
	newAccountType?: AccountType;
	familyId?: string;
	user?: UserDto;
	from?: UserFromType;
}) {
	const client = useClient(UsersClient);
	const adminClient = useClient(AdminsClient);
	const { currentUser, AccessLevel } = GetUser();

	const invalidate = ["users", "athletes"];
	const { mutate: createMutation, isLoading } = useToastMutation(
		(values: ManageUserDto) =>
			AccessLevel >= AccessLevelType.SuperAdmin
				? adminClient.createUser(values)
				: client.createUser(values),
		onClose,
		invalidate,
		"Utente Creato"
	);
	const { mutate: updateMutation, isLoading: isUpdating } = useToastMutation(
		(values: ManageUserDto) =>
			AccessLevel >= AccessLevelType.SuperAdmin
				? adminClient.updateUser(user?.id!, values)
				: client.updateUser(user?.id!, values),
		onClose,
		invalidate,
		"Utente Modificato"
	);

	return (
		<>
			<CsModal
				isOpen={isOpen}
				onClose={onClose}
				size="6xl"
				title={(!!user ? "Modifica" : "Crea") + " Utente"}
			>
				<UserForm
					isLoading={isLoading || isUpdating}
					action={!user ? "create" : "update"}
					initialValues={{
						accountType: !!user
							? user.accountType
							: newAccountType ?? 0,
						email: !!user ? user.email : "",
						password: !!user ? "" : generatePassword(10),
						athlete:
							!!user && user.accountType == AccountType.SingleUser
								? !!user.athlete
									? { ...user.athlete }
									: {
											...emptyAthleteFormValues,
											familyId:
												newAccountType !=
												AccountType.Family
													? familyId
													: undefined,
									  }
								: undefined,
						family: !!user ? user.family : undefined,
						associationId: !!user
							? user.associationId
							: AccessLevel >= AccessLevelType.SuperAdmin
							? currentUser?.associationId
							: undefined,
					}}
					onSubmit={!user ? createMutation : updateMutation}
				/>
			</CsModal>
		</>
	);
}
