import { CourseDto, CoursesClient } from "../../Api";
import { useQuery } from "@tanstack/react-query";
import { useClient } from "../UseClient";
import { FormControl, FormControlProps } from "../FormControl2";
import { classNames } from "../utils";

type Props = FormControlProps & { onChange?: (e: any) => void };
export function CourseField({
	id,
	name,
	label,
	hint,
	icon,
	onChange,
	...rest
}: Props) {
	return (
		<>
			<FormControl
				id={id}
				name={name}
				label={label}
				hint={hint}
				icon={icon}
				required={rest.required}
				disabled={rest.disabled}
			>
				{({ field, helper }) => (
					<CourseSelect
						id={id}
						className="border-2 border-gray-300"
						{...rest}
						{...field}
						course={field.value}
						setCourse={(i) => {
							helper.setValue(i?.id);
						}}
					/>
				)}
			</FormControl>
		</>
	);
}
export function CourseSelect({
	id,
	course,
	setCourse,
	dontInclude,
	placeholderText,
	className,
	required = false,
}: {
	id?: string;
	course: CourseDto | string | undefined;
	setCourse: (course: CourseDto | undefined) => void;
	dontInclude?: string[];
	placeholderText?: string;
	className?: string;
	required?: boolean;
}) {
	const courseClient = useClient(CoursesClient);

	const { data: courses, isLoading } = useQuery({
		queryKey: ["courses"],
		queryFn: () =>
			courseClient
				.getAllCourses(undefined, undefined, undefined, undefined)
				.then((r) => r.result),
	});
	// if (isLoading) <Spinner />;
	return (
		<select
			id={id}
			value={
				course
					? typeof course == "string"
						? course
						: course?.id
					: "placeholder"
			}
			onChange={(x) =>
				setCourse(
					courses?.items.find((y) => y.id == x.currentTarget.value)
				)
			}
			className={classNames(
				"py-1 rounded px-2 border-gray-300 text-black focus:ring-indigo-600 w-full",
				className ?? ""
			)}
		>
			<option value="placeholder" disabled={required}>
				{placeholderText ?? "Seleziona corso"}
			</option>
			{courses?.items
				.filter((x) => !dontInclude?.includes(x.id))
				.map((x) => (
					<option value={x.id} key={"select" + x.id}>
						{x.name}
					</option>
				))}
		</select>
	);
}
