import {
	Tabs,
	TabList,
	TabPanelsProps,
	TabPanels,
	Tab,
	TabPanel,
	Text,
	HStack,
	TabsProps,
	useMediaQuery,
} from "@chakra-ui/react";
import { useUI } from "../UiContext";
import GetUser, { AccessLevelType } from "./GetUser";
import { PropsWithChildren, useState } from "react";
import { classNames } from "./utils";
import { motion } from "framer-motion";

type Props = {
	tabs: TabProp[];
	tabStyle?: TabPanelsProps;
} & Omit<TabsProps, "children">;

export type TabProp = {
	name: string;
	tab: JSX.Element;
	accessLevel?: AccessLevelType;
};

export function TabActionContainer({ children }: PropsWithChildren) {
	return (
		<HStack
			position="absolute"
			right="3.5%"
			gap="0"
			alignItems="center"
			justifyContent="center"
		>
			{children}
		</HStack>
	);
}

export default function CsTabs({ tabs, tabStyle, ...rest }: Props) {
	const { color, tabBorderStyle, borderRadius } = useUI();
	const { AccessLevel } = GetUser();

	return (
		<>
			<Tabs
				minW={["30px", "30px", "600px"]}
				maxW="100vw"
				isLazy
				size="sm"
				// isFitted
				variant={tabBorderStyle}
				colorScheme={color.scheme}
				bg={color.secondary}
				borderRadius={borderRadius.primary}
				mx="5rem"
				{...rest}
			>
				<TabList borderTopRadius={borderRadius.primary}>
					{tabs.map((x, i) => {
						return (
							<>
								{x.accessLevel == undefined ||
								(x.accessLevel != undefined &&
									AccessLevel >= x.accessLevel) ? (
									<Tab
										key={x.name}
										_selected={{
											backgroundColor: `${color.secondary}`,
											color: "white",
											fontSize: "1.5rem",
											fontWeight: "bold",
											fontStyle: "italic",
										}}
										bg="gray.200"
										borderTopLeftRadius={
											i == 0 ? borderRadius.primary : "0"
										}
										borderTopRightRadius={
											i == tabs.length - 1
												? borderRadius.primary
												: "0"
										}
										fontSize="1.2rem"
										border={`1px solid ${color.secondary}`}
									>
										{x.name}
									</Tab>
								) : null}
							</>
						);
					})}
				</TabList>

				<TabPanels py="2.5rem" position="relative" {...tabStyle}>
					{tabs.map((x) => {
						return (
							<TabPanel key={x.name + "_" + x.tab}>
								{x.accessLevel == undefined ||
								(x.accessLevel != undefined &&
									AccessLevel >= x.accessLevel) ? (
									<>{x.tab}</>
								) : null}
							</TabPanel>
						);
					})}
				</TabPanels>
			</Tabs>
		</>
	);
}

export function CsTabs2({ tabs, tabStyle, ...rest }: Props) {
	const { color, tabBorderStyle, borderRadius } = useUI();
	const { AccessLevel } = GetUser();
	const isDesktop = useMediaQuery("(min-width:  992px)");
	const [currentTab, setCurrentTab] = useState(0);
	return (
		<>
			<div>
				<div className="sm:hidden">
					<label htmlFor="tabs" className="sr-only">
						Select a tab
					</label>
					{/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
					<select
						id="tabs"
						name="tabs"
						className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
						defaultValue={0}
						onChange={(v) =>
							setCurrentTab(Number(v.currentTarget.value))
						}
					>
						{tabs.map((tab, i) => (
							<option key={tab.name} value={i}>
								{tab.name}
							</option>
						))}
					</select>
				</div>
				<div className="hidden sm:block">
					<div className="border-b border-gray-200">
						<nav
							className="-mb-px flex space-x-8"
							aria-label="Tabs"
						>
							{tabs.map((tab, i) => (
								<div
									key={tab.name}
									onClick={() => setCurrentTab(i)}
									className={classNames(
										i === currentTab
											? "border-indigo-500 text-indigo-600"
											: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
										"whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
									)}
									aria-current={
										i === currentTab ? "page" : undefined
									}
								>
									{tab.name}
								</div>
							))}
						</nav>
					</div>
				</div>
			</div>
			<div className="pt-8">
				{tabs.map((tab, i) =>
					i == currentTab ? (
						<motion.div
							initial={{ y: 10, opacity: 0 }}
							animate={{ y: 0, opacity: 1 }}
							exit={{ y: -10, opacity: 0 }}
							transition={{ duration: 0.3 }}
						>
							{tab.tab}
						</motion.div>
					) : null
				)}
			</div>
			{/* <Tabs
				minW={["initial", "initial", "600px"]}
				maxW="100vw"
				isLazy
				isFitted={isDesktop ? true : false}
				// variant={tabBorderStyle}
				// colorScheme={color.scheme}
				// bg={color.secondary}
				borderRadius={borderRadius.primary}
				// mx="5rem"
				// px="1.5%"
				{...rest}
			>
				<TabList mb="2rem">
					{tabs.map((x, i) => {
						return (
							<>
								{x.accessLevel == undefined ||
								(x.accessLevel != undefined &&
									AccessLevel >= x.accessLevel) ? (
									<Tab
										key={x.name}
										_selected={{
											position: "relative",

											_after: {
												width: "100%",
												transformOrigin: "100",
											},
										}}
										_after={{
											position: "absolute",
											content: `""`,
											transition: "all 0.5s",
											width: "0%",
											bottom: "0%",
											transformOrigin: "0",
											h: "2px",
											bg: color.primary,
										}}
										transition="all 0.5s"
									>
										<Text fontSize="xl" fontWeight="bold">
											{x.name}
										</Text>
									</Tab>
								) : null}
							</>
						);
					})}
				</TabList>

				<TabPanels position="relative" {...tabStyle}>
					{tabs.map((x) => {
						return (
							<TabPanel key={x.name + "_" + x.tab}>
								{x.accessLevel == undefined ||
								(x.accessLevel != undefined &&
									AccessLevel >= x.accessLevel) ? (
									<>{x.tab}</>
								) : null}
							</TabPanel>
						);
					})}
				</TabPanels>
			</Tabs> */}
		</>
	);
}
