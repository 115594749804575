import { AssociationsClient, AssociationDto } from "../../Api";
import { useQuery } from "@tanstack/react-query";
import { useClient } from "../UseClient";
import { FormControl, FormControlProps } from "../FormControl2";
import { classNames } from "../utils";

type Props = FormControlProps & { onChange?: () => void; className?: string };
export function AssociationField({
	id,
	name,
	label,
	hint,
	icon,
	onChange,
	...rest
}: Props) {
	return (
		<>
			<FormControl
				id={id}
				name={name}
				label={label}
				hint={hint}
				icon={icon}
				required={rest.required}
				disabled={rest.disabled}
			>
				{({ field, helper }) => (
					<AssociationSelect
						id={id}
						{...rest}
						{...field}
						association={field.value}
						setAssociation={(i) => {
							helper.setValue(i?.associationId);
						}}
					/>
				)}
			</FormControl>
		</>
	);
}
export function AssociationSelect({
	association,
	setAssociation,
	dontInclude,
	placeholderText,
	className,
	id,
	...rest
}: {
	association: AssociationDto | string | undefined;
	setAssociation: (association: AssociationDto | undefined) => void;
	dontInclude?: string[];
	placeholderText?: string;
	className?: string;
	isRequired?: boolean;
	id?: string;
}) {
	const associationClient = useClient(AssociationsClient);

	const { data: associations, isLoading } = useQuery({
		queryKey: ["associations"],
		queryFn: () =>
			associationClient
				.getAllAssociations(undefined, undefined, undefined, undefined)
				.then((r) => r.result),
	});
	// if (isLoading) <Spinner />;
	return (
		<select
			id={id}
			name={id}
			value={
				association
					? typeof association == "string"
						? association
						: association?.associationId
					: "placeholder"
			}
			className={classNames(
				"py-1 rounded px-2 border-gray-300 text-black focus:ring-indigo-600 w-full",
				className ?? ""
			)}
			onChange={(x) =>
				setAssociation(
					associations?.items.find(
						(y) => y.associationId == x.currentTarget.value
					)
				)
			}
			// {...rest}
		>
			<option value="placeholder" disabled={rest.isRequired}>
				{placeholderText ?? "Seleziona associazione"}
			</option>
			{associations?.items
				.filter((x) => !dontInclude?.includes(x.associationId))
				.map((x) => (
					<option
						value={x.associationId}
						key={"select" + x.associationId}
					>
						{x.name}
					</option>
				))}
		</select>
	);
}
