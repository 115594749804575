import { Text, Grid, Th, HStack, useColorMode } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { TbArrowsSort } from "react-icons/tb";
import { Sorting } from "./CsTable";
import { FaArrowUp } from "react-icons/fa";

export type Toggle = {
	state: "flat" | "down" | "up";
};

type Props<T> = {
	id?: keyof T;
	toggle?: Toggle;
	setSort?: (property: string | undefined) => void;
	sortIndex?: string;
	label: string;
	defaultSorting?: Sorting<T>;
	width?: number;
};

export function TableButton<T>({
	id,
	setSort,
	sortIndex,
	label,
	width,
	defaultSorting,
}: Props<T>) {
	const state = sortIndex?.charAt(0);
	const [toggle, setToggle] = useState<Toggle>({
		state: state == "+" ? "up" : state == "-" ? "down" : "flat",
	});
	const sameStyle = {};
	const { colorMode } = useColorMode();

	const isMine = id == sortIndex?.substring(1);
	function toggleState(dontToggle?: boolean) {
		if (setSort != undefined) {
			switch (toggle.state) {
				case "flat":
					setSort("+" + (id as string));
					setToggle({ state: "up" });
					break;
				case "up":
					setSort("-" + (id as string));
					setToggle({ state: "down" });
					break;
				case "down":
					setSort(undefined);
					setToggle({ state: "flat" });
					break;
			}
		}
	}
	function click() {
		if (toggleState != undefined) toggleState();
	}

	useEffect(() => {
		if (!isMine) {
			setToggle({ state: "flat" });
		}
	}, [sortIndex]);

	const [startDrag, setStartDrag] = useState(0);
	const [size, setSize] = useState(0);

	if (id == undefined)
		return (
			<>
				<Th
					// as={motion.div}
					// width={200 + size}
					// key={label + size}

					{...sameStyle}
					// style={{
					// 	position: "relative",
					// 	width: 200,
					// 	height: "100px",
					// 	transition: "all 0.3s",
					// }}
				>
					{label as string}
				</Th>
			</>
		);

	// function handleStart(event: React.DragEvent<HTMLDivElement>) {
	// 	console.log("start" + event.clientX);
	// 	setStartDrag(event.clientX);
	// 	// event.dataTransfer.types
	// }
	// function handleStopt(event: React.DragEvent<HTMLDivElement>) {
	// 	console.log("end:" + event.clientX);
	// 	setSize(200 + (event.clientX - startDrag));
	// 	console.log(200 + size);
	// 	// event.dataTransfer.types
	// }

	return (
		<>
			<Th {...sameStyle} p="0.0rem">
				<HStack
					// bg="white"
					px="1.5rem"
					onClick={click}
					cursor="pointer"
					bg="transparent"
				>
					<Text>{label}</Text>
					<Grid
						placeItems="center"
						// textAlign="center"
						ml="0.5rem"
						color={
							isMine
								? toggle?.state === "flat"
									? "gray"
									: toggle?.state === "up"
									? "green.400"
									: "red.400"
								: "gray"
						}
						fontSize={
							toggle?.state == "flat" ? "initial" : "0.8rem"
						}
						transform={
							toggle?.state === "flat"
								? " rotate(180deg)"
								: toggle?.state === "up"
								? " rotate(0deg)"
								: " rotate(180deg)"
						}
						transition="all 0.5s"
					>
						{toggle?.state == "flat" ? (
							<TbArrowsSort />
						) : (
							<FaArrowUp />
						)}
					</Grid>
				</HStack>
			</Th>
		</>
	);
}
