import { useClient } from "../../../components/UseClient";
import QuoteForm from "./QuoteForm";
import { AthletesClient, ManageQuoteDto, QuoteDto } from "../../../Api";
import CsModal from "../../../components/CsModal";
import { ModalProps } from "../../../utilities/typeUtils";
import useToastMutation from "../../../components/Hooks/useToastMutation";
import { Modal } from "../../../components/UI/Modal";
import { useEffect } from "react";

export function QuoteModal({
	isOpen,
	onClose,

	action = "create",
	athleteId,
	set,
	quote,
}: ModalProps & {
	athleteId?: string;
	quote?: QuoteDto;
	set: () => void;
}) {
	const client = useClient(AthletesClient);

	const { mutate: createMutation, isLoading } = useToastMutation(
		(values: ManageQuoteDto) => client.createQuote(athleteId, values),
		onClose,
		["quotes"],
		"Quota aggiunta"
	);
	const { mutate: updateMutation, isLoading: isUpdating } = useToastMutation(
		(values: ManageQuoteDto) => client.updateQuote(quote?.id!, values),
		onClose,
		["quotes"],
		"Quota modificata"
	);
	useEffect(() => {
		if (!isOpen) set();
	}, [isOpen]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={() => {
				onClose();
			}}
			title={(!!quote ? "Modifica" : "Crea") + " Quota"}
		>
			<QuoteForm
				isLoading={isLoading || isUpdating}
				action={action}
				initialValues={{
					import: !!quote ? quote?.import : 0,
					importDate: !!quote ? quote?.importDate : "",
					deadline: !!quote ? quote?.deadline : "",
				}}
				onSubmit={quote ? updateMutation : createMutation}
			/>
		</Modal>
	);
}
