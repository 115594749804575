import { useClient } from "../../../components/UseClient";

import {
	AthletesClient,
	ContestRequestClient,
	ContestRequestDto,
	ManageContestRequestDto,
	QuoteDto,
} from "../../../Api";
import CsModal from "../../../components/CsModal";
import { ModalProps } from "../../../utilities/typeUtils";
import useToastMutation from "../../../components/Hooks/useToastMutation";
import { Modal } from "../../../components/UI/Modal";
import { useEffect } from "react";
import MathesForm from "./MatchesForm";

export function MatchesModal({
	isOpen,
	onClose,
	action = "create",
	request,
}: ModalProps & {
	athleteId?: string;
	request: ContestRequestDto;
}) {
	const client = useClient(ContestRequestClient);

	const { mutate: updateMutation, isLoading: isUpdating } = useToastMutation(
		(values: ManageContestRequestDto) =>
			client.updateRequest(request?.id!, values),
		onClose,
		["requests"],
		"Info incontri modificati"
	);

	return (
		<Modal
			isOpen={isOpen}
			onClose={() => {
				onClose();
			}}
			title={
				"Incontri " +
				request.athlete.surname +
				" " +
				request.athlete.name
			}
		>
			<MathesForm
				isLoading={isUpdating}
				action={action}
				initialValues={{
					wonMatches: !!request ? request?.wonMatches : undefined,
					playedMatches: !!request
						? request?.playedMatches
						: undefined,
					ranking: !!request ? request?.ranking : undefined,
				}}
				onSubmit={updateMutation}
			/>
		</Modal>
	);
}
