import { ManageCourseDto, CourseDto, CoursesClient } from "../../../Api";
import { useClient } from "../../../components/UseClient";
import CsModal from "../../../components/CsModal";
import CourseForm from "./CourseForm";
import useToastMutation from "../../../components/Hooks/useToastMutation";
import { ModalProps } from "../../../utilities/typeUtils";

export default function CourseModal({
	isOpen,
	onClose,
	subsidiary,
}: ModalProps & { subsidiary?: CourseDto }) {
	const client = useClient(CoursesClient);

	const { mutate: createCourse, isLoading: isCourseing } = useToastMutation(
		(values: ManageCourseDto) => client.createCourse(values),
		onClose,
		["courses"],
		"Corso Aggiunto"
	);
	const { mutate: updateCourse, isLoading: isUpdating } = useToastMutation(
		(values: ManageCourseDto) =>
			client.updateCourse(subsidiary?.id!, values),
		onClose,
		["courses"],
		"Corso Modificato"
	);

	return (
		<CsModal
			title={!!subsidiary ? "Modifica Corso" : "Crea Corso"}
			isOpen={isOpen}
			onClose={onClose}
		>
			<CourseForm
				action={subsidiary ? "update" : "create"}
				onSubmit={!!subsidiary ? updateCourse : createCourse}
				isLoading={isCourseing || isUpdating}
				initialValues={{
					name: subsidiary ? subsidiary.name : "",
					description: subsidiary
						? subsidiary.description
						: undefined,
				}}
			/>
		</CsModal>
	);
}
